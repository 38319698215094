import React, { useState } from "react";
import styled from "styled-components";
import PreviousIcon from "../../assets/images/previous.svg";
import NextIcon from "../../assets/images/next.svg";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
`;

const Pages = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1.5rem;
`;

const PageButton = styled.div`
  height: 1.67rem;
  width: 1.67rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f2f2f;
  border-radius: 0.27rem;
  color: #f6f6f6;
  font-size: 0.97rem;
  cursor: pointer;
  margin: 0 0.2rem;
`;

const PageButtons = styled.div`
  display: flex;
`;

const EllipsisSpan = styled.span`
  color: #b0b0b0;
  margin: 0 0.2rem;
`;

const Text = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  color: #cccccc;
  font-size: 1.112rem;
  letter-spacing: 0.5px;
`;

const JumptoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.input`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  height: 1.67rem;
  width: 3.33rem;
  border: 0.15rem solid var(--grey-2, #b0b0b0);
  border-radius: 0.29rem;
  background: none;
  color: #b0b0b0;
  text-align: center;
  outline: none;
`;

const PaginationContainerV2 = ({
  totalCount,
  currentPage,
  onPageChange,
  pageSize,
}) => {
  const [jumpToPage, setJumpToPage] = useState("");

  const handlePageClick = (pageNumber) => {
    if (onPageChange) {
      onPageChange(pageNumber);
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  const handleJumpToPage = () => {
    const pageNumber = parseInt(jumpToPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageClick(pageNumber);
    }
    setJumpToPage("");
  };

  // Function to render page buttons with ellipsis logic
  const renderPageButtons = () => {
    if (totalPages <= 7) {
      // If 7 or fewer pages, show all page numbers
      return Array.from({ length: totalPages }, (_, index) => {
        const pageNumber = index + 1;
        return (
          <PageButton
            key={index}
            style={{
              backgroundColor:
                currentPage === pageNumber ? "#2F2F2F" : "#000000",
              fontFamily:
                currentPage === pageNumber
                  ? "Articulat CF Medium"
                  : "Articulat CF Normal",
              color: currentPage === pageNumber ? "#F6F6F6" : "#b0b0b0",
              fontWeight: "bold",
              letterSpacing: "0.5px",
            }}
            onClick={() => handlePageClick(pageNumber)}
          >
            {pageNumber}
          </PageButton>
        );
      });
    }

    // Logic for more than 7 pages
    const pageButtons = [];

    // Always show first page
    pageButtons.push(
      <PageButton
        key="first"
        style={{
          backgroundColor: currentPage === 1 ? "#2F2F2F" : "#000000",
          color: currentPage === 1 ? "#F6F6F6" : "#b0b0b0",
          fontFamily:
            currentPage === 1 ? "Articulat CF Medium" : "Articulat CF Normal",
          fontWeight: "bold",
          letterSpacing: "0.5px",
        }}
        onClick={() => handlePageClick(1)}
      >
        1
      </PageButton>,
    );

    // Add left ellipsis if current page is far from the start
    if (currentPage > 4) {
      pageButtons.push(<EllipsisSpan key="left-ellipsis">...</EllipsisSpan>);
    }

    // Determine the range of page numbers to show around the current page
    const startPage = Math.max(2, Math.min(currentPage - 1, totalPages - 5));
    const endPage = Math.min(totalPages - 1, Math.max(currentPage + 1, 6));

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <PageButton
          key={i}
          style={{
            backgroundColor: currentPage === 1 ? "#2F2F2F" : "#000000",
            color: currentPage === 1 ? "#F6F6F6" : "#b0b0b0",
            fontFamily:
              currentPage === 1 ? "Articulat CF Medium" : "Articulat CF Normal",
            fontWeight: "bold",
            letterSpacing: "0.5px",
          }}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </PageButton>,
      );
    }

    // Add right ellipsis if current page is far from the end
    if (currentPage < totalPages - 3) {
      pageButtons.push(<EllipsisSpan key="right-ellipsis">...</EllipsisSpan>);
    }

    // Always show last page
    pageButtons.push(
      <PageButton
        key="last"
        style={{
          backgroundColor: currentPage === totalPages ? "#b0b0b0" : "#2f2f2f",
          color: currentPage === totalPages ? "#4b4b4b" : "#b0b0b0",
          fontFamily:
            currentPage === totalPages
              ? "Articulat CF Medium"
              : "Articulat CF Normal",
          fontWeight: "bold",
          letterSpacing: "0.5px",
        }}
        onClick={() => handlePageClick(totalPages)}
      >
        {totalPages}
      </PageButton>,
    );

    return pageButtons;
  };

  return (
    <Container>
      <Pages>
        <PageButtons>
          <PageButton
            disabled={currentPage === 1}
            onClick={() =>
              currentPage > 1 && handlePageClick(Math.max(1, currentPage - 1))
            }
            style={{
              marginRight: "0.5rem",
              opacity: currentPage === 1 ? 0.4 : 1,
              pointerEvents: currentPage === 1 ? "none" : "auto",
            }}
          >
            <img
              src={PreviousIcon}
              alt=""
              style={{ height: "1.12rem", width: "1.12rem" }}
            />
          </PageButton>

          {renderPageButtons()}

          <PageButton
            style={{
              marginLeft: "0.5rem",
              opacity: currentPage === totalPages ? 0.4 : 1,
              pointerEvents: currentPage === totalPages ? "none" : "auto",
            }}
            onClick={() =>
              currentPage < totalPages &&
              handlePageClick(Math.min(totalPages, currentPage + 1))
            }
          >
            <img
              src={NextIcon}
              alt=""
              style={{ height: "1.12rem", width: "1.12rem" }}
            />
          </PageButton>
        </PageButtons>

        <JumptoContainer>
          <Text
            style={{
              marginLeft: "2.5rem",
              color: "#b0b0b0",
              fontFamily: "Articulat CF Medium",
              fontWeight: "bold",
              letterSpacing: "0.5px",
            }}
          >
            Jump to
          </Text>
          <InputContainer
            style={{ marginLeft: "0.5rem" }}
            value={jumpToPage}
            onChange={(e) => setJumpToPage(e.target.value)}
          />
          <PageButton
            style={{
              marginLeft: "0.5rem",
            }}
            onClick={handleJumpToPage}
          >
            <img
              src={NextIcon}
              alt=""
              style={{ height: "1.12rem", width: "1.12rem" }}
            />
          </PageButton>
        </JumptoContainer>
      </Pages>
    </Container>
  );
};

PaginationContainerV2.propTypes = {
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default PaginationContainerV2;
