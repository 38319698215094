import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import GenericModal from "../../../components/common/_genericModal";
import Text from "../../../components/common/_text";
import styled from "styled-components";
import CustomRow from "../../../components/common/_customRow";
import Column from "../../../components/common/_customColumn";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import PropTypes from "prop-types";

const CustomListItem = styled.li`
  ::marker {
    color: #b0b0b0;
    font-size: 0.5rem;
  }
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Normal";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: "bold";
  letter-spacing: 0.5px;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #b0b0b0;
`;

const DeleteAccountConfirmationModal = ({ isOpen, onClose }) => {
  const { logout, userFullName } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);

  const [confirmationText, setConfirmationText] = useState("");
  const [isApiLoading, setApiLoading] = useState(false);

  const handleDeleteClick = async () => {
    setApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/deleteAccount",
        { data: null },
      );
      setApiLoading(false);
      logout();
    } catch (err) {
      console.log("Error: deleteAccount", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to delete the account, please try again later");
      }
      setApiLoading(false);
    }
  };

  return (
    <GenericModal
      title={"Are you sure you want to delete your account?"}
      onClose={onClose}
      show={isOpen}
      disableCloseOnOutsideClick={isApiLoading}
      maxHeight={"70vh"}
    >
      <CustomRow>
        <CustomColumn>
          <Text
            $color={"#B0B0B0"}
            $fontSize={"1.25rem"}
            style={{
              letterSpacing: "0.03472rem",
              fontFamily: "Articulat CF Normal",
              fontWeight: "bold",
            }}
          >
            If you choose to delete your account, you will lose access to all
            the workspaces that you have created and joined. Please be certain
            about your decision
          </Text>
        </CustomColumn>
        <CustomColumn mb={2.77}>
          <ul
            style={{
              marginTop: "1rem",
              listStyleType: "none",
              paddingLeft: "0",
              fontFamily: "Articulat CF Normal",
              fontWeight: "bold",
            }}
          >
            <CustomListItem>
              <Text
                $color={"#B0B0B0"}
                $fontSize={"1.25rem"}
                style={{ letterSpacing: "0.03472rem" }}
              >
                - You will not be able to access condense without creating a new
                account
              </Text>
            </CustomListItem>
            <CustomListItem>
              <Text
                $color={"#B0B0B0"}
                $fontSize={"1.25rem"}
                style={{ letterSpacing: "0.03472rem" }}
              >
                - This action cannot be undone and the deleted content cannot be
                restored
              </Text>
            </CustomListItem>
          </ul>
        </CustomColumn>
        <CustomColumn mb={1.666}>
          <Text
            $color={"#f6f6f6"}
            $fontWeight={"bold"}
            $fontSize={"1.666rem"}
            $lineHeight={1}
          >{`Type '${userFullName}' to confirm`}</Text>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <InputFieldContainer>
            <InputField
              placeholder={`${userFullName}`}
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              readOnly={isApiLoading}
            />
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
            disabled={isApiLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            width="13.888rem"
            onClick={() => handleDeleteClick()}
            disabled={isApiLoading || confirmationText !== userFullName}
          >
            Delete Account
          </StyledPrimaryButton2>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};
DeleteAccountConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteAccountConfirmationModal;
