import React, { useMemo, useState } from "react";
import styled from "styled-components";
import ApplicationDeleteConfirmationDialog from "./ApplicationDeleteConfirmationDialog";
import { ReactComponent as ApplicationIcon } from "../../../assets/images/application_icon.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/images/card_setting_icon.svg";
import { ReactComponent as BackIcon } from "../../../assets/images/card_back_icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/card_edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/card_delete_icon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ApplicationEditDialog from "./ApplicationEditDialog";
import PropTypes from "prop-types";
import PermissionModal from "./PermissionModal";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";

const StyledApplicationIcon = styled(ApplicationIcon)`
  height: 2.222rem;
  width: 2.222rem;
  flex-shrink: 0;
  path {
    fill: ${({ $isUnpublished }) => ($isUnpublished ? "#EDB62E" : "#259D55")};
  }
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  height: 1.388rem;
  width: 1.388rem;
  cursor: pointer;
  flex-shrink: 0;
`;

const StyledBackIcon = styled(BackIcon)`
  height: 1.388rem;
  width: 1.388rem;
  cursor: pointer;
  flex-shrink: 0;
`;

const StyledEditIcon = styled(EditIcon)`
  height: 1.111rem;
  width: 1.111rem;
  cursor: pointer;
  flex-shrink: 0;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  height: 1.111rem;
  width: 1.111rem;
  cursor: pointer;
  flex-shrink: 0;
`;

const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.555rem;
  background-color: #2f2f2f;
`;

const TitleContainer = styled.div`
  padding: 0.833rem 0.833rem 0rem 0.833rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 0.555rem;
  margin-bottom: 0.972rem;
`;

const NameAndVersionSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.277rem;
  overflow: hidden;
`;

const ApplicationNameText = styled.p`
  margin: 0;
  font-size: 0.866rem;
  line-height: 1.25rem;
  font-style: ${({ $isUnpublished }) => ($isUnpublished ? "italic" : "normal")};
  color: ${({ $isUnpublished }) => ($isUnpublished ? "#B0B0B0" : "#F6F6F6")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const ApplicationVersionText = styled.p`
  margin: 0;
  font-size: 0.541rem;
  line-height: 0.694rem;
  color: #cccccc;
  font-style: ${({ $isUnpublished }) => ($isUnpublished ? "italic" : "normal")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const RepoAndStatusContainer = styled.div`
  display: flex;
  gap: 0.541rem;
  padding: 0rem 0.833rem;
  margin-bottom: ${({ $isSettingsOpen }) =>
    $isSettingsOpen ? "1.388rem" : "0.972rem"};
`;

const RepoNameAndApplicationStatusSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.277rem;
  overflow: hidden;
  flex: 1;
`;

const RepoAndStatusLabelText = styled.p`
  margin: 0;
  font-size: 0.541rem;
  line-height: 0.694rem;
  color: #777777;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const RepoAndStatusText = styled.p`
  margin: 0;
  font-size: 0.649rem;
  line-height: 0.833rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ $isUnpublished }) => ($isUnpublished ? "#EDB62E" : "#259D55")};
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const TimingsContainer = styled.div`
  padding: 0rem 0.833rem 0.833rem 0.833rem;
  display: flex;
  gap: 0.555rem;
`;

const LastVisitedAndExpiresOnSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.277rem;
  overflow: hidden;
  flex: 1;
`;

const EditAndDeleteContainer = styled.div`
  padding: 0.555rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ $isUnpublished }) =>
    $isUnpublished ? "#EDB62E66" : "#259D5566"};
  border-bottom-left-radius: 0.555rem;
  border-bottom-right-radius: 0.555rem;
`;

const EditDeleteButtonContainer = styled.div`
  display: flex;
  gap: 1.111rem;
  flex-shrink: 0;
`;

const TimingsText = styled.p`
  margin: 0;
  font-size: 0.649rem;
  line-height: 0.833rem;
  color: #ececec;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const ApplicationCard = ({ application, onDelete, refetchApplications }) => {
  // Hooks
  const history = useHistory();
  // State values
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isPermissionConfirmationOpen, setPermissionConfirmationOpen] =
    useState(false);
  const [contentTextVal, setContentTextVal] = useState("");
  const [titleTextVal, setTitleTextVal] = useState("");
  const [btnTextVal, setBtnTextVal] = useState("");
  const [isPermissionDisabled, setPermissionDisabled] = useState(false);

  // Format Date and memoize them
  const { lastVisitedOn, expireOn } = useMemo(() => {
    const expireOnOptions = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const lastVisitedOnOptions = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    const expiryDate = new Date(application.expireAt * 1000);
    const expireOn = expiryDate.toLocaleString("en-US", expireOnOptions);
    const lastVisitedDate = new Date(application.lastVisitedAt * 1000);
    const lastVisitedOn = lastVisitedDate.toLocaleString(
      "en-US",
      lastVisitedOnOptions,
    );
    return { expireOn, lastVisitedOn };
  }, [application]);

  const checkAppPermission = async (app) => {
    if (app.repositoryName) {
      let vcProvider = app?.vcProvider ?? "github";
      let response = await axiosAdapter(
        "GET",
        env.REACT_APP_URL + "version-control/getVcAuthDetails",
      );
      //this is to check version control
      let gitObjects = response?.data?.data[vcProvider]?.provider;
      if (gitObjects) {
        let payload2 = {
          applicationId: parseInt(application.id),
        };
        setBtnTextVal("Continue");
        let response2 = await axiosAdapter(
          "POST",
          env.REACT_APP_URL +
            "pipeline/checkUserHasRepoAndBranchAccessForApplication",
          payload2,
        );
        //this is to check repo and branch
        if (response2.data.code === 200) {
          history.push("/repoInformation", {
            isRedirected: true,
            applicationId: application.id,
          });
        } else {
          if (
            response2?.data?.remarks === "You do not have access to this branch"
          ) {
            setTitleTextVal(`You do not have access to the Branch`);
            setContentTextVal(
              `You cannot access this application because you don’t have access to the branch or it has been deleted. Please contact your admin for assistance.`,
            );
          } else if (
            response2?.data?.remarks ===
            "You do not have access to this repository"
          ) {
            setTitleTextVal(`You do not have access to the Repository`);
            setContentTextVal(
              `You cannot access this application because you don’t have access to the repository or it has been deleted. Please contact your admin for assistance.`,
            );
          } else {
            setTitleTextVal(`You cannot access this application`);
            setContentTextVal(
              `It seems something went wrong, Please try again after a while. If the issue persists, contact your admin for assistance.`,
            );
          }
          setPermissionConfirmationOpen(true);
          setPermissionDisabled(true);
        }
      } else {
        setPermissionDisabled(false);
        setTitleTextVal(`You are not connected to ${app.name}`);
        setContentTextVal(
          `You need to Connect to ${app.name} to access this application. Please go to settings to connect and continue.`,
        );
        setBtnTextVal("Go to Settings");
        setPermissionConfirmationOpen(true);
      }
    } else {
      history.push("/repoInformation", {
        isRedirected: true,
        applicationId: application.id,
      });
    }
  };

  return (
    <>
      <CardContainer
        onClick={() => {
          checkAppPermission(application);
        }}
      >
        <TitleContainer>
          <StyledApplicationIcon
            $isUnpublished={application.applicationStatus !== "Published"}
          />
          <NameAndVersionSection>
            <ApplicationNameText
              $isUnpublished={application.applicationStatus !== "Published"}
            >
              {application.name || "Application"}
            </ApplicationNameText>
            <ApplicationVersionText
              $isUnpublished={application.applicationStatus !== "Published"}
            >
              {application.version
                ? `Version ${application.version}`
                : `Version Number Not Available`}
            </ApplicationVersionText>
          </NameAndVersionSection>
          {isSettingsOpen ? (
            <StyledBackIcon
              onClick={(e) => {
                e.stopPropagation();
                setSettingsOpen(false);
              }}
            />
          ) : (
            <StyledSettingsIcon
              onClick={(e) => {
                e.stopPropagation();
                setSettingsOpen(true);
              }}
            />
          )}
        </TitleContainer>
        <RepoAndStatusContainer $isSettingsOpen={isSettingsOpen}>
          <RepoNameAndApplicationStatusSection>
            <RepoAndStatusLabelText>Repository Name</RepoAndStatusLabelText>
            <RepoAndStatusText
              $isUnpublished={application.applicationStatus !== "Published"}
            >
              {application.repositoryName ? application.repositoryName : "--"}
            </RepoAndStatusText>
          </RepoNameAndApplicationStatusSection>
          <RepoNameAndApplicationStatusSection>
            <RepoAndStatusLabelText>Application Status</RepoAndStatusLabelText>
            <RepoAndStatusText
              $isUnpublished={application.applicationStatus !== "Published"}
            >
              {application.applicationStatus}
            </RepoAndStatusText>
          </RepoNameAndApplicationStatusSection>
        </RepoAndStatusContainer>
        {isSettingsOpen ? (
          <EditAndDeleteContainer
            $isUnpublished={application.applicationStatus !== "Published"}
          >
            <LastVisitedAndExpiresOnSection>
              <TimingsText>{`Expires On: ${expireOn}`}</TimingsText>
            </LastVisitedAndExpiresOnSection>
            <EditDeleteButtonContainer>
              <StyledEditIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setEditDialogOpen(true);
                }}
              />
              <StyledDeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteConfirmationOpen(true);
                }}
              />
            </EditDeleteButtonContainer>
          </EditAndDeleteContainer>
        ) : (
          <TimingsContainer>
            <LastVisitedAndExpiresOnSection>
              <RepoAndStatusLabelText>Last Visited On</RepoAndStatusLabelText>
              <TimingsText>{lastVisitedOn}</TimingsText>
            </LastVisitedAndExpiresOnSection>
            <LastVisitedAndExpiresOnSection>
              <RepoAndStatusLabelText>Expires On</RepoAndStatusLabelText>
              <TimingsText>{expireOn}</TimingsText>
            </LastVisitedAndExpiresOnSection>
          </TimingsContainer>
        )}
      </CardContainer>
      <ApplicationDeleteConfirmationDialog
        dialogTitle={`Are you sure you want to delete ${
          application.name || "Unnamed Application"
        } ?`}
        onDialogClose={() => setDeleteConfirmationOpen(false)}
        showDeleteDialog={isDeleteConfirmationOpen}
        onDeleteClick={() => {
          onDelete(application.id);
          setDeleteConfirmationOpen(false);
        }}
      />
      <ApplicationEditDialog
        dialogTitle={`Edit ${application.name || "Unnamed Application"} ?`}
        onDialogClose={() => setEditDialogOpen(false)}
        showEditDialog={isEditDialogOpen}
        applicationNameProp={application.name || "Unnamed Application"}
        applicationExpiryBufferProp={application.expiryBuffer}
        applicationId={application.id}
        refetchApplications={refetchApplications}
      />
      <PermissionModal
        dialogTitle={titleTextVal}
        contentText={contentTextVal}
        onDialogClose={() => setPermissionConfirmationOpen(false)}
        showDeleteDialog={isPermissionConfirmationOpen}
        btnText={btnTextVal}
        isPermissionDisabled={isPermissionDisabled}
        onDeleteClick={() => {
          if (!isPermissionDisabled) {
            history.push({
              pathname: "/settings",
              search: `?section=codeRespository`,
            });
          }
        }}
      />
    </>
  );
};

ApplicationCard.propTypes = {
  application: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  refetchApplications: PropTypes.func.isRequired,
};

export default ApplicationCard;
