import {
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import BackIcon from "../../assets/images/left_arrow_icon.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import { getCurrentBranch, gitStatus } from "./utils";
import GenericModal from "../../components/common/_genericModal";
import useContainerDimensions from "../../hooks/useContainerDimentions";
import VSCodeUI from "./components/VSCodeUI";
import { ReactComponent as WarningIcon } from "../../assets/images/warning_yellow_icon.svg";
import { ReactComponent as DownIcon } from "../../assets/images/chevron_down.svg";
import { ReactComponent as UpIcon } from "../../assets/images/drop up proper.svg";
import KafkaBrokerDropDown from "../ApplicationsPage/BrokerDropDown";

// Constant values
const IMAGE_NAME_REGEX =
  /^(?:[a-z0-9.-]+\/)?[a-z0-9][a-z0-9._-]{0,254}(?:\/[a-z0-9][a-z0-9._-]{0,254})*$/;
const TAG_NAME_REGEX = /^[A-Za-z0-9][-A-Za-z0-9_.]{0,127}$/;
const IMAGE_TAG_REGEX =
  /^([a-z0-9._-]+(?:\/[a-z0-9._-]+)*)?(?::[a-z0-9_.-]{1,128})?$/i;

const StyledWarningIcon = styled(WarningIcon)`
  width: 1.666rem;
  height: 1.666rem;
  path {
    fill: #ea3a3a;
  }
`;

const MainContainer = styled.div`
  background-color: #171717;
  height: 100%;
  width: 100%;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;
  background-color: #171717;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 16.666rem;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 0.277rem;
  background-color: #2f2f2f;
  border-radius: 0.138rem;
  box-shadow: -0.555rem 0.277rem 0.625rem 0rem #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 0.277rem;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #f6f6f6;
  font-size: 1.67rem;
  margin: 0;
`;

const BrokerButton = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  width: 15.167rem;
  height: 3.33rem;
  border: 0.0694rem solid var(--white-1, #f6f6f6);
  border-radius: 0.83rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.12rem;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const StyledDownImage = styled(DownIcon)`
  height: 1.8rem;
  width: 1.8rem;
`;

const StyledUpImage = styled(UpIcon)`
  height: 1.8rem;
  width: 1.8rem;
`;

const BrokerText = styled.div`
  font-size: 1.12rem;
  font-weight: bold;
  color: #f6f6f6;
  letter-spacing: 0.5px;
  font-family: "Articulat CF Medium";
`;

const ModalSubtext = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  margin: 0;
  font-size: 1.25rem;
  color: #ececec;
  opacity: 0.7;
  letter-spacing: 0.5px;
`;

const ImageHeader = styled.div`
  display: flex;
  color: #b0b0b0;
  font-family: Articulat CF Normal;
  font-weight: bold;
  font-size: 0.84rem;
  padding-top: 1.112rem;
  padding-bottom: 0.4rem;
  letter-spacing: 0.5px;
`;

const ModalTextFieldOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ModalTextfieldContainer = styled.div`
  width: 100%;
  /* margin-top: 2.084rem; */
`;

const ModalWarningfieldContainer = styled.div`
  width: 100%;
  height: 0.5rem;
`;

const ModalTextfield = styled.input`
  padding: 0.7rem;
  outline: none;
  background: inherit;
  width: 100%;
  border-radius: 0.55rem;
  border: 0.138rem solid #909090;
  color: #ececec;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "auto")};
  font-size: 1.112rem;
  font-family: Articulat CF Normal;
  font-weight: bold;
  letter-spacing: 0.5px;
  &::placeholder {
    font-family: Articulat CF Normal;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #777777;
    font-size: 0.972rem;
  }
`;

const ModalButtonContainer = styled.div`
  margin-top: 2.78rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const ModalWarningText = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0rem;
  font-size: 0.972rem;
  color: #ea3a3a;
  font-style: italic;
  line-height: 1.083rem;
`;

const VSCodeEditor = () => {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const isRequestFromRestoreApp =
    location.state?.isRequestFromRestoreApp ?? false;

  // State variables
  const [isLoading, setLoading] = useState(true);
  const [podName, setPodName] = useState("");
  const [currentBranch, setCurrentBranch] = useState("");
  const [showBuildModal, setShowBuildModal] = useState(false);
  const [isBrokerOpen, setIsBrokerOpen] = useState(false);
  const [redirectToBuildPage, setRedirectToBuildPage] = useState(false);
  const [imageName, setImageName] = useState(location.state?.imageName || "");
  const [tagName, setTagName] = useState(location.state?.tag || "");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [broker, setBroker] = useState("");

  const [isImageNameValid, setImageNameValidity] = useState(
    !!location.state?.imageName,
  );
  const brokerRef = useRef(null);
  const [isTagNameValid, setTagNameValidity] = useState(!!location.state?.tag);

  const editorContainerRef = useRef(null);
  const editorContainerDimensions = useContainerDimensions(editorContainerRef);

  useEffect(() => {
    (async () => {
      try {
        let podDetailsResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getApplicationContainer",
          {
            applicationId: location.state.applicationId,
          },
        );
        if (
          podDetailsResponse.data &&
          podDetailsResponse.data.status === "Success"
        ) {
          let match = podDetailsResponse.data.data.podName.match(
            /devel-container-deployment-([^-\s]+)/,
          );
          if (match) {
            setPodName(match[1]);
            setLoading(false);
            try {
              await axiosAdapter(
                "POST",
                env.REACT_APP_URL + "pipeline/updateApplicationLastVisit",
                {
                  applicationId: location.state?.applicationId,
                },
              );
            } catch (err) {
              console.error(
                "Failed to update last visit time for application",
                err,
              );
              showMessage("Failed to update last visit time for application");
            }
          } else {
            throw new Error("Could not find the pod name");
          }
        } else {
          throw new Error("Unsuccess response for the pod details request");
        }
      } catch (err) {
        console.log("Error: getApplicationContainer", err);
        showMessage("Error fetching pod details");
        history.push("/applications");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchKafkaCredentials = async () => {
    try {
      const response = await axiosAdapter(
        "GET",
        `${env.REACT_APP_URL}kafka-management/getKafkaCredentials`,
        {},
      );
      if (response?.data) {
        setBroker(response.data.data.bootstrapServer);
        setPassword(response.data.data.password);
        setUsername(response.data.data.username);
      }
    } catch (error) {
      showMessage(
        error.response?.data?.remarks ||
          "An error occurred while fetching Kafka credentials",
        "error",
      );
    }
  };

  useEffect(() => {
    if (!isBrokerOpen) return; // Only add event listener when dropdown is open

    const handleClickOutside = (event) => {
      if (brokerRef.current && !brokerRef.current.contains(event.target)) {
        setIsBrokerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isBrokerOpen]);

  useEffect(() => {
    fetchKafkaCredentials();
  }, []);

  if (!location.state?.isRedirected) return <Redirect to={{ pathname: "/" }} />;

  // Redirect to Build page where all the require props are collected for build process and user clicks build in the Modal
  if (
    redirectToBuildPage ||
    (location.state?.applicationStatus === 3 && location.state?.userConfigs)
  ) {
    return (
      <Redirect
        to={{
          pathname: "/buildLogs",
          state: {
            isRedirected: true,
            imageName: imageName,
            tagName: tagName,
            repoName: location.state?.repositoryName,
            branch: currentBranch,
            username: location.state?.gitUsername,
            applicationId: location.state?.applicationId,
            oldUserConfigs: location.state?.userConfigs,
            authProvider: location.state?.authProvider,
            openUserConfigs:
              location.state?.applicationStatus === 3 &&
              location.state?.userConfigs,
            buildId: location.state?.buildId,
            isRequestFromRestoreApp: isRequestFromRestoreApp,
            oldIconFile: location.state?.icon_file,
            oldApplicationName: location.state?.applicationName,
            oldApplicationVersion: location.state?.applicationVersion,
            oldDescription: location.state?.description,
            isIngressRequired: location?.state?.isIngressRequired,
          },
        }}
      />
    );
  }

  const handleToggle = () => {
    setIsBrokerOpen((prev) => !prev);
  };
  return (
    <>
      {isLoading && (
        <ParentContainer>
          <AnimationContainer>
            <ScrollAnimationBar />
            <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
          </AnimationContainer>
        </ParentContainer>
      )}
      <MainContainer
        style={isLoading ? { visibility: "hidden" } : { visibility: "visible" }}
      >
        <CustomRow
          style={{
            display: "flex",
            padding: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Column
            style={{
              display: "flex",
              gap: "1.112rem",
              maxWidth: "60rem",
              minWidth: "41rem",
              flex: 1,
            }}
          >
            <button
              onClick={() => history.push("/applications")}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
              aria-label="Go Back"
            >
              <img
                src={BackIcon}
                alt="Go Back"
                style={{
                  height: "2.225rem",
                  width: "2.225rem",
                  marginTop: "0.138rem",
                }}
              />
            </button>
            <Text
              style={{
                fontFamily: "Articulat CF Medium",
                fontWeight: "bold",
              }}
            >
              Configure your{" "}
              {location.state?.applicationName ?? "New Application"}
            </Text>
          </Column>
          <Column
            style={{
              display: "flex",
              gap: "1.112rem",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            {!isRequestFromRestoreApp && (
              <BrokerButton onClick={handleToggle}>
                <BrokerText>Broker Credentials</BrokerText>
                <IconWrapper>
                  {isBrokerOpen ? <StyledUpImage /> : <StyledDownImage />}
                </IconWrapper>
              </BrokerButton>
            )}

            <StyledButtonPrimary
              style={{ width: "14.167rem" }}
              onClick={async () => {
                try {
                  setLoading(true);
                  let status = await gitStatus({
                    applicationId: location.state?.applicationId,
                  });
                  if (status) {
                    let currentBranch = await getCurrentBranch({
                      applicationId: location.state?.applicationId,
                      repoName: location.state?.repositoryName,
                    });
                    if (currentBranch && currentBranch.status === "Success") {
                      setCurrentBranch(currentBranch.branchName);
                      setLoading(false);
                      setShowBuildModal(true);
                    } else {
                      showMessage(
                        "Failed to obtain the current branch",
                        "error",
                      );
                      setLoading(false);
                    }
                  } else {
                    setLoading(false);
                    showMessage(
                      "Seems like your codebase has uncommitted code or isn't in sync with the remote repository",
                    );
                  }
                } catch (err) {
                  setLoading(false);
                  console.log("Error: gitStatus", err);
                  showMessage(
                    "Failed to obtain the current status of the codebase",
                    "error",
                  );
                }
              }}
            >
              Publish Application
            </StyledButtonPrimary>
          </Column>
        </CustomRow>

        <Column
          style={{
            height: "calc(100vh - 9.2rem)",
            display: "flex",
            flexDirection: "column",
          }}
          ref={editorContainerRef}
        >
          {!isLoading && (
            <VSCodeUI
              height={editorContainerDimensions.height}
              width={editorContainerDimensions.width}
              podName={podName}
              repoName={location.state?.repositoryName || ""}
              applicationId={location.state?.applicationId}
              authProvider={location.state?.authProvider}
            />
          )}
        </Column>
      </MainContainer>
      <GenericModal
        show={showBuildModal}
        title={"Add details to build your Application"}
        disableCloseOnOutsideClick={true}
        onClose={() => setShowBuildModal(false)}

        // maxHeight="60vh"
      >
        {/* <ModalContent> */}

        <ModalSubtext>
          One last step before we can build your application
        </ModalSubtext>
        <CustomRow
          style={{
            width: "100%",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ModalTextFieldOuterContainer
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              gap: "1.699rem",
              justifyContent: "space-between",
            }}
          >
            <Column>
              <ImageHeader
                style={{
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Image name
              </ImageHeader>
              <ModalTextfieldContainer>
                <ModalTextfield
                  placeholder="Example: condense-service_1"
                  value={imageName}
                  disabled={isRequestFromRestoreApp}
                  onChange={(e) => {
                    setImageName(e.target.value);
                    // setImageTagValidity(
                    //   IMAGE_TAG_REGEX.test(e.target.value + ":" + tagName),
                    // );
                    setImageNameValidity(IMAGE_NAME_REGEX.test(e.target.value));
                  }}
                />
              </ModalTextfieldContainer>
            </Column>
            <Column>
              <ImageHeader
                style={{
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Tag
              </ImageHeader>
              <ModalTextfieldContainer>
                <ModalTextfield
                  placeholder="Example: 1.0.1"
                  value={tagName}
                  onChange={(e) => {
                    setTagName(e.target.value);
                    // setImageTagValidity(
                    //   IMAGE_TAG_REGEX.test(imageName + ":" + e.target.value),
                    // );
                    setTagNameValidity(TAG_NAME_REGEX.test(e.target.value));
                  }}
                />
              </ModalTextfieldContainer>
            </Column>
          </ModalTextFieldOuterContainer>
        </CustomRow>
        <ModalWarningfieldContainer>
          {(!isImageNameValid && imageName.length > 0) ||
          (!isTagNameValid && tagName.length > 0) ? (
            <ModalTextfieldContainer
              style={{
                marginTop: "0rem",
                paddingTop: "0.55rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.555rem",
              }}
            >
              <StyledWarningIcon />
              <ModalWarningText>
                {!isImageNameValid
                  ? "Invalid image name format"
                  : "Invalid tag format"}
              </ModalWarningText>
            </ModalTextfieldContainer>
          ) : null}
        </ModalWarningfieldContainer>
        <ModalButtonContainer>
          <StyledButtonsecondary
            onClick={() => setShowBuildModal(false)}
            style={{ width: "14.167rem" }}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            style={{ width: "14.167rem" }}
            disabled={
              !isImageNameValid ||
              !isTagNameValid ||
              imageName.length === 0 ||
              tagName.length === 0
            }
            onClick={() => setRedirectToBuildPage(true)}
          >
            Build Application
          </StyledButtonPrimary>
        </ModalButtonContainer>
        {/* </ModalContent> */}
      </GenericModal>
      {isBrokerOpen && (
        <div ref={brokerRef}>
          <KafkaBrokerDropDown
            username={username}
            password={password}
            broker={broker}
          />
        </div>
      )}
    </>
  );
};

export default VSCodeEditor;
