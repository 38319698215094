import { ReactComponent as Avataar_1 } from "../../assets/images/Avataar_1.svg";
import { ReactComponent as Avataar_2 } from "../../assets/images/Avataar_2.svg";
import { ReactComponent as Avataar_3 } from "../../assets/images/Avataar_3.svg";
import { ReactComponent as Avataar_4 } from "../../assets/images/Avataar_4.svg";
import { ReactComponent as Avataar_5 } from "../../assets/images/Avataar_5.svg";
import { ReactComponent as Avataar_6 } from "../../assets/images/Avataar_6.svg";
import { ReactComponent as Avataar_7 } from "../../assets/images/Avataar_7.svg";
import { ReactComponent as Avataar_8 } from "../../assets/images/Avataar_8.svg";
import { ReactComponent as Avataar_10 } from "../../assets/images/Avataar_10.svg";
import { ReactComponent as Avataar_11 } from "../../assets/images/Avataar_11.svg";
import { ReactComponent as Avataar_12 } from "../../assets/images/Avataar_12.svg";
import { ReactComponent as Avataar_13 } from "../../assets/images/Avataar_13.svg";
import { ReactComponent as Avataar_14 } from "../../assets/images/Avataar_14.svg";
import { ReactComponent as Avataar_15 } from "../../assets/images/Avataar_15.svg";

export const workspaceAvatarMap = {
  "Avataar_1.svg": Avataar_1,
  "Avataar_2.svg": Avataar_2,
  "Avataar_3.svg": Avataar_3,
  // "Avataar_4.svg": Avataar_4,
  "Avataar_5.svg": Avataar_5,
  "Avataar_6.svg": Avataar_6,
  "Avataar_7.svg": Avataar_7,
  "Avataar_8.svg": Avataar_8,
  "Avataar_10.svg": Avataar_10,
  "Avataar_11.svg": Avataar_11,
  "Avataar_12.svg": Avataar_12,
  "Avataar_13.svg": Avataar_13,
  "Avataar_14.svg": Avataar_14,
  "Avataar_15.svg": Avataar_15,
};
