import { getSmoothStepPath, EdgeLabelRenderer, BaseEdge } from "reactflow";
import { ReactComponent as RightArrowIcon } from "../../../assets/images/pipeline_right_arrow_icon.svg";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledRightArrowIcon = styled(RightArrowIcon)`
  height: 10px;
  width: 10px;
  transform: ${(props) => (props.isReversed ? "rotate(180deg)" : "none")};
  & path {
    stroke: ${(props) => props.edgeTypeColor};
  }
`;

const TopicEdge = ({ id, ...props }) => {
  const isReversed = props.sourceX > props.targetX; // Check direction

  // Calculate the midpoint between source and target
  const midX = (props.sourceX + props.targetX) / 2;
  const midY = (props.sourceY + props.targetY) / 2;

  // Use smoother path for all edges
  const [edgePath, labelX] = getSmoothStepPath({
    ...props,
    borderRadius: 12,
    centerX: midX,
    centerY: midY,
  });

  // Position the label at the midpoint of the path with slight adjustment
  // This ensures all topic labels appear centered on their paths
  const labelPosition = {
    // Center the label horizontally on the path
    x: midX,
    // Position just slightly above the midpoint (adjust as needed)
    y: midY - 15,
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{ strokeWidth: "1px", stroke: "#B0B0B0" }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelPosition.x}px, ${labelPosition.y}px)`,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
            zIndex: 1000, // Ensure labels appear above paths
          }}
        >
          <div
            style={{
              fontSize: "10px",
              fontFamily: "Articulat CF Medium",
              fontWeight: "bold",
              color: "#ECECEC",
              lineHeight: "1",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <StyledRightArrowIcon
              edgeTypeColor={props.data.edgeTypeColor}
              isReversed={isReversed}
            />
            <span style={{ color: props.data.edgeTypeColor }}>Topic</span>
          </div>
          <div
            style={{
              backgroundColor: "#0D0D0D",
              border: "1px solid #B0B0B0",
              padding: "4px 10px",
              borderRadius: "4px",
              fontSize: "10px",
              fontFamily: "Articulat CF Medium",
              fontWeight: "bold",
              color: "#ECECEC",
              lineHeight: "15.6px",
              maxWidth: "220px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {props.label}
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

TopicEdge.propTypes = {
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  data: PropTypes.shape({
    isCommonEdge: PropTypes.bool,
    edgeTypeColor: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  targetY: PropTypes.number.isRequired,
};

export default TopicEdge;
