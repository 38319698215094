import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import ConversionPath from "../../assets/images/cable_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import ElectricCable from "../../assets/images/electrical_services_FILL0_wght400_GRAD0_opsz24 1.svg";
import MergeIcon from "../../assets/images/merge_FILL0_wght400_GRAD0_opsz24 1.svg";
import RecentreIcon from "../../assets/images/recenter_FILL0_wght400_GRAD0_opsz24 1.svg";
import RouteIcon from "../../assets/images/route_FILL0_wght400_GRAD0_opsz24 1.svg";
import TripIcon from "../../assets/images/trip_origin_FILL0_wght400_GRAD0_opsz24 1.svg";
import ZoomIcon from "../../assets/images/zoom_in_map_FILL0_wght400_GRAD0_opsz24 1.svg";
import CheckIcon from "../../assets/images/check_circle_FILL0_wght400_GRAD0_opsz24 1.svg";
import PublishApplicationModal from "./publishApplicationModal";
import GenericModal from "../common/_genericModal";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import PropTypes from "prop-types";
import Column from "../common/_customColumn";
import CustomRow from "../common/_customRow";

import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";

const MAX_CHARACTERS = 130;
const MAX_VERSION_CHARACTERS = 30;
const link = env.REACT_APP_S3_BUCKET;

const icons = [
  { src: ConversionPath, alt: "ConversionPath" },
  { src: ElectricCable, alt: "ElectricCable" },
  { src: ZoomIcon, alt: "ZoomIcon" },
  { src: RecentreIcon, alt: "RecentreIcon" },
  { src: TripIcon, alt: "TripIcon" },
  { src: RouteIcon, alt: "RouteIcon" },
  { src: MergeIcon, alt: "MergeIcon" },
];

const InputFieldContainer = styled.div`
  padding: 0.972rem 0.833rem;
  width: 100%;
  border-radius: 0.555rem;
  border: 0.138rem solid #777777;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputField = styled.input`
  font-family: "Articulat CF Normal";
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: 0.972rem;
  line-height: 1.111rem;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;

  &::placeholder {
    color: #b0b0b0;
    font-weight: bold;
  }
`;

const DescriptionContainer = styled.div`
  position: relative;
`;

const CharacterCount = styled.span`
  position: absolute;
  right: 0.833rem;
  bottom: 0.833rem;
  font-size: 0.972rem;
  color: #b0b0b0;
`;

const ModalTextArea = styled.textarea`
  width: 100%;
  padding: 0.694rem;
  outline: none;
  resize: none;
  border-radius: 0.555rem;
  border: 0.138rem solid #777777;
  font-family: "Articulat CF Normal";
  letter-spacing: 0.5px;
  font-size: 0.972rem;
  font-weight: bold;
  line-height: 1.111rem;
  color: #f6f6f6;
  background-color: inherit;

  &::placeholder {
    color: #b0b0b0;
    font-weight: bold;
  }
`;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  color: #f6f6f6;
  font-size: 1.25rem;
  line-height: 1.555rem;
  margin: 0;
`;

const CheckMark = styled.img`
  position: absolute;
  bottom: 2.77rem;
  left: 2.77rem;
  width: 1.67rem;
  height: 1.67rem;
  display: ${({ selected }) => (selected ? "block" : "none")};
`;

const LogoBackground = styled.div`
  height: 3.89rem;
  width: 3.89rem;
  border-radius: 0.84rem;
  background: ${({ selected }) =>
    selected ? "rgba(71, 204, 214, 0.4)" : "#777777"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const StyledIcon = styled.img`
  cursor: "pointer";
  height: 3rem;
  width: 3rem;
`;

/**
 * Renders a modal for publishing a connector application.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isModelOpen - Whether the modal is open or not.
 * @param {function} props.setIsModalOpen - Function to toggle the modal open/closed.
 * @param {string} props.buildId - The ID of the build.
 * @param {string} props.applicationId - The ID of the application.
 * @param {string} props.customConnectorIconFile - The name of the custom icon file.
 * @param {string} props.customApplicationName - The name of the previousApplication.
 * @param {string} props.customApplicationVersion - The version of the previousApplication.
 * @param {string} props.customApplicationDescription - The description of the previousApplication.
 * @return {JSX.Element} The rendered PublishConnectorModal component.
 */

const PublishConnectorModal = ({
  isModelOpen,
  setIsModalOpen,
  buildId,
  applicationId,
  customConnectorIconFile,
  customApplicationName,
  customApplicationVersion,
  customApplicationDescription,
  isRequestFromRestoreApp = false,
}) => {
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const [applicationName, setApplicationName] = useState(
    customApplicationName || "",
  );
  const [version, setVersion] = useState(customApplicationVersion || "");
  const [text, setText] = useState(customApplicationDescription || "");
  const [secondModal, setSecondModal] = useState(false);
  const { showMessage } = useContext(SnackbarContext);
  const APPLICATION_NAME_REGEX = /^[a-zA-Z0-9- ]+$/;
  const VERSION_REGEX = /^[a-zA-Z]?[0-9]*([._-][0-9]*)*$/;
  const handleChange = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS) {
      setText(e.target.value);
    }
  };

  const handleVersionChange = (e) => {
    const inputValue = e.target.value;

    if (
      inputValue.length === 0 ||
      (inputValue.length <= MAX_VERSION_CHARACTERS &&
        VERSION_REGEX.test(inputValue))
    ) {
      setVersion(inputValue);
    }
    if (inputValue.length > MAX_VERSION_CHARACTERS) {
      showMessage("Version cannot be more than 30 characters");
    }
    // } else {
    //   showMessage("Invalid version format");
    // }
  };

  useEffect(() => {
    if (customConnectorIconFile) {
      setSelectedIconIndex(
        icons.findIndex((icon) => customConnectorIconFile.includes(icon.alt)),
      );
    }
  }, [customConnectorIconFile]);

  const handleIconClick = (index) => {
    setSelectedIconIndex(index);
  };
  const handleSecondModal = () => {
    setSecondModal(true);
  };

  const handlePublish = async () => {
    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");

    try {
      let stringifyData = {
        workspaceId: parseInt(selectedWorkspaceId),
        applicationName: applicationName,
        applicationVersion: version,
        applicationId: applicationId,
        buildId,
        description: text,
        icon_file: `${icons[selectedIconIndex].alt}.svg`,
      };

      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/publishApplication",
        stringifyData,
      );

      handleSecondModal();
    } catch (err) {
      console.log("Error: publishApplication", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to Publish Application !!!");
      }
    }
  };

  return (
    <GenericModal
      onClose={() => {}}
      show={isModelOpen}
      title={"Are you sure you want to publish?"}
      maxHeight={"85vh"}
    >
      <Text
        style={{
          color: "#ECECEC",
          fontSize: "1.25rem",
          lineHeight: "1.55rem",
          opacity: "0.7",
          marginBottom: "2.225rem",
          letterSpacing: "0.5px",
          fontFamily: "Articulat CF Normal",
          fontWeight: "bold",
        }}
      >
        If you choose to publish this application, everyone in your team will be
        able to access this application and use it in their pipelines
      </Text>
      <CustomRow style={{ rowGap: "1.111rem" }}>
        <Column style={{ width: "calc(50% - 0.555rem)" }}>
          <InputFieldContainer>
            <InputField
              placeholder="Name Your Application"
              onChange={(e) => {
                const inputValue = e.target.value;
                if (
                  APPLICATION_NAME_REGEX.test(inputValue) ||
                  inputValue === ""
                ) {
                  setApplicationName(inputValue);
                } else {
                  showMessage(
                    "Invalid Application Name , no specials characters except hyphen allowed",
                  );
                }
              }}
              value={applicationName}
              disabled={isRequestFromRestoreApp}
            />
            <Text
              style={{
                marginLeft: "0.555rem",
                fontSize: "0.972rem",
                lineHeight: "1.111rem",
                color: "#B0B0B0",
                opacity: "0.7",
                textWrap: "nowrap",
                overflow: "visible",
                whiteSpace: "normal",
                textOverflow: "clip",
                letterSpacing: "0.5px",
                fontFamily: "Articulat CF Normal",
                fontWeight: "bold",
              }}
            ></Text>
          </InputFieldContainer>
        </Column>

        <Column style={{ width: "calc(50% - 0.555rem)" }}>
          <InputFieldContainer>
            <InputField
              placeholder="Add a Version Number - Example: v1.0.0"
              value={version}
              onChange={handleVersionChange}
            />
            <Text
              style={{
                marginLeft: "0.555rem",
                fontSize: "0.972rem",
                lineHeight: "1.111rem",
                color: "#B0B0B0",
                opacity: "0.7",
              }}
            >
              {version.length}/{MAX_VERSION_CHARACTERS}
            </Text>
          </InputFieldContainer>
        </Column>
        <Column xs={12} style={{ marginBottom: "0.486rem" }}>
          <DescriptionContainer>
            <ModalTextArea
              placeholder="Describe Your Application"
              value={text}
              onChange={handleChange}
              rows={4}
            />
            <CharacterCount>
              {text.length}/{MAX_CHARACTERS}
            </CharacterCount>
          </DescriptionContainer>
        </Column>
        <Column xs={2.6} style={{ alignSelf: "flex-start" }}>
          <Text
            style={{
              marginRight: "1.4583rem",
              color: "#f6f6f6",
              letterSpacing: "0.5px",
              fontFamily: "Articulat CF Normal",
              fontWeight: "bold",
            }}
          >
            Choose an icon to display
          </Text>
        </Column>

        <Column xs={9.3} style={{ marginBottom: "1.777rem" }}>
          <CustomRow
            style={{
              display: "flex",
              gap: "1.9rem",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {icons.map((icon, index) => (
              <LogoBackground
                key={icon}
                onClick={() => handleIconClick(index)}
                selected={selectedIconIndex === index}
                style={{ cursor: "pointer" }}
              >
                <StyledIcon
                  src={icon.src}
                  alt={icon.alt}
                  selected={selectedIconIndex === index}
                />
                <CheckMark
                  src={CheckIcon}
                  selected={selectedIconIndex === index}
                />
              </LogoBackground>
            ))}
          </CustomRow>
        </Column>

        <Column
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={() => setIsModalOpen(!isModelOpen)}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            width="13.888rem"
            disabled={
              !(applicationName.trim() && version.trim() && text.trim())
            }
            onClick={() => handlePublish()}
          >
            Publish Application
          </StyledButtonPrimary>
        </Column>
      </CustomRow>

      {secondModal && (
        <PublishApplicationModal
          isModelOpen={secondModal}
          hightLightWord={applicationName}
          connectorName={applicationName}
          version={version}
          description={text}
          iconFile={`${link}Connector-Images/${icons[selectedIconIndex].alt}.svg`}
        />
      )}
    </GenericModal>
  );
};

PublishConnectorModal.propTypes = {
  isModelOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  buildId: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  customConnectorIconFile: PropTypes.string,
  customApplicationName: PropTypes.string,
  customApplicationVersion: PropTypes.string,
  customApplicationDescription: PropTypes.string,
  isRequestFromRestoreApp: PropTypes.bool.isRequired,
};

PublishConnectorModal.defaultProps = {
  customConnectorIconFile: null,
  customApplicationName: "",
  customApplicationVersion: "",
  customApplicationDescription: "",
};

export default PublishConnectorModal;
