import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import connector from "../../assets/images/cable_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import pipeline from "../../assets/images/account_tree_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import applications from "../../assets/images/Applicationsapplications.svg";
import ksql from "../../assets/images/ksql-white.svg";
import SettingsIcon from "../../assets/images/setting_logout.svg";
import routes from "./RoutePaths";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import { AuthContext } from "../../context/AuthContext";

const Logo = styled.img`
  height: 1.672rem;
  width: 1.672rem;
  margin-right: 0.84rem;
  line-height: 2.1rem;
  filter: ${(props) =>
    props.isActive || props.isHovered
      ? "brightness(0) saturate(100%) invert(69%) sepia(81%) saturate(398%) hue-rotate(143deg) brightness(93%) contrast(88%)"
      : "none"};
`;

const MenuItem = styled.div`
  align-items: center;
  background: ${(props) => (props.isActive ? "#47CCD629" : "#2F2F2F")};
  border: ${(props) =>
    props.isActive
      ? "0.1rem solid var(--Primary-Accent, #47CCD6)"
      : "0.1rem solid transparent"};
  border-radius: ${(props) => (props.isActive ? "0.55rem" : "0")};
  box-sizing: border-box;
  color: ${(props) => (props.isActive ? "#47ccd6" : "#fff")};
  cursor: pointer;
  display: flex;
  height: 3.05rem;
  line-height: 2.1rem;
  margin: 0.8rem 0.7rem 0.8rem 0.8rem;
  overflow: hidden;
  padding: 0.7rem;
  position: relative;
  width: 14.3722rem;

  &:hover {
    background: transparent;
    border: 0.1rem solid #47ccd6;
    border-radius: 0.55rem;
    box-sizing: border-box;
    color: #47ccd6;
    height: 3.05rem;
    margin: 0.8rem 0.7rem 0.8rem 0.8rem;
    padding: 0.7rem;
    width: 14.3722rem;
  }
  &:active {
    background: #1e565a;
  }

  @media (max-width: 53.333rem) {
    width: calc(15.5562rem - 1.184rem);
  }
`;
const StyledSidebar = styled.div`
  background: #2f2f2f;
  border-right: 0.1rem solid #4b4b4b;
  height: calc(100vh - 3.95rem);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.112rem;
  font-family: "Articulat CF Medium";
  height: 1.2rem;
  justify-content: flex-start;
  line-height: 2.89rem;
  width: 9.861rem;
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const routesNames = [
  {
    name: "Pipelines",
    path: routes.CONNECTORS_PLAYGROUND,
    Logo: pipeline,
  },
  {
    name: "Connectors",
    path: routes.CONNECTORS,
    Logo: connector,
  },
  {
    name: "Applications",
    path: routes.APPLICATIONS,
    Logo: applications,
  },
  {
    name: "KSQL",
    path: routes.KSQL,
    Logo: ksql,
  },
];

const SideBar = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedRoute, setSelectedRoute] = useState(location.pathname);
  const [hoveredRoute, setHoveredRoute] = useState(null);
  const [isKSQLAllowed, setIsKSQLAllowed] = useState(false);
  const { selectedWorkspaceData } = useContext(AuthContext);
  const handleClick = (route) => {
    setSelectedRoute(route);
    history.push(route);
  };

  const isUserWorkspaceViewer = async (workspaceId) => {
    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/getUserWorkspaceRole",
        {
          workspaceId: parseInt(workspaceId),
        },
      );

      if (response.data.data.role !== "VIEW") {
        setIsKSQLAllowed(true);
      } else {
        setIsKSQLAllowed(false);
      }
      return response.data.data.role === "VIEW";
    } catch (error) {
      console.log("Unable to access workspace role");
      setIsKSQLAllowed(false);
      return false;
    }
  };

  useEffect(() => {
    const workspaceId = localStorage.getItem("selectedWorkspaceId");
    if (!workspaceId) {
      setIsKSQLAllowed(false);
      return;
    }
    isUserWorkspaceViewer(workspaceId);
  }, [selectedWorkspaceData]);

  return (
    <StyledSidebar>
      <div>
        {routesNames.length > 0 &&
          routesNames.map((route, index) => {
            if (route.name === "KSQL" && !isKSQLAllowed) {
              return null;
            }
            return (
              <MenuItem
                key={route.path}
                isActive={
                  selectedRoute === route.path ||
                  (selectedRoute === "/createApplication"
                    ? setSelectedRoute("/applications")
                    : null) ||
                  (selectedRoute === "/repoInformation"
                    ? setSelectedRoute("/applications")
                    : null)
                }
                onClick={() => handleClick(route.path)}
                onMouseEnter={() => setHoveredRoute(route.path)}
                onMouseLeave={() => setHoveredRoute(null)}
                style={{ marginTop: "1.1rem" }}
              >
                <Logo
                  src={route.Logo}
                  alt="Logo"
                  isActive={selectedRoute === route.path}
                  isHovered={hoveredRoute === route.path}
                />
                <Text>{route.name}</Text>
              </MenuItem>
            );
          })}
      </div>
      <div>
        <MenuItem
          isActive={selectedRoute === "/settings"}
          onClick={() => handleClick("/settings")}
          onMouseEnter={() => setHoveredRoute("/settings")}
          onMouseLeave={() => setHoveredRoute(null)}
          style={{ marginBottom: "1.4rem" }}
        >
          <Logo
            src={SettingsIcon}
            alt="Logo"
            isActive={selectedRoute === "/settings"}
            isHovered={hoveredRoute === "/settings"}
          />
          <Text>Settings</Text>
        </MenuItem>
      </div>
    </StyledSidebar>
  );
};

export default SideBar;
