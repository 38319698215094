import React, { forwardRef, useContext } from "react";
import styled from "styled-components";
import FlexContainer from "../common/_flexContainer";
import Text from "../common/_text";
import { AuthContext } from "../../context/AuthContext";
import Tooltip from "../common/_tooltip";
import { ReactComponent as SettingsIcon } from "../../assets/images/setting_logout.svg";
import { useHistory } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "../../assets/images/LogoutIcon.svg";
import moment from "moment";

const FlexContainerForDropdown = styled(FlexContainer)`
  background: #2f2f2f;
  width: 16.666rem;
  border: 0.069rem solid #5e5e5e;
  border-radius: 0.833rem;
  box-shadow: 0.277rem 0.277rem 1.111rem 0.277rem #0000001c;
  z-index: 1;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;
const Chip = styled(FlexContainer)`
  width: 2.777rem;
  height: 2.777rem;
  border-radius: 0.277rem;
  background: #4b4b4b;
  place-content: center;
`;
const FlexItem = styled(FlexContainer)`
  width: 100%;
  padding: 0.555rem;
  justify-content: flex-start;
  gap: 0.833rem;
  border-radius: 0.555rem;
  cursor: pointer;
  &:hover {
    background: #4b4b4b;
  }
`;
const SettingsIconStyled = styled(SettingsIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;
const LogoutIconStyled = styled(LogoutIcon)`
  width: 1.666rem;
  height: 1.666rem;
`;
const Divider = styled.div`
  width: 100%;
  border-top: 0.069rem solid #5e5e5e;
`;
const InlineText = styled(Text)`
  display: inline;
`;

const LoginAvatarDropdownContent = forwardRef((props, ref) => {
  const { loginTime, logout, userFullName, username, userEmail } =
    useContext(AuthContext);
  const history = useHistory();

  return (
    <FlexContainerForDropdown {...props} ref={ref}>
      <FlexContainer
        $width="100%"
        $padding="1.111rem"
        $justifyContent="flex-start"
        $gap="0.833rem"
      >
        <Chip>
          <Text $fontWeight="bold" $fontSize="1.666rem" $color="#CCCCCC">
            {userFullName.substring(0, 1).toUpperCase()}
          </Text>
        </Chip>
        <FlexContainer
          $width="calc(100% - 2.777rem - 0.833rem)"
          $height="2.777rem"
          $flexWrap="nowrap"
          $flexDirection="column"
          $justifyContent="space-between"
          $alignItems="flex-start"
        >
          <Tooltip title={userFullName}>
            <Text
              $width="min-content"
              $maxWidth="100%"
              $fontWeight="bold"
              $fontSize="1.111rem"
              $noWrap
            >
              {userFullName}
            </Text>
          </Tooltip>
          <Tooltip title={userEmail}>
            <Text
              $width="min-content"
              $maxWidth="100%"
              $fontSize="0.833rem"
              $fontFamily="Articulat CF Normal"
              $fontWeight="bold"
              $letterSpacing="0.5px"
              $color="#777777"
              $noWrap
              style={{ letterSpacing: "0" }}
            >
              {userEmail}
            </Text>
          </Tooltip>
        </FlexContainer>
        <FlexContainer $width="100%" $gap="0.277rem">
          <FlexItem onClick={() => history.push("/settings")}>
            <SettingsIconStyled />
            <Text $fontWeight="bold">Settings</Text>
          </FlexItem>
          <FlexItem onClick={() => logout()}>
            <LogoutIconStyled />
            <Text $fontWeight="bold" $color="#EA3A3A">
              Logout
            </Text>
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
      <Divider />
      <FlexContainer
        $width="100%"
        $padding="1.111rem"
        $justifyContent="flex-start"
      >
        <Text
          $fontSize="0.833rem"
          $color="#777777"
          $fontFamily="Articulat CF Normal"
          $fontWeight="bold"
          $letterSpacing="0.5px"
        >
          {`Last logged in at `}
          <InlineText $fontSize="0.833rem" $color="#CCCCCC">
            {moment.unix(loginTime).format("hh:mm A")}
          </InlineText>
        </Text>
      </FlexContainer>
    </FlexContainerForDropdown>
  );
});

export default LoginAvatarDropdownContent;
