import React, { useContext, useState } from "react";
import styled from "styled-components";
import GenericSelection from "../common/_dropDownComponent";
import GenericModal from "../common/_genericModal";
import warning_icon from "../../assets/images/warning_yellow_icon.svg";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import PropTypes from "prop-types";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";

import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";

const Label = styled.div`
  color: #b0b0b0;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.972rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.55rem;
  margin-bottom: 1.112rem;
`;

const CreateNewTopicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -0.7rem;
  gap: 0.55rem;
  letter-spacing: 0px;
`;

const CreateButton = styled.span`
  font-family: Articulat CF Medium;
  line-height: 1.45rem;
  font-size: 1.112rem;
`;

const StyledInput = styled.input`
  display: flex;
  align-items: center;
  height: 3.33rem;
  padding-left: 0.8rem;
  outline: none;
  background: #2f2f2f;
  width: 100%;
  border-radius: 0.84rem;
  border: 0.069rem solid #777777;
  color: #ececec;
  font-size: 1.112rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  &:focus {
    border: 0.069rem solid #f6f6f6;
  }
  &::placeholder {
    font-family: "Articulat CF Normal";
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const WarningText = styled.div`
  margin-top: 0.7rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.041rem;
  font-style: italic;
  line-height: 1.112rem;
  display: flex;
  align-items: center;
  color: #edb62e;
`;

const ModalButtonContainer = styled.div`
  margin-top: 2.084rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.972rem;
  line-height: 1.4rem;
  padding-left: 0.55rem;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #b0b0b0;
  }
  &[autoComplete="off"] {
    box-shadow: none;
  }
`;

const InputFieldContainer = styled.div`
  padding: 1.112rem 0.8rem;
  width: 100%;
  border-radius: 0.84rem;
  border: 0.069rem solid #f6f6f6;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NonTelematicsInputForm = ({
  configs,
  setConfigs,
  allTopics,
  setAllTopics,
  setPreviewData,
  connectorVersionList,
  setSelectedConnectorVersion,
  selectedConnectorVersion,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [newTopicNamesMapper, setNewTopicNamesMapper] = useState({}); // mapper to store the new topicnames entered
  const [newTopicModalMapper, setNewTopicModalMapper] = useState({}); // mapper to store the state of new topic modal
  const [showPassMapper, setShowPassMapper] = useState({}); // mapper to store the state of visibility of password
  const [showOldPassword, setShowOldPassword] = useState(false);

  // Function to create a new topic and store it if the response is successful
  const createNewTopic = async (index, newTopicName) => {
    try {
      let topicCreationResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "kafka-management/createTopic",
        JSON.stringify({
          topicName: newTopicName,
        }),
      );
      if (topicCreationResponse.data.status === "SUCCESS") {
        setAllTopics((prev) => {
          let tempTopics = [...prev, { name: newTopicName }];
          return tempTopics;
        });
        setConfigs((prev) => {
          let tempConfigs = [...prev];
          tempConfigs[index] = {
            ...tempConfigs[index],
            value: { name: newTopicName },
          };
          return tempConfigs;
        });
        setPreviewData((prev) => {
          let prevData = prev;
          prevData.interfaces[index] = {
            name: newTopicName,
            direction: configs[index].direction,
          };
          return prevData;
        });
        setNewTopicNamesMapper((prev) => {
          let tempMap = { ...prev, [index]: null };
          return tempMap;
        });
        setNewTopicModalMapper((prev) => {
          let tempMap = { ...prev, [index]: false };
          return tempMap;
        });
      } else {
        console.log(
          "New topic addition failed",
          topicCreationResponse.data.message,
        );
        showMessage(topicCreationResponse.data.message);
      }
    } catch (err) {
      console.log("Err createTopic", err);
      showMessage(
        err.response?.data?.remarks || "Error in creating new topic",
        "error",
      );
    }
  };

  return (
    <>
      {connectorVersionList.length && selectedConnectorVersion ? (
        <InputContainer>
          <Label>Custom Connector Version</Label>
          <GenericSelection
            dropdownItems={connectorVersionList}
            selectedItem={selectedConnectorVersion}
            placeholder={"Select a Connector Version"}
            onSelect={(item) => {
              setSelectedConnectorVersion(item);
            }}
            nameFunction={(item) => item}
            filterFunction={(arr, text) =>
              arr.filter((item) =>
                item.toLowerCase().includes(text.toLowerCase()),
              )
            }
            searchPlaceHolder={"Search for Versions"}
          />
        </InputContainer>
      ) : null}
      {configs?.map((field, index) => {
        if (field.type === "topic") {
          return (
            <InputContainer key={field.id}>
              <Label>
                {`${field.label} (${field.direction})`}
                {field.isRequired && " *"}
              </Label>
              <GenericSelection
                dropdownItems={
                  Array.isArray(allTopics)
                    ? allTopics.map((topic) =>
                        typeof topic === "string" ? { name: topic } : topic,
                      )
                    : []
                }
                selectedItem={field.value}
                placeholder={"Select a Topic"}
                onSelect={(item) => {
                  let tempConfigs = [...configs];
                  tempConfigs[index] = {
                    ...tempConfigs[index],
                    value: item,
                  };
                  setConfigs(tempConfigs);
                }}
                nameFunction={(item) => item.name}
                filterFunction={(arr, text) =>
                  arr.filter((item) =>
                    item?.name?.toLowerCase().includes(text.toLowerCase()),
                  )
                }
                searchPlaceHolder={"Search for Topics"}
              />
              <CreateNewTopicContainer>
                <CreateButton
                  style={{
                    color: "#777777",
                    fontFamily: "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  Can't Find the Topic?
                </CreateButton>
                <CreateButton
                  style={{
                    color: "#CCCCCC",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontFamily: "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                  onClick={() =>
                    setNewTopicModalMapper((prev) => {
                      let tempMap = { ...prev, [index]: true };
                      return tempMap;
                    })
                  }
                >
                  Create a New Topic
                </CreateButton>
              </CreateNewTopicContainer>
              <GenericModal
                onClose={() =>
                  setNewTopicModalMapper((prev) => {
                    let tempMap = { ...prev, [index]: false };
                    return tempMap;
                  })
                }
                show={newTopicModalMapper[index] || false}
                title={"Create a New Topic"}
              >
                <StyledInput
                  placeholder="Add a New Topic Name"
                  value={newTopicNamesMapper[index] || ""}
                  onChange={(e) =>
                    setNewTopicNamesMapper((prev) => {
                      let tempNewTopicMap = {
                        ...prev,
                        [index]: e.target.value,
                      };
                      return tempNewTopicMap;
                    })
                  }
                />
                <WarningText>
                  <img
                    src={warning_icon}
                    alt="warning_icon"
                    style={{
                      marginRight: "0.347rem",
                    }}
                  />
                  A New Topic with this name will be created and available for
                  use
                </WarningText>
                <ModalButtonContainer>
                  <StyledButtonsecondary
                    style={{ width: "14.167rem" }}
                    onClick={() =>
                      setNewTopicModalMapper((prev) => {
                        let tempMap = { ...prev, [index]: false };
                        return tempMap;
                      })
                    }
                  >
                    Cancel
                  </StyledButtonsecondary>
                  <StyledButtonPrimary
                    style={{ width: "14.167rem" }}
                    disabled={!newTopicNamesMapper[index]?.length}
                    onClick={() =>
                      createNewTopic(index, newTopicNamesMapper[index])
                    }
                  >
                    Create Topic
                  </StyledButtonPrimary>
                </ModalButtonContainer>
              </GenericModal>
            </InputContainer>
          );
        } else
          return (
            <InputContainer key={field.id}>
              <Label htmlFor={field.name}>
                {field.label}
                {field.isRequired && " *"}
              </Label>
              <InputFieldContainer
                style={
                  field.type === "password"
                    ? { padding: "0.84rem 0.84rem" }
                    : {}
                }
              >
                <InputField
                  type={
                    field.type === "password"
                      ? showPassMapper[index]
                        ? "text"
                        : field.type
                      : field.type === "port"
                        ? "number"
                        : field.type
                  }
                  id={field.name}
                  name={field.name}
                  placeholder={field.placeHolder}
                  required={field.isRequired}
                  value={field.value || ""}
                  autoComplete="one-time-code"
                  onChange={(e) => {
                    if (field.label === "Title") {
                      if (
                        /^[a-z0-9][a-z0-9.-]*$/.test(
                          e.target.value.toLowerCase(),
                        ) ||
                        e.target.value === ""
                      ) {
                        e.target.value = e.target.value.toLowerCase();
                      } else {
                        showMessage(
                          "Only lowercase alphanumeric characters along with [-.] are allowed and cannot start with [-.]",
                          "error",
                        );
                        return;
                      }
                    }
                    let tempConfigs = [...configs];
                    tempConfigs[index] = {
                      ...tempConfigs[index],
                      value: e.target.value,
                      ...(field.label === "Title"
                        ? {
                            name: "title",
                          }
                        : {}),
                    };
                    setConfigs(tempConfigs);
                    if (field.label === "Title") {
                      setPreviewData((prev) => ({
                        ...prev,
                        title: e.target.value,
                      }));
                    }
                  }}
                  min={field.type === "port" ? field.min : undefined}
                  max={field.type === "port" ? field.max : undefined}
                />
                {field.type === "password" && (
                  <img
                    src={
                      showOldPassword ? DontShowPasswordIcon : ShowPasswordIcon
                    }
                    alt="Show Password"
                    onClick={() => {
                      let tempMap = { ...showPassMapper };
                      tempMap[index] = !tempMap[index];
                      setShowPassMapper(tempMap);
                      setShowOldPassword(!showOldPassword);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "0.347rem",
                      height: "1.437rem",
                      width: "1.458rem",
                    }}
                  />
                )}
              </InputFieldContainer>
            </InputContainer>
          );
      })}
    </>
  );
};

NonTelematicsInputForm.propTypes = {
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      type: PropTypes.oneOf(["topic", "password", "port", "text"]).isRequired,
      label: PropTypes.string.isRequired,
      direction: PropTypes.string,
      isRequired: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ]),
      name: PropTypes.string,
      placeHolder: PropTypes.string,
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  ).isRequired,
  setConfigs: PropTypes.func.isRequired,
  allTopics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setAllTopics: PropTypes.func.isRequired,
  setPreviewData: PropTypes.func.isRequired,
  connectorVersionList: PropTypes.arrayOf(PropTypes.string),
  setSelectedConnectorVersion: PropTypes.func,
  selectedConnectorVersion: PropTypes.string,
};

NonTelematicsInputForm.defaultProps = {
  connectorVersionList: [],
  setSelectedConnectorVersion: () => {},
  selectedConnectorVersion: null,
};

export default NonTelematicsInputForm;
