import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import SQLEditor from "./SQLEditor";
import SQLResult from "./SQLResult";
import SQLStreams from "./KSQLStreams";
import SQLTables from "./KSQLTables";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import loadingAnimation from "../../assets/images/Loading Animation.gif";

const Container = styled.div`
  padding: 1.2rem;
  width: 100%;
  height: calc(100vh - 3.95rem);
  background-color: #0d0d0d;
  overflow-x: scroll;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  margin: 0;
  color: #f6f6f6;
  font-size: 1.666rem;
  font-weight: bold;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
  display: flex;
  gap: 1rem;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 3.95rem);
  background-color: #171717;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 16.666rem;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 0.277rem;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 0.277rem;
  background-color: #2f2f2f;
  border-radius: 0.138rem;
  box-shadow: -0.555rem 0.277rem 0.625rem 0rem #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

function KSQLPage() {
  const history = useHistory();
  const [ksqlJsonResponse, setKsqlJsonResponse] = useState();
  const [ksqlError, setKsqlError] = useState("");
  const [isKSQLLoading, setIsKSQLLoading] = useState(true);

  const isUserWorkspaceViewer = async (workspaceId) => {
    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/getUserWorkspaceRole",
        {
          workspaceId: parseInt(workspaceId),
        },
      );

      return response.data.data.role === "VIEW";
    } catch (error) {
      console.log("Unable to access workspace role");
      return false;
    }
  };

  useEffect(() => {
    const checkUserAccess = async () => {
      setIsKSQLLoading(true);
      const workspaceId = localStorage.getItem("selectedWorkspaceId");

      if (!workspaceId) {
        history.push("/");
        return;
      }

      // Check if user is a viewer and redirect if they are
      const isViewer = await isUserWorkspaceViewer(workspaceId);
      if (isViewer) {
        history.push("/");
        return;
      }

      setIsKSQLLoading(false);
    };

    checkUserAccess();
  }, [history]);

  if (isKSQLLoading) {
    return (
      <ParentContainer>
        <AnimationContainer>
          <ScrollAnimationBar />
          <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
        </AnimationContainer>
      </ParentContainer>
    );
  }

  return (
    <Container>
      <CustomColumn mb={1.111}>
        <Text>KSQL</Text>
      </CustomColumn>
      <SQLEditor
        setJSONData={setKsqlJsonResponse}
        setKSQLError={setKsqlError}
      />
      <SQLResult ksqlJsonResponse={ksqlJsonResponse} ksqlError={ksqlError} />
      <CustomColumn>
        <SQLStreams {...ksqlJsonResponse} />
        <SQLTables {...ksqlJsonResponse} />
      </CustomColumn>
    </Container>
  );
}

export default KSQLPage;
