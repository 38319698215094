import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const SpinnerPrimary = styled.div`
  border: 0.275rem solid #5e5e5e;
  border-top: 0.275rem solid #47ccd6;
  border-left: 0.275rem solid #47ccd6;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  /* top: 3rem; */
  animation: ${spin} 0.7s linear infinite;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 0.25rem;
    height: 0.25rem;
    background-color: #47ccd6;
    border-radius: 50%;
  }
  &::before {
    top: -0.01rem;
    left: calc(83% + 0rem);
    transform: translateX(0%);
  }
  &::after {
    left: -0.01rem;
    top: calc(83% + 0rem);
    transform: translateY(0%);
  }
`;

const Spinner = () => {
  return (
    <div>
      <SpinnerContainer>
        <SpinnerPrimary />
      </SpinnerContainer>
    </div>
  );
};

export default Spinner;
