import React from "react";
import styled from "styled-components";
import NonTelematicsInputForm from "./streamStoreConnectorConfigFormInput";
import TelematicsInputForm from "./telematicsConnectorConfigInputForm";
import PropTypes from "prop-types";

const Container = styled.div`
  padding-right: 1.736rem;
  height: 36rem;
  border-radius: 0.84rem;
  background-color: #2f2f2f;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  ::-webkit-scrollbar {
    width: 0.2777rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 0.1388rem;
  }

  ::-webkit-scrollbar-button {
    width: 0.2777rem;
    height: 0.6944rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0rem 1.67rem 1.67rem 1.67rem;
`;

const HeadingContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #2f2f2f;
  z-index: 2;
  padding: 1.67rem;
`;

const Heading = styled.div`
  font-size: 1.67rem;
  text-align: left;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: #2f2f2f;
  padding-right: 1.736rem;
`;

const ConfigForm = ({
  deviceData,
  configs,
  setConfigs,
  allTopics,
  setAllTopics,
  setPreviewData,
  connectorVersionList,
  setSelectedConnectorVersion,
  selectedConnectorVersion,
  disabled,
}) => {
  return (
    <Container>
      <HeadingContainer>
        <Heading>Add your Configurations</Heading>
      </HeadingContainer>
      <Content style={{ pointerEvents: disabled ? "none" : "auto" }}>
        {deviceData?.length ? (
          <TelematicsInputForm
            configs={configs}
            setConfigs={setConfigs}
            deviceData={deviceData}
            setPreviewData={setPreviewData}
            allTopics={allTopics}
            setAllTopics={setAllTopics}
          />
        ) : (
          <NonTelematicsInputForm
            configs={configs}
            setConfigs={setConfigs}
            allTopics={allTopics}
            setAllTopics={setAllTopics}
            setPreviewData={setPreviewData}
            connectorVersionList={connectorVersionList}
            setSelectedConnectorVersion={setSelectedConnectorVersion}
            selectedConnectorVersion={selectedConnectorVersion}
          />
        )}
      </Content>
    </Container>
  );
};

ConfigForm.propTypes = {
  deviceData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  configs: PropTypes.object.isRequired,
  setConfigs: PropTypes.func.isRequired,
  allTopics: PropTypes.array.isRequired,
  setAllTopics: PropTypes.func.isRequired,
  setPreviewData: PropTypes.func.isRequired,
  connectorVersionList: PropTypes.array,
  setSelectedConnectorVersion: PropTypes.func,
  selectedConnectorVersion: PropTypes.string,
};

export default ConfigForm;
