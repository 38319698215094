import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import up_icon from "../../assets/images/up_icon.svg";
import down_icon from "../../assets/images/down_icon.svg";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as AddIcon } from "../../assets/images/AddRule.svg";
import ArrowRight from "../../assets/images/ArrowRight.svg";
import ArrowLeft from "../../assets/images/ArrowLeft.svg";
import search_icon from "../../assets/images/search_icon.svg";
import LockIcon from "../../assets/images/LockSimple.svg";
import CloseIcon from "../../assets/images/Clear.svg";
import Tooltip from "../../components/common/_tooltip";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.noMargin ? "0" : "1.112rem")};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: #fafafa;
  font-size: 1.112rem;
  font-weight: bold;
  padding: 1.111rem 0px;
`;

const IconContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.67rem;
  height: 1.67rem;
  // margin-left: 0.5rem;
  margin-right: 0.5rem;

  img {
    width: 100%;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.67rem;
  height: 1.67rem;
  margin-left: 0.5rem;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.label`
  font-weight: bold;
  font-family: "Articulat CF Medium";
  margin-left: 0.347rem;
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: "Articulat CF Medium";
  margin-right: 0.06rem;
`;

const Dropdown = styled.div`
  padding: 0.55rem 0.88rem;
  font-size: 0.972rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  width: ${(props) => (props.widthValue ? props.widthValue : "100%")};
  height: ${(props) => (props.heightValue ? props.heightValue : "")};
  border-radius: ${(props) =>
    props.isFromUtilSetupJson ? "0rem 0.6rem 0.6rem 0rem" : "0.6rem"};
  border: ${(props) =>
    props.isShowRedBorder
      ? "0.069rem solid #EA3A3A"
      : props.isFromUtilSetupJson
        ? ""
        : props.disabled
          ? "0.069rem solid #0D0D0D"
          : "0.069rem solid #0D0D0D"};
  cursor: ${(props) => (props.disabled && "not-allowed") || "pointer"};
  background-color: #0d0d0d;
  color: ${(props) => (props.disabled ? "#4B4B4B" : "#CCCCCC")};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OptionContainer = styled.div`
  background-color: #2f2f2f;
  border-radius: 0.6rem;
  border: ${(props) =>
    props.noMargin ? "0.069rem solid #777777" : "0.1rem solid #909090"};
  box-sizing: border-box;
  position: absolute;
  width: ${(props) => (props.isFromUtilSetupJson ? props.widthValue : "20rem")};
  z-index: 1000; /* Ensure it appears above other elements */
  transform-origin: top;

  ${(props) => {
    switch (props.dropdownPosition) {
      case "right":
        return "left: 100%; margin-left: 0.5rem; top: 0;";
      case "left":
        return "right: 100%; margin-right: 0.5rem; top: 0;";
      case "top":
        return "bottom: 100%; margin-bottom: 0.5rem;";
      case "bottom":
      default:
        return "top: 100%; margin-top: 0.5rem;";
    }
  }}
`;

const Divider = styled.div`
  height: 0.138rem;
  background-color: #4b4b4b;
`;

const DropdownOptionsContainer = styled.div`
  max-height: 14.45rem;
  background-color: #2f2f2f;
  overflow-y: auto;
  border-radius: 0.6rem;
`;

const DropdownOption = styled.div`
  padding: 0.55rem 0.7rem;
  cursor: pointer;
  color: #cccccc;
  font-size: 0.972rem;
  font-weight: bold;
  line-height: 1.51rem;
  background-color: #2f2f2f;

  &:hover {
    background-color: #4b4b4b;
    border-radius: 0.5rem;
  }
`;

const SearchFieldContainer = styled.div`
  padding-left: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:focus-within {
    ${IconContainer} img {
      filter: brightness(0) invert(1);
    }
  }
`;

const StyledInput = styled.input`
  padding: 0.55rem;
  width: 100%;
  border-top-right-radius: 0.84rem;
  background-color: #2f2f2f;
  border: none;
  color: #cccccc;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.112rem;
  line-height: 1.51rem;
  text-align: justify;
  box-sizing: border-box;
  outline: none;

  &::placeholder {
    color: #5e5e5e;
    font-family: "Articulat CF Normal";
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 1.112rem;
    line-height: 1.51rem;
    text-align: justify;
  }
`;

const StyledLabel = styled.div`
  font-size: 1.112rem;
  /* font-weight: bold; */
  line-height: 1.51rem;
  text-align: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
`;

const AddGroup = styled.span`
  display: flex;
  align-items: center; /* Align icon and text */
  gap: 0.4rem; /* Add spacing between icon and text */
`;

const CustomRightDropdown = ({
  icon = "",
  dropdownItems,
  selectedItem,
  title,
  placeholder,
  loading,
  onSelect,
  nameFunction,
  filterFunction,
  searchPlaceHolder,
  disable,
  noMargin,
  openOnHover,
  widthValue,
  heightValue,
  isFromUtilSetupJson,
  dropdownPositionValue = "right",
  isShowCreateTopic = false,
  isShowRedBorder = false,
  isShowLockIcon = false,
}) => {
  const [filter, setFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState(
    dropdownPositionValue,
  );
  const [dropdownPositionVal, setDropdownPositionVal] = useState("");

  const dropdownRef = useRef(null);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleMouseEnter = () => {
    if (openOnHover && !disable) {
      setIsOpen(true);
    }
  };
  const handleMouseLeave = () => {
    if (openOnHover && !disable) {
      setIsOpen(false);
      setFilter("");
    }
  };

  const handleDropdownClick = () => {
    if (!disable) {
      const dropdown = dropdownRef.current;
      if (dropdown) {
        const rect = dropdown.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        // Calculate available space below and above the dropdown button
        const spaceBelow = windowHeight - rect.bottom;
        const spaceAbove = rect.top;

        // Calculate available space to the right and left of the dropdown button
        const spaceRight = windowWidth - rect.right;
        const spaceLeft = rect.left;

        // Determine the position of the dropdown options
        let topPosition = rect.bottom; // Default: below the dropdown button
        let leftPosition = rect.left; // Default: align with the left of the dropdown button

        // If there's not enough space below, show above the dropdown button
        let checkVal = isShowCreateTopic ? 250 : 200;
        if (spaceBelow < checkVal && spaceAbove > spaceBelow) {
          topPosition = rect.top - checkVal; // Adjust height as needed
        }

        // If there's not enough space to the right, align to the left
        if (spaceRight < 500 && spaceLeft > spaceRight) {
          leftPosition = rect.right - 500; // Adjust width as needed
        }

        setDropdownPositionVal({
          top: topPosition,
          left: rect.right,
        });
      }
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (item) => {
    if (!disable) {
      onSelect(item);
      setIsOpen(false);
      setFilter("");
    }
  };

  useEffect(() => {
    setFilteredItems(filterFunction(dropdownItems, filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, dropdownItems]);

  const setValueEmpty = () => {
    if (!isShowLockIcon && !disable) {
      onSelect("");
    }
  };

  const getTruncatedText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <Container
      noMargin={noMargin}
      widthValue={widthValue}
      heightValue={heightValue}
      isFromUtilSetupJson={isFromUtilSetupJson}
      dropdownPosition={dropdownPosition}
    >
      {title && (
        <TitleContainer>
          <Title style={{ fontSize: "1.111rem", lineHeight: "1" }}>
            {title}
          </Title>
        </TitleContainer>
      )}
      <SelectionContainer onMouseLeave={handleMouseLeave}>
        <Dropdown
          ref={dropdownRef}
          onClick={handleDropdownClick}
          disabled={disable}
          onMouseEnter={handleMouseEnter}
          noMargin={noMargin}
          onMouseLeave={handleMouseLeave}
          widthValue={widthValue}
          heightValue={heightValue}
          isFromUtilSetupJson={isFromUtilSetupJson}
          dropdownPosition={dropdownPosition}
          isShowRedBorder={isShowRedBorder}
        >
          <span style={{ display: "flex" }}>
            {selectedItem && !isShowLockIcon && !disable && (
              <IconContainer2>
                <img
                  src={CloseIcon}
                  alt="up_icon"
                  style={{ height: "1rem", width: "1rem" }}
                  onClick={setValueEmpty}
                />
              </IconContainer2>
            )}
            {dropdownPositionValue === "right" ? (
              selectedItem && nameFunction(selectedItem)?.length > 40 ? (
                <Tooltip title={nameFunction(selectedItem)} placement="top">
                  <span>
                    {getTruncatedText(nameFunction(selectedItem), 40)}
                  </span>
                </Tooltip>
              ) : (
                <>{selectedItem ? nameFunction(selectedItem) : placeholder}</>
              )
            ) : (
              <>{selectedItem ? nameFunction(selectedItem) : placeholder}</>
            )}
          </span>
          {loading ? (
            <Spinner style={{ height: "1.39rem", width: "1.39rem" }} />
          ) : isShowLockIcon ? (
            <IconContainer>
              <img
                src={LockIcon}
                alt="up_icon"
                style={{ height: "1.38rem", width: "1.38rem" }}
              />
            </IconContainer>
          ) : (
            <IconContainer>
              {isOpen ? (
                <img
                  src={dropdownPosition === "right" ? ArrowLeft : up_icon}
                  alt="up_icon"
                  style={{ height: "1.39rem", width: "1.39rem" }}
                />
              ) : (
                <img
                  src={dropdownPosition === "right" ? ArrowRight : down_icon}
                  alt="down_icon"
                  style={{ height: "1.39rem", width: "1.39rem" }}
                />
              )}
            </IconContainer>
          )}
        </Dropdown>
        {isOpen && (
          <>
            <div
              type="button"
              tabIndex={0}
              aria-label="Close dropdown"
              onClick={() => {
                setIsOpen(false);
                setFilter("");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space") {
                  setIsOpen(false);
                  setFilter("");
                }
              }}
              style={{
                zIndex: 99,
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: 0,
                left: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            />

            <OptionContainer
              style={{
                position: "fixed",
                top: dropdownPositionVal.top,
                left: dropdownPositionVal.left,
                zIndex: 1000,
              }}
              noMargin={noMargin}
              onMouseEnter={handleMouseEnter}
              isFromUtilSetupJson={isFromUtilSetupJson}
              widthValue={widthValue}
              dropdownPosition={dropdownPosition}
              dropdownPositionVal={dropdownPositionVal}
            >
              <SearchFieldContainer>
                <IconContainer>
                  <img src={search_icon} alt="search_icon" />
                </IconContainer>
                <StyledInput
                  placeholder={searchPlaceHolder || placeholder}
                  value={filter}
                  onChange={handleFilterChange}
                />
              </SearchFieldContainer>
              <Divider />
              <DropdownOptionsContainer>
                {/* {dropdownPosition === "right" && (
                  <DropdownOption
                    style={{
                      cursor: "default",
                      pointerEvents: "none",
                      marginLeft: "0.5rem",
                      fontSize: "0.85rem",
                    }}
                  >
                    Select
                  </DropdownOption>
                )} */}
                {filteredItems.length ? (
                  filteredItems.map((item, index) => (
                    <DropdownOption
                      style={{ margin: "0.5rem" }}
                      key={item}
                      onClick={() => handleOptionClick(item)}
                    >
                      {nameFunction(item)}
                    </DropdownOption>
                  ))
                ) : (
                  <DropdownOption>
                    <StyledLabel>No items</StyledLabel>
                  </DropdownOption>
                )}
              </DropdownOptionsContainer>
              {isShowCreateTopic && (
                <SearchFieldContainer
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    fontWeight: "bold",
                    paddingLeft: "1rem",
                    padding: "0.7rem",
                    borderRadius: "0 0.5rem 0.5rem 0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => handleOptionClick("newTopic")}
                >
                  <AddGroup>
                    <AddIcon style={{ height: "1rem", width: "1rem" }} />
                    <span style={{ color: "#47CCD6" }}>Create a New Topic</span>
                  </AddGroup>
                </SearchFieldContainer>
              )}
            </OptionContainer>
          </>
        )}
      </SelectionContainer>
    </Container>
  );
};

CustomRightDropdown.propTypes = {
  icon: PropTypes.string,
  dropdownItems: PropTypes.array.isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  nameFunction: PropTypes.func.isRequired,
  filterFunction: PropTypes.func.isRequired,
  searchPlaceHolder: PropTypes.string,
  disable: PropTypes.bool,
  noMargin: PropTypes.bool,
  openOnHover: PropTypes.bool,
  widthValue: PropTypes.string,
  heightValue: PropTypes.string,
  isFromUtilSetupJson: PropTypes.bool,
  dropdownPosition: PropTypes.string,
  isShowCreateTopic: PropTypes.bool,
};

CustomRightDropdown.defaultProps = {
  loading: false,
  disable: false,
  noMargin: false,
  openOnHover: false,
  placeholder: "Select an option",
  searchPlaceHolder: "",
  widthValue: "",
  heightValue: "",
  isFromUtilSetupJson: false,
  dropdownPosition: "",
  isShowCreateTopic: false,
};

export default CustomRightDropdown;
