import React, { useState, useRef, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Xarrow from "react-xarrows";
import DragIcon from "../../assets/images/DragIcon.svg";
import Delete from "../../assets/images/DeleteUpdated.svg";
import Column from "../../components/common/_customColumn";
import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import CustomDropdown from "./CustomDropDown";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import Tooltip from "../../components/common/_tooltip";
import JsonDialogsonDialog from "../../components/UtilityComponents/JsonDialogsonDialog";
import AddIcon from "../../assets/images/AddRuleIcon.svg";
import AddIcon2 from "../../assets/images/AddRuleWhite.svg";
// import close from "/close.svg";

// Rule Card component (sub-component)
const RuleCard = ({
  id,
  text,
  groupId,
  index,
  moveRule,
  onCardClick,
  isSelected,
  handleAddRule,
  removeConnection,
  ruleId,
  missedRules,
  isDisableFields,
}) => {
  const ref = useRef(null);

  // Set up drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: "RULE",
    item: { id, groupId, index, type: "RULE" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Set up drop functionality for reordering within same group
  const [, drop] = useDrop({
    accept: "RULE",
    hover(item, monitor) {
      if (!ref.current || item.groupId !== groupId) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRule(groupId, dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      item.index = hoverIndex;
    },
  });

  // Apply drag and drop refs to the card
  drag(drop(ref));

  // Handle click event
  const handleClick = () => {
    onCardClick(id);
  };

  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const deltetRule = () => {
    setIsDeleteClicked(true);
  };

  const onclickDelete = () => {
    removeConnection(`${groupId}-${id}`, "rule");
  };
  const [addRuleHovered, setAddRuleHovered] = useState(false);

  return (
    <>
      {id == "new_rule_btn" ? (
        <div
          ref={ref}
          id={id}
          className={`rule-card ${isSelected ? "selected" : ""}`}
          style={{
            padding: "0.8333rem",
            margin: "0.555rem 0",
            marginLeft: "1.67rem",
            backgroundColor: isSelected ? "#e6f7ff" : "transparent",
            border: isSelected
              ? "0.1388rem dashed #1890ff"
              : addRuleHovered
                ? "0.12rem dashed #F6F6F6"
                : "0.12rem dashed #5E5E5E",
            borderRadius: "0.5rem",
            boxShadow: "0 0.0694rem 0.2777rem rgba(0, 0, 0, 0.1)",
            borderImageSlice: 1,
            opacity: isDragging ? 0.5 : 1,
            cursor: "move",
            zIndex: 5,
            position: "relative",
            userSelect: "none",
            width: "14.444rem",
            height: "3.333rem",
            fontSize: "1.112rem",
            color: isDisableFields
              ? "#B0B0B0"
              : addRuleHovered
                ? "#F6F6F6"
                : "#B0B0B0",
            cursor: "pointer",
            // "&:hover": {
            //   backgroundColor: "#ef7117",
            //   border: "0.1388rem dashed #47CCD6",
            //   color: "#47CCD6",
            // },
          }}
          onMouseEnter={(e) => {
            if (!isDisableFields) {
              setAddRuleHovered(true);
            }
          }}
          onMouseLeave={(e) => {
            if (!isDisableFields) {
              setAddRuleHovered(false);
            }
          }}
          onClick={handleAddRule}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              fontFamily: "Articulat CF Normal",
              fontWeight: "bold",
              letterSpacing: "0.5px",
              // width: "100%",
            }}
          >
            <img
              src={addRuleHovered ? AddIcon2 : AddIcon}
              alt="+"
              style={{
                height: "1.38rem",
                width: "1.38rem",
                marginRight: "1rem",
                marginLeft: "1rem",
                transition: "0.3s ease-in-out",
              }}
              onMouseEnter={(e) => {
                setAddRuleHovered(true);
              }}
              onMouseLeave={(e) => {
                setAddRuleHovered(false);
              }}
            />
            {text}
          </div>
        </div>
      ) : (
        <div
          ref={ref}
          id={id}
          className={`rule-card ${ruleId === id ? "selected" : ""}`}
          style={{
            padding: "0.6rem",
            margin: "0.555rem ",
            marginLeft: "1.67rem",
            backgroundColor: missedRules.includes(id)
              ? "#0D0D0D"
              : ruleId === id
                ? "#162C2D"
                : "#0D0D0D",
            border: missedRules.includes(id)
              ? "0.0694rem solid #EA3A3A"
              : ruleId === id
                ? "0.0694rem solid #47CCD6"
                : "transparent",
            borderRadius: "0.555rem",
            boxShadow: "0 0.0694rem 0.277rem rgba(0, 0, 0, 0.1)",
            opacity: isDragging ? 0.5 : 1,
            cursor: "move",
            zIndex: 5,
            position: "relative",
            userSelect: "none",
            width: "14.444rem",
            height: "3.333rem",
            fontSize: "1.112rem",
            color: ruleId === id ? "#F6F6F6" : "#B0B0B0",
          }}
          onClick={handleClick}
        >
          <div style={{ display: "flex" }}>
            <div>
              <img
                style={{
                  height: "1.39rem",
                  width: "1.39rem",
                  paddingBottom: "0.2rem",
                }}
                src={DragIcon}
                alt="dragIcon"
              />
            </div>
            <Tooltip title={<>{text}</>} placement={"top"}>
              <div
                style={{
                  marginLeft: "0.67rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "10rem",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                {text}
              </div>
            </Tooltip>

            {!isDisableFields && (
              <div style={{ marginLeft: "auto" }}>
                <img
                  src={Delete}
                  alt="delete"
                  style={{
                    cursor: "pointer",
                    height: "1.67rem",
                    width: "1.67rem",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    deltetRule(`${groupId}-${id}`);
                  }}
                />{" "}
              </div>
            )}

            {isDeleteClicked && (
              <JsonDialogsonDialog
                dialogTitle="You are deleting a rule"
                showEditDialog={isDeleteClicked}
                onDialogClose={(e) => {
                  e.stopPropagation();
                  setIsDeleteClicked(false);
                }}
                descriptionText={`You are deleting ${text}. This action can't be retrieved or undone. Do you want to proceed?`}
                onclickProcced={(e) => {
                  e.stopPropagation();
                  onclickDelete();
                }}
                buttonName={"Delete Rule"}
                color="#F6F6F6"
                backgroundColor="#EA3A3A"
              />
            )}

            {/* <img
              //   src={close}
              alt="delete"
              onClick={(e) => {
                console.log("🚀 ~ e:", e);
                e.stopPropagation();
                removeConnection(`${groupId}-${id}`);
              }}
              style={{ height: "1.389rem" }}
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

// Group Card component (main component)
const GroupCard = ({
  id,
  text,
  index,
  rules,
  moveGroup,
  moveRule,
  addRule,
  onCardClick,
  isSelected,
  removeConnection,
  ruleId,
  missedRules,
  isDisableFields,
}) => {
  console.log("🚀 ~ groupppppid:", id);
  const ref = useRef(null);

  // Set up drag functionality
  const [{ isDragging }, drag] = useDrag({
    type: "GROUP",
    item: { id, index, type: "GROUP" },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Set up drop functionality for reordering
  const [, drop] = useDrop({
    accept: ["GROUP", "RULE"],
    hover(item, monitor) {
      // Only handle group reordering here
      if (item.type !== "GROUP" || !ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveGroup(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      item.index = hoverIndex;
    },
  });

  // Apply drag and drop refs to the card
  drag(drop(ref));

  // Handle click event
  const handleClick = () => {
    onCardClick(id);
  };

  // Handle add rule
  const handleAddRule = (e) => {
    e.stopPropagation();
    addRule(id);
  };
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);

  const handleDeleteGroup = () => {
    setIsDeleteClicked(true);
  };

  const onClickDeleteGroup = () => {
    removeConnection(id, "group");
  };
  return (
    <div className="group-container">
      <div
        ref={ref}
        id={id}
        className={`group-card ${isSelected ? "selected" : ""}`}
        style={{
          padding: "0.4167rem",
          margin: "0.84rem 0px 0.555rem 0px",
          backgroundColor: "#0D0D0D",
          //   border: isSelected ? "0.0694rem solid #1890ff" : "transparent",
          borderRadius: "0.555rem",
          boxShadow: "0 0.1388rem 0.555rem rgba(0, 0, 0, 0.15)",
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          zIndex: 30,
          position: "relative",
          userSelect: "none",
          width: "19.652rem",
          height: "3.333rem",
          display: "flex",
          //   justifyContent: "space-between",
          alignItems: "center",
          color: "#B0B0B0",
          fontFamily: "Articulat CF Medium",
          fontSize: "1.112rem",
          padding: "0.3rem 1.112rem",
          display: "flex",
        }}
        onClick={handleClick}
      >
        <div>
          <img
            style={{
              height: "1.39rem",
              width: "1.39rem",
              paddingBottom: "0.2rem",
            }}
            src={DragIcon}
            alt="dragIcon"
          />
        </div>
        <div
          style={{
            paddingLeft: "0.555rem",
            fontFamily: "Articulat CF Normal",
            fontWeight: "bold",
            letterSpacing: "0.5px",
          }}
        >
          {text}
        </div>
        {!isDisableFields && (
          <div style={{ marginLeft: "auto" }}>
            <img
              src={Delete}
              alt="delete"
              style={{
                cursor: "pointer",
                height: "1.67rem",
                width: "1.67rem",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteGroup();
              }}
            />
          </div>
        )}

        {isDeleteClicked && (
          <JsonDialogsonDialog
            dialogTitle="You are deleting a group"
            showEditDialog={isDeleteClicked}
            onDialogClose={(e) => {
              e.stopPropagation();
              setIsDeleteClicked(false);
            }}
            descriptionText={`You are deleting ${text} and all its associated rules. This action can't be retrieved or undone. Do you want to proceed?`}
            onclickProcced={(e) => {
              e.stopPropagation();
              onClickDeleteGroup();
            }}
            buttonName={"Delete Group"}
            color="#F6F6F6"
            backgroundColor="#EA3A3A"
          />
        )}
        {/* <button
          className="add-rule-btn"
          onClick={handleAddRule}
          style={{
            background: "#52c41a",
            color: "white",
            width: "1.67rem",
            height: "1.67rem",
            borderRadius: "50%",
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            fontSize: "1.112rem",
            fontWeight: "bold",
            lineHeight: 1,
          }}
        >
          +
        </button> */}
      </div>

      {/* Render all rules for this group */}
      <div className="rules-container">
        {rules.map((rule, ruleIndex) => (
          <RuleCard
            key={rule.id}
            id={rule.id}
            text={rule.text}
            groupId={id}
            index={ruleIndex}
            moveRule={moveRule}
            onCardClick={onCardClick}
            isSelected={isSelected && rule.id === isSelected}
            handleAddRule={handleAddRule}
            removeConnection={removeConnection}
            ruleId={ruleId}
            missedRules={missedRules}
            isDisableFields={isDisableFields}
          />
        ))}
      </div>
    </div>
  );
};

const AndOrDropdown = ({ value, onChange, type, isDisableFields }) => {
  return (
    <div
      className="dropdown-container"
      style={{ top: type === "rule" ? "-2.5rem" : "" }}
    >
      <select
        className="dropdown"
        value={value}
        onChange={onChange}
        disabled={isDisableFields}
        style={{
          width: "3.2rem",
          fontFamily: "Articulat CF Normal",
          fontWeight: "bold",
          letterSpacing: "0.5px",
        }}
      >
        <option
          style={{
            fontFamily: "Articulat CF Normal",
            fontWeight: "bold",
            letterSpacing: "0.5px",
          }}
          value="AND"
        >
          AND
        </option>
        <option
          style={{
            fontFamily: "Articulat CF Normal",
            fontWeight: "bold",
            letterSpacing: "0.5px",
          }}
          value="OR"
        >
          OR
        </option>
      </select>
    </div>
  );
};

const FeildsContainer = styled.div`
  width: 37.638rem;
  height: 28.1rem;
  padding: 1.112rem;
`;

const ValueTextField = styled.span`
  display: flex;
  flex-flow: column;
  width: 48%;
  margin-bottom: 1.112rem;
`;
const TextField = styled.input`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  flex: 1 1 0%;
  padding: 0.8rem;
  font-size: 1.12rem;
  color: rgb(255, 255, 255);
  background-color: #181818;
  border: none;
  border-radius: 0.5rem;
  overflow-x: auto;
  white-space: nowrap;
  outline: none;
`;

const Label = styled.label`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #b3b3b3;
  font-size: 0.972rem;
`;

// Main component for the hierarchical cards with connections
const ReactFlowProviderContent = ({
  handleLogic,
  previewFunction,
  handlePreview,
  kafkaLastMessage,
  isReadTopic,
  jsonValue,
  setRulesError,
  showMessage,
  onAddParent,
  handleGroupsAndRules,
  groupsAndRules,
  connectionsVal,
  setMissedRulesTemp,
  fieldOptionValues = [],
  setisConfigurationOpen,
  isDisableFields = false,
}) => {
  const [fieldTypeOptions, setFieldTypeOptions] = useState([]);
  const [conditionTypeOptions, setConditionTypeOptions] = useState([
    { label: "Equals to", value: "equals" },
    // { label: "Not equals to", value: "not_equals" },
    { label: "Greater than", value: "greater_than" },
    // { label: "Greater than or equal to", value: "greater_than_or_equals" },
    { label: "Less than", value: "less_than" },
    // { label: "Less than or equal to", value: "less_than_or_equals" },
    // { label: "Contains", value: "contains" },
    // { label: "Starts with", value: "starts_with" },
    // { label: "Ends with", value: "ends_with" },
    // { label: "Between", value: "between" },
    { label: "Matches", value: "matches" },
    { label: "In", value: "in" },
  ]);
  const [fieldOptions, setFieldOptions] = useState(fieldOptionValues || []);

  const handleAddParent = () => {
    addGroup();
  };
  useEffect(() => {
    console.log(connectionsVal, "hellllllllll", groupsAndRules);
    if (groupsAndRules.length > 0) {
      let fieldOptionsVal = fieldOptions;
      setGroups(groupsAndRules);
      let newConnectionsTemp = transformGroupsToConnections(groupsAndRules);
      setConnections(newConnectionsTemp);
      groupsRef.current = groupsAndRules;
    }
    onAddParent(handleAddParent);
  }, []);

  const validateRules = (fieldOptionsVal, groupsVal) => {
    const fieldLabels = fieldOptionsVal.map((option) => option.label);

    const missingRules = groupsVal.flatMap((group) =>
      group.rules.filter(
        (rule) =>
          rule.id !== "new_rule_btn" &&
          !/^Rule \d+(\.\d+)?$/.test(rule.text) && // Ignore 'Rule {num}' format
          !fieldLabels.includes(rule.text),
      ),
    );

    return {
      missingRuleIds: missingRules.map((rule) => rule.id),
      missingRuleKeys: missingRules.map((rule) => rule.text),
    };
  };

  useEffect(() => {
    (async () => {
      try {
        let response = await axiosAdapter(
          "GET",
          env.REACT_APP_URL + "admin/getUtilityDataTypes",
        );

        if (response.data.status === "SUCCESS") {
          const { dataTypes, conditionTypes } = response.data;
          // setFieldTypeOptions(
          //   dataTypes.map((item) => ({ label: item, value: item })),
          // );
          let types = ["Integer", "String", "Float", "Boolean"];
          setFieldTypeOptions(
            types.map((item) => ({ label: item, value: item })),
          );
          // setConditionTypeOptions(
          //   conditionTypes.map((item) => ({
          //     label: item,
          //     value: item,
          //   })),
          // );
        }
      } catch (error) {
        console.error("Error fetching field and condition types:", error);
      }
    })();
  }, []);

  const isNotEmptyObject = (obj) =>
    obj && typeof obj === "object" && Object.keys(obj).length > 0;

  const sampleData = isNotEmptyObject(jsonValue)
    ? jsonValue
    : jsonValue && typeof jsonValue === "string"
      ? JSON.parse(jsonValue)
      : "";

  // let fieldOptions = [];
  // if (typeof sampleData === "object") {
  //   fieldOptions = Object.entries(sampleData).map(([key, value]) => ({
  //     label: key, // Key from sampleData
  //     value: ["string", "number", "boolean"].includes(typeof value)
  //       ? value
  //       : "Unknown",
  //   }));
  // } else {
  //   fieldOptions = [];
  // }

  console.log(fieldOptions, "fieldOptions");
  // State for groups and their rules
  const [groups, setGroups] = useState([
    {
      id: "group1",
      text: "Group 1",
      rules: [
        // { id: "rule1_1", text: "Rule 1.1", top: "100%" },
        // { id: "rule1_2", text: "Rule 1.2" },
        { id: "new_rule_btn", text: "Add Rule" },
      ],
    },
  ]);
  const [missedRules, setMissedRules] = useState([]);

  console.log("🚀 ~ ReactFlowProviderContent ~ groups:", groups);

  // State for selections and connections
  const [selectedCards, setSelectedCards] = useState([]);
  const [operator, setOperator] = useState("&");
  const [connections, setConnections] = useState([]);
  console.log("🚀 ~ ReactFlowProviderContent ~ connections:", connections);

  // Get next group ID
  const getNextGroupId = (tempArray) => {
    // Extract numeric values from group IDs (e.g., "group1" → 1, "group2" → 2)
    const groupNumbers = tempArray
      .map((group) => parseInt(group.id.replace("group", ""), 10))
      .filter((num) => !isNaN(num)); // Ensure valid numbers

    // Find the highest group number
    const lastGroupNum =
      groupNumbers.length > 0 ? Math.max(...groupNumbers) : 0;

    return {
      groupId: `group${lastGroupNum + 1}`, // Increment the last group number
      groupLen: tempArray.length,
    };
  };

  // Get next rule ID for a group
  // const getNextRuleId = (groupId) => {
  //   const groupIndex = groups.findIndex((g) => g.id === groupId);
  //   console.log("🚀 ~ getNextRuleId ~ groupIndex:", groupIndex)
  //   if (groupIndex === -1) return "rule1_1";

  //   const rulesCount = groups[groupIndex].rules.length + 1;
  //   console.log("🚀 ~ getNextRuleId ~ rulesCount:", rulesCount)
  //   return `rule${groupIndex + 1}_${rulesCount}`;
  // };

  const getNextRuleId = (groupId) => {
    const group = groups.find((g) => g.id === groupId);
    if (!group) return { id: "rule1_1", ruleNumber: 1, textRuleNumber: "1.1" };

    const groupNumberMatch = group.text.match(/\d+/);
    const groupNumber = groupNumberMatch
      ? parseInt(groupNumberMatch[0], 10)
      : 1;

    const ruleNumbers = group.rules
      .map((rule) => {
        const match = rule.id.match(/rule\d+_(\d+)/);
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((num) => num !== null);

    const nextRuleNumber =
      ruleNumbers.length > 0 ? Math.max(...ruleNumbers) + 1 : 1;
    console.log("🚀 ~ getNextRuleId ~ nextRuleNumber:", nextRuleNumber);

    const textRuleNumbers = group.rules
      .filter((rule) => rule.id !== "new_rule_btn") // Exclude "new_rule_btn"
      .map((rule) => {
        const match = rule.text.match(/\d+\.(\d+)/); // Extract number after "Rule X."
        return match ? parseInt(match[1], 10) : null;
      })
      .filter((num) => num !== null);

    let nextTextRuleNumber;
    if (textRuleNumbers.length > 0) {
      nextTextRuleNumber = Math.max(...textRuleNumbers) + 1;
    } else {
      const existingRulesCount = group.rules.filter(
        (rule) => rule.id !== "new_rule_btn",
      ).length;
      nextTextRuleNumber = existingRulesCount + 1;
    }

    return {
      id: `rule${groupId.replace("group", "")}_${nextRuleNumber}`,
      ruleNumber: nextRuleNumber,
      textRuleNumber: `${groupNumber}.${nextTextRuleNumber}`,
    };
  };

  const addGroup = () => {
    let tempArray = groupsRef.current.length > 0 ? groupsRef.current : groups;
    const newGroupId = getNextGroupId(tempArray);
    console.log("🚀 ~ addGroup ~ newGroupId:", newGroupId);
    const previousGroupId =
      tempArray.length > 0
        ? `group${Math.max(...tempArray.map((g) => parseInt(g.id.replace("group", ""), 10)))}`
        : null;

    // const previousGroupId =
    //   tempArray.length > 0 ? `group${tempArray.length}` : null;

    const templateRules = [{ id: "new_rule_btn", text: "Add Rule" }];

    const newGroup = {
      id: newGroupId.groupId,
      text: `Group ${parseInt(newGroupId.groupId.replace("group", ""), 10)}`,
      rules: templateRules,
      ...(newGroupId.groupLen >= 1 && { operator: "AND" }),
    };

    // Add the new group
    setGroups((prevGroups) => {
      const groupsAndRules = [...prevGroups, newGroup]; // Capture updated groups
      let newConnectionsTemp = transformGroupsToConnections(groupsAndRules); // Transform new structure
      setConnections(newConnectionsTemp); // Update connections

      return groupsAndRules; // Update state
    });

    // Automatically connect to the previous group if it exists
    if (previousGroupId) {
      // const newConnection = {
      //   id: `group${parseInt(previousGroupId.replace("group", ""), 10)}-group${parseInt(newGroupId.groupId.replace("group", ""), 10)}`,
      //   // id: `${previousGroupId}-${newGroupId.groupId}`,
      //   start: previousGroupId,
      //   end: newGroupId.groupId,
      // };
      // setConnections((prevConnections) => {
      //   // Check if connection already exists
      //   const connectionExists = prevConnections.some(
      //     (conn) =>
      //       conn.start === previousGroupId && conn.end === newGroupId.groupId,
      //   );
      //   return connectionExists
      //     ? prevConnections
      //     : [...prevConnections, newConnection];
      // });
    }

    return newGroupId.groupId;
  };

  const checkRuleExceeds100 = (groups) => {
    const totalRules = groups.reduce((acc, group) => {
      return (
        acc + group.rules.filter((rule) => rule.id !== "new_rule_btn").length
      );
    }, 0);

    return totalRules > 99;
  };

  const addRule = (groupId) => {
    if (!isDisableFields) {
      const isRuleExceeds100 = checkRuleExceeds100(groups);
      if (isRuleExceeds100) {
        showMessage(
          "Rule limit exceeded! You can add a maximum of 100 rules.",
          "error",
        );
        return;
      }
      setGroupId(groupId);
      const updatedGroups = groups.map((group) => {
        if (group.id === groupId) {
          const newRuleId = getNextRuleId(groupId);

          // Create new rules array, replacing "new_rule_btn"
          const newRules = [
            ...group.rules.filter((rule) => rule.id !== "new_rule_btn"),
            {
              id: newRuleId.id,
              text: `Rule ${newRuleId.textRuleNumber}`,
              ...(newRuleId.ruleNumber > 1 && { operator: "AND" }),
            },
            {
              id: "new_rule_btn",
              text: "Add Rule",
            },
          ];

          // Automatically create connection to the group
          const newConnection = {
            id: `${groupId}-${newRuleId.id}`,
            start: groupId,
            end: newRuleId.id,
          };

          // Update connections
          // setConnections((prevConnections) => {
          //   // Check if connection already exists
          //   const connectionExists = prevConnections.some(
          //     (conn) => conn.start === groupId && conn.end === newRuleId.id,
          //   );

          //   return connectionExists
          //     ? prevConnections
          //     : [...prevConnections, newConnection];
          // });

          return { ...group, rules: newRules };
        }
        return group;
      });

      let newConnectionsTemp = transformGroupsToConnections(updatedGroups);
      setConnections(newConnectionsTemp);

      console.log(updatedGroups, "Updated Groups");

      const newRuleBtnGroup = updatedGroups.find(
        (g) => g.id === "new_rule_btn",
      );
      if (newRuleBtnGroup) {
        updatedGroups = updatedGroups.filter((g) => g.id !== "new_rule_btn");
        updatedGroups.push(newRuleBtnGroup);
      }

      // Call handleRule to update state externally
      handleRule(
        groupId,
        "write",
        { fieldName: "", fieldType: "", conditionType: "", value: "" },
        "yes",
        updatedGroups,
      );

      // Reset field selections
      setSelectedField("");
      setSelectedFieldValue("");
      setSelectedFieldType("");
      setSelectedCondition("");
      setSelectedValueTemp("");

      // Reset dropdowns
      if (fieldOptionsDropdownRef.current) {
        fieldOptionsDropdownRef.current.setSelectedValue("");
      }
      if (fieldTypeDropdownRef.current) {
        fieldTypeDropdownRef.current.setSelectedValue("");
      }
      if (conditionTypeDropdownRef.current) {
        conditionTypeDropdownRef.current.setSelectedValue("");
      }
    }
  };

  const transformGroupsToConnections = (groups) => {
    const connections = [];

    groups?.forEach((group, index) => {
      // Connect each group to its rules, excluding "Add Rule" buttons
      try {
        if (group.rules && group.rules.length > 0) {
          group?.rules
            ?.filter((rule) => rule.id !== "new_rule_btn")
            .forEach((rule) => {
              connections.push({
                id: `${group.id}-${rule.id}`,
                start: group.id,
                end: rule.id,
              });
            });
        }
      } catch (error) {}
      // Connect the current group to the previous group if it exists
      if (index > 0) {
        connections.push({
          id: `${groups[index - 1].id}-${group.id}`,
          start: groups[index - 1].id,
          end: group.id,
        });
      }
    });

    return connections;
  };

  // Move a group
  const moveGroup = (dragIndex, hoverIndex) => {
    if (!isDisableFields) {
      setGroups((prevGroups) => {
        const updatedGroups = [...prevGroups];

        // Preserve operators separately before swapping
        const operatorA = updatedGroups[dragIndex].operator;
        const operatorB = updatedGroups[hoverIndex].operator;

        // Swap group properties including text and rules, but keep the operators unchanged
        [updatedGroups[dragIndex], updatedGroups[hoverIndex]] = [
          {
            ...updatedGroups[hoverIndex],
            id: `group${dragIndex + 1}`,
            text: prevGroups[hoverIndex].text,
            rules: updatedGroups[hoverIndex].rules.map((rule, idx) => ({
              ...rule,
              id:
                rule.id === "new_rule_btn"
                  ? rule.id
                  : `rule${dragIndex + 1}_${idx + 1}`,
            })),
            operator: operatorA, // Keep original operator
          },
          {
            ...updatedGroups[dragIndex],
            id: `group${hoverIndex + 1}`,
            text: prevGroups[dragIndex].text,
            rules: updatedGroups[dragIndex].rules.map((rule, idx) => ({
              ...rule,
              id:
                rule.id === "new_rule_btn"
                  ? rule.id
                  : `rule${hoverIndex + 1}_${idx + 1}`,
            })),
            operator: operatorB, // Keep original operator
          },
        ];

        const groupA = `group${dragIndex + 1}`;
        const groupB = `group${hoverIndex + 1}`;

        let newConnectionsTemp = transformGroupsToConnections(updatedGroups);
        setConnections(newConnectionsTemp);
        return updatedGroups;
      });
    }
  };

  // Move a rule within a group while keeping the operator unchanged
  const moveRule = (groupId, dragIndex, hoverIndex) => {
    if (!isDisableFields) {
      setGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (group.id === groupId) {
            const newRules = [...group.rules];

            const lengthOfRules = group.rules.length;

            if (
              dragIndex !== lengthOfRules - 1 &&
              hoverIndex !== lengthOfRules - 1
            ) {
              // Get references to the original rules
              const dragRule = newRules[dragIndex];
              const hoverRule = newRules[hoverIndex];

              // Swap only text and data, keep id and operator unchanged
              newRules[dragIndex] = {
                ...dragRule,
                text: hoverRule.text,
                data: hoverRule.data,
              };

              newRules[hoverIndex] = {
                ...hoverRule,
                text: dragRule.text,
                data: dragRule.data,
              };

              return { ...group, rules: newRules };
            }
          }
          return group;
        }),
      );
    }
  };

  // Handle card click for creating connections
  const handleCardClick = (id) => {
    setHandleClickState(true);
    let groupNo = getGroupNumber(id);
    let groupId = "group" + groupNo;
    let ruleId = id;
    setGroupId(groupId);
    setRuleId(id);
    console.log("🚀 ~ handleCardClick ~ id:", id);
    if (id.includes("group")) {
      setHasRules(false);
    } else {
      if (isDisableFields) {
        setHasRules(false);
      } else {
        setHasRules(true);
      }
    }

    let selectedObj = handleRule(id, "read");
    if (selectedObj) {
      setSelectedField(selectedObj?.fieldName);
      setSelectedFieldValue(selectedObj?.fieldName);
      setSelectedFieldType(selectedObj?.fieldType);
      setSelectedCondition(selectedObj?.conditionType);
      setSelectedValueTemp(selectedObj?.value);
      if (fieldOptionsDropdownRef.current) {
        fieldOptionsDropdownRef.current.setSelectedValue(
          selectedObj?.fieldName,
        );
      }

      if (fieldTypeDropdownRef.current) {
        fieldTypeDropdownRef.current.setSelectedValue(selectedObj?.fieldType);
      }
      if (conditionTypeDropdownRef.current) {
        conditionTypeDropdownRef.current.setSelectedValue(
          selectedObj?.conditionType,
        );
      }
    } else {
      setSelectedField("");
      setSelectedFieldValue("");
      setSelectedFieldType("");
      setSelectedCondition("");
      setSelectedValueTemp("");
      if (fieldOptionsDropdownRef.current) {
        fieldOptionsDropdownRef.current.setSelectedValue("");
      }

      if (fieldTypeDropdownRef.current) {
        fieldTypeDropdownRef.current.setSelectedValue("");
      }
      if (conditionTypeDropdownRef.current) {
        conditionTypeDropdownRef.current.setSelectedValue("");
      }
    }

    // If card is already selected, deselect it
    if (selectedCards.includes(id)) {
      setSelectedCards(selectedCards.filter((cardId) => cardId !== id));
      return;
    }

    if (
      selectedCards.length > 0 &&
      id &&
      ((selectedCards.length > 1 &&
        selectedCards[0].includes("rule") &&
        id.includes("rule") &&
        selectedCards[0].replace("rule", "").split("_")[0] !==
          id.replace("rule", "").split("_")[0]) ||
        (selectedCards[0].includes("group") &&
          id.includes("rule") &&
          selectedCards[0].replace("group", "") !==
            id.replace("rule", "").split("_")[0]))
    ) {
      return;
    }

    // If no card selected yet, select this one
    if (selectedCards.length === 0 && id.includes("group")) {
      setSelectedCards([id]);
      return;
    }
    console.log("🚀 ~ handleCardClick ~ selectedCards:", selectedCards);

    try {
      // Remove any selectedCards that are not present in groups
      const groupIds = groups.map((group) => group.id); // Extract all valid group IDs
      const filteredSelectedCards = selectedCards.filter((cardId) =>
        groupIds.includes(cardId),
      );
      setSelectedCards(filteredSelectedCards);
    } catch (error) {}

    // If one card already selected, create a connection
    if (selectedCards.length === 1 && selectedCards[0].includes("group")) {
      const newConnection = {
        id: `${selectedCards[0]}-${id}`,
        start: selectedCards[0],
        end: id,
      };

      // Check if this connection already exists
      const connectionExists = connections.some(
        (conn) =>
          (conn.start === selectedCards[0] && conn.end === id) ||
          (conn.start === id && conn.end === selectedCards[0]),
      );

      if (!connectionExists) {
        let newConnectionsTemp = transformGroupsToConnections([
          ...connections,
          newConnection,
        ]);
        // setConnections(newConnectionsTemp);
      }

      // Reset selection
      setSelectedCards([]);
    }
  };

  // Remove a connection
  const removeConnection = (connectionId, type) => {
    console.log("🚀 ~ removeConnection ~ connectionId:", connectionId, type);

    if (type === "group") {
      setGroups((prevGroups) => {
        const remainingGroups = prevGroups.filter(
          (group) => group.id !== connectionId,
        );

        // If the first group in the updated list has an `operator`, remove it
        if (remainingGroups.length > 0 && remainingGroups[0].operator) {
          delete remainingGroups[0].operator;
        }

        // Also check the first group's rules if they have an `operator`, remove it
        if (remainingGroups.length > 0 && remainingGroups[0].rules.length > 0) {
          delete remainingGroups[0].rules[0].operator;
        }

        let newConnectionsTemp = transformGroupsToConnections(remainingGroups);
        setConnections(newConnectionsTemp);
        return remainingGroups;
      });
    } else {
      const [groupId, ruleId] = connectionId.split("-");

      setGroups((prevGroups) => {
        let updatedGroups = prevGroups.map((group) => {
          if (group.id === groupId) {
            let updatedRules = group.rules.filter((rule) => rule.id !== ruleId);

            // If the first rule in THIS group has an `operator`, remove it
            if (updatedRules.length > 0 && updatedRules[0].operator) {
              delete updatedRules[0].operator;
            }

            return { ...group, rules: updatedRules };
          }
          return group;
        });

        console.log(updatedGroups, "updatedGroups");

        let newConnectionsTemp = transformGroupsToConnections(updatedGroups);
        setConnections(newConnectionsTemp);
        return updatedGroups;
      });

      setHasRules(false);
      setSelectedField("");
      setSelectedFieldValue("");
      setSelectedFieldType("");
      setSelectedCondition("");
      setSelectedValueTemp("");
      if (fieldOptionsDropdownRef.current) {
        fieldOptionsDropdownRef.current.setSelectedValue("");
      }

      if (fieldTypeDropdownRef.current) {
        fieldTypeDropdownRef.current.setSelectedValue("");
      }
      if (conditionTypeDropdownRef.current) {
        conditionTypeDropdownRef.current.setSelectedValue("");
      }
    }
  };

  // Flatten all IDs to check selected status
  const getAllIds = () => {
    const ids = [];
    groups.forEach((group) => {
      ids.push(group.id);
      group.rules.forEach((rule) => {
        ids.push(rule.id);
      });
    });
    return ids;
  };
  const handleRuleOperatorChange = (groupId, newOperator) => {
    const [slitGroup, splitRule] = groupId.split("-");

    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === slitGroup && !splitRule.includes("group")
          ? {
              ...group,
              rules: group.rules.map((rule) =>
                rule.id === splitRule
                  ? { ...rule, operator: newOperator }
                  : rule,
              ),
            }
          : group.id === splitRule && splitRule.includes("group")
            ? { ...group, operator: newOperator }
            : group,
      ),
    );
    console.log("🚀 ~ handleRuleOperatorChange ~ groupId:", groupId);
    console.log("🚀 ~ handleRuleOperatorChange ~ newOperator:", newOperator);
    console.log("🚀 ~ handleRuleOperatorChange ~ ruleId:", ruleId);
    console.log("🚀 ~ handleRuleOperatorChange ~ groupId:", groupId);
  };

  const inputRef = useRef(null);
  const conditionTypeDropdownRef = useRef(null);
  const fieldTypeDropdownRef = useRef(null);
  const fieldOptionsDropdownRef = useRef(null);
  const [selectedField, setSelectedField] = useState("");
  const [selectedFieldValue, setSelectedFieldValue] = useState("");
  const [hasRules, setHasRules] = useState(false);
  const [selectedFieldType, setSelectedFieldType] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [selectedValueTemp, setSelectedValueTemp] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [groupId, setGroupId] = useState("");
  const [ruleId, setRuleId] = useState("");
  const [handleClickState, setHandleClickState] = useState(false);

  const handleFieldChange = (e) => {
    setHandleClickState(false);
    const newField = e.target.label;
    const newValueField = e.target.value;

    setSelectedField(newField);
    setSelectedFieldValue(newValueField);
    validateAndSetValue(newField, "fieldName");
  };

  useEffect(() => {
    if (!handleClickState)
      updateFieldType(fieldOptionsDropdownRef.current.getSelectedValue());
  }, [selectedFieldValue]);

  const updateFieldType = (selectedField) => {
    if (selectedField) {
      let selectedFieldTypeStr = "unknown"; // Default type if not recognized

      if (typeof selectedFieldValue === "string") {
        selectedFieldTypeStr = "string";
      } else if (typeof selectedFieldValue === "number") {
        selectedFieldTypeStr = Number.isInteger(selectedFieldValue)
          ? "integer"
          : "float";
      } else if (typeof selectedFieldValue === "boolean") {
        selectedFieldTypeStr = "boolean";
      }

      const matchedOption = fieldTypeOptions.find((option) => {
        return (
          option.label.toLowerCase() === selectedFieldTypeStr ||
          option.value.toLowerCase() === selectedFieldTypeStr
        );
      });

      if (matchedOption) {
        handleFieldTypeDropdownChange(matchedOption.value);
      }
    }
  };

  const handleFieldTypeDropdownChange = (value) => {
    setSelectedFieldType(value);
    if (fieldTypeDropdownRef.current) {
      fieldTypeDropdownRef.current.setSelectedValue(value); // Update dropdown value manually
    }
    validateAndSetValue(value, "fieldType");
  };

  const validateAndSetValue = (inputValue, type) => {
    let inputValueTemp = inputRef.current.value;
    let selectedFieldTypeVal =
      type === "fieldType" ? inputValue : selectedFieldType;

    let existingRule = {
      fieldName: type === "fieldName" ? inputValue : selectedField,
      fieldType: type === "fieldType" ? inputValue : selectedFieldType,
      conditionType: type === "conditionType" ? inputValue : selectedCondition,
      value: "",
    };
    handleRule(ruleId, "write", existingRule, "no", []);

    if (!inputValueTemp?.trim()) {
      return;
    }

    if (!selectedFieldTypeVal && inputValueTemp?.trim()) {
      // setRulesError(true);
      // showMessage("Please select Field Type", "error");
      return;
    }
    let parsedValue = inputValueTemp.trim(); // Trim spaces
    let isValid = true;

    switch (selectedFieldTypeVal.toLowerCase()) {
      case "integer":
        if (/^-?\d+$/.test(parsedValue)) {
          parsedValue = Number(parsedValue);
          isValid = true;
        } else {
          isValid = false;
        }
        break;

      case "float":
        if (/^-?\d+\.\d+$/.test(parsedValue)) {
          parsedValue = parseFloat(parsedValue);
          isValid = true;
        } else {
          isValid = false;
        }
        break;

      case "boolean":
        if (["true", "TRUE"].includes(parsedValue.toLowerCase())) {
          parsedValue = "true";
        } else if (["false", "FALSE"].includes(parsedValue.toLowerCase())) {
          parsedValue = "false";
        } else {
          isValid = false;
        }
        break;

      case "string":
        if (parsedValue.toLowerCase())
          isValid = true; // Always valid
        else isValid = false;
        break;

      case "unknown":
        isValid = true;
        break;

      default:
        isValid = false;
    }
    if (!isValid) {
      let selectedFieldCheck =
        type === "fieldName" ? inputValue : selectedField;
      let selectedFieldTypeCheck =
        type === "fieldType" ? inputValue : selectedFieldType;
      let selectedConditionCheck =
        type === "conditionType" ? inputValue : selectedCondition;

      if (
        selectedFieldCheck &&
        selectedFieldTypeCheck &&
        selectedConditionCheck &&
        type === "value"
      ) {
        showMessage("Value Type and Field Type must match", "error");
      }
      setSelectedValue("");
      // setRulesError(true);
      return;
    }
    setSelectedValue(parsedValue);
    // setRulesError(false);

    handleRule(
      ruleId,
      "write",
      {
        ...existingRule, // Spread existing rule properties
        value: parsedValue, // Update only the value field
      },
      "no",
      [],
    );
  };

  const getGroupNumber = (ruleId) => {
    const match = ruleId.match(/rule(\d+)_\d+/);
    return match ? match[1] : null;
  };

  const handleRule = (
    inputId,
    type,
    newData = {},
    isTakeValue = "no",
    val = [],
  ) => {
    let foundGroup = null;
    let foundRule = null;
    let updatedRuleId = inputId;
    let data = isTakeValue === "yes" ? val : groups;

    if (/^group\d+$/.test(inputId)) {
      // If inputId is a group, find the group
      foundGroup = data.find((group) => group.id === inputId);
      if (foundGroup) {
        // Find the last valid rule before "new_rule_btn"
        const validRules = foundGroup.rules.filter(
          (rule) => rule.id !== "new_rule_btn",
        );
        if (validRules.length > 0) {
          updatedRuleId = validRules[validRules.length - 1].id;
          setRuleId(updatedRuleId);
        } else {
          return; // No valid rules to update
        }
      }
    } else {
      // If inputId is a specific rule, find the corresponding group and rule
      for (const group of data) {
        const rule = group.rules.find((rule) => rule.id === inputId);
        if (rule) {
          foundGroup = group;
          foundRule = rule;
          break;
        }
      }
      if (!foundRule) return null; // Rule not found
    }

    if (!foundGroup || !updatedRuleId) return null;

    if (type === "read") {
      return foundRule?.data || null;
    } else if (type === "write") {
      const updatedData = data.map((group) => {
        if (group.id === foundGroup.id) {
          return {
            ...group,
            rules: group.rules.map((rule) => {
              if (rule.id === updatedRuleId) {
                return {
                  ...rule,
                  data: newData,
                  text: newData.fieldName ? newData.fieldName : rule.text, // Update text if fieldName exists
                };
              }
              return rule;
            }),
          };
        }
        return group;
      });

      console.log(updatedData, "updatedData");
      setGroups(updatedData);
      setHasRules(true);
    }
  };

  const groupsRef = useRef([]);
  useEffect(() => {
    console.log(groups, "mainArray");
    processRules(groups);
    let expression = generateExpression(groups);
    let logic = extractLogic(groups);
    let logicVal = "";
    console.log(logic, "logic");
    previewFunction(expression);
    if (logic?.onlyOneValidGroup) {
      logicVal = cleanLogicString(logic?.logic);
    } else {
      logicVal = logic?.logic;
    }
    handleLogic(logicVal);
    handleGroupsAndRules(groups, connections);
    groupsRef.current = groups;
    if (groups.length <= 0) {
      setisConfigurationOpen(false);
    }
  }, [groups]);

  const cleanLogicString = (logicString) => {
    if (!logicString) return "";

    // Remove surrounding parentheses if present
    return logicString.startsWith("(") && logicString.endsWith(")")
      ? logicString.slice(1, -1)
      : logicString;
  };

  const extractLogic = (groups) => {
    let logicSymbols = [];
    let validGroupCount = 0;
    let validGroupIndices = []; // Track groups with valid rules

    groups.forEach((group, groupIndex) => {
      let rules = group.rules.filter((rule) => rule.data?.conditionType); // Filter valid rules

      if (rules.length > 0) {
        validGroupIndices.push(groupIndex); // Keep track of valid groups

        let ruleOperators = rules
          .slice(1) // Exclude the first rule, as it doesn't have an operator
          .map((rule) => rule.operator || "AND"); // Default to AND if operator is missing

        let groupLogic =
          ruleOperators.length > 0 ? `(${ruleOperators.join("|")})` : "()";

        if (validGroupCount > 0) {
          logicSymbols.push(group.operator === "OR" ? "OR" : "AND");
        }

        logicSymbols.push(groupLogic);
        validGroupCount++;
      }
    });

    return {
      logic: logicSymbols.join(""),
      onlyOneValidGroup: validGroupIndices.length === 1,
    };
  };
  const conditionMap = {
    between: "BETWEEN",
    equals: "==",
    not_equals: "!==",
    greater_than: ">",
    greater_than_or_equals: ">=",
    less_than: "<",
    less_than_or_equals: "<=",
    contains: "CONTAINS",
    starts_with: "STARTS WITH",
    ends_with: "ENDS WITH",
    matches: "MATCHES",
    in: "IN",
  };

  const generateExpression = (groups) => {
    if (!groups || groups.length === 0) return "";

    let firstValidGroupFound = false;

    return groups
      .map((group, groupIndex) => {
        const rules = group.rules
          .filter((rule) => rule.data?.conditionType) // Only keep valid rules
          .map((rule, index) => {
            const operator =
              conditionMap[rule.data.conditionType] || rule.data.conditionType;
            const condition = `${rule.text} ${operator} ${rule.data.value}`;

            return index === 0
              ? condition
              : `${rule.operator === "OR" ? "||" : "&&"} ${condition}`;
          });

        if (rules.length === 0) return ""; // Ignore empty groups

        const groupExpression =
          rules.length > 1 ? `(${rules.join(" ")})` : rules[0];

        // Ensure we don't add an operator before the first valid group
        if (!firstValidGroupFound) {
          firstValidGroupFound = true;
          return groupExpression;
        }

        return `${group.operator === "OR" ? "||" : "&&"} ${groupExpression}`;
      })
      .filter(Boolean)
      .join(" ");
  };

  const processRules = (groups) => {
    let fields = [];
    let types = [];
    let operators = [];
    let values = [];
    let groupErrors = [];

    let hasValidRule = false; // Flag to check if there is any valid rule

    groups.forEach((group) => {
      let groupFields = [];
      let groupTypes = [];
      let groupOperators = [];
      let groupValues = [];

      group.rules.forEach((rule, index) => {
        if (rule.id !== "new_rule_btn" && rule.data) {
          // Exclude `new_rule_btn` rules
          hasValidRule = true; // Found at least one valid rule

          const fieldName = rule.data.fieldName || "";
          const fieldType = rule.data.fieldType || "";
          let conditionType = rule.data.conditionType || "";
          const value = rule.data.value !== undefined ? rule.data.value : "";

          // Convert conditionType to uppercase
          conditionType = conditionType.toUpperCase();

          groupFields.push(fieldName);
          groupTypes.push(fieldType);
          groupOperators.push(conditionType);
          groupValues.push(value);

          // Log if any field is empty or undefined
          if (!fieldName || !fieldType || !conditionType || value === "") {
            groupErrors.push(
              `Validation Error in ${group.text}, Rule ${index + 1}: ` +
                `Field(${fieldName}), Type(${fieldType}), ` +
                `Operator(${conditionType}), Value(${value}) is missing or empty.`,
            );
          }
        }
      });

      // Ensure all arrays have the same length
      const expectedLength = Math.max(
        groupFields.length,
        groupTypes.length,
        groupOperators.length,
        groupValues.length,
      );

      if (
        groupFields.length !== expectedLength ||
        groupTypes.length !== expectedLength ||
        groupOperators.length !== expectedLength ||
        groupValues.length !== expectedLength
      ) {
        groupErrors.push(
          `Mismatch in group '${group.text}': Fields(${groupFields.length}), Types(${groupTypes.length}), Operators(${groupOperators.length}), Values(${groupValues.length})`,
        );
      }

      fields.push(...groupFields);
      types.push(...groupTypes);
      operators.push(...groupOperators);
      values.push(...groupValues);
    });

    // If there are no valid rules except "new_rule_btn", set error
    if (!hasValidRule) {
      groupErrors.push("No valid rules found in any group.");
    }

    const missingRules = validateRules(fieldOptions, groups);
    console.log(missingRules, "missingRules");
    setMissedRules(missingRules?.missingRuleIds);
    setMissedRulesTemp(missingRules?.missingRuleKeys);
    if (missingRules.missingRuleIds.length > 0) {
      groupErrors.push(`Missing rules`);
    }

    if (groupErrors.length > 0) {
      setRulesError(true);
      console.error("Validation Errors:\n" + groupErrors.join("\n"));
      return { error: groupErrors.join("\n") };
    } else {
      setRulesError(false);
    }

    let returnObj = {
      fields: fields.join("|"),
      type: types.join("|"),
      operator: operators.join("|"),
      values: values.join("|"),
    };

    console.log(returnObj, "returnObj");

    handlePreview(JSON.stringify(returnObj));
    return returnObj;
  };

  const findGroupOperator = (groupId, groups) => {
    const group = groups.find((g) => g.id === groupId);
    return group && group.operator ? group.operator : "";
  };

  const findRuleOperator = (groupId, ruleId, groups) => {
    const group = groups.find((g) => g.id === groupId);
    if (!group) return ""; // Group not found

    const rule = group.rules.find((r) => r.id === ruleId);
    return rule && rule.operator ? rule.operator : "";
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        width: "100%",
      }}
    >
      {/* <div>
        <button
          style={{
            padding: "5px 10px",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={handleAddParent}
        >
          Add Group
        </button>
      </div> */}
      <div style={{ flex: 5 }}>
        <div style={{ overflow: "auto" }}>
          <Column
            style={{
              paddingLeft: "5.11rem",
              height: "28rem",
              overflowY: "auto",
              overflowX: "hidden",
              position: "relative",
            }}
          >
            <DndProvider backend={HTML5Backend}>
              <div className="cards-container-wrap">
                <div className="hierarchical-cards-container">
                  {groups.map((group, index) => (
                    <GroupCard
                      key={group.id}
                      id={group.id}
                      text={group.text}
                      index={index}
                      rules={group.rules}
                      moveGroup={moveGroup}
                      moveRule={moveRule}
                      addRule={addRule}
                      onCardClick={handleCardClick}
                      isSelected={selectedCards.includes(group.id)}
                      removeConnection={removeConnection}
                      ruleId={ruleId}
                      missedRules={missedRules}
                      isDisableFields={isDisableFields}
                    />
                  ))}
                </div>

                {connections.map((connection, i) => {
                  const isGroupToGroup =
                    connection.start.includes("group") &&
                    connection.end.includes("group");
                  const isGroupToRule =
                    connection.start.includes("group") &&
                    connection.end.includes("rule");

                  let operatorValue = connection.operator || "";

                  if (isGroupToGroup && i > 0) {
                    operatorValue = findGroupOperator(connection.end, groups);
                  } else if (isGroupToRule && i > 0) {
                    const groupId = connection.start; // Directly use the group ID from `start`
                    const ruleId = connection.end; // Full rule ID, e.g., "rule1_3"

                    console.log(
                      "Checking for group:",
                      groupId,
                      "Rule:",
                      ruleId,
                    );

                    const group = groups.find((g) => g.id === groupId);
                    const rule = group?.rules?.find((r) => r.id === ruleId);
                    operatorValue = rule?.operator || "";

                    console.log("Operator value found:", operatorValue);
                  }

                  console.log(missedRules, "linessssssss", connection.end);
                  return (
                    <Xarrow
                      key={connection.id}
                      start={connection.start}
                      end={connection.end}
                      zIndex={connections.length - i}
                      color={
                        missedRules.includes(connection.end)
                          ? "#EA3A3A"
                          : ruleId === connection.end
                            ? "#47CCD6"
                            : "#5E5E5E"
                      }
                      // strokeWidth={1.5}
                      startAnchor={{
                        position: "left", // Anchor position (left, right, top, bottom, auto)
                      }}
                      endAnchor={{
                        position: "left", // Anchor position (left, right, top, bottom, auto)
                      }}
                      path="grid"
                      onClick={() => removeConnection(connection.id, "rule")}
                      headShape="circle"
                      headSize={4}
                      strokeWidth={2}
                      animateDrawing={0.5}
                      curveness={50}
                      _cpx1Offset={
                        connection.start.includes("group") &&
                        connection.end.includes("group")
                          ? -30
                          : 0
                      }
                      _cpy1Offset={0}
                      _cpx2Offset={
                        connection.start.includes("group") &&
                        connection.end.includes("group")
                          ? -30
                          : 0
                      }
                      _cpy2Offset={0}
                      labels={(() => {
                        let ruleIndexId;
                        const groupId = isGroupToRule ? connection.start : "";

                        if (connection.end.includes("rule")) {
                          const [groupIdNew, setRuleIndexId] =
                            connection.end.split("_");
                          ruleIndexId = parseInt(setRuleIndexId);
                        }

                        const groupRules = connections
                          .filter(
                            (conn) =>
                              conn.start === groupId &&
                              conn.end.includes("rule"),
                          )
                          .map((conn) => {
                            const [, ruleIdx] = conn.end.split("_"); // Extract rule index
                            return parseInt(ruleIdx); // Convert to number for sorting
                          })
                          .sort((a, b) => a - b); // Sort in ascending order

                        const ruleCount = groupRules.length; // Total rules in the group
                        const firstRuleIndex = groupRules[0] || null; // First rule index (if exists)

                        return connection.start.includes("group") &&
                          connection.end.includes("group")
                          ? {
                              middle: (
                                <div>
                                  <AndOrDropdown
                                    type="group"
                                    value={operatorValue}
                                    onChange={(e) =>
                                      handleRuleOperatorChange(
                                        connection.id,
                                        e.target.value,
                                      )
                                    }
                                    isDisableFields={isDisableFields}
                                  />
                                </div>
                              ),
                            }
                          : {
                              end: (
                                <div>
                                  {ruleCount > 1 &&
                                    ruleIndexId !== firstRuleIndex && (
                                      <AndOrDropdown
                                        type="rule"
                                        value={operatorValue}
                                        onChange={(e) =>
                                          handleRuleOperatorChange(
                                            connection.id,
                                            e.target.value,
                                          )
                                        }
                                        isDisableFields={isDisableFields}
                                      />
                                    )}
                                </div>
                              ),
                            };
                      })()}
                    />
                  );
                })}
              </div>
              <style>{`
           .toolbar {
                display: flex;
                align-items: center;
                gap: 1.112rem;
                margin-bottom: 1.112rem;
              }

              .add-group-btn {
                padding: 0.555rem 1.112rem;
                background-color: #1890ff;
                color: white;
                border: none;
                border-radius: 0.277rem;
                cursor: pointer;
                font-weight: bold;
              }

              .add-group-btn:hover {
                background-color: #40a9ff;
              }

              .instructions {
                color: #666;
                font-style: italic;
              }

              .cards-container-wrap {
                position: relative;
                width: 100%;
              }

              .group-container {
                margin-bottom: 1.112rem;
              }

              .rules-container {
                padding-left: 3.75rem;
              }

              .group-card.selected,
              .rule-card.selected {
                border-color: #1890ff;
                box-shadow: 0 0 0 0.1388rem rgba(24, 144, 255, 0.2);
              }

              .connection-list {
                margin-top: 1.67rem;
                border-top: 0.0694rem solid #eee;
                padding-top: 1.112rem;
              }

              .connection-list button {
                background-color: #ff4d4f;
                color: white;
                border: none;
                border-radius: 0.277rem;
                padding: 0.277rem 0.555rem;
                cursor: pointer;
              }

              .connection-list button:hover {
                background-color: #ff7875;
              }

              .add-rule-btn:hover {
                background-color: #73d13d;
              }
              .dropdown-container {
                display: inline-block;
                position: relative;
              }

              .dropdown {
                padding: 0 0.1388rem;
                font-size: 0.83rem;
                border: 0.0694rem solid #ccc;
                border-radius: 0.277rem;
                background: white;
                cursor: pointer;
                outline: none;
                height: 1.389rem;
                width: 3.75rem;
                line-height:0.83rem;
              }

              .dropdown:hover {
                border-color: #888;
              }
            `}</style>
            </DndProvider>
          </Column>
        </div>
      </div>
      <div style={{ flex: 7, borderLeft: "0.07rem solid rgb(75, 75, 75)" }}>
        <Column>
          <FeildsContainer>
            <CustomRow
              xs={6}
              style={{
                justifyContent: "space-around",
              }}
            >
              <CustomDropdown
                ref={fieldOptionsDropdownRef}
                label="Field Name"
                options={fieldOptions}
                value={selectedField}
                onChange={handleFieldChange}
                disabled={!hasRules}
                style={{
                  opacity: hasRules ? 1 : 0.5,
                  "& select": {
                    color: hasRules ? "#B0B0B0" : "#666666",
                    fontFamily: hasRules
                      ? "Articulat CF Medium"
                      : "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  },
                }}
                type="utility"
              />

              <CustomDropdown
                ref={fieldTypeDropdownRef}
                label="Field Type"
                options={fieldTypeOptions}
                value={selectedFieldType}
                onChange={(e) => handleFieldTypeDropdownChange(e.target.value)}
                disabled={!hasRules}
                style={{
                  opacity: hasRules ? 1 : 0.5,
                  "& select": {
                    color: hasRules ? "#B0B0B0" : "#666666",
                    fontFamily: hasRules
                      ? "Articulat CF Medium"
                      : "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  },
                }}
              />
            </CustomRow>
            <CustomRow xs={6} style={{ justifyContent: "space-around" }}>
              <CustomDropdown
                ref={conditionTypeDropdownRef}
                label="Condition Type"
                options={conditionTypeOptions}
                value={selectedCondition}
                onChange={(e) => {
                  const newCondition = e.target.value;
                  setSelectedCondition(newCondition);
                  validateAndSetValue(newCondition, "conditionType");
                }}
                disabled={!hasRules}
                style={{
                  opacity: hasRules ? 1 : 0.5,
                  "& select": {
                    color: hasRules ? "#B0B0B0" : "#666666",
                    fontFamily: hasRules
                      ? "Articulat CF Medium"
                      : "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  },
                }}
              />

              <ValueTextField>
                <Label
                  style={{
                    opacity: hasRules ? 1 : 0.5,
                    paddingBottom: "0.3rem",
                  }}
                >
                  Value
                </Label>
                <TextField
                  type="text"
                  placeholder="Enter value"
                  value={selectedValueTemp}
                  onChange={(e) => {
                    setSelectedValueTemp(e.target.value);
                    validateAndSetValue(e.target.value, "value");
                  }}
                  ref={inputRef}
                  disabled={!hasRules}
                  style={{
                    opacity: hasRules ? 1 : 0.5,
                    "& select": {
                      color: hasRules ? "#B0B0B0" : "#666666",
                      fontFamily: hasRules
                        ? "Articulat CF Medium"
                        : "Articulat CF Normal",
                      fontWeight: "bold",
                      letterSpacing: "0.5px",
                    },
                  }}
                />
              </ValueTextField>
            </CustomRow>
          </FeildsContainer>
        </Column>
      </div>
    </div>
  );
};

export default ReactFlowProviderContent;
