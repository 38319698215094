import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import styled from "styled-components";
import ArrowDown from "../../assets/images/Arrow.svg";
import ArrowUp from "../../assets/images/ArrowActiveUp.svg";

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1.112rem;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

const Label = styled.label`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #b3b3b3;
  font-size: 0.972rem;
  margin-bottom: 0.2777rem;
`;

const SelectBox = styled.div`
  background: #181818;
  color: ${({ disabled }) => (disabled ? "#666666" : "#ffffff")};
  border: 1px solid
    ${({ isOpen, disabled }) =>
      isOpen && !disabled ? "#ECECEC" : "transparent"};
  padding: 0.695rem;
  font-size: 1.112rem;
  border-radius: 0.4167rem;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 17.152rem;
  height: 3.333rem;
  position: relative;

  &:hover {
    border-color: ${({ disabled }) => (!disabled ? "#ECECEC" : "transparent")};
  }
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 17.152rem;
  height: 14.583rem;
  background: #222;
  border-radius: 0.4167rem;
  border: 1px solid #666;
  list-style: none;
  padding: 0.555rem 0;
  margin: 0.2777rem 0 0;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  z-index: 10;
  overflow: auto;
`;

const DropdownItem = styled.li`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0.695rem;
  font-size: 0.972rem;
  color: #f6f6f6;
  cursor: pointer;
  height: 2.916rem;
  margin-left: 0.555rem;
  /* display: flex;
  align-items: center;
  transition: background-color 0.2s ease; */

  &:hover {
    background: #4b4b4b;
    width: 16.041rem;
    height: 2.916rem;
    border-radius: 0.555rem;
    margin-left: 0.555rem;
  }
`;

const ArrowIcon = styled.img`
  height: 1.67rem;
  width: 1.67rem;
  transition: opacity 0.2s ease;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

const CustomDropdown = forwardRef(
  (
    {
      label,
      options = [],
      value,
      onChange,
      disabled = false,
      placeholder = "Select",
      type = "",
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownVal, setDropdownVal] = useState("");
    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    React.useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, [isOpen]);
    const handleMouseLeave = () => {
      setIsOpen(false);
    };

    const handleSelect = (selectedValue, labelVal) => {
      if (!disabled) {
        setDropdownVal(labelVal);
        onChange({ target: { value: selectedValue, label: labelVal } });
        setIsOpen(false);
      }
    };

    useImperativeHandle(ref, () => ({
      getSelectedValue: () => dropdownVal,
      setSelectedValue: (val) => setDropdownVal(val),
    }));

    const handleClick = () => {
      if (!disabled) {
        setIsOpen(!isOpen);
      }
    };

    const selectedOption = options.find((option) =>
      type !== "utility" ? option.value === value : option.label === value,
    );
    const displayText = selectedOption ? selectedOption.label : placeholder;

    return (
      <div ref={dropdownRef}>
        <DropdownContainer disabled={disabled} className="dropdown-container">
          <Label>{label}</Label>
          <SelectBox isOpen={isOpen} onClick={handleClick} disabled={disabled}>
            <span
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontFamily: "Articulat CF Normal",
                fontWeight: "bold",
                letterSpacing: "0.5px",
              }}
            >
              {displayText}
            </span>
            <ArrowIcon
              src={isOpen ? ArrowUp : ArrowDown}
              alt="dropdown arrow"
              disabled={disabled}
            />
          </SelectBox>

          {!disabled && (
            <DropdownList isOpen={isOpen} onMouseLeave={handleMouseLeave}>
              {options.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => handleSelect(option.value, option.label)}
                >
                  {option.label}
                </DropdownItem>
              ))}
              {options.length === 0 && (
                <DropdownItem style={{ color: "#666" }}>
                  No options available
                </DropdownItem>
              )}
            </DropdownList>
          )}
        </DropdownContainer>
      </div>
    );
  },
);

export default CustomDropdown;
