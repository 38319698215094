import React, { useCallback, useContext, useEffect, useState } from "react";
import Text from "../../../components/common/_text";
import Divider from "../../../components/common/_divider";
import styled from "styled-components";
import passwordIcon from "../../../assets/images/passwordIcon.svg";
import Column from "../../../components/common/_customColumn";
import CustomRow from "../../../components/common/_customRow";
import { StyledButtonPrimary } from "../../../components/common/_buttonNewOne";
import { ChangePasswordModal } from "./ChangePasswordModal";

const PasswordIcon = styled.div`
  width: 2.777rem;
  height: 2.777rem;
  margin-right: 1.112rem;
`;

export const AuthenticationComponent = () => {
  const [isPasswordChangeModalOpen, setPasswordChangeModalOpen] =
    useState(false);
  const [lastPasswordChangeDate, setLastPasswordChangeDate] = useState("");

  useEffect(() => {
    const lastPasswordChange = localStorage.getItem("lastPasswordChange");
    if (lastPasswordChange) {
      setLastPasswordChangeDate(lastPasswordChange);
    }
  }, []);
  return (
    <>
      <Text $fontWeight="bold" $fontSize="1.388rem">
        Authentication
      </Text>
      <Divider />
      <CustomRow
        style={{
          display: "flex",
          justifyContent: "normal",
          width: "76.5277rem",
        }}
      >
        <PasswordIcon xs={1}>
          <img
            style={{ height: "2.777rem", width: "2.777rem" }}
            src={passwordIcon}
            alt="passwordIcon"
          />
        </PasswordIcon>
        <Column style={{ justifyContent: "normal" }} xs={8}>
          <text>You have set a password</text>
          <Text
            style={{
              color: "#777777",
              fontSize: "0.833rem",
              fontFamily: "Articulat CF Normal",
              fontWeight: "bold",
            }}
          >
            Password last changed on {lastPasswordChangeDate}
          </Text>
        </Column>
        <StyledButtonPrimary
          style={{
            width: "14.167rem",
            height: "2.777rem",
            borderRadius: "0.555rem",
            marginLeft: "7.3rem",
          }}
          onClick={() => {
            setPasswordChangeModalOpen(true);
          }}
          xs={3}
        >
          Change Password
        </StyledButtonPrimary>
        {isPasswordChangeModalOpen && (
          <ChangePasswordModal
            isOpen={isPasswordChangeModalOpen}
            onClose={() => setPasswordChangeModalOpen(false)}
            onPasswordChange={(date) => {
              setLastPasswordChangeDate(date);
            }}
          />
        )}
      </CustomRow>
    </>
  );
};
