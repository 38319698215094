import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import FlexContainer from "../common/_flexContainer";
import styled from "styled-components";
import { workspaceAvatarMap } from "../../components/common/_workspaceAvatarMap";
import Text from "../common/_text";
import moment from "moment";
import WorkspaceCardMembers from "./_WorkspaceCardMembers";
import { StyledButtonPrimary } from "../common/_buttonNewOne";
import PropTypes from "prop-types";

const MainContainer = styled(FlexContainer)`
  background: #2f2f2f;
  border-radius: 0.833rem;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;

const AvatarIcon = styled.div`
  height: 3.055rem;
  width: 3.055rem;
`;

const MultiLineText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
`;

const PrimaryButtonStyled = styled(StyledButtonPrimary)`
  width: 100%;
  border-radius: 0.555rem;
  height: 2.777rem;
`;

const WorkspaceCard = ({ eachWorkSpace }) => {
  const { setSelectedWorkspaceData } = useContext(AuthContext);
  const history = useHistory();

  const handleWorkSpaceClick = () => {
    setSelectedWorkspaceData(eachWorkSpace);
    history.push("/connectorsPlayground");
  };

  return (
    <MainContainer
      $width="100%"
      $alignItems="flex-start"
      $gap="1.111rem"
      $padding="1.111rem"
    >
      <FlexContainer
        $width="calc(100% - 3.056rem - 1.111rem)"
        $height="3.055rem"
        $flexDirection="column"
        $flexWrap="nowrap"
        $alignItems="flex-start"
      >
        <Text
          $maxWidth="100%"
          $fontSize="1.388rem"
          $fontFamily="Articulat CF Medium"
          $fontWeight="bold"
          $letterSpacing="0.5px"
          $noWrap
        >
          {eachWorkSpace.workspaceName}
        </Text>
        <Text
          $fontFamily="Articulat CF Normal"
          $letterSpacing="0.5px"
          $fontWeight="bold"
          $maxWidth="100%"
          $fontSize="0.833rem"
          $color="#777777"
          $noWrap
        >
          {`Created on ${moment
            .unix(eachWorkSpace.createdOn)
            .format("DD MMM, YYYY")}`}
        </Text>
      </FlexContainer>
      <AvatarIcon
        as={workspaceAvatarMap[eachWorkSpace.iconFile ?? "Avataar_1.svg"]}
      />
      <FlexContainer
        $width="calc(65% - 1.111rem)"
        $flexDirection="column"
        $flexWrap="nowrap"
        $alignItems="flex-start"
        $gap="0.277rem"
        style={{ letterSpacing: "0px" }}
      >
        <Text
          $fontFamily="Articulat CF Normal"
          $fontWeight="bold"
          $fontSize="0.833rem"
          $color="#777777"
          $letterSpacing="0.5px"
        >
          About Workspace
        </Text>
        <MultiLineText
          $fontFamily="Articulat CF Normal"
          $letterSpacing="0.5px"
          $fontWeight="bold"
          $fontSize="0.972rem"
        >
          {eachWorkSpace.description}
        </MultiLineText>
      </FlexContainer>
      <FlexContainer
        $width="35%"
        $flexDirection="column"
        $flexWrap="nowrap"
        $alignItems="flex-start"
        $gap="0.277rem"
      >
        <Text
          $fontFamily="Articulat CF Normal"
          $letterSpacing="0.5px"
          $fontWeight="bold"
          $fontSize="0.833rem"
          $color="#777777"
          style={{ letterSpacing: "0px" }}
        >
          Members
        </Text>
        {eachWorkSpace.workspaceMembers.length ? (
          <WorkspaceCardMembers row={eachWorkSpace} />
        ) : (
          <Text
            $fontFamily="Articulat CF Normal"
            $letterSpacing="0.5px"
            $fontWeight="bold"
            $fontSize="0.972rem"
          >
            No members assigned
          </Text>
        )}
      </FlexContainer>
      <PrimaryButtonStyled onClick={handleWorkSpaceClick}>
        Open Workspace
      </PrimaryButtonStyled>
    </MainContainer>
  );
};

WorkspaceCard.propTypes = {
  eachWorkSpace: PropTypes.shape({
    workspaceName: PropTypes.string.isRequired,
    createdOn: PropTypes.number.isRequired,
    iconFile: PropTypes.string,
    description: PropTypes.string,
    workspaceMembers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default WorkspaceCard;
