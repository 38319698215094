export const parseKsqlResponse = (response) => {
  const result = {
    data: [],
    queryType: null,
    metadata: {},
  };

  // Handle SELECT query responses
  if (response.select && response.select.length > 0) {
    result.queryType = "select";

    // Extract header information
    let headers = [];
    let columnTypes = {};

    if (response.select[0].header) {
      const schemaStr = response.select[0].header.schema;

      const columnMatches = [
        ...schemaStr.matchAll(
          /`([^`]+)`\s+((?:STRUCT<.*?>|ARRAY<.*?>|[^,]+))(?:,|\s*$)/g,
        ),
      ];

      if (columnMatches.length > 0) {
        headers = columnMatches
          .map((match) => match[1]) // Extract column names
          .filter((colName, index) => {
            const colType = columnMatches[index][2].trim();
            return !colName.includes(".") || colType.startsWith("STRUCT<"); // Only include top-level fields
          });
      } else {
        // Fallback for complex schemas with nested structures
        const simpleMatches = schemaStr.match(/`([^`]+)`/g);
        if (simpleMatches) {
          headers = simpleMatches.map((match) => match.replace(/`/g, ""));
          // Set default type for all columns
          headers.forEach((header) => {
            columnTypes[header] = "STRING";
          });
        }
      }

      result.metadata.queryId = response.select[0].header.queryId;
    }

    // Extract row data
    const rows = response.select
      .filter((item) => item.row && item.row.columns)
      .map((item) => item.row.columns);

    result.data = rows.map((row) => {
      const obj = {};
      headers.forEach((header, index) => {
        let value = row[index];
        obj[header] = safeValue(value);
      });
      return obj;
    });

    // Handle final message if present
    const finalMessage = response.select.find((item) => item.finalMessage);
    if (finalMessage) {
      result.metadata.finalMessage = finalMessage.finalMessage;
    }
  }

  // Handle all SHOW responses by checking the @type property
  else if (response.show && response.show.length > 0) {
    const showResponse = response.show[0];
    const responseType = showResponse["@type"];

    // Set query type based on the @type property
    result.queryType = `show_${responseType}`;

    // Handle different show response types
    switch (responseType) {
      case "streams":
        result.data = processStreams(showResponse);
        break;
      case "tables":
        result.data = processTables(showResponse);
        break;
      case "source_descriptions":
        result.data = processSourceDescriptions(showResponse);
        result.metadata.fieldsData = extractFieldsData(showResponse);
        break;
      case "topics":
        result.data = processTopics(showResponse);
        break;
      case "queries":
        result.data = processQueries(showResponse);
        break;
      case "properties":
        result.data = processProperties(showResponse);
        break;
      case "connectors":
        result.data = processConnectors(showResponse);
        break;
      case "functions":
        result.data = processFunctions(showResponse);
        break;
      case "types":
        result.data = processTypes(showResponse);
        break;
      default:
        // Generic handler for unknown show types
        result.data = processGenericShow(showResponse, responseType);
    }
  }

  // Handle DESCRIBE responses (e.g., DESCRIBE STREAM/TABLE)
  else if (response.describe && response.describe.length > 0) {
    result.queryType = "describe";
    const describeResponse = response.describe[0];
    result.data = processDescribe(describeResponse);

    // Add source metadata if available
    if (describeResponse.sourceDescription) {
      result.metadata.sourceInfo = {
        name: describeResponse.sourceDescription.name,
        type: describeResponse.sourceDescription.type,
        topic: describeResponse.sourceDescription.topic,
        format: describeResponse.sourceDescription.format,
      };
    }
  }

  // Handle EXPLAIN responses
  else if (response.explain && response.explain.length > 0) {
    result.queryType = "explain";
    result.data = processExplain(response.explain[0]);
  }

  // Handle CREATE responses
  else if (response.create && Object.keys(response.create).length >= 0) {
    result.queryType = "create";
    result.data = [{ status: "CREATE operation successful" }];
  }

  if (!result.data || result.data.length === 0) {
    result.data = [
      { NO_DATA: "No data could be parsed from the query response" },
    ];
  }

  return result;
};

function processStreams(response) {
  const streams = response.streams || [];

  return streams.map((stream) => {
    const processed = {};
    Object.entries(stream).forEach(([key, value]) => {
      processed[key] = safeValue(value);
    });
    return processed;
  });
}

function processTables(response) {
  const tables = response.tables || [];

  return tables.map((table) => {
    const processed = {};
    Object.entries(table).forEach(([key, value]) => {
      processed[key] = safeValue(value);
    });
    return processed;
  });
}

function processSourceDescriptions(response) {
  const sourceDescriptions = response.sourceDescriptions || [];

  return sourceDescriptions.map((source) => {
    let lastMessage = "-";
    if (source.statistics) {
      const match = source.statistics.match(/last-message: ([^\s]+)/);
      if (match && match[1]) {
        lastMessage = match[1];
      }
    }

    const result = {
      name: source.name || "-",
      type: source.type || "-",
      topic: source.topic || "-",
      keyFormat: source.keyFormat || "-",
      valueFormat: source.valueFormat || "-",
      partitions: source.partitions || "-",
      replication: source.replication || "-",
      lastMessage: lastMessage,
    };

    return result;
  });
}

function extractFieldsData(response) {
  const sourceDescriptions = response.sourceDescriptions || [];

  return sourceDescriptions.map((source) => {
    const fields = source.fields || [];
    return {
      sourceName: source.name,
      fields: fields.map((field) => ({
        name: field.name,
        type: field.schema ? safeValue(field.schema.type) : "",
        keyType: field.type || "",
      })),
    };
  });
}

function processTopics(response) {
  const topics = response.topics || [];

  return topics.map((topic) => ({
    name: topic.name || "-",
    partitions: safeValue(topic.partitions),
    replication: safeValue(topic.replication),
    consumers: safeValue(topic.consumerCount),
    consumerGroupSize: safeValue(topic.consumerGroupSize),
  }));
}

function processQueries(response) {
  const queries = response.queries || [];

  return queries.map((query) => ({
    queryId: query.id || "-",
    sql: query.queryString || "-",
    status: query.status || "-",
    sinkName: query.sinkNames ? safeValue(query.sinkNames.join(", ")) : "-",
  }));
}

function processProperties(response) {
  const properties = response.properties || [];

  return Object.entries(properties).map(([key, value]) => ({
    name: key,
    value: safeValue(value),
    scope: determinePropertyScope(key),
  }));
}

function determinePropertyScope(propertyName) {
  if (propertyName.startsWith("ksql.")) {
    return "KSQL";
  } else if (propertyName.startsWith("kafka.")) {
    return "KAFKA";
  } else if (propertyName.startsWith("consumer.")) {
    return "CONSUMER";
  } else if (propertyName.startsWith("producer.")) {
    return "PRODUCER";
  }
  return "OTHER";
}

function processConnectors(response) {
  const connectors = response.connectors || [];

  return connectors.map((connector) => ({
    name: connector.name || "-",
    type: connector.type || "-",
    class: connector.className || "-",
    status: connector.state || "-",
  }));
}

function processFunctions(response) {
  const functions = response.functions || [];

  return functions.map((func) => ({
    name: func.name || "-",
    type: func.type || "-",
    description: func.description || "-",
  }));
}

function processTypes(response) {
  const types = response.types || [];

  return types.map((type) => ({
    type: type.name || "-",
    schema: safeValue(type.schema),
  }));
}

function processDescribe(response) {
  if (
    !response ||
    !response.sourceDescription ||
    !response.sourceDescription.fields
  ) {
    return [{ error: "No valid description data found" }];
  }

  const fields = response.sourceDescription.fields;

  return fields.map((field) => ({
    field: field.name || "-",
    type: field.schema ? safeValue(field.schema.type) : "-",
    key: safeValue(field.key),
  }));
}

function processExplain(response) {
  if (!response) return [{ explanation: "No explanation provided" }];

  if (typeof response.queryDescription === "string") {
    const lines = response.queryDescription.split("\n");
    return lines.map((line) => ({
      explanation: line,
    }));
  } else if (
    response.queryDescription &&
    Array.isArray(response.queryDescription.steps)
  ) {
    return response.queryDescription.steps.map((step, index) => ({
      step: safeValue(index + 1),
      operation: step.operation || "-",
      source: step.source || "-",
    }));
  }
  // Fallback
  else {
    return [{ explanation: "Could not parse explanation format" }];
  }
}

function processGenericShow(response, responseType) {
  const typeName = responseType.replace(/_/g, "");
  const dataArray = response[typeName] || [];

  if (Array.isArray(dataArray) && dataArray.length > 0) {
    return dataArray.map((item) => {
      const row = {};
      Object.keys(item).forEach((key) => {
        row[key] = safeValue(item[key]);
      });
      return row;
    });
  } else {
    return Object.entries(response).map(([key, value]) => ({
      property: key,
      value: safeValue(value),
    }));
  }
}

function safeValue(value) {
  if (value === null || value === undefined) {
    return "-";
  } else if (typeof value === "object") {
    return JSON.stringify(value);
  } else if (typeof value === "boolean") {
    return value.toString();
  } else if (typeof value === "number") {
    return value;
  } else {
    return String(value);
  }
}
