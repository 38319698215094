import styled, { keyframes } from "styled-components";
import ConnectorIcon from "../../../assets/images/connector_node_preview_icon.svg";
import SettingsIcon from "../../../assets/images/settings_icon.svg";
import { ReactComponent as InspectIcon } from "../../../assets/images/node_status_inspect_icon.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/images/node_status_error_icon.svg";
import { ReactComponent as RunningIcon } from "../../../assets/images/node_status_running_icon.svg";
import { useMemo, useState } from "react";
import { Handle, Position } from "reactflow";
import PropTypes from "prop-types";
import ConnectorsIcon from "../../../assets/images/Connectors.svg";
import { env } from "../../../env";

const StyledInspectIcon = styled(InspectIcon)`
  height: 12px;
  width: 12px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  height: 12px;
  width: 12px;
`;

const StyledRunningIcon = styled(RunningIcon)`
  height: 12px;
  width: 12px;
  & path {
    stroke: ${(props) =>
      getCategoryColor(props.category, props.connectionType)};
  }
`;

const CardContainer = styled.div`
  width: 256px;
  height: 126px;
  max-width: 256px;
  max-height: 126px;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0px 0px 40px 0px #00000029;
`;

const LeftLine = styled.div`
  width: 4px;
  background-color: #47ccd6;
  border-radius: 5px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 12px;
  overflow: hidden;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconContainer = styled.div`
  align-items: baseline;
  display: flex;

  height: 2rem;
  width: 2rem;
  border-radius: 0.35rem;
  background-color: #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NodeStatusContainer = styled.div`
  height: 20px;
  border: 1px solid #f6f6f6;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Running {
    background: ${(props) =>
      getBackgroundColor(props.category, props.connectionType)};
    border-color: ${(props) =>
      getCategoryColor(props.category, props.connectionType)};
  }

  &.Inspect {
    background: #edb62e29;
    border-color: #edb62e;
  }

  &.Error {
    background: #ea3a3a29;
    border-color: #ea3a3a;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 2px solid rgba(76, 74, 74, 0.1);
  border-top: 2px solid
    ${(props) => getCategoryColor(props.category, props.connectionType)};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: ${spin} 0.7s linear infinite;
`;

const NodeStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TitleAndIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 190px;
`;

const RunningStatus = ({ category, connectionType }) => {
  return (
    <NodeStatusDiv>
      <StyledRunningIcon category={category} connectionType={connectionType} />
      <Text
        style={{
          fontSize: "10px",
          color: getCategoryColor(category, connectionType),
          fontFamily: "Articulat CF Normal",
          fontWeight: "bold",
          letterSpacing: "0.5px",
        }}
      >
        Running
      </Text>
    </NodeStatusDiv>
  );
};

const InspectStatus = () => (
  <NodeStatusDiv>
    <StyledInspectIcon />
    <Text
      style={{
        fontSize: "10px",
        color: "#EDB62E",
        lineHeight: "12px",
        fontFamily: "Articulat CF Normal",
        fontWeight: "bold",
        letterSpacing: "0.5px",
      }}
    >
      Inspect
    </Text>
  </NodeStatusDiv>
);

const ErrorStatus = () => (
  <NodeStatusDiv>
    <StyledErrorIcon />
    <Text
      style={{
        fontSize: "10px",
        color: "#EA3A3A",
        lineHeight: "12px",
        fontFamily: "Articulat CF Normal",
        fontWeight: "bold",
        letterSpacing: "0.5px",
      }}
    >
      Error
    </Text>
  </NodeStatusDiv>
);

const displayNodeStatus = (status, category, connectionType) => {
  switch (status) {
    case "Running":
      return (
        <RunningStatus category={category} connectionType={connectionType} />
      );
    case "Inspect":
      return <InspectStatus />;
    case "Error":
      return <ErrorStatus />;
    default:
      return <Spinner category={category} connectionType={connectionType} />;
  }
};

const getCategoryColor = (category, connectionType = "") => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B";
    case "Store Connector":
      return "#B5E3CE";
    case "Application":
      return "#EB6F95";
    case "Telematics Device":
      return "#F7C0D5";
    case "Transform":
      if (connectionType && connectionType === "Output") {
        return "#C7A6F1";
      } else {
        return "#EB6F95";
      }
    default:
      return "#EB6F95";
  }
};

const getBackgroundColor = (category, connectionType) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B29";
    case "Store Connector":
      return "#B5E3CE29";
    case "Application":
      return "#EB6F9529";
    case "Telematics Device":
      return "#F7C0D529";
    case "Transform":
      if (connectionType && connectionType === "Output") {
        return "#A675E929";
      } else {
        return "#EB6F9529";
      }
    default:
      return "#EB6F9529";
  }
};

const PipelineConnectorCard = (props) => {
  const {
    title,
    connectionType,
    category,
    name,
    interfaces,
    isConfigure,
    status = "",
    icon = "",
  } = props.data;

  const [connection, setConnection] = useState({ left: false, right: false });

  useMemo(() => {
    const left = interfaces.some((topic) => topic.direction === "input");
    const right = interfaces.some((topic) => topic.direction === "output");
    setConnection({ left, right });
  }, [interfaces]);

  const getIconByType = (type, name, connectionType) => {
    let link = env.REACT_APP_S3_BUCKET;
    if (!name) return ConnectorsIcon;

    let formattedName = "";

    if (connectionType === "Custom Connector") {
      formattedName = icon;
    } else {
      let tempName = name.replace(/\s+/g, "");
      try {
        if (tempName === "GoogleCloudPub/Sub") {
          formattedName = "GoogleCloudPubSub";
        } else if (tempName === "HTTP") {
          formattedName = "HTTPS";
        } else if (tempName === "HTTPS") {
          formattedName = "HTTPSUnlock";
        } else {
          formattedName = tempName + ".svg";
        }
      } catch (error) {
        formattedName = tempName + ".svg";
      }
    }
    if (!formattedName) return ConnectorsIcon;

    console.log(formattedName, "formattedName");

    try {
      return `${link}Connector-Images/${formattedName}`; // Update the path as needed
    } catch (error) {
      console.error("Error loading icon:", formattedName, error);
      return ConnectorsIcon; // Update the path as needed
    }
  };

  const iconSrc = useMemo(
    () => getIconByType(category, name, connectionType),
    [category, name, connectionType],
  );

  return (
    <>
      {connection.left && (
        <Handle
          style={{
            backgroundColor: "#B0B0B0",
            cursor: "not-allowed",
            border: "none",
          }}
          type="target"
          position={Position.Left}
        />
      )}
      <CardContainer>
        <LeftLine
          style={{
            backgroundColor: getCategoryColor(category, connectionType),
          }}
        />
        <ContentContainer>
          <ContentRow style={{ gap: "8px" }}>
            <TitleAndIconRow>
              <IconContainer>
                <img
                  src={iconSrc}
                  alt=""
                  style={{
                    height: category === "Application" ? "2rem" : "",
                    width: category === "Application" ? "2rem" : "",
                  }}
                />
                {/* <img src={ConnectorIcon} alt="Connector Icon" /> */}
              </IconContainer>
              <TitleAndSubtitleContainer>
                {title && (
                  <Text
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#F6F6F6",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {title}
                  </Text>
                )}
                {name && (
                  <Text
                    style={{
                      fontSize: "10px",
                      lineHeight: "14px",
                      color: "#777777",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {name}
                  </Text>
                )}
              </TitleAndSubtitleContainer>
            </TitleAndIconRow>
            <img src={SettingsIcon} alt="Connector Icon" />
          </ContentRow>
          <ContentRow style={{ gap: "8px" }}>
            <TitleAndSubtitleContainer
              style={{ width: "50%", maxWidth: "50%" }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#777777",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Connector Category
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: getCategoryColor(category, connectionType),
                  fontFamily: "Articulat CF Normal",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                {category === "Transform" && connectionType === "Output"
                  ? "Utility"
                  : category}
              </Text>
            </TitleAndSubtitleContainer>
            <TitleAndSubtitleContainer
              style={{ width: "50%", maxWidth: "50%" }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  lineHeight: "12px",
                  color: "#777777",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Connection Type
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: getCategoryColor(category, connectionType),
                  fontFamily: "Articulat CF Normal",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                {category === "Transform" && connectionType === "Output"
                  ? "Transform"
                  : connectionType}
              </Text>
            </TitleAndSubtitleContainer>
          </ContentRow>
          <ContentRow style={{ gap: "8px" }}>
            <Text
              style={{ fontSize: "12px", lineHeight: "16px", color: "#47CCD6" }}
            >
              {isConfigure ? "Configure" : ""}
            </Text>
            <NodeStatusContainer
              className={status || "Running"}
              category={category}
              connectionType={connectionType}
            >
              {displayNodeStatus(status, category, connectionType)}
            </NodeStatusContainer>
          </ContentRow>
        </ContentContainer>
      </CardContainer>
      {connection.right && (
        <Handle
          style={{
            backgroundColor: "#B0B0B0",
            cursor: "not-allowed",
            border: "none",
          }}
          type="source"
          position={Position.Right}
        />
      )}
    </>
  );
};

RunningStatus.propTypes = {
  category: PropTypes.oneOf([
    "Stream Connector",
    "Store Connector",
    "Application",
    "Telematics Device",
  ]).isRequired,
};
PipelineConnectorCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    connectionType: PropTypes.string.isRequired,
    category: PropTypes.oneOf([
      "Stream Connector",
      "Store Connector",
      "Application",
      "Telematics Device",
    ]).isRequired,
    name: PropTypes.string.isRequired,
    interfaces: PropTypes.arrayOf(
      PropTypes.shape({
        direction: PropTypes.oneOf(["input", "output"]).isRequired,
      }),
    ).isRequired,
    isConfigure: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(["Running", "Inspect", "Error", ""]),
    icon: PropTypes.bool,
  }).isRequired,
};

export default PipelineConnectorCard;
