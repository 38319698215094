import styled from "styled-components";
import Column from "../../../components/common/_customColumn";
import CustomRow from "../../../components/common/_customRow";
import GenericModal from "../../../components/common/_genericModal";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import { useContext, useState } from "react";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import PropTypes from "prop-types";

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: "bold";
  letter-spacing: "0.5px";
  color: #ececec;
  font-size: 1.25rem;
  line-height: 1.555rem;
  margin: 0;
  opacity: 0.7;
`;

export const RevokeInvitationModal = ({
  isOpen,
  onClose,
  emailId,
  refetchUsersAndRoles,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [isApiLoading, setApiLoading] = useState(false);

  const handleRevokeClick = async () => {
    setApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/revokeUserInvitation",
        {
          emailId,
        },
      );
      refetchUsersAndRoles();
      showMessage("Invitation revoked successfully", "success");
      onClose();
    } catch (err) {
      console.log("Error: revokeUserInvitation", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to revoke the invitation, please try again later");
      }
      setApiLoading(false);
    }
  };

  return (
    <GenericModal
      show={isOpen}
      onClose={onClose}
      title={`Cancel request sent to ${emailId}?`}
    >
      <CustomRow>
        <CustomColumn mb={6.25}>
          <Text>
            {`Are you sure you want to cancel the request sent to ${emailId} ?
            You’ll need to send a new invite if you add them again.`}
          </Text>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
            disabled={isApiLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            width="13.888rem"
            onClick={() => handleRevokeClick()}
            disabled={isApiLoading}
          >
            Cancel Request
          </StyledPrimaryButton2>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};

RevokeInvitationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  emailId: PropTypes.string.isRequired,
  refetchUsersAndRoles: PropTypes.func.isRequired,
};
