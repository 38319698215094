import FlexContainer from "../../../components/common/_flexContainer";
import { ReactComponent as GithubIcon } from "../../../assets/images/code_repository_github.svg";
import { ReactComponent as GitlabIcon } from "../../../assets/images/code_repository_gitlab.svg";
import { ReactComponent as BitbucketIcon } from "../../../assets/images/code_repository_bitbucket.svg";
import styled from "styled-components";
import Text from "../../../components/common/_text";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import { env } from "../../../env";
import PropTypes from "prop-types";

const GithubIconStyled = styled(GithubIcon)`
  width: 2.777rem;
  height: 2.777rem;
  flex: none;
`;
const GitlabIconStyled = styled(GitlabIcon)`
  width: 2.777rem;
  height: 2.777rem;
  flex: none;
`;
const BitbucketIconStyled = styled(BitbucketIcon)`
  width: 2.777rem;
  height: 2.777rem;
  flex: none;
`;
const PrimaryButton2 = styled(StyledPrimaryButton2)`
  height: 2.777rem;
  border-radius: 0.555rem;
  flex: none;
`;
const SecondaryButton = styled(StyledButtonsecondary)`
  height: 2.777rem;
  border-radius: 0.555rem;
  border: 0.069rem solid ${(props) => (props.disabled ? "#797979" : "#F6F6F6")};
  &:hover {
    border: 0.069rem solid #47ccd6;
  }
  flex: none;
`;

const vcNameMap = {
  github: "GitHub",
  gitlab: "GitLab",
  bitbucket: "Bitbucket",
};

const CodeRepositoryItem = ({
  provider,
  isConnected,
  userName,
  setDisconnectionProvider,
  setDisconnectModalOpen,
}) => {
  const handleConnect = () => {
    switch (provider) {
      case "github": {
        window.location.assign(
          `https://github.com/login/oauth/authorize?client_id=${encodeURIComponent(
            env.REACT_APP_GITHUB_OAUTH_CLIENT_ID,
          )}&scope=repo&redirect_uri=${encodeURIComponent(
            env.REACT_APP_OAUTH_REDIRECTION_URL,
          )}&state=${encodeURIComponent(window.location.origin)}/oauth/authorize|github`,
        );
        break;
      }
      case "gitlab": {
        const gitlabUrl = `https://gitlab.com/oauth/authorize?client_id=${encodeURIComponent(
          env.REACT_APP_GITLAB_OAUTH_CLIENT_ID,
        )}&redirect_uri=${encodeURIComponent(
          env.REACT_APP_OAUTH_REDIRECTION_URL,
        )}&response_type=code&scope=api+read_api+read_user+read_repository+write_repository&state=${encodeURIComponent(
          window.location.origin,
        )}/oauth/authorize|gitlab`;
        window.location.assign(gitlabUrl);
        break;
      }
      case "bitbucket": {
        const bitbucketUrl = `https://bitbucket.org/site/oauth2/authorize?client_id=${encodeURIComponent(
          env.REACT_APP_BITBUCKET_OAUTH_CLIENT_ID,
        )}&redirect_uri=${encodeURIComponent(
          env.REACT_APP_OAUTH_REDIRECTION_URL,
        )}&response_type=code&state=${encodeURIComponent(
          window.location.origin,
        )}/oauth/authorize|bitbucket`;
        window.location.assign(bitbucketUrl);
        break;
      }
      default:
        break;
    }
  };

  const providerIcons = {
    github: <GithubIconStyled />,
    gitlab: <GitlabIconStyled />,
    bitbucket: <BitbucketIconStyled />,
  };

  return (
    <FlexContainer $width="100%" $gap="1.666rem" $flexWrap="nowrap">
      <FlexContainer
        $width="calc(100% - 12.222rem - 1.666rem)"
        $gap="0.833rem"
        $flexWrap="nowrap"
      >
        {providerIcons[provider] || null}
        <FlexContainer
          $width="calc(100% - 2.777rem - 0.833rem)"
          $flexDirection="column"
          $alignItems="flex-start"
          $gap="0.138rem"
        >
          <Text $width="100%" $fontWeight="bold" $noWrap>
            {isConnected ? userName : vcNameMap[provider]}
          </Text>
          <Text
            $width="100%"
            $fontSize="0.833rem"
            $fontFamily="Articulat CF Normal"
            $fontWeight="bold"
            $letterSpacing="0.5px"
            $color={isConnected ? "#47CCD6" : "#777777"}
          >
            {isConnected
              ? "Connected"
              : `Not connected. Click the 'Connect' button to set up ${vcNameMap[provider]} for your account`}
          </Text>
        </FlexContainer>
      </FlexContainer>
      {isConnected ? (
        <PrimaryButton2
          width="12.222rem"
          onClick={() => {
            setDisconnectModalOpen(true);
            setDisconnectionProvider(provider);
          }}
        >
          Disconnect
        </PrimaryButton2>
      ) : (
        <SecondaryButton width="12.222rem" onClick={handleConnect}>
          Connect
        </SecondaryButton>
      )}
    </FlexContainer>
  );
};

CodeRepositoryItem.propTypes = {
  provider: PropTypes.oneOf(["github", "gitlab", "bitbucket"]).isRequired,
  isConnected: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  setDisconnectionProvider: PropTypes.func.isRequired,
  setDisconnectModalOpen: PropTypes.func.isRequired,
};

CodeRepositoryItem.defaultProps = {
  userName: "",
};

export default CodeRepositoryItem;
