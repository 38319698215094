import React from "react";
import GenericModal from "../../components/common/_genericModal";
import styled from "styled-components";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import PropTypes from "prop-types";
import Tooltip from "../../components/common/_tooltip";

const Title = styled.div`
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  font-size: 1.67rem;
  font-weight: bold;
  color: #f6f6f6;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  justify-content: flex-end;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-top: 1rem;
`;

const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 15rem;
`;

const Label = styled.span`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1rem;
  color: #a0a0a0;
`;

const Value = styled.span`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.1rem;
  color: #ffffff;
  display: block;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DetailsModal = ({ isModalOpen, onClose, title, details }) => {
  const CHARACTER_LIMIT = 15;
  return (
    <GenericModal show={isModalOpen} onClose={onClose}>
      <Title>{title}</Title>
      <DetailsContainer>
        {Object.entries(details).map(([key, value]) => {
          const formattedKey = key
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase());
          const displayValue = value || "NA";

          return (
            <DetailItem key={key}>
              <Label>{formattedKey.trim()}</Label>
              {String(displayValue).length > CHARACTER_LIMIT ? (
                <Tooltip title={String(displayValue)} placement="top">
                  <Value>{displayValue}</Value>
                </Tooltip>
              ) : (
                <Value>{displayValue}</Value>
              )}
            </DetailItem>
          );
        })}
      </DetailsContainer>
      <ButtonContainer>
        <StyledButtonPrimary style={{ width: "14.167rem" }} onClick={onClose}>
          Done
        </StyledButtonPrimary>
      </ButtonContainer>
    </GenericModal>
  );
};

DetailsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.object.isRequired,
};

export default DetailsModal;
