import styled from "styled-components";

const breakpoints = {
  xs: "40rem",
  sm: "83.333rem",
  md: "94.861rem",
  lg: "104.166rem",
  xl: "131.944rem",
  xxl: "187.5rem",
  xxxl: "270.833rem",
};

/**
 * A custom column component that allows you to specify the width of the column
 * at different screen sizes.
 *
 * @param {number} [props.xs] - The width of the column at extra small screens upto 576px.
 * @param {number} [props.sm] - The width of the column at small screens upto 768px.
 * @param {number} [props.md] - The width of the column at medium screens upto 992px.
 * @param {number} [props.lg] - The width of the column at large screens upto 1200px.
 * @param {number} [props.xl] - The width of the column at extra large screens upto 1440px.
 * @param {number} [props.xxl] - The width of the column at extra extra large screens more than 1440px.
 * @return {JSX.Element} The rendered column component.
 */
const Column = styled.div`
  width: 100%;

  ${(props) => {
    const widths = {
      xs: props.xs !== undefined ? props.xs : 12,
      sm: props.sm !== undefined ? props.sm : props.xs,
      md: props.md !== undefined ? props.md : (props.sm ?? props.xs),
      lg:
        props.lg !== undefined ? props.lg : (props.md ?? props.sm ?? props.xs),
      xl:
        props.xl !== undefined
          ? props.xl
          : (props.lg ?? props.md ?? props.sm ?? props.xs),
      xxl:
        props.xxl !== undefined
          ? props.xxl
          : (props.xl ?? props.lg ?? props.md ?? props.sm ?? props.xs),
      xxxl:
        props.xxxl !== undefined
          ? props.xxxl
          : (props.xxl ??
            props.xl ??
            props.lg ??
            props.md ??
            props.sm ??
            props.xs),
    };

    return `
      @media (max-width: ${breakpoints.xs}) {
        width: ${(widths.xs / 12) * 100}%;
      }
      @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.sm}) {
        width: ${(widths.sm / 12) * 100}%;
      }
      @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}) {
        width: ${(widths.md / 12) * 100}%;
      }
      @media (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
        width: ${(widths.lg / 12) * 100}%;
      }
      @media (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl}) {
        width: ${(widths.xl / 12) * 100}%;
      }
      @media (min-width: ${breakpoints.xl}) and (max-width: ${
        breakpoints.xxl
      }) {
        width: ${(widths.xxl / 12) * 100}%;
      }
      @media (min-width: ${breakpoints.xxl}) and (max-width: ${
        breakpoints.xxxl
      }) {
        width: ${(widths.xxxl / 12) * 100}%;
      }
      @media (min-width: ${breakpoints.xxxl}) {
        width: ${(widths.xxxl / 12) * 100}%;
      }
    `;
  }}
`;

export default Column;
