import React, { useEffect, useContext, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { AuthContext } from "../../context/AuthContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1a1a1a;
  color: #f6f6f6;
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #f6f6f6;
  border-top: 5px solid #2f2f2f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingText = styled.h2`
  font-family: "Articulat CF Medium";
  font-size: 1.5rem;
  margin: 0;
  color: #f6f6f6;
`;

export const LoadingScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    getAccessTokenSilently,
    isAuthenticated,
    user,
    error: auth0Error,
    isLoading: auth0Loading,
  } = useAuth0();
  const { showMessage } = useContext(SnackbarContext);
  const { login } = useContext(AuthContext);
  const [hasStartedAuth, setHasStartedAuth] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inviteCode = urlParams.get("invitationCode");
  const [invite, setInvite] = useState("");

  const [fullName, setFullName] = useState(location.state?.fullName ?? "");
  const [emailAddress, setEmailAddress] = useState(location.state?.emailId);
  const [orgName, setOrgName] = useState(location.state?.orgName ?? "");

  useEffect(() => {
    if (inviteCode) {
      setInvite(inviteCode);
    }
  }, [inviteCode]);

  // If there is an invite code in the URL, prefill the invitation details
  useEffect(() => {
    if (invite) {
      (async () => {
        try {
          const response = await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "user-management/getInvitationDetails",
            {
              inviteCode: invite,
            },
          );
          showMessage(response.data.remarks, "success");
          setEmailAddress(response.data.data.email);
          setFullName(response.data.data.fullName);
          setOrgName(response.data.data.orgName);
        } catch (err) {
          console.log("Error: getInvitationDetails", err);
          showMessage(
            err?.response?.data?.remarks ||
              "Unable to obtain the invitation details. Please try again later",
            "error",
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite]);

  const handleAuthentication = useCallback(async () => {
    // Only proceed if we haven't started the auth process yet
    if (hasStartedAuth) return;

    try {
      // If still loading Auth0, wait
      if (auth0Loading) return;

      // If not authenticated after Auth0 has finished loading, redirect to signin
      if (!isAuthenticated || !user) {
        history.push("/signin");
        return;
      }

      setHasStartedAuth(true);
      const token = await getAccessTokenSilently();

      const ssoCallbackResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/ssoCallback",
        {
          authorization: token,
        },
      );

      const callback_data = ssoCallbackResponse.data?.data;
      const jwe_token = callback_data?.token;
      const email = callback_data?.email;

      if (jwe_token) {
        showMessage("Login Successful", "success");
        login(jwe_token);
      } else if (email) {
        let state = {
          emailId: email,
          fullName: callback_data?.name,
          auth0Id: callback_data?.auth0id,
          isSSO: true,
          inviteCode: invite ?? "",
        };
        if (email === emailAddress) {
          state = {
            ...state,
            invitedEmail: true,
            orgName,
            fullName,
          };
        }

        history.push("/signupRequiredDetails", state);
      }
    } catch (error) {
      console.error("Authentication error:", error);
      showMessage("Unable to login, please try again later");
      history.push("/signin");
    }
  }, [
    isAuthenticated,
    user,
    auth0Loading,
    getAccessTokenSilently,
    history,
    showMessage,
    login,
    hasStartedAuth,
    emailAddress,
    fullName,
    invite,
    orgName,
  ]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      handleAuthentication();
    }

    return () => {
      mounted = false;
    };
  }, [handleAuthentication]);

  useEffect(() => {
    if (auth0Error) {
      showMessage("Unable to login, please try again later");
      console.error("Auth0 error:", auth0Error);
      history.push("/signin");
    }
  }, [auth0Error, showMessage, history]);

  return (
    <LoadingContainer>
      <Spinner />
      <LoadingText>Authenticating...</LoadingText>
    </LoadingContainer>
  );
};
