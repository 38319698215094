import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableData,
  TableRow,
  TableWrapper,
} from "../../components/common/_table";
import SQLTablesIcon from "../../assets/images/ksql-tables.svg";
import Collapse from "../../assets/images/left_arrow_icon.svg";
import HoverRight from "../../assets/images/ksql-arrow-right.svg";
import NoRecordsTablesBg from "../../assets/images/ksql-tables-empty.svg";

import DetailsModal from "./DetailsModal";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import PaginationContainerV2 from "../../components/common/_paginationContainerV2";
// import SearchBar from "../WorkspaceSettings/components/SearchBar";
// import Expand from '../../assets/images/ksql-arrow-right.svg'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
`;

const SQLResultContainer = styled.div`
  margin-top: ${(props) => (props.isFullscreen ? "0" : "1rem")};
  width: ${(props) => (props.isFullscreen ? "98%" : "100%")};
  overflow: hidden;
  background-color: ${(props) => (props.isFullscreen ? "#000000" : "#2f2f2f")};
  border: 0.069rem solid #5e5e5e;
  position: ${(props) => (props.isFullscreen ? "fixed" : "relative")};
  top: ${(props) => (props.isFullscreen ? "5rem" : "auto")};
  left: ${(props) => (props.isFullscreen ? "1rem" : "auto")};
  right: ${(props) => (props.isFullscreen ? "1rem" : "auto")};
  bottom: ${(props) => (props.isFullscreen ? "0rem" : "auto")};
  z-index: ${(props) => (props.isFullscreen ? "1000" : "0")};
  border-radius: ${(props) =>
    props.isFullscreen ? "0.75rem 0.75rem 0 0" : "0.75rem"};
  border-bottom: ${(props) =>
    props.isFullscreen ? "none" : "0.069rem solid #5E5E5E"};
  padding: ${(props) => (props.isFullscreen ? "0 1rem 0 1rem" : "0")};
`;

const SQLResultHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isFullscreen ? "1.3rem 0.9rem" : "1rem")};
  background-color: ${(props) => (props.isFullscreen ? "#000000" : "#2f2f2f")};
  border-bottom: ${(props) =>
    props.isFullscreen ? "none" : "0.069rem solid #5E5E5E"};
  border-radius: ${(props) =>
    props.isFullscreen ? "0" : "0.75rem 0.75rem 0 0"};
`;

const SQLResultContent = styled.div`
  height: ${(props) => (props.isFullscreen ? "88%" : "23rem")};
  width: ${(props) => (props.isFullscreen ? "99%" : "100%")};
  margin: ${(props) => (props.isFullscreen ? "0 0.5rem 0 0.5rem" : "0")};
  background-color: ${(props) => (props.isFullscreen ? "#2f2f2f" : "#000000")};
  border-radius: ${(props) => (props.isFullscreen ? "0.75rem" : "0")};
  padding: ${(props) => (props.isFullscreen ? "1rem" : "0")};
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  margin: 0;
  margin-left: 1rem;
  color: #f6f6f6;
  font-size: 1.666rem;
  font-weight: bold;
`;

const SQLResultActions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.97rem;
`;

// const Button = styled.button`
//   outline: none;
//   border: none;
//   background: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `
const EnhancedTableRow = styled(TableRow)`
  position: relative;
  cursor: pointer;
  color: #f3f4f5;

  &:hover {
    background-color: ${(props) => (props.isFullscreen ? "none" : "#47CCD629")};
    color: ${(props) => (props.isFullscreen ? "#f3f4f5" : "#47CCD6")};
  }

  &:hover .row-action {
    opacity: ${(props) => (props.isFullscreen ? 0 : 1)};
    right: 1.5rem;
  }
`;

const RowAction = styled.div`
  position: absolute;
  right: 3.6rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.2s ease;
  color: #47ccd6;
  display: flex;
  align-items: center;
`;

const NoRecords = styled.div`
  background-image: url(${NoRecordsTablesBg});
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const cellStyle = {
  fontFamily: "Articulat CF Normal",
  fontWeight: "bold",
  letterSpacing: "0.5px",
  padding: "0rem 1.7rem",
  fontSize: "1rem",
  height: "3.05rem",
};

const headerCellStyle = {
  textAlign: "left",
  color: "#f6f6f6",
  fontSize: "1.3rem",
  height: "3.33rem",
  paddingLeft: "1.7rem",
  fontFamily: "Articulat CF Medium",
  fontWeight: "bold",
  letterSpacing: "0.5px",
};

const SQLTables = (ksqlResponse) => {
  const [recordsCount, setRecordsCount] = useState();
  const [results, setResults] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  // const [searchValue, setSearchValue] = useState('');
  const [selectedTable, setSelectedTable] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 10;

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const openModal = async (table) => {
    await fetchTableDetails(table.tableName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchTables = useCallback(async () => {
    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL +
          `stream/ksql/showTables?page=${page}&limit=${limit}`,
      );

      setResults(response.data.data.tables);
      setRecordsCount(response.data.data.pagination.totalCount);
    } catch (error) {
      console.log("Unable to fetch tables");
    }
  }, [page, limit, ksqlResponse]);

  const fetchTableDetails = async (tableName) => {
    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "stream/ksql/describeTable",
        {
          tableName,
        },
      );

      setSelectedTable({ tableName, ...response.data.data });
    } catch (error) {
      console.log("Unable to fetch table details");
    }
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("tableName", {
      header: () => "Table Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("topic", {
      header: () => "Kafka Topic Name",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: results,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    fetchTables();
  }, [fetchTables]);

  return (
    <>
      <Overlay isVisible={isFullscreen} onClick={toggleFullscreen} />
      <SQLResultContainer isFullscreen={isFullscreen}>
        <SQLResultHeader isFullscreen={isFullscreen}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
              src={isFullscreen ? Collapse : SQLTablesIcon}
              alt={isFullscreen ? "Back" : "Result"}
              onClick={isFullscreen ? toggleFullscreen : () => {}}
            />
            <Text>Tables</Text>
          </div>
          <SQLResultActions>
            {recordsCount && (
              <div style={{ color: "#b0b0b0" }}>
                {recordsCount} {recordsCount === 1 ? "Record" : "Records"} Found
              </div>
            )}
            {/* {!isFullscreen && <Button onClick={toggleFullscreen}>
              <div style={{color:"#47CCD6",fontSize:"16px",marginRight:"0.5rem"}}>View All</div>
              <img src={Expand} alt='Expand' />
            </Button>}
            {isFullscreen && 
              <SearchBar value={searchValue} setValue={setSearchValue} placeholderText='Search for Tables'/> } */}
          </SQLResultActions>
        </SQLResultHeader>
        <SQLResultContent isFullscreen={isFullscreen}>
          {results?.length && table.getRowModel().rows.length ? (
            <TableWrapper style={{ height: "100%" }}>
              <Table style={{ width: "100%" }}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      style={{
                        borderBottom: isFullscreen
                          ? "none"
                          : "0.069rem solid #9090903D",
                        background: isFullscreen ? "#4B4B4B" : "none",
                      }}
                    >
                      {headerGroup.headers.map((header, index) => (
                        <th
                          key={header.id}
                          style={{
                            ...headerCellStyle,
                            width: isFullscreen ? "100%" : "18rem",
                            borderTopLeftRadius: index === 0 ? "0.75rem" : "0",
                            borderBottomLeftRadius:
                              index === 0 ? "0.75rem" : "0",
                            borderTopRightRadius:
                              index === headerGroup.headers.length - 1
                                ? "0.75rem"
                                : "0",
                            borderBottomRightRadius:
                              index === headerGroup.headers.length - 1
                                ? "0.75rem"
                                : "0",
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <EnhancedTableRow
                      isFullscreen={isFullscreen}
                      key={row.id}
                      style={{ borderBottom: "0.069rem solid #9090903D" }}
                      onClick={() => openModal(row.original)}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableData key={cell.id} style={cellStyle}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableData>
                      ))}
                      <RowAction className="row-action">
                        <img
                          src={HoverRight}
                          alt="details"
                          style={{ height: "1.2rem", width: "1.2rem" }}
                        />
                      </RowAction>
                    </EnhancedTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
          ) : (
            <NoRecords></NoRecords>
          )}
        </SQLResultContent>
        <PaginationContainerV2
          totalCount={recordsCount}
          currentPage={page}
          pageSize={limit}
          onPageChange={setPage}
        />
      </SQLResultContainer>
      {/* Modal */}
      {isModalOpen && (
        <DetailsModal
          title={"Table Details"}
          isModalOpen={isModalOpen}
          onClose={closeModal}
          details={selectedTable}
        />
      )}
    </>
  );
};

export default SQLTables;
