import React from "react";
import CustomRow from "../../components/common/_customRow";
import styled from "styled-components";
import backIcon from "../../assets/images/left_arrow_icon.svg";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { StyledButtonPrimary } from "../common/_buttonNewOne";
import Column from "../../components/common/_customColumn";
import Icon_strean from "../../assets/images/streamIcon.svg";
import Search from "../../assets/images/Searchnew.svg";
import Prettify from "../../assets/images/Prettify.svg";
import CopyIcon from "../../assets/images/CopyIcon.svg";
import JSONPretty from "react-json-pretty";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import SetupJson from "../../assets/images/Setup-Json.svg";
import UploadedKeys from "../../assets/images/Uploaded-keys.svg";
import JsonKeys from "../../assets/images/Json-Keys.svg";
import JsonDialogsonDialog from "./JsonDialogsonDialog";
import ErrorEmptyState from "../../assets/images/ErrorEmptyState.svg";

const OverlayContainer = styled.div`
  position: fixed;
  top: 3.83rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black-1, #0d0d0d);
  z-index: 1;
  height: 100%;
  margin: 1.12rem;
  overflow: hidden;
  border-top-right-radius: 0.84rem;
  border-top-left-radius: 0.84rem;
  border: 1px solid var(--grey-1, #777777);
  padding: 1.112rem;
`;

const Text = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #f6f6f6;
  font-size: 1.67rem;
`;

const PasteText = styled.div`
  color: #777777;
  font-size: 1.2rem;
  padding-left: 1.112rem;
  padding-top: 1.112rem;
`;

// Styled Components
const TableContainer = styled.div`
  margin-top: 1.112rem;
  overflow: hidden;
`;

const TableBodyContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 25rem);
  min-height: calc(100vh - 25rem);
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => (props.header ? "#5e5e5e80" : "transparent")};
  border-bottom: ${(props) => (props.header ? "none" : "1px solid #9090903D")};
  padding: 0.8rem;
  ${(props) => (props.header ? "border-radius: 0.84rem" : "")}
`;

const ColumnNew = styled.div`
  flex: 1; /* Ensures equal width for all columns */
  text-align: center;
  padding: 0.5rem;
  word-wrap: break-word; /* Ensures long words break properly */
  overflow-wrap: break-word; /* Alternative for word breaking */
  max-width: 50%; /* Keeps consistency */
`;

const TableHeaderText = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #b0b0b0;
  font-size: 1.3rem;
`;

const TableContentText = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #f6f6f6;
  font-size: 1.2rem;
`;

const Icon = styled.div`
  height: 2.225rem;
  width: 2.225rem;
  cursor: pointer;
`;

const KeysContainer = styled.div`
  width: 31.67rem;
  height: calc(100vh - 8rem);
  background-color: #2f2f2f;
  padding: 1.112rem;
  border-radius: 0.84rem;
`;

const DefaultScreen = styled.div`
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Ensure text is centered */
`;

const HeaderContainer = styled.div`
  padding-bottom: 1.67rem;
`;

const JsonView = styled.div`
  width: 62.777rem;
  height: calc(100vh - 8rem);
  background-color: #2f2f2f;
  border-radius: 0.84rem;
`;

const StreamIcon = styled.div`
  height: 2.225rem;
  width: 2.225rem;
  border-radius: 0.28rem;
  background-color: #4b4b4b;
  padding: 0.28rem;
`;

const IconsContainer = styled.div`
  justify-content: space-between;
  width: 3.4rem;
  display: flex;
  /* height: 3.89rem; */
`;

const JsonHeading = styled.div`
  font-size: 1.67rem;
  text-align: left;
  padding: 1.3rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  line-height: 1.5rem;
  color: #fafafa;
`;

const Heading = styled.div`
  font-size: 1.67rem;
  text-align: left;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  margin-left: 0.84rem;
  color: #fafafa;
`;

const SearchKey = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  width: 29.44rem;
  height: 3.333rem;
  background-color: #272727;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.8rem;
  margin-top: 1.112rem;
  border: 0.07rem solid #777777;
`;

const HorizontalLine = styled.div`
  width: 62.722rem;
  border-top: 0.07rem solid #4b4b4b;
  margin-right: 8rem;
  position: relative;
`;

const TableHeader = styled.div`
  width: 29.722rem;
  height: 3.889rem;
  background-color: #5e5e5e80;
  justify-content: space-between;
  border-radius: 0.84rem;
  margin-top: 1.112rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 20px; */
  width: 62.78rem;
  max-height: calc(100vh - 16rem);
  min-height: calc(100vh - 16rem);
  /* overflow-y: scroll; */
  overflow: auto;
`;

const TextAreaWrapper = styled.div`
  position: relative;
  width: 62.78rem;
  background: #2f2f2f;
  border-radius: 0.5rem;
  display: flex;
  height: calc(100vh - 16rem);
  overflow: auto;
`;

const EditorContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  // height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const LineNumbers = styled.div`
  width: 3rem;
  height: 100%;
  background: #272727;
  color: #777777;
  font-family: monospace;
  font-size: 1rem;
  line-height: 1.5;
  text-align: right;
  user-select: none;
  border-right: 1px solid #4b4b4b;
  overflow: hidden;
  padding-right: 0.5rem;
`;

const LineNumbersContent = styled.div`
  padding-top: 0.7rem;
  padding-right: 0.5rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  border: none;
  font-size: 1rem;
  font-family: monospace;
  line-height: 1.5;
  resize: none;
  background: transparent;
  color: #777777;
  caret-color: #777777;
  outline: none;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.6rem;
  overflow-y: auto;
  height: calc(100vh - 16rem);
  max-height: calc(100vh - 16rem);
  min-height: calc(100vh - 16rem);

  /* Hide scrollbar for WebKit browsers */
  /* &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  } */

  /* &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  } */

  /* Hide scrollbar for Firefox */
  /* scrollbar-width: none;  */

  /* Hide scrollbar for IE/Edge */
  /* -ms-overflow-style: none; */
`;

const HighlightOverlay = styled.pre`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0.7rem;
  pointer-events: none; /* Make sure it doesn't block interaction */
  font-size: 1rem;
  font-family: monospace;
  line-height: 1.5;
  white-space: pre-wrap;
  background: transparent;
  color: transparent;
  overflow: hidden; /* Prevent any scroll */

  .error-line {
    background-color: rgba(255, 0, 0, 0.2); /* Light red highlight */
    display: block;
    width: 100%;
  }
`;

const AddGroup = styled.span`
  display: flex;
  align-items: center; /* Align icon and text */
  gap: 1rem; /* Add spacing between icon and text */
`;

export const JsonModal = ({
  handleCloseModal,
  setEditDialogOpen,
  isEditDialogOpen,
  setJsonValue,
  jsonValue,
  isReuploadJson,
  isReadTopic,
  setGroupsAndRules,
  groupsAndRules,
  isConfigurationOpen,
  setisConfigurationOpen,
  setConnectionsVal,
  connectionsVal,
  setJsonOnlyKeys,
  setFieldOptionValues,
  utilityName = "",
}) => {
  const history = useHistory();
  const location = useLocation();

  // const handleBackClick = useCallback(() => {
  //   history.push("/connectors");
  // }, [history]);

  const [searchValue, setSearchValue] = useState("");
  const { showMessage } = useContext(SnackbarContext);
  const [jsonInput, setJsonInput] = useState("");
  const textAreaRef = useRef(null);
  const [jsonKeys, setJsonKeys] = useState([]);
  const [jsonKeysTemp, setJsonKeysTemp] = useState([]);
  const [isProceedClicked, setIsProceedClicked] = useState(false);

  const handleSearchTextChange = useCallback(
    (text) => {
      setSearchValue(text);

      if (text.trim() === "") {
        setJsonKeysTemp(jsonKeys); // Reset to full list if search is cleared
      } else {
        const filteredKeys = jsonKeys.filter(
          (item) =>
            item.key.toLowerCase().includes(text.toLowerCase()) ||
            item.type.toLowerCase().includes(text.toLowerCase()), // Allow search by key and type
        );
        setJsonKeysTemp(filteredKeys);
      }
    },
    [jsonKeys],
  );

  const [error, setError] = useState("");
  const [errorLine, setErrorLine] = useState([]);
  const [erroPresent, setErroPresent] = useState(false);

  const lineNumbersRef = useRef(null);
  const highlightRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [isValidateClicked, setIsValidateClicked] = useState(false);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;

    if (lineNumbersRef.current) {
      lineNumbersRef.current.scrollTop = scrollTop;
    }
    if (highlightRef.current) {
      highlightRef.current.scrollTop = scrollTop;
    }
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollTop;
    }
  };

  const handleJsonChange = (input) => {
    let value =
      typeof input === "object" && input?.target ? input.target.value : input; // Ensure it's a string

    setJsonInput(value); // Store raw text first
    validateJson(value); // Validate separately

    try {
      const parsedJson = JSON.parse(value);
      if (typeof parsedJson === "object" && parsedJson !== null) {
        const keysWithTypes = Object.keys(parsedJson).map((key) => ({
          key,
          type: Array.isArray(parsedJson[key])
            ? "array"
            : typeof parsedJson[key] === "number"
              ? Number.isInteger(parsedJson[key])
                ? "integer"
                : "float"
              : typeof parsedJson[key] === "boolean"
                ? "boolean"
                : "string",
        }));
        setJsonKeys(keysWithTypes);
        setJsonKeysTemp(keysWithTypes);
      } else {
        setJsonKeys([]);
      }
    } catch (e) {
      setJsonKeys([]); // If JSON is invalid, reset keys
    }
  };

  // const handleJsonChange = (input) => {
  //   let value = typeof input === "object" && input?.target ? input.target.value : input; // Ensure value is always a string

  //   let formattedInput = '';

  //   try {
  //     const parsedJson = JSON.parse(value);
  //     formattedInput = JSON.stringify(parsedJson, null, 2);
  //   } catch (error) {
  //     formattedInput = value || ""; // Default to an empty string if undefined/null
  //   }

  //   setJsonInput(formattedInput);
  //   validateJson(formattedInput);

  //   try {
  //     const parsedJson = JSON.parse(formattedInput);
  //     if (typeof parsedJson === "object" && parsedJson !== null) {
  //       const keysWithTypes = Object.keys(parsedJson).map((key) => ({
  //         key,
  //         type: Array.isArray(parsedJson[key]) ? "array" : typeof parsedJson[key],
  //       }));
  //       setJsonKeys(keysWithTypes);
  //     } else {
  //       setJsonKeys([]);
  //     }
  //   } catch (e) {
  //     setJsonKeys([]);
  //   }
  // };

  useEffect(() => {
    if (jsonValue) {
      const prettyJSON = JSON.stringify(JSON.parse(jsonValue), null, 2);
      handleJsonChange(prettyJSON);
      setIsValidateClicked(true);
    }
  }, [jsonValue]);

  // Handle input change
  const handleInputChange = (e) => {
    handleJsonChange(e.target.value);

    // Preserve scroll position before updating state
    const scrollPosition = textAreaRef.current.scrollTop;
    // Restore scroll position after update
    setTimeout(() => {
      textAreaRef.current.scrollTop = scrollPosition;
    }, 0);
  };

  // Handle paste event
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text").trim(); // Get pasted text and trim spaces

    try {
      // Try parsing the entire input as JSON
      const pastedJson = JSON.parse(pastedText);

      // Ensure jsonInput is valid JSON; otherwise, default to an empty object
      let existingJson = {};
      try {
        existingJson = jsonInput ? JSON.parse(jsonInput) : {};
      } catch {
        existingJson = {};
      }

      if (typeof pastedJson === "object" && !Array.isArray(pastedJson)) {
        // Merge existing JSON with pasted JSON
        const updatedJson = { ...existingJson, ...pastedJson };
        handleJsonChange(JSON.stringify(updatedJson, null, 2));
      } else {
        showMessage("Invalid JSON format!", "fail");
      }
    } catch (error) {
      // If parsing fails, allow raw text pasting (for JSON editing)
      const cursorPos = e.target.selectionStart;
      const newText =
        jsonInput.slice(0, cursorPos) + pastedText + jsonInput.slice(cursorPos);
      handleJsonChange(newText);
    }
  };

  const validateJson = (input) => {
    try {
      JSON.parse(input);
      setErrorLine([]); // No error
      setErroPresent(false);
    } catch (e) {
      const lineMatch = e.message.match(/at line (\d+)/);
      if (lineMatch && lineMatch[1]) {
        const errorLine = parseInt(lineMatch[1], 10);
        setErrorLine([errorLine]);
      } else {
        setErrorLine([]);
        // Reset if no line number is found
      }
    }
  };

  const handleJsonOptions = (jsonVal) => {
    const sampleData = isNotEmptyObject(jsonVal)
      ? jsonVal
      : jsonVal && typeof jsonVal === "string"
        ? JSON.parse(jsonVal)
        : "";

    let fieldOptions = [];
    if (typeof sampleData === "object") {
      fieldOptions = Object.entries(sampleData).map(([key, value]) => ({
        label: key, // Key from sampleData
        value: ["string", "number", "boolean"].includes(typeof value)
          ? value
          : "Unknown",
      }));
    } else {
      fieldOptions = [];
    }
    return fieldOptions;
  };

  const isNotEmptyObject = (obj) =>
    obj && typeof obj === "object" && Object.keys(obj).length > 0;

  const onclickProcced = () => {
    showMessage(
      isReuploadJson
        ? "The JSON has been successfully edited. Your changes have been saved and will be reflected"
        : "The JSON has been successfully uploaded. The changes will be reflected.",
      "success",
      isReuploadJson ? "JSON Edited" : "JSON Uploaded",
    );
    setEditDialogOpen(false);
    handleCloseModal();
    setJsonValue(jsonInput);
    let fieldOptionsVal = handleJsonOptions(jsonInput);
    setFieldOptionValues(fieldOptionsVal);
    setErrorLine([]);
    setisConfigurationOpen(isConfigurationOpen);
    setGroupsAndRules(groupsAndRules);
    setConnectionsVal(connectionsVal);
    try {
      let jsonArray = transformArray(
        jsonKeysTemp.length > 0 ? jsonKeysTemp : [],
      );
      setJsonOnlyKeys(jsonArray);
    } catch (error) {
      setJsonOnlyKeys([]);
    }
  };

  const transformArray = (data) => data.map(({ key }) => key);

  const handleValidate = () => {
    setIsValidateClicked(true);

    setJsonKeysTemp(jsonKeys);
    try {
      JSON.parse(jsonInput);

      if (jsonKeysTemp.length > 0) {
        if (isReuploadJson) {
          setEditDialogOpen(true);
        } else {
          // showMessage("JSON is valid!", "success");
          // setEditDialogOpen(true);
          setErrorLine([]);
          setErroPresent(false);
          onclickProcced();
        }
      } else {
        showMessage("Invalid JSON");
      }
    } catch (err) {
      setErroPresent(true);
      showMessage("Invalid JSON: " + err.message, "fail");
      const match = err.message.match(/at position (\d+)/);

      console.log("errorLine 2");
      if (match) {
        const pos = parseInt(match[1], 10);
        const lines = jsonInput.substring(0, pos).split("\n");
        setErrorLine([lines.length]);
      } else {
        setErrorLine([]);
      }
    }
  };

  const handlePrettify = () => {
    try {
      const prettyJSON = JSON.stringify(JSON.parse(jsonInput), null, 2);
      setJsonInput(prettyJSON);
      setError("");
      setErrorLine([]);
    } catch (err) {
      setError(err.message);
      const match = err.message.match(/at position (\d+)/);
      if (match) {
        const pos = parseInt(match[1], 10);
        const lines = jsonInput.substring(0, pos).split("\n");
        setErrorLine([lines.length]);
      }
    }
  };

  const handleCopy = () => {
    if (jsonInput) {
      navigator.clipboard.writeText(jsonInput);
      showMessage(
        "The JSON has been successfully copied to your clipboard",
        "success",
        "JSON Copied",
      );
    } else {
      showMessage("Please Add your Json");
    }
  };

  const highlightError = (text) => {
    if (!errorLine) return text;
    return text
      .split("\n")
      .map((line, index) => {
        if (index + 1 === errorLine) {
          return `<span class="error-line">${line}</span>`;
        }
        return line;
      })
      .join("\n");
  };

  const getHighlightedText = () => {
    const lines = jsonInput.split("\n");
    return lines.map((line, index) => {
      const lineNumber = index + 1;

      console.log(errorLine, "errorLine");
      const isErrorLine = errorLine.includes(lineNumber); // Check if this line has an error

      return (
        <div key={index} className={isErrorLine ? "error-line" : ""}>
          {line || "\u00A0"}
        </div>
      );
    });
  };

  const LineNumbersComponent = () => {
    const lines = jsonInput.split("\n").length; // Calculate the number of lines dynamically
    return (
      <LineNumbers ref={lineNumbersRef}>
        <ScrollContainer>
          <LineNumbersContent>
            {Array.from({ length: lines }, (_, i) => {
              const lineNumber = i + 1;
              const isErrorLine = errorLine.includes(lineNumber); // Check for multiple errors
              return (
                <div
                  key={lineNumber}
                  style={{
                    color: isErrorLine ? "#ff6b6b" : "#777777", // Red color for error line number
                    fontWeight: "normal",
                    height: "1.5em",
                  }}
                >
                  {lineNumber}
                </div>
              );
            })}
          </LineNumbersContent>
        </ScrollContainer>
      </LineNumbers>
    );
  };

  return (
    <Column>
      <CustomRow>
        <OverlayContainer>
          <HeaderContainer>
            <CustomRow>
              <AddGroup>
                <Icon onClick={handleCloseModal}>
                  <img
                    src={backIcon}
                    alt="Back Icon"
                    style={{ width: "2.225rem", height: "2.225rem" }}
                  />
                </Icon>
                <Text>
                  {utilityName ? `JSON for ${utilityName}` : "Preview JSON"}
                </Text>
              </AddGroup>
              {!utilityName && (
                <StyledButtonPrimary
                  onClick={handleValidate}
                  style={{ width: "14.167rem" }}
                  disabled={jsonInput ? false : true}
                >
                  Validate JSON
                </StyledButtonPrimary>
              )}
            </CustomRow>
          </HeaderContainer>
          {isEditDialogOpen && (
            <JsonDialogsonDialog
              dialogTitle="You have edited the JSON"
              showEditDialog={isEditDialogOpen}
              onDialogClose={() => {
                setEditDialogOpen(false);
              }}
              onclickProcced={onclickProcced}
              buttonName={"Proceed"}
              descriptionText={`You're about to edit the JSON, and any changes will directly affect the rules you've built. Are you sure you want to proceed?`}
            />
          )}

          <CustomRow>
            <KeysContainer>
              <Column>
                <CustomRow style={{ justifyContent: "normal" }}>
                  {/* <StreamIcon> */}
                  <img
                    style={{ height: "2rem", width: "2rem" }}
                    src={UploadedKeys}
                  />
                  {/* </StreamIcon> */}
                  <Heading>
                    {isReadTopic ? "Detected" : "Uploaded"} "Keys"
                  </Heading>
                </CustomRow>
                {isValidateClicked ? (
                  <>
                    <SearchKey>
                      <div className="wsConnectorSearchIcon">
                        <img
                          src={Search}
                          alt=""
                          style={{ width: "1.67rem", height: "1.67rem" }}
                        />
                      </div>
                      <div style={{ marginLeft: "1.112rem" }}>
                        <input
                          className="KeySearchTextInput"
                          type="text"
                          value={searchValue}
                          onChange={(e) => {
                            handleSearchTextChange(e.target.value);
                          }}
                          placeholder="Search for key or data type"
                          style={{
                            color: "#777777",
                            width: "25rem",
                            fontFamily: "Articulat CF Normal",
                            fontWeight: "bold",
                            letterSpacing: "0.5px",
                          }}
                        />
                      </div>
                    </SearchKey>

                    <TableContainer>
                      <Row header>
                        <ColumnNew>
                          <TableHeaderText>Key</TableHeaderText>
                        </ColumnNew>
                        <ColumnNew>
                          <TableHeaderText>Type</TableHeaderText>
                        </ColumnNew>
                      </Row>
                      <TableBodyContainer>
                        {erroPresent ? (
                          <>
                            <img
                              style={{
                                height: "18rem",
                                width: "18rem",
                                marginTop: "5rem",
                                marginLeft: "5rem",
                              }}
                              src={ErrorEmptyState}
                              alt=""
                            />
                          </>
                        ) : (
                          <>
                            {jsonKeysTemp.map((item, index) => (
                              <Row key={index}>
                                <ColumnNew>
                                  <TableContentText>
                                    {item.key}
                                  </TableContentText>
                                </ColumnNew>
                                <ColumnNew>
                                  <TableContentText>
                                    {item.type}
                                  </TableContentText>
                                </ColumnNew>
                              </Row>
                            ))}
                          </>
                        )}
                      </TableBodyContainer>
                    </TableContainer>
                  </>
                ) : (
                  <DefaultScreen>
                    <img
                      src={JsonKeys}
                      style={{
                        height: "18rem",
                        width: "18rem",
                        marginTop: "5rem",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#F6F6F6",
                      }}
                    >
                      JSON Keys and Values
                    </span>
                    <span
                      style={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: "#B0B0B0",
                        marginTop: "0.5rem",
                        letterSpacing: "0.5px",
                        fontFamily: "Articulat CF Normal",
                      }}
                    >
                      You'll be able to see the keys and values
                      <br /> here once you paste your JSON
                    </span>
                  </DefaultScreen>
                )}
              </Column>
            </KeysContainer>
            <JsonView>
              <JsonHeading>
                <CustomRow>
                  <AddGroup>
                    <img
                      style={{
                        height: "2rem",
                        width: "2rem",
                        marginRight: "1.112rem",
                      }}
                      src={SetupJson}
                    />
                    {isReadTopic ? "Detected" : "Uploaded"} JSON
                  </AddGroup>
                  <AddGroup>
                    <IconsContainer>
                      {/* <img
                        src={Prettify}
                        onClick={handlePrettify}
                        alt="prettify"
                        style={{ height: "1.5rem", width: "1.5rem" }}
                      /> */}
                      <img
                        src={CopyIcon}
                        onClick={handleCopy}
                        alt="CopyIcon"
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          cursor: "pointer",
                          marginLeft: "2rem",
                        }}
                      />
                    </IconsContainer>
                  </AddGroup>
                </CustomRow>
              </JsonHeading>
              <HorizontalLine />
              <Container>
                <TextAreaWrapper ref={scrollContainerRef}>
                  <LineNumbersComponent />
                  {errorLine.length > 0 && (
                    <HighlightOverlay
                      ref={highlightRef}
                      style={{ pointerEvents: "none" }}
                    >
                      {getHighlightedText()}
                    </HighlightOverlay>
                  )}
                  <TextArea
                    ref={textAreaRef}
                    value={jsonInput}
                    onChange={handleInputChange}
                    onScroll={handleScroll} // Add scroll event handler
                    onPaste={handlePaste}
                    placeholder="Paste your JSON here..."
                    style={{ color: "#B0B0B0" }}
                    id="jsonInputField"
                    disabled={utilityName ? true : false}
                  />
                </TextAreaWrapper>
              </Container>
            </JsonView>
          </CustomRow>
        </OverlayContainer>
      </CustomRow>
    </Column>
  );
};
