import React, { useContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import styled from "styled-components";
import { ReactComponent as CopyIcon } from "../../../assets/images/workspace_settings_copy_icon.svg";
import GridContainer from "../../../components/common/_gridContainer";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";

const InputFieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  font-size: 0.972rem;
  color: #b0b0b0;
  margin-bottom: 0.555rem;
`;

const Input = styled.input`
  && {
    width: 100%;
    padding: ${({ $isCopyContentRequired }) =>
      $isCopyContentRequired
        ? "0.972rem 3.611rem 0.972rem 0.972rem"
        : "0.972rem 1.111rem"};
    background: #2f2f2f;
    color: #f6f6f6;
    caret-color: #f6f6f6;
    border: 0.069rem solid #b0b0b0;
    border-radius: 0.555rem;
    &:disabled {
      color: #b0b0b0;
      border: 0.069rem solid #2f2f2f;
      border-radius: 0.555rem;
    }
    &:focus-visible {
      outline: none;
    }
    &:is(:-webkit-autofill, :autofill) {
      -webkit-box-shadow: 0 0 0 2.083rem #2f2f2f inset !important;
      -webkit-text-fill-color: #f6f6f6 !important;
      caret-color: #f6f6f6;
    }
  }
`;

const CopyIconStyled = styled(CopyIcon)`
  width: 1.666rem;
  height: 1.666rem;
  background: transparent;
  position: absolute;
  top: calc(1.263rem + (3.666rem - 0.833rem) / 2);
  right: 0.972rem;
  cursor: pointer;
  &:hover {
    path {
      fill: ${({ $hoverIconColor }) => $hoverIconColor || "#F6F6F6"};
    }
  }
`;

export const InputField = ({
  value,
  setValue,
  disabled,
  labelText,
  fieldName,
  inputFieldType,
  autoCompleteType,
  isCopyContentRequired,
}) => {
  const { showMessage } = useContext(SnackbarContext);

  const copyContentToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(value[fieldName]);
      showMessage(
        "Organisation ID successfully copied to the clipboard",
        "success",
      );
    } catch (error) {
      console.error(error.message);
      showMessage(error.message, "error");
    }
  };

  const allowOnlyNumbers = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  return (
    <InputFieldWrapper>
      <Label htmlFor={fieldName}>{labelText}</Label>
      <Input
        $isCopyContentRequired={isCopyContentRequired}
        id={fieldName}
        type={inputFieldType}
        autoComplete={autoCompleteType}
        value={value[fieldName]}
        onChange={(e) =>
          setValue((value) => ({
            ...value,
            [fieldName]:
              fieldName === "username"
                ? e.target.value.toLowerCase()
                : inputFieldType === "tel" || fieldName === "phoneNumber"
                  ? allowOnlyNumbers(e.target.value).slice(0, 15)
                  : e.target.value,
          }))
        }
        disabled={disabled}
      />
      {isCopyContentRequired && (
        <CopyIconStyled onClick={copyContentToClipBoard} />
      )}
    </InputFieldWrapper>
  );
};

InputField.propTypes = {
  value: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  inputFieldType: PropTypes.string.isRequired,
  autoCompleteType: PropTypes.string.isRequired,
  isCopyContentRequired: PropTypes.bool,
};

InputField.defaultProps = {
  disabled: false,
  isCopyContentRequired: false,
};

export const InputFieldGridContainer = styled(GridContainer)`
  grid: auto-flow / repeat(3, 1fr);
`;

InputField.propTypes = {
  autoCompleteType: PropTypes.string,
};
