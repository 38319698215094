import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import { useState, useContext, useEffect } from "react";
import Google from "../../assets/images/google_logo.svg";
import Microsoft from "../../assets/images/microsoft_logo.svg";

import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { useAuth0 } from "@auth0/auth0-react";

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Normal";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
`;

export const NewSignUp = () => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  const { showMessage } = useContext(SnackbarContext);

  const [fullName, setFullName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [disableEmailAddress, setDisableEmailAddress] = useState(false);
  const [ssoType, setSSOType] = useState("");
  const [isHoveredGoogle, setIsHoveredGoogle] = useState(false);
  const [isHoveredMicrosoft, setIsHoveredMicrosoft] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inviteCode = urlParams.get("invitationCode");

  // If there is an invite code in the URL, prefill the invitation details
  useEffect(() => {
    if (inviteCode) {
      (async () => {
        try {
          const response = await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "user-management/getInvitationDetails",
            {
              inviteCode,
            },
          );
          showMessage(response.data.remarks, "success");
          setEmailAddress(response.data.data.email);
          setFullName(response.data.data.fullName);
          setOrgName(response.data.data.orgName);
          setDisableEmailAddress(true);
        } catch (err) {
          console.log("Error: getInvitationDetails", err);
          showMessage(
            err?.response?.data?.remarks ||
              "Unable to obtain the invitation details. Please try again later",
            "error",
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value.trim().toLowerCase();
    setEmailAddress(newEmail);
    if (EMAIL_REGEX.test(newEmail)) {
      setEmailAddressError("");
    }
  };

  const handleSSOLogin = async (connection) => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          connection,
          redirect_uri:
            window.location.origin +
            `/authLoading${inviteCode ? `?invitationCode=${inviteCode}` : ""}`,
        },
      });
    } catch (error) {
      showMessage("Unable to login, please try again later");
      console.error("SSO login error:", error);
    }
  };

  const handleSSOCheck = async () => {
    try {
      if (emailAddressError) return;

      const emailSSOCheckResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/emailTypeSSO",
        {
          email: emailAddress,
        },
      );

      const data = emailSSOCheckResponse.data?.data;
      if (data?.sso || data?.ssotype) {
        if (data?.ssotype) {
          setSSOType(data?.ssotype);
        }
        showMessage(
          "Looks like you already have an account with us, Please login to continue",
        );
      } else {
        history.push("/signupRequiredDetails", {
          emailId: emailAddress,
          fullName,
          orgName,
          inviteCode,
          invitedEmail: true,
        });
      }
    } catch (error) {
      console.error("Email Validation error:", error);
      showMessage("Unable to login, please try again later");
      history.push("/signin");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !emailAddressError) {
      e.preventDefault();
      handleSSOCheck();
    }
  };

  const handleMouseEnterGoogle = () => setIsHoveredGoogle(true);
  const handleMouseLeaveGoogle = () => setIsHoveredGoogle(false);

  const handleMouseEnterMicrosoft = () => setIsHoveredMicrosoft(true);
  const handleMouseLeaveMicrosoft = () => setIsHoveredMicrosoft(false);

  return (
    <CommonFirstPage
      increaseTopGap="true"
      page="signUp"
      nonSSOflow={ssoType === "native" ? true : false}
    >
      <CustomRow>
        <CustomColumn mb={2.777}>
          <Text style={{ letterSpacing: "0.5px" }}>Welcome to Condense,</Text>
          <Text style={{ letterSpacing: "0.5px" }}>
            Create an account to continue
          </Text>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              emailAddressError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Email Address"
              name="emailAddress"
              onChange={handleEmailChange}
              value={emailAddress}
              disabled={disableEmailAddress}
              onKeyDown={handleKeyDown}
            />
          </InputFieldContainer>
          {emailAddressError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {emailAddressError}
            </Text>
          )}
        </CustomColumn>

        <CustomColumn mb={1.111}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={handleSSOCheck}
            disabled={emailAddressError}
          >
            Signup
          </StyledButtonPrimary>
        </CustomColumn>

        <CustomColumn
          mb={1.111}
          style={{ display: "flex", alignItems: "center" }}
        >
          <hr style={{ flexGrow: 1, border: "0.0347rem solid #CCCCCC" }} />
          <Text
            style={{
              fontSize: "1rem",
              fontWeight: 500,
              color: "#CCCCCC",
              margin: "0 0.694rem 0 0.694rem",
            }}
          >
            or Signup with
          </Text>
          <hr style={{ flexGrow: 1, border: "0.0347rem solid #CCCCCC" }} />
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <StyledButtonsecondary
            style={{
              width: "100%",
              height: "3.888rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flex: 1,
              backgroundColor: isHoveredGoogle ? "#4B4B4B" : "#2F2F2F",
              color: "white",
              fontWeight: 500,
              border: "none",
              borderRadius: "0.54rem",
            }}
            onClick={() => handleSSOLogin("google-oauth2")}
            onMouseEnter={handleMouseEnterGoogle}
            onMouseLeave={handleMouseLeaveGoogle}
          >
            <img
              src={Google}
              alt=""
              style={{ width: "1.8rem", height: "1.8rem", flexShrink: 0 }}
            />{" "}
            Google
          </StyledButtonsecondary>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <StyledButtonsecondary
            style={{
              width: "100%",
              height: "3.888rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flex: 1,
              backgroundColor: isHoveredMicrosoft ? "#4B4B4B" : "#2F2F2F",
              color: "white",
              fontWeight: 500,
              border: "none",
              borderRadius: "0.54rem",
            }}
            onClick={() => handleSSOLogin("windowslive")}
            onMouseEnter={handleMouseEnterMicrosoft}
            onMouseLeave={handleMouseLeaveMicrosoft}
          >
            <img
              src={Microsoft}
              alt=""
              style={{ width: "1.8rem", height: "1.8rem", flexShrink: 0 }}
            />{" "}
            Microsoft
          </StyledButtonsecondary>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
