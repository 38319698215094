import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import FlexContainer from "../common/_flexContainer";
import { getAllWorkspaces, remToPx } from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import Text from "../common/_text";
import { AuthContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { workspaceAvatarMap } from "../common/_workspaceAvatarMap";
import { ReactComponent as OpenIcon } from "../../assets/images/select_open_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/select_close_icon.svg";
import Dropdown from "../common/_dropdown";
import WorkspaceDropdownContent from "./WorkspaceDropdownContent";
import Tooltip from "../common/_tooltip";
import { CreateEditWorkspaceModalContext } from "../../context/CreateEditWorkspaceModalContext";

const OpenIconStyled = styled(OpenIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;
const CloseIconStyled = styled(CloseIcon)`
  width: 1.25rem;
  height: 1.25rem;
`;
const AvatarImage = styled(FlexContainer)`
  height: 2.222rem;
  width: 2.222rem;
`;
const FlexContainerForSelect = styled(FlexContainer)`
  flex-wrap: nowrap;
  max-width: 18.055rem;
  cursor: pointer;
`;
const dropdownOffset = remToPx(0.9);

const WorkspaceSelect = () => {
  const history = useHistory();
  const { userRole } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);
  const { refetchWorkspacesMap } = useContext(CreateEditWorkspaceModalContext);
  const [workspaces, setWorkspaces] = useState({
    workspaceCount: 0,
    workspaceList: [],
  });
  const [workspaceLoading, setWorkspaceLoading] = useState(true);
  const { selectedWorkspaceData, setSelectedWorkspaceData } =
    useContext(AuthContext);
  const [workspaceDropdownOpen, setWorkspaceDropdownOpen] = useState(false);

  const handleWorkspaceError = (err) => {
    const errorMessage =
      err.response?.data?.remarks ||
      "Something went wrong!! Failed to fetch workspaces";
    showMessage(errorMessage);
    setWorkspaceLoading(false);
  };

  useMemo(async () => {
    try {
      const allWorkspaces = await getAllWorkspaces();
      setWorkspaces(allWorkspaces.data);
      const selectedWorkspaceId = parseInt(
        localStorage.getItem("selectedWorkspaceId"),
      );

      if (!selectedWorkspaceId) {
        setWorkspaceLoading(false);
        return;
      }

      const selectedWorkspace = allWorkspaces.data.workspaceList.find(
        (workspace) => workspace.id === selectedWorkspaceId,
      );

      if (!selectedWorkspace) {
        showMessage("Selected workspace is not available!");
        setSelectedWorkspaceData(null);
        setTimeout(() => history.push("/"), 1000);
      } else {
        setSelectedWorkspaceData(selectedWorkspace);
      }
      setWorkspaceLoading(false);
    } catch (err) {
      handleWorkspaceError(err);
    }
  }, [history, setSelectedWorkspaceData, showMessage]);

  const refetchAllWorkspaces = useCallback(async () => {
    try {
      setWorkspaceLoading(true);
      const allWorkspaces = await getAllWorkspaces();
      setWorkspaces(allWorkspaces.data);
      setWorkspaceLoading(false);
    } catch (err) {
      handleWorkspaceError(err);
    }
  }, [showMessage]);

  useEffect(() => {
    const currentRefetchWorkspacesMap = refetchWorkspacesMap.current;
    currentRefetchWorkspacesMap.set("WorkspaceSelect", refetchAllWorkspaces);
    return () => currentRefetchWorkspacesMap.delete("WorkspaceSelect");
  }, [refetchAllWorkspaces, refetchWorkspacesMap]);

  const currentPath = history.location.pathname;
  const isSettingsPage = currentPath.includes("/settings");
  if (isSettingsPage) {
    return null;
  }

  if (workspaceLoading) {
    return (
      <FlexContainer $gap="0.833rem">
        <Text $fontWeight="bold" $fontSize="1.25rem">
          Loading
        </Text>
      </FlexContainer>
    );
  }

  const getNoWorkspacesText = () =>
    userRole === "USER" ? "No workspaces assigned" : "No workspaces created";

  const filteredWorkspaceList = workspaces.workspaceList
    .filter((workspace) => workspace.id !== selectedWorkspaceData?.id)
    .slice(0, 4);

  return (
    <FlexContainer $gap="0.833rem">
      {!workspaces.workspaceCount ? (
        <Text $fontWeight="bold" $fontSize="1.25rem">
          {getNoWorkspacesText()}
        </Text>
      ) : (
        <Dropdown
          open={workspaceDropdownOpen}
          onOpenChange={setWorkspaceDropdownOpen}
          offsetValue={dropdownOffset}
          placement="bottom-start"
          content={
            <WorkspaceDropdownContent workspaceList={filteredWorkspaceList} />
          }
        >
          <FlexContainerForSelect
            $gap="0.833rem"
            onClick={() => setWorkspaceDropdownOpen(!workspaceDropdownOpen)}
          >
            {selectedWorkspaceData && (
              <AvatarImage
                as={workspaceAvatarMap[selectedWorkspaceData.iconFile]}
              />
            )}
            <Tooltip
              title={
                selectedWorkspaceData
                  ? selectedWorkspaceData.workspaceName
                  : "No workspace selected"
              }
              disabled={workspaceDropdownOpen}
            >
              <Text
                $fontWeight="bold"
                $fontSize="1.25rem"
                $lineHeight="2.222rem"
                $width={
                  selectedWorkspaceData
                    ? "calc(100% - 2.222rem - 1.25rem - 0.833rem - 0.833rem))"
                    : "calc(100% - 1.25rem - 0.833rem)"
                }
                $noWrap
              >
                {selectedWorkspaceData
                  ? selectedWorkspaceData.workspaceName
                  : "No workspace selected"}
              </Text>
            </Tooltip>
            {workspaceDropdownOpen ? <OpenIconStyled /> : <CloseIconStyled />}
          </FlexContainerForSelect>
        </Dropdown>
      )}
    </FlexContainer>
  );
};

export default WorkspaceSelect;
