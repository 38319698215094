import React, { useEffect, useState, useContext } from "react";
import FlexContainer from "../common/_flexContainer";
import { ReactComponent as CondenseIcon } from "../../assets/images/condenseLogo.svg";
import styled from "styled-components";
import WorkspaceSelect from "./WorkspaceSelect";
import { useLocation } from "react-router-dom";
import LoginAvatar from "./LoginAvatar";
import GrafanaDashBoardButton from "./GrafanaDashBoardButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as ActivityAuditorIcon } from "../../assets/images/activity_auditor_icon.svg";
import { AuthContext } from "../../context/AuthContext";

const CondenseIconStyled = styled(CondenseIcon)`
  width: 10.416rem;
  height: 2.222rem;
  cursor: pointer;
`;
const VerticalDivider = styled.div`
  border-right: 0.138rem solid #9090908f;
  height: 2.222rem;
`;

const FlexContainerWithDefaultTextFormatted = styled(FlexContainer)`
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  border-bottom: 0.069rem solid #9090903d;
  background: #2f2f2f;
  color: #f6f6f6;
  text-align: start;
`;

const StyledActivityAuditorButton = styled(ActivityAuditorIcon)`
  height: 1.667rem;
  width: 1.667rem;
  cursor: pointer;
  path {
    stroke: ${({ $isActivityAuditor }) =>
      $isActivityAuditor ? "#47CCD6" : "#f6f6f6"};
  }
  &:hover path {
    stroke: #47ccd6; /* Primary accent color on hover */
  }
`;

const NavBar = () => {
  const history = useHistory();
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(null);
  const { userRole } = useContext(AuthContext);

  useEffect(() => {
    const storedPreviousLocation = sessionStorage.getItem("previousLocation");
    if (storedPreviousLocation) {
      setPreviousLocation(storedPreviousLocation);
    }
  }, [location]);

  const handleActivityAuditorClick = () => {
    if (location.pathname === "/activityAuditor") {
      if (previousLocation) {
        history.push(previousLocation);
      } else {
        history.push("/");
      }
    } else {
      sessionStorage.setItem("previousLocation", location.pathname);
      setPreviousLocation(location.pathname);
      history.push("/activityAuditor");
    }
  };
  return (
    <FlexContainerWithDefaultTextFormatted
      $width="100%"
      $padding="0.84rem 1.112rem"
      $gap="1.112rem"
    >
      <FlexContainer $gap="1.112rem">
        <CondenseIconStyled
          onClick={() => {
            if (location.pathname !== "/onBoarding") {
              history.push("/");
            }
          }}
        />
        {location.pathname !== "/onBoarding" &&
          location.pathname !== "/settings" &&
          location.pathname !== "/activityAuditor" &&
          WorkspaceSelect && (
            <>
              <VerticalDivider />
              <WorkspaceSelect />
            </>
          )}
      </FlexContainer>
      <FlexContainer $gap="1.112rem">
        <StyledActivityAuditorButton
          onClick={handleActivityAuditorClick}
          $isActivityAuditor={location.pathname === "/activityAuditor"}
        />
        {userRole === "ADMIN" && <GrafanaDashBoardButton />}
        <LoginAvatar />
      </FlexContainer>
    </FlexContainerWithDefaultTextFormatted>
  );
};

export default NavBar;
