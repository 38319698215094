import React, { useMemo } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import RCSlider from "rc-slider";
import "rc-slider/assets/index.css";
import sliderHandle from "../../assets/images/slider_handle.svg";
import styled from "styled-components";
import Text from "./_text";

const RCSliderStyled = styled(RCSlider)`
  margin-right: 1rem;
  &.rc-slider {
    height: 0.972rem;
    width: 100%;
    padding: 0.347rem 0rem;
    margin-right: 2rem;
    border-radius: 0rem;
    background-color: ${(props) =>
      props.isDisabled ? "#2f2f2f !important" : ""};
  }
  & .rc-slider-mark {
    top: 1.333rem;
  }
  & .rc-slider-step {
    height: 0rem;
  }
  & .rc-slider-mark-text {
    width: max-content;
  }
`;

const SliderMarkerLabel = ({
  sliderValue,
  markPoint,
  markLabelFormatterFunction,
  isDisabled,
}) => {
  return (
    <Text
      $fontWeight="bold"
      $fontSize="0.833rem"
      $lineHeight="1.3"
      $fontFamily="Articulat CF Normal"
      $letterSpacing="0.5px"
      $color={
        isDisabled
          ? "#777777"
          : sliderValue === markPoint
            ? "#47CCD6"
            : "#F6F6F6"
      }
      $textAlign="start"
    >
      {markLabelFormatterFunction
        ? markLabelFormatterFunction(markPoint) || markPoint
        : markPoint}
    </Text>
  );
};

/**
 * A customizable slider component.
 * @param {Object} props - The props object.
 * @param {number} props.sliderValue - The current value of the slider.
 * @param {function(number): void} props.setSliderValue - Function to set the slider value.
 * @param {number} props.minValue - The minimum value of the slider.
 * @param {number} props.maxValue - The maximum value of the slider.
 * @param {boolean} [props.disabled] - Whether the slider is disabled.
 * @param {number} props.markInterval - The interval at which marks are displayed.
 * @param {number} props.stepInterval - The interval at which the slider steps.
 * @param {function(number): string} props.markLabelFormatterFunction - A function to format the label of each mark.
 */
const Slider = ({
  sliderValue,
  setSliderValue,
  minValue,
  maxValue,
  disabled,
  markInterval,
  stepInterval,
  markLabelFormatterFunction,
  isFromUtilities = false,
  isDisabled = false,
}) => {
  const marks = useMemo(() => {
    const markPoints = [];

    for (let i = minValue; i <= maxValue; i += markInterval) markPoints.push(i);

    const marksObj = Object.fromEntries(
      markPoints.map((markPoint) => [
        markPoint,
        <SliderMarkerLabel
          key={markPoint}
          sliderValue={sliderValue}
          markPoint={markPoint}
          markLabelFormatterFunction={markLabelFormatterFunction}
          isDisabled={isDisabled}
        />,
      ]),
    );

    return marksObj;
  }, [
    markInterval,
    markLabelFormatterFunction,
    maxValue,
    minValue,
    sliderValue,
  ]);

  // Modified onChange to enforce step increments when isFromUtilities is true
  const handleChange = (value) => {
    if (isFromUtilities) {
      const snappedValue = Math.round(value / markInterval) * markInterval;
      setSliderValue(snappedValue);
    } else {
      setSliderValue(value);
    }
  };
  return (
    <RCSliderStyled
      min={minValue}
      max={maxValue}
      value={sliderValue}
      onChange={handleChange}
      disabled={disabled || isDisabled}
      isDisabled={isDisabled}
      marks={marks}
      step={isFromUtilities ? markInterval : stepInterval || null} // Enforcing only allowed steps
      activeDotStyle={{
        width: "0.139rem",
        height: "0.555rem",
        border: "none",
        borderRadius: "0.555rem",
        backgroundColor: isDisabled ? "#5E5E5E" : "#47CCD6",
        bottom: "auto",
        transform: "translate(-50%, -40%)",
      }}
      dotStyle={{
        width: "0.139rem",
        height: "0.555rem",
        border: "none",
        borderRadius: "0.555rem",
        backgroundColor: "#5E5E5E",
        bottom: "auto",
        transform: "translate(-50%, -40%)",
      }}
      styles={{
        handle: {
          width: "0.833rem",
          height: "1.25rem",
          margin: "0rem",
          padding: "0rem",
          transform: "translate(-50%, -40%)",
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          backgroundImage: isDisabled ? "" : `url(${sliderHandle})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          opacity: "1",
          boxShadow: "none",
        },
        rail: {
          height: "0.139rem",
          borderRadius: "0rem",
          backgroundColor: "#5E5E5E",
        },
        track: {
          height: "0.139rem",
          borderRadius: "0rem",
          backgroundColor: "#47CCD6",
        },
      }}
    />
  );
};

SliderMarkerLabel.propTypes = {
  sliderValue: PropTypes.number.isRequired,
  markPoint: PropTypes.number.isRequired,
  markLabelFormatterFunction: PropTypes.func,
};

SliderMarkerLabel.defaultProps = {
  markLabelFormatterFunction: null,
};

Slider.propTypes = {
  sliderValue: PropTypes.number.isRequired,
  setSliderValue: PropTypes.func.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  markInterval: PropTypes.number.isRequired,
  stepInterval: PropTypes.number,
  markLabelFormatterFunction: PropTypes.func,
  isDisabled: PropTypes.bool,
};

Slider.defaultProps = {
  disabled: false,
  stepInterval: 1,
  markLabelFormatterFunction: null,
};

export default Slider;
