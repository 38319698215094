import styled from "styled-components";
import CountryCodes from "country-codes-list";
import { ReactComponent as UpIcon } from "../../assets/images/up_icon.svg";
import { ReactComponent as DownIcon } from "../../assets/images/down_icon.svg";
import search_icon from "../../assets/images/MagnifyingGlass.svg";
import { useState } from "react";
import PropTypes from "prop-types";
import cross_icon from "../../assets/images/X.svg";

const countryCodes = CountryCodes.customList(
  "countryNameEn",
  "{countryCallingCode}",
);
const countryCodeArray = Object.entries(countryCodes).map(
  ([country, code]) => ({
    country,
    code: code.replace(" ", ""),
  }),
);

const StyledUpIcon = styled(UpIcon)`
  height: 1.111rem;
  width: 1.111rem;
  :hover {
    cursor: pointer;
  }
`;

const StyledDownIcon = styled(DownIcon)`
  height: 1.111rem;
  width: 1.111rem;
  :hover {
    cursor: pointer;
    path {
      stroke: ${({ $hoverIconColor }) => $hoverIconColor || "#F6F6F6"};
    }
  }
`;

const ContryCodeDropDown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.555rem;
  background-color: #2f2f2f;
  padding: 1.1455rem 0.8675rem;
  position: relative;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
  ${({ addBorder }) =>
    addBorder &&
    `
    padding: 1.111rem 0.833rem;
    border: 0.069rem solid #b0b0b0;
  `}
`;

const CountryCodeOptionsContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  background-color: #2f2f2f;
  border-radius: 0.555rem;
  border: 0.069rem solid #777777;
  width: 18.055rem;
  max-height: 16.277rem;
  display: flex;
  flex-direction: column;
`;

const ScrollableList = styled.div`
  overflow-y: auto;
  max-height: 12rem; /* Adjust this value as needed */
  padding: 0 1.111rem 1.111rem 1.111rem;
`;

const CountryCodeOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.555rem;
  background-color: ${({ isSelected }) =>
    isSelected ? "#4B4B4B" : "transparent"};
  color: ${({ isSelected }) =>
    isSelected ? "var(--primary-accent-color)" : "#CCCCCC"};
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  border-radius: 0.555rem;
  &:hover {
    border: 0.069rem solid #777777;
    border-radius: 0.555rem;
  }
`;

const CountryCodeChipContainer = styled.div`
  padding: 0.138rem 0.416rem;
  border-radius: 0.555rem;
  background-color: #4b4b4b;
`;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.111rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #777777;
  border-radius: 0.555rem;
`;

const SearchContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #2f2f2f;
  border-radius: 0.555rem 0.555rem 0 0;
  padding: 1.111rem 1.111rem 0.25rem 1.111rem;
  z-index: 101;
`;

export const CountryCodeSelector = ({
  selectedContryCode,
  onContryCodeSelect,
  disabled = false,
  addBorder = false,
}) => {
  const [isCCselectorOpen, setIsCCselectorOpen] = useState(false);
  const [countrySearchText, setCountrySearchText] = useState("");

  return (
    <ContryCodeDropDown disabled={disabled} addBorder={addBorder}>
      <Text
        style={{
          fontSize: "1.111rem",
          lineHeight: "1.111rem",
          marginRight: "0.555rem",
          color: "#CCCCCC",
          cursor: "pointer",
        }}
        onClick={() => setIsCCselectorOpen(!isCCselectorOpen)}
      >{`+${selectedContryCode}`}</Text>
      {isCCselectorOpen ? (
        <StyledUpIcon onClick={() => setIsCCselectorOpen(!isCCselectorOpen)} />
      ) : (
        <StyledDownIcon
          onClick={() => setIsCCselectorOpen(!isCCselectorOpen)}
        />
      )}
      {isCCselectorOpen && (
        <>
          <button
            onClick={() => setIsCCselectorOpen(!isCCselectorOpen)}
            style={{
              zIndex: "99",
              width: "100vw",
              height: "100vh",
              position: "fixed",
              top: "0",
              left: "0",
              background: "transparent",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
            aria-label="Toggle Credit Card selector"
          ></button>

          <CountryCodeOptionsContainer>
            <SearchContainer>
              <InputFieldContainer
                style={{
                  padding: "0.416rem 0.416rem",
                  borderRadius: "0.277rem",
                  backgroundColor: "#0D0D0D",
                  height: "2.222rem",
                  gap: "0.555rem",
                  marginBottom: "0.555rem",
                  borderColor: "#5E5E5E",
                }}
              >
                <img
                  src={search_icon}
                  alt="search_icon"
                  style={{
                    height: "1.111rem",
                    width: "1.111rem",
                    opacity: "0.5",
                  }}
                />
                <InputField
                  placeholder="Search for Country"
                  onChange={(e) => setCountrySearchText(e.target.value)}
                  value={countrySearchText}
                  style={{
                    fontSize: "1.041rem",
                    lineHeight: "1.041rem",
                  }}
                />
                {countrySearchText && (
                  <button
                    onClick={() => setCountrySearchText("")}
                    style={{
                      background: "transparent",
                      border: "none",
                      color: "#CCCCCC",
                      fontSize: "1.2rem",
                      cursor: "pointer",
                    }}
                    aria-label="Clear search"
                  >
                    <img
                      src={cross_icon}
                      alt="Clear"
                      style={{
                        height: "0.694rem",
                        width: "0.694rem",
                      }}
                    />
                  </button>
                )}
              </InputFieldContainer>
            </SearchContainer>
            <ScrollableList>
              {countryCodeArray
                .filter(
                  (item) =>
                    item.country
                      .toLowerCase()
                      .includes(countrySearchText.toLowerCase()) ||
                    item.code.includes(countrySearchText.replace("+", "")),
                )
                .map((item) => (
                  <CountryCodeOption
                    onClick={() => {
                      onContryCodeSelect(item);
                      setIsCCselectorOpen(!isCCselectorOpen);
                    }}
                    key={item.country}
                    isSelected={selectedContryCode === item.code}
                  >
                    <Text
                      style={{
                        fontSize: "0.972rem",
                        lineHeight: "0.972rem",
                        color: "#CCCCCC",
                        width: "70%",
                      }}
                    >
                      {item.country}
                    </Text>
                    <CountryCodeChipContainer>
                      <Text
                        style={{
                          fontSize: "0.972rem",
                          lineHeight: "0.972rem",
                          color: "#CCCCCC",
                          width: "25%",
                        }}
                      >{`+${item.code}`}</Text>
                    </CountryCodeChipContainer>
                  </CountryCodeOption>
                ))}
            </ScrollableList>
          </CountryCodeOptionsContainer>
        </>
      )}
    </ContryCodeDropDown>
  );
};
CountryCodeSelector.propTypes = {
  selectedContryCode: PropTypes.string.isRequired,
  onContryCodeSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  addBorder: PropTypes.bool,
};

CountryCodeSelector.defaultProps = {
  disabled: false,
  addBorder: false,
};
