import React, { useState, useCallback, createContext, useMemo } from "react";
import PropTypes from "prop-types";

const SnackbarContext = createContext({
  message: "",
  showMessage: () => {},
});

const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");

  const showMessage = useCallback((newMessage, type, headerText) => {
    setMessage(newMessage);
    setMessageType(type);
    setHeaderMessage(headerText);
  }, []);

  const value = useMemo(
    () => ({ message, showMessage, messageType, headerMessage }),
    [message, messageType, showMessage, headerMessage],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.element,
};
export { SnackbarProvider, SnackbarContext };
