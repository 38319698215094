import React, { useCallback, useContext, useEffect, useState } from "react";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";
import CodeRepositoryItem from "./CodeRepositoryItem";
import axiosAdapter from "../../../utils";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import { VersionControlDisconnectModal } from "../VcDisconnectModal";
import Divider from "../../../components/common/_divider";
import { env } from "../../../env";

const getUsersVcDetails = async () => {
  const response = await axiosAdapter(
    "GET",
    env.REACT_APP_URL + "version-control/getVcAuthDetails",
  );
  return response.data;
};

const CodeRepositorySectionContents = () => {
  const { showMessage } = useContext(SnackbarContext);

  const [isDisconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const [disconnectionProvider, setDisconnectionProvider] = useState("");

  const [connectedProvider, setConnectedProvider] = useState({
    github: false,
    gitlab: false,
    bitbucket: false,
  });
  const [connectedProviderUsernames, setConnectedProviderUsernames] = useState({
    github: "",
    gitlab: "",
    bitbucket: "",
  });

  const refetchVcData = useCallback(async () => {
    try {
      const response = await getUsersVcDetails();
      let tempConnectedProvider = {
        github: false,
        gitlab: false,
        bitbucket: false,
      };
      let tempConnectedProviderUsernames = {
        github: "",
        gitlab: "",
        bitbucket: "",
      };
      for (let provider of Object.keys(response.data)) {
        if (response.data[provider]) {
          tempConnectedProvider[provider] = true;
          tempConnectedProviderUsernames[provider] =
            response.data[provider].username;
        } else {
          tempConnectedProvider[provider] = false;
          tempConnectedProviderUsernames[provider] = "";
        }
      }
      setConnectedProvider(tempConnectedProvider);
      setConnectedProviderUsernames(tempConnectedProviderUsernames);
    } catch (err) {
      console.log("Error: getVcAuthDetails", err);
      if (err.response?.data?.remarks) {
        showMessage(
          <p
            style={{
              fontFamily: "Articulat CF Normal",
              fontWeight: "bold",
              letterSpacing: "0.5px",
            }}
          >
            err.response.data.remarks{" "}
          </p>,
        );
      } else {
        showMessage(
          <p
            style={{
              fontFamily: "Articulat CF Normal",
              fontWeight: "bold",
              letterSpacing: "0.5px",
            }}
          >
            Failed to refetch the User's Version Control Details{" "}
          </p>,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsersVcDetails();
        let tempConnectedProvider = {
          github: false,
          gitlab: false,
          bitbucket: false,
        };
        let tempConnectedProviderUsernames = {
          github: "",
          gitlab: "",
          bitbucket: "",
        };
        for (let provider of Object.keys(response.data)) {
          if (response.data[provider]) {
            tempConnectedProvider[provider] = true;
            tempConnectedProviderUsernames[provider] =
              response.data[provider].username;
          } else {
            tempConnectedProvider[provider] = false;
            tempConnectedProviderUsernames[provider] = "";
          }
        }
        setConnectedProvider(tempConnectedProvider);
        setConnectedProviderUsernames(tempConnectedProviderUsernames);
      } catch (err) {
        console.log("Error: getVcAuthDetails", err);
        if (err.response?.data?.remarks) {
          showMessage(
            <p
              style={{
                fontFamily: "Articulat CF Normal",
                fontWeight: "bold",
                letterSpacing: "0.5px",
              }}
            >
              err.response.data.remarks{" "}
            </p>,
          );
        } else {
          showMessage(
            <p
              style={{
                fontFamily: "Articulat CF Normal",
                fontWeight: "bold",
                letterSpacing: "0.5px",
              }}
            >
              Unable to get the User's Version Control Details{" "}
            </p>,
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FlexContainer $width="100%" $gap="1.666rem">
        <Text $fontWeight="bold" $fontSize="1.388rem" $letterSpacing="0.5px">
          Code Repositories
        </Text>
        <Divider />
        {Object.keys(connectedProvider).map((provider) => (
          <CodeRepositoryItem
            key={provider}
            provider={provider}
            isConnected={connectedProvider[provider]}
            userName={connectedProviderUsernames[provider]}
            setDisconnectModalOpen={setDisconnectModalOpen}
            setDisconnectionProvider={setDisconnectionProvider}
          />
        ))}
      </FlexContainer>
      {isDisconnectModalOpen && (
        <VersionControlDisconnectModal
          isOpen={isDisconnectModalOpen}
          onClose={() => {
            setDisconnectModalOpen(false);
            setDisconnectionProvider("");
          }}
          vcProvider={disconnectionProvider}
          refetchVcData={refetchVcData}
        />
      )}
    </>
  );
};

export default CodeRepositorySectionContents;
