import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import Dialog from "../../../components/common/_dialog";
import FlexContainer from "../../../components/common/_flexContainer";
import SearchBar from "./SearchBar";
import Text from "../../../components/common/_text";
import axiosAdapter from "../../../utils";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import Divider from "../../../components/common/_divider";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import WorkspaceMemberDetailCell from "./EditWorkspaceMembersTableComponents/WorkspaceMemberDetailCell";
import WorkspaceMemberPermissionCell from "./EditWorkspaceMembersTableComponents/WorkspaceMemberPermissionCell";
import WorkspaceMemberRevokeOrAddCell from "./EditWorkspaceMembersTableComponents/WorkspaceMemberRevokeOrAddCell";
import {
  Table,
  TableBody,
  TableData,
  TableRow,
  TableWrapper,
} from "../../../components/common/_table";
import { ReactComponent as InfoIcon } from "../../../assets/images/info_icon.svg";
import styled from "styled-components";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../../components/common/_buttonNewOne";
import { env } from "../../../env";
import { ReactComponent as EmptyWorkspaceImage } from "../../../assets/images/workspace_empty_state.svg";
import LoadingAnimation from "../../../assets/images/Loading Animation.gif";
import PropTypes from "prop-types"; // Import PropTypes

const InfoIconStyled = styled(InfoIcon)`
  flex: none;
  width: 1.666rem;
  height: 1.666rem;
`;

const StyledEmptyWorkspaceImage = styled(EmptyWorkspaceImage)`
  margin-top: 1.388rem;
  width: 30.694rem;
  height: 15.138rem;
`;

const WorkspaceEditMembersDialog = ({
  isEditWorkspaceMembersDialogOpen,
  setIsEditWorkspaceMembersDialogOpen,
  selectedWorkspaceForEdit,
  refetchWorkspaces,
  workspaceName,
}) => {
  const [workspaceMembersData, setWorkspaceMembersData] = useState({
    membersCount: 0,
    membersList: [],
  });
  const [workspaceMembersDataLoading, setWorkspaceMembersDataLoading] =
    useState(true);
  const [workspaceMembersDataError, setWorkspaceMembersDataError] =
    useState(false);
  const { showMessage } = useContext(SnackbarContext);

  const columnHelper = useMemo(() => createColumnHelper(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor("fullName", {
        id: "workspaceMemberDetail",
        cell: (props) => <WorkspaceMemberDetailCell row={props.row.original} />,
        filterFn: "includesString",
        meta: { width: "calc(100% - 13.888rem - 2.777rem)" },
      }),
      columnHelper.display({
        id: "workspaceMemberPrivilege",
        cell: (props) => (
          <WorkspaceMemberPermissionCell
            row={props.row.original}
            setWorkspaceMembersData={setWorkspaceMembersData}
          />
        ),
        meta: { width: "13.888rem" },
      }),
      columnHelper.display({
        id: "workspaceMemberRevokeOrAdd",
        cell: (props) => (
          <WorkspaceMemberRevokeOrAddCell
            row={props.row.original}
            setWorkspaceMembersData={setWorkspaceMembersData}
          />
        ),
        meta: { width: "2.777rem" },
      }),
    ],
    [columnHelper],
  );
  const [columnFilters, setColumnFilters] = useState([]);
  const table = useReactTable({
    data: workspaceMembersData.membersList,
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  const workspaceMemberSearchValue = table
    .getColumn("workspaceMemberDetail")
    .getFilterValue();
  const setWorkspaceMemberSearchValue = table.getColumn(
    "workspaceMemberDetail",
  ).setFilterValue;

  const fetchWorkspaceMembers = useCallback(async () => {
    const stringifiedRequestBody = JSON.stringify({
      workspaceId: parseInt(selectedWorkspaceForEdit.id),
    });

    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/getAllWorkspaceMembers",
        stringifiedRequestBody,
      );
      setWorkspaceMembersData(response.data.data);
      setWorkspaceMembersDataLoading(false);
    } catch (err) {
      setWorkspaceMembersDataError(true);
      setWorkspaceMembersDataLoading(false);
      showMessage(
        err.response?.data?.remarks || "Unable to fetch workspace members!",
      );
    }
  }, [selectedWorkspaceForEdit.id, showMessage]);

  useEffect(() => {
    fetchWorkspaceMembers();
  }, [fetchWorkspaceMembers]);

  const setAllWorkspaceMembers = async () => {
    const stringifiedRequestBody = JSON.stringify({
      workspaceId: parseInt(selectedWorkspaceForEdit.id),
      userList: workspaceMembersData.membersList.map((workspaceMember) => ({
        userId: parseInt(workspaceMember.userId),
        userPermission: workspaceMember.userPermission,
      })),
    });

    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/setAllWorkspaceMembers",
        stringifiedRequestBody,
      );
      showMessage(response.data.remarks, "success");
      setIsEditWorkspaceMembersDialogOpen(false);
      refetchWorkspaces();
    } catch (err) {
      showMessage(
        err.response?.data?.remarks || "Unable to edit workspace members!",
      );
    }
  };

  const handleClose = () => setIsEditWorkspaceMembersDialogOpen(false);

  const renderDialogHeader = () => (
    <FlexContainer $gap="1.111rem" $padding={""}>
      <FlexContainer
        $width="calc(100% - 17.361rem - 1.111rem)"
        $flexDirection="column"
        $justifyContent="flex-start"
        $alignItems="flex-start"
        $gap="0.555rem"
      >
        <Text $fontWeight="bold" $fontSize="1.666rem" $noWrap>
          Edit member of {workspaceName}
        </Text>
        {!workspaceMembersDataLoading && !workspaceMembersDataError && (
          <Text
            $fontSize="0.833rem"
            $color="#47CCD6"
            $noWrap
            $fontFamily="Articulat CF Normal"
            $fontWeight="bold"
            $letterSpacing="0.5px"
          >
            {" "}
            {`${workspaceMembersData.membersCount} ${
              workspaceMembersData.membersCount !== 1 ? "Members" : "Member"
            }`}
          </Text>
        )}
      </FlexContainer>
    </FlexContainer>
  );

  const renderDialogContent = () => {
    if (workspaceMembersDataLoading || workspaceMembersDataError) {
      return (
        <>
          <Divider $thickness="0.104rem" $color="#4B4B4B" />
          <FlexContainer
            $width="100%"
            $justifyContent="center"
            $alignItems="center"
          >
            {workspaceMembersDataLoading ? (
              <img
                src={LoadingAnimation}
                alt="Loading..."
                width="250"
                height="250"
              />
            ) : (
              <Text $width="100%" $textAlign="center">
                Failed to obtain data
              </Text>
            )}
          </FlexContainer>
          <Divider $thickness="0.104rem" $color="#4B4B4B" />
        </>
      );
    }

    const hasMembers = table.getRowModel().rows.length > 0;

    return (
      <>
        {hasMembers && (
          <SearchBar
            value={workspaceMemberSearchValue}
            setValue={setWorkspaceMemberSearchValue}
            placeholderText="Search for Members"
            border="0.104rem solid #4B4B4B"
            color="#B0B0B0"
            width="17.361rem"
          />
        )}

        {hasMembers ? (
          <TableWrapper $maxHeight="20.493rem">
            <Table $width="max(37.5rem, 100%)">
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} $border="0.104rem solid #4B4B4B">
                    {row.getVisibleCells().map((cell) => (
                      <TableData
                        key={cell.id}
                        $width={cell.column.columnDef.meta.width}
                        $padding="1.111rem 1.111rem 1.111rem 0"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableData>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
              gap: "0.833rem",
              marginBottom: "1.111rem",
              borderTop: "0.104rem solid #4B4B4B",
            }}
          >
            <StyledEmptyWorkspaceImage />
            <Text $fontSize="1.388rem" $fontWeight="bold" $color="#f6f6f6">
              Can't find any users here
            </Text>
            <Text
              style={{ maxWidth: "30.694rem" }}
              $textAlign="center"
              $color="#777777"
              $fontSize="1.111rem"
              $lineHeight="1.444rem"
              $fontFamily="Articulat CF Normal"
              $fontWeight="bold"
              $letterSpacing="0.5px"
            >
              No users to show in this workspace. Invite users from your
              organisation to add them to {workspaceName}.
            </Text>
          </div>
        )}

        {hasMembers && (
          <FlexContainer
            $width="100%"
            $flexWrap="nowrap"
            $justifyContent="flex-start"
            $gap="0.555rem"
          >
            <InfoIconStyled />
            <Text
              $fontSize="0.962rem"
              $fontStyle="italic"
              $color="#47CCD6"
              $fontFamily="Articulat CF Normal"
              $fontWeight="bold"
              letterSpacing="0.5px"
            >
              These changes will apply only to this workspace. To change access
              at the organization level, review users & roles
            </Text>
          </FlexContainer>
        )}
      </>
    );
  };

  const renderDialogFooter = () => (
    <FlexContainer $width="100%" $justifyContent="flex-end" $gap="1.111rem">
      <StyledButtonsecondary width="14.166rem" onClick={handleClose}>
        {workspaceMembersDataLoading || workspaceMembersDataError
          ? "Close"
          : "Cancel"}
      </StyledButtonsecondary>
      {!(workspaceMembersDataLoading || workspaceMembersDataError) && (
        <StyledButtonPrimary
          width="14.166rem"
          onClick={
            table.getRowModel().rows.length
              ? setAllWorkspaceMembers
              : handleClose
          }
        >
          {table.getRowModel().rows.length ? "Save Changes" : "Invite Members"}
        </StyledButtonPrimary>
      )}
    </FlexContainer>
  );

  const DialogContent = (
    <FlexContainer $gap="1.111rem" $padding="2.222rem">
      {renderDialogHeader()}
      {renderDialogContent()}
      {renderDialogFooter()}
    </FlexContainer>
  );

  return (
    <Dialog
      content={DialogContent}
      open={isEditWorkspaceMembersDialogOpen}
      onOpenChange={setIsEditWorkspaceMembersDialogOpen}
      maxHeight={"76vh"}
      style={{ overflow: "hidden" }}
    />
  );
};

WorkspaceEditMembersDialog.propTypes = {
  isEditWorkspaceMembersDialogOpen: PropTypes.bool.isRequired,
  setIsEditWorkspaceMembersDialogOpen: PropTypes.func.isRequired,
  selectedWorkspaceForEdit: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  refetchWorkspaces: PropTypes.func.isRequired,
};

WorkspaceMemberDetailCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string,
      userId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,

  original: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string,
    userId: PropTypes.number.isRequired,
  }),
};

WorkspaceMemberPermissionCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      userId: PropTypes.number.isRequired,
      userPermission: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  original: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    userPermission: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }),
  setWorkspaceMembersData: PropTypes.func.isRequired,
};

WorkspaceMemberRevokeOrAddCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      userId: PropTypes.number.isRequired,
      userPermission: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  original: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    userPermission: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
  }),
  setWorkspaceMembersData: PropTypes.func.isRequired,
};
export default WorkspaceEditMembersDialog;
