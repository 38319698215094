import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchBar from "./components/SearchBar";
import UpgradePlanSectionContents from "./components/UpgradePlanSectionContents";
import PersonalDetailsSectionContents from "./components/PersonalDetailsSectionContents";
import Text from "../../components/common/_text";
import CodeRepositorySectionContents from "./components/CodeRepositorySectionContents";
import OrganisationDetailsSectionContents from "./components/OrganisationDetailsSectionContents";
import WorkspaceDetailsSectionContents from "./components/WorkspaceDetailsSectionContents";
import UsersRolesSectionContents from "./components/UsersRolesSectionContents";
import AccountActionsSectionContents from "./components/AccountActionsSectionContents";
import { AuthContext } from "../../context/AuthContext";
import { AuthenticationComponent } from "./components/AuthenticationComponent";

const Main = styled.main`
  background: #0d0d0d;
  height: calc(100vh - 3.95rem);
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  gap: 2.777rem;
  padding: 1.666rem;
  font-family: "Articulat CF Medium";
  font-weight: 500;
  font-size: 1.111rem;
  line-height: 1.3;
  color: #f6f6f6;
  text-align: start;
`;

const Section = styled.section`
  width: 100%;
  background: ${({ $background }) => $background || "#0d0d0d"};
  border: 0.138rem solid ${({ $borderColor }) => $borderColor || "#2f2f2f"};
  border-radius: 0.833rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: flex-start;
  gap: 1.666rem;
  padding: ${({ $padding }) => $padding || "1.666rem"};
`;

const Settings = () => {
  const { userRole: accountRole } = useContext(AuthContext);
  const [searchSettings, setSearchSettings] = useState("");
  const lowerCaseSearchSettings = searchSettings.toLowerCase();
  const [refetchWorkspaces, setRefetchWorkspaces] = useState(() => () => {});
  const [refetchUsers, setRefetchUsers] = useState(() => () => {});

  // Refs for sections
  const personalDetailsRef = useRef(null);
  const codeRepositoryRef = useRef(null);
  const organisationDetailsRef = useRef(null);
  const workspaceDetailsRef = useRef(null);
  const usersRolesRef = useRef(null);
  const accountActionsRef = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const section = params.get("section");
    if (section) {
      switch (section) {
        case "personalDetails":
          personalDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "codeRespository":
          codeRepositoryRef.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "organizationDetails":
          organisationDetailsRef.current?.scrollIntoView({
            behavior: "smooth",
          });
          break;
        case "workspaceDetails":
          workspaceDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "usersAndRoles":
          usersRolesRef.current?.scrollIntoView({ behavior: "smooth" });
          break;
        case "accountActions":
          accountActionsRef.current?.scrollIntoView({ behavior: "smooth" });
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <Main>
      <Text $fontWeight="bold" $fontSize="1.666rem" $margin="0 0 -0.555rem 0">
        Settings
      </Text>
      <SearchBar
        value={searchSettings}
        setValue={setSearchSettings}
        placeholderText="Search for Settings"
        margin="0 0 -0.555rem 0"
        width="17.361rem"
      />
      {/* <Section $borderColor="#47CCD6" $background="#47CCD629">
        <UpgradePlanSectionContents />
      </Section> */}
      {"personal details".includes(lowerCaseSearchSettings) && (
        <Section ref={personalDetailsRef}>
          <PersonalDetailsSectionContents
            refetchUsers={refetchUsers}
            refetchWorkspaces={refetchWorkspaces}
          />
        </Section>
      )}
      {"authentication".includes(lowerCaseSearchSettings) && (
        <Section>
          <AuthenticationComponent />
        </Section>
      )}
      {"code repository".includes(lowerCaseSearchSettings) && (
        <Section ref={codeRepositoryRef}>
          <CodeRepositorySectionContents />
        </Section>
      )}
      {"organisation details".includes(lowerCaseSearchSettings) && (
        <Section ref={organisationDetailsRef}>
          <OrganisationDetailsSectionContents />
        </Section>
      )}
      {"workspace details".includes(lowerCaseSearchSettings) && (
        <Section $padding="1.111rem 1.666rem" ref={workspaceDetailsRef}>
          <WorkspaceDetailsSectionContents
            setRefetchWorkspaces={setRefetchWorkspaces}
            refetchUsers={refetchUsers}
          />
        </Section>
      )}
      {"users and roles".includes(lowerCaseSearchSettings) &&
        accountRole === "ADMIN" && (
          <Section $padding="1.111rem 1.666rem" ref={usersRolesRef}>
            <UsersRolesSectionContents
              setRefetchUsers={setRefetchUsers}
              refetchWorkspaces={refetchWorkspaces}
            />
          </Section>
        )}
      {"account actions".includes(lowerCaseSearchSettings) && (
        <Section ref={accountActionsRef}>
          <AccountActionsSectionContents />
        </Section>
      )}
    </Main>
  );
};

export default Settings;
