import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { useState, useContext, useRef } from "react";
import { CountryCodeSelector } from "../../components/common/_countryCodeSelector";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { AuthContext } from "../../context/AuthContext";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import SSOBackground from "../../assets/images/sso_bg.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ORG_NAME_REGEX = /^[a-zA-Z0-9.,&-]+(?: [a-zA-Z0-9.,&-]+)*$/;
const FULL_NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńñòóôöõøśšùúûüųūýÿżźžÀÁÂÄÃÅĄČĆĘÈÉÊËĖĮÌÍÎÏŁŃÑÒÓÔÖÕØŚŠÙÚÛÜŲŪÝŸŻŹŽ ]+$/;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const CondenseLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.1rem;
  margin-top: 4.1rem;
`;

const CondenseLogoImage = styled.img`
  height: 3.333rem;
  width: 15.763rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  background: black;
  background-image: url(${SSOBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.111rem;
  line-height: 1.944rem;
  // font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  text-decoration: none;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
  text-decoration: none;
`;

export const SSORequiredInfo = () => {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const { login } = useContext(AuthContext);

  const [fullName, setFullName] = useState(location.state?.fullName ?? "");
  const [fullNameError, setFullNameError] = useState("");
  const disableFullName =
    location.state?.inviteCode && location.state?.invitedEmail ? true : false;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const emailAddress = location.state?.emailId;
  const [orgName, setOrgName] = useState(location.state?.orgName ?? "");
  const [orgNameError, setOrgNameError] = useState("");
  const disableOrgName =
    location.state?.inviteCode && location.state?.invitedEmail ? true : false;
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const inviteHash = location.state?.inviteCode ?? "";
  const isSSO = location.state?.isSSO;
  const confirmButtonRef = useRef(null);

  const validateFullName = (name) => {
    const tempName = name.trim();
    if (!tempName) {
      return {
        isValid: false,
        error: "Please enter a value for the full name",
      };
    }
    if (!(tempName.length >= 3 && tempName.length <= 250)) {
      return {
        isValid: false,
        error: "Full name length should be between 3 and 250 characters",
      };
    }
    if (!FULL_NAME_REGEX.test(tempName)) {
      return {
        isValid: false,
        error:
          "Full name can only include alphabets,spaces and accented alphabets",
      };
    }
    return { isValid: true, value: tempName };
  };

  const validateOrgName = (name) => {
    const tempName = name.trim();
    if (!tempName) {
      return {
        isValid: false,
        error: "Please enter a value for the Organization name",
      };
    }
    if (!(tempName.length >= 3 && tempName.length <= 250)) {
      return {
        isValid: false,
        error:
          "Organization name length should be between 3 and 250 characters",
      };
    }
    if (!ORG_NAME_REGEX.test(tempName)) {
      return {
        isValid: false,
        error: "Organization name can only include a-z, A-Z, 0-9 and .,&-",
      };
    }
    return { isValid: true, value: tempName };
  };

  const validatePhoneNumber = (phone) => {
    if (!phone) {
      return {
        isValid: false,
        error: "Please enter a value for the phone number",
      };
    }
    return { isValid: true, value: phone };
  };

  const checkPhoneNumberAvailability = async (phoneNumber, countryCode) => {
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/checkPhoneNumberAvailibility",
        { phoneNumber, countryCode },
      );

      return { isValid: true };
    } catch (err) {
      const error =
        err.response?.data?.remarks ||
        "Unable to check the phone number availability, please try again later";
      return { isValid: false, error };
    }
  };

  const checkOrgNameAvailability = async (orgName) => {
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/checkOrganizationNameAvailibility",
        { organizationName: orgName },
      );
      return { isValid: true };
    } catch (err) {
      const error =
        err.response?.data?.remarks ||
        "Unable to check the organization name availability, please try again later";
      return { isValid: false, error };
    }
  };

  const generateOtpAndRedirect = async (emailAddress, userData) => {
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/generateOtp",
        { emailId: emailAddress, isNewAccount: true },
      );

      history.push("/otpVerification", {
        isRedirected: true,
        isSignUp: true,
        ...userData,
      });
      return { isValid: true };
    } catch (err) {
      const error =
        err.response?.data?.remarks ||
        "Unable to generate OTP, please try again later";
      return { isValid: false, error };
    }
  };

  const handleCreateAccountClick = async () => {
    setDisableAllButtons(true);
    clearErrors();

    // Validate all fields
    const orgNameResult = validateOrgName(orgName);
    const fullNameResult = validateFullName(fullName);
    const phoneResult = validatePhoneNumber(phoneNumber);
    const errors = [];

    // Update error states
    if (!fullNameResult.isValid) {
      showMessage(fullNameResult.error, "error");
      errors.push("fullName");
    }
    if (!orgNameResult.isValid) {
      showMessage(orgNameResult.error, "error");
      errors.push("orgName");
    }
    if (!phoneResult.isValid) {
      showMessage(phoneResult.error, "error");
      errors.push("phoneNumber");
    }

    // If any validation failed, return early
    if (errors.length > 0) {
      setDisableAllButtons(false);
      return;
    }
    try {
      const [phoneAvailability, orgAvailability] = await Promise.all([
        checkPhoneNumberAvailability(phoneNumber, countryCode),
        !disableOrgName
          ? checkOrgNameAvailability(orgNameResult.value)
          : { isValid: true },
      ]);

      if (!phoneAvailability.isValid && !orgAvailability.isValid) {
        setPhoneNumberError("Phone number already used,");
        setOrgNameError("An organisation with this name already exists");
        showMessage(
          "Both Organization Name and Phone Number already exist",
          "error",
        );
      } else {
        if (!phoneAvailability.isValid) {
          setPhoneNumberError(phoneAvailability.error);
          showMessage(phoneAvailability.error, "error");
        }
        if (!orgAvailability.isValid) {
          setOrgNameError(orgAvailability.error);
          showMessage(orgAvailability.error, "error");
        }
      }

      if (!phoneAvailability.isValid || !orgAvailability.isValid) {
        setDisableAllButtons(false);
        return;
      }

      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/createAccount",
        {
          username: null,
          emailId: emailAddress,
          countryCode: countryCode,
          phoneNumber: phoneNumber,
          fullName: fullName,
          organizationName: orgName,
          inviteCode:
            location.state?.inviteCode && location.state?.invitedEmail
              ? inviteHash
              : null,
          isSSO: isSSO || false,
          auth0id: location.state?.auth0Id,
        },
      );

      const data = response.data?.data;
      const jwe_token = data?.token;

      if (jwe_token) {
        showMessage("Account created successfully", "success");
        login(jwe_token);
        setDisableAllButtons(false);
      }
    } catch (err) {
      console.log("Error: createAccount", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage(
          "Unable to create account, please try again later",
          "error",
        );
      }
      setDisableAllButtons(false);
    }
  };

  const handleNativeCreateAccountClick = async () => {
    setDisableAllButtons(true);
    clearErrors();

    // Validate all fields
    const fullNameResult = validateFullName(fullName);
    const orgNameResult = validateOrgName(orgName);
    const phoneResult = validatePhoneNumber(phoneNumber);
    const errors = [];
    // Update error states
    if (!fullNameResult.isValid) {
      showMessage(fullNameResult.error, "error");
      errors.push("fullName");
    }
    if (!orgNameResult.isValid) {
      showMessage(orgNameResult.error, "error");
      errors.push("orgName");
    }
    if (!phoneResult.isValid) {
      showMessage(phoneResult.error, "error");
      errors.push("phoneNumber");
    }

    // If any validation failed, return early
    if (errors.length > 0) {
      setDisableAllButtons(false);
      return;
    }
    try {
      const [phoneAvailability, orgAvailability] = await Promise.all([
        checkPhoneNumberAvailability(phoneNumber, countryCode),
        !disableOrgName
          ? checkOrgNameAvailability(orgNameResult.value)
          : { isValid: true },
      ]);

      if (!phoneAvailability.isValid && !orgAvailability.isValid) {
        setPhoneNumberError("Phone number already used,");
        setOrgNameError("An organisation with this name already exists");
        showMessage(
          "Both Organization Name and Phone Number already exist",
          "error",
        );
      } else {
        if (!phoneAvailability.isValid) {
          setPhoneNumberError(phoneAvailability.error);
          showMessage(phoneAvailability.error, "error");
        }
        if (!orgAvailability.isValid) {
          setOrgNameError(orgAvailability.error);
          showMessage(orgAvailability.error, "error");
        }
      }

      if (!phoneAvailability.isValid || !orgAvailability.isValid) {
        setDisableAllButtons(false);
        return;
      }

      // Generate OTP and redirect
      const otpResult = await generateOtpAndRedirect(emailAddress, {
        emailId: emailAddress,
        countryCode,
        phoneNumber: phoneResult.value,
        fullName: fullNameResult.value,
        organizationName: orgNameResult.value,
        inviteCode: inviteHash,
      });

      if (!otpResult.isValid) {
        showMessage(otpResult.error, "error");
        setDisableAllButtons(false);
      }
    } catch (err) {
      showMessage("Unable to process request, please try again later", "error");
      setDisableAllButtons(false);
    }
  };

  const clearErrors = () => {
    setFullNameError("");
    setOrgNameError("");
    setPhoneNumberError("");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        !disableAllButtons &&
        fullName &&
        phoneNumber &&
        orgName &&
        countryCode
      ) {
        confirmButtonRef.current?.click();
      }
    }
  };

  return (
    <MainContainer onKeyDown={handleKeyDown}>
      <CustomRow style={{ width: "30vw" }}>
        <CondenseLogoContainer>
          <CondenseLogoImage src={condenseLogo} alt="Condense-Logo" />
        </CondenseLogoContainer>
        <CustomColumn
          mb={2.222}
          style={{ textAlign: "center", fontFamily: "Articulat CF" }}
        >
          <Text>Before you can get started, </Text>
          <Text>help us know you better</Text>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              fullNameError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Full Name"
              name="fullName"
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
              readOnly={disableFullName}
            />
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              columnGap: "1.111rem",
              ...(phoneNumberError && { marginBottom: "0.555rem" }),
            }}
          >
            <CountryCodeSelector
              selectedContryCode={countryCode}
              onContryCodeSelect={(item) => setCountryCode(item.code)}
            />
            <InputFieldContainer
              style={phoneNumberError ? { borderColor: "#EA3A3A" } : {}}
            >
              <InputField
                placeholder="Phone Number"
                name="phoneNumber"
                onChange={(e) => {
                  const input = e.target.value.trim().replace(/\D/g, "");
                  if (input.length <= 15) {
                    setPhoneNumber(input);
                  }
                }}
                value={phoneNumber}
              />
            </InputFieldContainer>
          </div>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={orgNameError ? { borderColor: "#EA3A3A" } : {}}
          >
            <InputField
              placeholder="Organization Name"
              name="organizationName"
              onChange={(e) => setOrgName(e.target.value)}
              value={orgName}
              readOnly={disableOrgName}
            />
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <StyledButtonPrimary
            ref={confirmButtonRef}
            style={{ width: "100%", height: "3.888rem", marginTop: "2rem" }}
            onClick={
              isSSO ? handleCreateAccountClick : handleNativeCreateAccountClick
            }
            disabled={
              disableAllButtons ||
              !fullName ||
              !phoneNumber ||
              !emailAddress ||
              !orgName ||
              !countryCode
            }
          >
            Confirm
          </StyledButtonPrimary>
        </CustomColumn>
      </CustomRow>
    </MainContainer>
  );
};
