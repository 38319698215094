import React from "react";
import Text from "../../../components/common/_text";
import styled from "styled-components";
import { StyledButtonPrimary } from "../../../components/common/_buttonNewOne";
import PipelineIcon from "../../../assets/images/pipeline_bg.svg";
import { useHistory } from "react-router-dom";

const FlexItem = styled.div`
  flex: 0 0 100%;
  margin: ${({ $margin }) => $margin || ""};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 2.597rem;
  margin-left: 1.8rem;
  line-height: 4.3333rem;
  color: #f6f6f6;
`;

const PrimaryButtonStyled = styled(StyledButtonPrimary)`
  width: 19.166rem;
  height: 3.055rem;
  margin-left: 1.8rem;
  margin-top: 2.2rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  ${({ disabled }) =>
    disabled &&
    `w
    background-color: #5e5e5e;
    cursor: not-allowed;
  `}
`;

const PipelineIconStyled = styled.img`
  height: calc(100vh - 27.85rem);
  width: 100%;
`;
const Content = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.297rem;
  text-align: left;
  color: #cccccc;
  margin-left: 1.8rem;
  padding-top: 1.3rem;
  margin-top: 0rem;
`;

const NoNodes = ({ isNodeClickable }) => {
  const history = useHistory();
  return (
    <>
      <Container>
        <Title>Ready to create your Pipeline?</Title>
        <Content>
          You currently have no connectors added. Click on the button below to
          get started
        </Content>
        <PrimaryButtonStyled
          onClick={() => history.push("/connectors")}
          disabled={!isNodeClickable}
        >
          Add Connector
        </PrimaryButtonStyled>
        <PipelineIconStyled
          style={{ fontWeight: "", marginTop: "4.03472rem" }}
          src={PipelineIcon}
          alt="PipelineIcon"
        />
      </Container>
    </>
  );
};

export default NoNodes;
