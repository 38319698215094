import styled from "styled-components";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import { useState, useContext, useEffect } from "react";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import DontShowPasswordIcon from "../../assets/images/dont_show_password_icon.svg";
import Google from "../../assets/images/google_logo.svg";
import Microsoft from "../../assets/images/microsoft_logo.svg";

import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { env } from "../../env";
import { useAuth0 } from "@auth0/auth0-react";

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const PASSWORD_REGEX =
  /^(?!.*\\)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+=[\]{}|;:,.<>?/~]).{8,32}$/;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Normal";
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: 1.111rem;
  line-height: 1.944rem;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
  border: 0.069rem solid #2f2f2f;
`;

export const SignIn = () => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  const { showMessage } = useContext(SnackbarContext);
  const { login } = useContext(AuthContext);

  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isHoveredGoogle, setIsHoveredGoogle] = useState(false);
  const [isHoveredMicrosoft, setIsHoveredMicrosoft] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const inviteCode = urlParams.get("invitationCode");

  const [ssoType, setSSOType] = useState("");

  useEffect(() => {
    setIsFormValid(emailAddress.trim() !== "" && password.trim() !== "");
    if (password.trim() !== "") {
      setPasswordError("");
    }
  }, [emailAddress, password]);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value.trim().toLowerCase();
    setEmailAddress(newEmail);
    if (EMAIL_REGEX.test(newEmail)) {
      setEmailAddressError("");
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (PASSWORD_REGEX.test(newPassword)) {
      setPasswordError("");
    }
  };

  const handleLoginClick = async () => {
    setDisableAllButtons(true);
    setEmailAddressError("");
    setPasswordError("");
    let isEmailValid = true;
    let isPasswordValid = true;

    // Email validations
    if (!emailAddress.trim()) {
      setEmailAddressError("Please enter a value for the email");
      isEmailValid = false;
    }
    if (emailAddress.length > 320) {
      setEmailAddressError("Email length should be less than 320 characters");
      isEmailValid = false;
    }

    if (isEmailValid && !EMAIL_REGEX.test(emailAddress)) {
      setEmailAddressError("Please enter a valid email address");
      isEmailValid = false;
    }

    // Password validations
    if (!password.length) {
      setPasswordError("Please enter a value for the password");
      isPasswordValid = false;
    }

    // if (isPasswordValid && !PASSWORD_REGEX.test(password)) {
    //   setPasswordError(
    //     `Password must have a minimum of 8 characters, with one uppercase, one lowercase, one number, & one special character`,
    //   );
    //   isPasswordValid = false;
    // }

    if (!isEmailValid || !isPasswordValid) {
      setDisableAllButtons(false);
      return;
    }

    try {
      const loginResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/userLogin",
        {
          email: emailAddress,
          password,
        },
      );
      showMessage("Login Successful", "success");
      login(loginResponse.data.data.token);
      setDisableAllButtons(false);
    } catch (err) {
      console.log("Error: userLogin", err);
      if (err.response?.data?.remarks) {
        showMessage("Invalid email or password");
      } else {
        showMessage("Unable to login, please try again later");
      }
      setDisableAllButtons(false);
    }
  };

  const handleSSOLogin = async (connection) => {
    try {
      await loginWithRedirect({
        authorizationParams: {
          connection,
          redirect_uri:
            window.location.origin +
            `/authLoading${inviteCode ? `invitationCode=${inviteCode}` : ""}`,
          prompt: "select_account",
        },
      });
    } catch (error) {
      showMessage("Unable to login, please try again later");
      console.error("SSO login error:", error);
    }
  };

  const handleSSOCheck = async () => {
    try {
      if (emailAddressError) return;

      const emailSSOCheckResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/emailTypeSSO",
        {
          email: emailAddress,
        },
      );

      const data = emailSSOCheckResponse.data?.data;
      if (data?.sso && data?.ssotype) {
        handleSSOLogin(data.ssotype);
      } else if (data?.ssotype === "native") {
        setSSOType("native");
      } else {
        showMessage(
          "We can't find this email address in our records. Please check the email address or create a new account to continue",
        );
      }
    } catch (error) {
      console.error("Email Validation error:", error);
      showMessage("Unable to login, please try again later");
      history.push("/signin");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSSOCheck();
    }
  };

  const handleLoginWithKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLoginClick();
    }
  };

  const handleMouseEnterGoogle = () => setIsHoveredGoogle(true);
  const handleMouseLeaveGoogle = () => setIsHoveredGoogle(false);

  const handleMouseEnterMicrosoft = () => setIsHoveredMicrosoft(true);
  const handleMouseLeaveMicrosoft = () => setIsHoveredMicrosoft(false);
  return (
    <CommonFirstPage
      increaseTopGap="true"
      page="signIn"
      nonSSOflow={ssoType === "native" ? true : false}
    >
      <CustomRow>
        <CustomColumn mb={2.777}>
          <Text style={{}}>Welcome back, login to continue</Text>
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <InputFieldContainer
            style={
              emailAddressError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Email Address"
              name="emailAddress"
              onChange={handleEmailChange}
              value={emailAddress}
              disabled={ssoType === "native"}
              onKeyDown={handleKeyDown}
            />
          </InputFieldContainer>
          {emailAddressError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {emailAddressError}
            </Text>
          )}
        </CustomColumn>
        {ssoType ? (
          <>
            <CustomColumn mb={2.833}>
              <InputFieldContainer
                style={
                  passwordError
                    ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                    : {}
                }
              >
                <InputField
                  placeholder="Password"
                  name="password"
                  onChange={handlePasswordChange}
                  value={password}
                  type={showPassword ? "text" : "password"}
                  onKeyDown={handleLoginWithKey}
                />
                <button
                  type="button"
                  aria-label={showPassword ? "Hide Password" : "Show Password"}
                  onClick={() => setShowPassword(!showPassword)}
                  onKeyDown={(e) => {
                    // Allow interaction via "Enter" or "Space" key for accessibility
                    if (e.key === "Enter" || e.key === " ") {
                      setShowPassword(!showPassword);
                    }
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    cursor: "pointer",
                    marginLeft: "0.347rem",
                    height: "1.25rem",
                    width: "1.25rem",
                    fontFamily: "Articulat CF Normal",
                    letterSpacing: "0.5px",
                    fontWeight: "bold",
                  }}
                >
                  <img
                    src={showPassword ? DontShowPasswordIcon : ShowPasswordIcon}
                    alt={showPassword ? "Hide Password" : "Show Password"}
                    style={{
                      height: "1.25rem",
                      width: "1.25rem",
                      marginBottom: "0.3472rem",
                    }}
                  />
                </button>
              </InputFieldContainer>
              {passwordError && (
                <Text
                  style={{
                    fontSize: "0.833rem",
                    lineHeight: "1.388rem",
                    color: "#EA3A3A",
                  }}
                >
                  {passwordError}
                </Text>
              )}
            </CustomColumn>
            <CustomColumn mb={1.111}>
              <StyledButtonPrimary
                style={{
                  width: "100%",
                  height: "3.888rem",
                  backgroundColor: isFormValid ? "#47CCD6" : "#5E5E5E",
                  cursor: isFormValid ? "pointer" : "not-allowed",
                }}
                onClick={handleLoginClick}
                disabled={!isFormValid || disableAllButtons}
              >
                Login
              </StyledButtonPrimary>
              <CustomColumn mb={0.222}>
                <Text
                  style={{
                    fontSize: "1.111rem",
                    color: "#CCCCCC",
                    textDecoration: "underline",
                    lineHeight: "1.444rem",
                    cursor: "pointer",
                    marginTop: "1.1rem",
                    paddingBottom: 0,
                  }}
                  onClick={() =>
                    !disableAllButtons &&
                    history.push("/forgotPassword", {
                      email: emailAddress,
                    })
                  }
                >
                  Forgot Password?
                </Text>
              </CustomColumn>
            </CustomColumn>
          </>
        ) : (
          <CustomColumn mb={1.111}>
            <StyledButtonPrimary
              style={{ width: "100%", height: "3.888rem" }}
              onClick={handleSSOCheck}
              disabled={disableAllButtons}
            >
              Continue
            </StyledButtonPrimary>
          </CustomColumn>
        )}

        <CustomColumn
          mb={1.111}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <hr style={{ flexGrow: 1, border: "0.0347rem solid #CCCCCC" }} />
          <Text
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: "#CCCCCC",
              margin: "0 .6944rem 0 .6944rem",
            }}
          >
            or Login with
          </Text>
          <hr style={{ flexGrow: 1, border: "0.0347rem solid #CCCCCC" }} />
        </CustomColumn>
        <CustomRow
          style={{
            display: "flex",
            gap: "1rem",
            width: "100%",
            marginTop: "0.6944rem",
          }}
        >
          <StyledButtonsecondary
            style={{
              height: "3.888rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flex: 1,
              backgroundColor: isHoveredGoogle ? "#4B4B4B" : "#2F2F2F",
              color: "white",
              border: "none",

              borderRadius: "0.54rem",
            }}
            onClick={() => handleSSOLogin("google-oauth2")}
            onMouseEnter={handleMouseEnterGoogle}
            onMouseLeave={handleMouseLeaveGoogle}
            disabled={disableAllButtons}
          >
            <img
              src={Google}
              alt="Google Logo"
              style={{ width: "1.8rem", height: "1.8rem", flexShrink: 0 }}
            />
            Google
          </StyledButtonsecondary>
          <StyledButtonsecondary
            style={{
              height: "3.888rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flex: 1,
              backgroundColor: isHoveredMicrosoft ? "#4B4B4B" : "#2F2F2F",
              color: "white",

              border: "none",
              borderRadius: "0.54rem",
            }}
            onClick={() => handleSSOLogin("windowslive")}
            onMouseEnter={handleMouseEnterMicrosoft}
            onMouseLeave={handleMouseLeaveMicrosoft}
            disabled={disableAllButtons}
          >
            <img
              src={Microsoft}
              alt=""
              style={{ width: "1.8rem", height: "1.8rem", flexShrink: 0 }}
            />
            Microsoft
          </StyledButtonsecondary>
        </CustomRow>
      </CustomRow>
    </CommonFirstPage>
  );
};
