import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import Column from "../../components/common/_customColumn";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import OtpInputField from "../../components/common/_otpInputField";
import { useState, useContext, useEffect } from "react";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import ChevronRight from "../../assets/images/Chevron-left.svg";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0;
  color: #f6f6f6;
  font-size: 1.527rem;
  line-height: 2.166rem;
`;

const ForgotContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $isSelected }) => ($isSelected ? "pointer" : "not-allowed")};
  font-size: 1.25rem;
  line-height: 1.666rem;
  font-weight: ${({ $isSelected }) => ($isSelected ? "bold" : "500")};
  color: ${({ $isSelected }) => ($isSelected ? "#47CCD6" : "#777777")};
  padding-bottom: ${({ $isSelected }) =>
    $isSelected ? "0.625rem" : "0.694rem"};
  border-bottom: ${({ $isSelected }) =>
    $isSelected ? "0.138rem solid #47CCD6" : "0.069rem solid #4B4B4B"};
  border-bottom-left-radius: ${({ $isSelected }) =>
    $isSelected ? "0.138rem" : "0"};
  border-bottom-right-radius: ${({ $isSelected }) =>
    $isSelected ? "0.138rem" : "0"};
`;

export const OtpVerification = () => {
  const history = useHistory();
  const location = useLocation();
  const { showMessage } = useContext(SnackbarContext);

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [timer, setTimer] = useState(0);

  if (!location.state?.isRedirected) {
    history.push("/");
  }

  const handleVerifyClick = async () => {
    setDisableAllButtons(true);
    setOtpError("");
    if (otp.trim().length !== 6) {
      setOtpError("Please enter a 6 digit OTP");
      setDisableAllButtons(false);
      return;
    }

    try {
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/verifyOtp",
        {
          emailId: location.state?.emailId,
          otp: otp,
          sendUsernameEmail: location.state?.isForgotUsername,
        },
      );

      if (location.state?.isSignUp) {
        history.push("/createNewAccount", {
          isRedirected: true,
          emailId: location.state?.emailId,
          countryCode: location.state?.countryCode,
          phoneNumber: location.state?.phoneNumber,
          fullName: location.state?.fullName,
          organizationName: location.state?.organizationName,
          inviteCode: location.state?.inviteCode,
          otp: otp,
        });
      } else if (location.state?.isForgotUsername) {
        history.push("/forgotUsernamePage", {
          isRedirected: true,
          emailId: location.state?.emailId,
          username: response.data?.data?.username,
        });
      } else {
        history.push("/resetPasswordPage", {
          isRedirected: true,
          emailId: location.state?.emailId,
          otp: otp,
        });
      }
    } catch (err) {
      console.log("Error: verifyOtp", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to verify OTP, please try again later", "error");
      }
      setDisableAllButtons(false);
    }
  };

  const resendOtp = async () => {
    setDisableAllButtons(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/generateOtp",
        {
          emailId: location.state?.emailId,
          isNewAccount: location.state?.isSignUp,
        },
      );

      // As OTP is re-sent, set disableAllButtons to false
      setTimer(30);
      setDisableAllButtons(false);
      showMessage(
        "We have successfully resent the OTP to your registered email address",
        "success",
      );
    } catch (err) {
      console.log("Error: generateOtp", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to generate OTP, please try again later", "error");
      }
      setDisableAllButtons(false);
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && otp.trim().length === 6) {
      handleVerifyClick();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [otp]);

  return (
    <CommonFirstPage>
      <CustomRow>
        {!location.state?.isSignUp && (
          <CustomColumn mb={2.083} style={{ display: "flex" }}>
            {/* <ForgotContainer $isSelected={location.state?.isForgotUsername}>
              Forgot Username
            </ForgotContainer> */}
            {/* <ForgotContainer $isSelected={!location.state?.isForgotUsername}>
              Forgot Password
            </ForgotContainer> */}
          </CustomColumn>
        )}
        <CustomColumn mb={2.777}>
          <Text style={{}}>
            {`We have sent an email with OTP to `}
            <span
              style={{ color: "#47CCD6" }}
            >{` ${location.state?.emailId}`}</span>
            {`, please enter the OTP to continue`}
          </Text>
        </CustomColumn>
        <CustomColumn
          mb={2.777}
          style={{ display: "flex", flexDirection: "column", gap: "0.555rem" }}
        >
          <OtpInputField onChange={setOtp} isError={Boolean(otpError)} />
          {otpError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {otpError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={1.111}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={handleVerifyClick}
            disabled={disableAllButtons || otp.trim().length !== 6}
          >
            Verify
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <Text
            style={{
              color: "#777777",
              fontSize: "1.111rem",
              lineHeight: "1.388rem",
            }}
          >
            Didn’t get a code?
            <button
              type="button"
              aria-label="Click to Resend OTP"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                color: timer > 0 ? "#F6F6F6" : "#CCCCCC",
                fontWeight: "bold",
                textDecoration: timer ? "none" : "underline",
                cursor: "pointer",
                fontSize: "1.111rem",
                marginLeft: "0.277rem",
              }}
              onClick={() => !disableAllButtons && resendOtp()}
              disabled={disableAllButtons || timer > 0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  !disableAllButtons && resendOtp();
                }
              }}
            >
              {timer > 0 ? `Resend OTP in ${timer} s` : "Resend OTP"}
            </button>
          </Text>
        </CustomColumn>

        <CustomColumn
          mb={2.777}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "auto",
            cursor: "pointer",
          }}
          onClick={() =>
            !disableAllButtons &&
            history.push(location.state.isSignUp ? "/signUp" : "/signIn")
          }
        >
          <img
            src={ChevronRight}
            alt="Back Icon"
            style={{
              width: "0.833rem",
              height: "0.833rem",
              marginRight: "0.555rem",
            }}
          />
          <Text
            style={{
              color: "#CCCCCC",
              fontSize: "1.111rem",
              lineHeight: "1.388rem",
              marginBottom: "0px",
            }}
          >
            Back to {location.state.isSignUp ? "Signup" : "Signin"}
          </Text>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
