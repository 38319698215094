import React from "react";
import styled from "styled-components";
import ApplicationEmpty from "../../assets/images/Application Empty State Full updated.svg";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";

const Container = styled.div`
  padding: 2.8rem 4.62rem 0rem 4.62rem;
  width: 100%;
  height: calc(100vh - 3.95rem);
  background-color: #0d0d0d;
`;

const Title = styled.div`
  font-family: Articulat CF Medium;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 2.597rem;
  line-height: 4.3333rem;
  color: #f6f6f6;
`;
const Content = styled.div`
  font-family: Articulat CF Normal;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.297rem;
  text-align: left;
  color: #cccccc;
  padding-top: 1.3rem;
`;

function LandingApplicationPage({ buttonDisabled }) {
  const history = useHistory();

  const handleClick = () => {
    history.push("/createApplication");
  };

  return (
    <Container>
      <CustomRow>
        <Column xs={9} sm={10} lg={12}>
          <Title>Get started with your first Application!</Title>
        </Column>
      </CustomRow>
      <CustomRow>
        <Column xs={9}>
          <Content>
            You haven’t created any applications yet. Click on the button below
            to get started
          </Content>
        </Column>
      </CustomRow>
      {buttonDisabled ? (
        <StyledButtonPrimary
          style={{
            width: "21.11rem",
            marginTop: "2.2rem",
            backgroundColor: "#5e5e5e",
          }}
          disabled={true}
        >
          Add New Application
        </StyledButtonPrimary>
      ) : (
        <StyledButtonPrimary
          style={{ width: "21.11rem", marginTop: "2.2rem" }}
          onClick={() => handleClick()}
        >
          Add New Application
        </StyledButtonPrimary>
      )}

      <div
        style={{
          paddingTop: "3.3rem",
          width: "100%",
        }}
      >
        <img src={ApplicationEmpty} alt="" width="100%"></img>
      </div>
    </Container>
  );
}

export default LandingApplicationPage;
