import styled from "styled-components";
import PropTypes from "prop-types";

const MainContainer = styled.div`
  background-color: #4b4b4b;
  border-radius: 0.84rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "normal")};
`;

const Button = styled.button`
  border-radius: 0.84rem;
  background-color: #4b4b4b;
  color: #777777;
  font-size: ${({ $fontSize }) => $fontSize};
  font-family: "Articulat CF Medium";
  font-weight: bold;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "normal")};
`;

/**
 * A toggle button component, which consists of two buttons.
 * The currently selected option has a different background color and text color.
 *
 * @param {Object} props - Component props
 * @param {string|number} props.toggleValue - The current value of the toggle button
 * @param {Function} props.toggleFunction - The function to be called when the value of the toggle button is changed
 * @param {string} props.text1 - The text to be displayed on the first button
 * @param {string} props.text2 - The text to be displayed on the second button
 * @param {string|number} props.value1 - The value of the first button
 * @param {string|number} props.value2 - The value of the second button
 * @param {string} [props.height="3.333rem"] - The height of the component
 * @param {boolean} [props.isDisabled=false] - Whether the component is disabled
 * @param {string} [props.fontSize="1.112rem"] - The font size of the buttons
 */
const ToggleButton = ({
  toggleValue,
  toggleFunction,
  text1,
  text2,
  value1,
  value2,
  height = "3.333rem",
  isDisabled,
  fontSize = "1.112rem",
}) => {
  return (
    <MainContainer style={{ height: height }} isDisabled={isDisabled}>
      <Button
        style={{
          ...(toggleValue === value1
            ? { backgroundColor: "#777777", color: "#F6F6F6" }
            : {}),
        }}
        disabled={isDisabled}
        onClick={() => toggleFunction(value1)}
        $fontSize={fontSize}
      >
        <span style={{ letterSpacing: "0.5px" }}>{text1}</span>
      </Button>
      <Button
        style={{
          ...(toggleValue === value2
            ? { backgroundColor: "#777777", color: "#F6F6F6" }
            : {}),
        }}
        disabled={isDisabled}
        onClick={() => toggleFunction(value2)}
        $fontSize={fontSize}
      >
        <span style={{ letterSpacing: "0.5px" }}>{text2}</span>
      </Button>
    </MainContainer>
  );
};

ToggleButton.propTypes = {
  toggleValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  toggleFunction: PropTypes.func.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  value1: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  value2: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,

  height: PropTypes.string,
  fontSize: PropTypes.string,
  isDisabled: PropTypes.bool,
};

ToggleButton.defaultProps = {
  height: "3.333rem",
  fontSize: "1.112rem",
  isDisabled: false,
};

export default ToggleButton;
