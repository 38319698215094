import styled from "styled-components";
import Column from "../common/_customColumn";
import GenericModal from "../common/_genericModal";
import { useCallback, useContext, useState } from "react";
import CustomRow from "../common/_customRow";
import GenericSelectionWithLabel from "../common/_genericSelectionWithLabel";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";
import PropTypes from "prop-types";

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0;
  color: #ececec;
  font-size: 1.25rem;
  line-height: 1.555rem;
`;

const InputFieldContainer = styled.div`
  flex-grow: 1;
  padding: 1.015rem;
  border-radius: 0.555rem;
  border: 0.069rem solid #b0b0b0;
  background-color: inherit;
`;

const InputField = styled.input`
  font-size: 0.972rem;
  font-weight: bold;
  line-height: 1.111rem;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #777777;
  }
`;

const createExpiryBufferObject = (value) => {
  if (value === 1) {
    return {
      name: "1 Day",
      value: 1,
    };
  } else {
    return {
      name: `${value} Days`,
      value: value,
    };
  }
};

const JsonDialogsonDialog = ({
  dialogTitle,
  showEditDialog,
  onDialogClose,
  onclickProcced,
  descriptionText = "",
  buttonName = "Submit",
  backgroundColor = "",
  color = "",
}) => {
  // Hooks
  const { showMessage } = useContext(SnackbarContext);

  // State variables
  const [loading, setLoading] = useState(false);

  return (
    <GenericModal
      title={dialogTitle}
      show={showEditDialog}
      onClose={onDialogClose}
      disableCloseOnOutsideClick={loading}
    >
      <CustomRow>
        <CustomColumn mb={1.666}>
          <Text>{descriptionText}</Text>
        </CustomColumn>
        <Column
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "3.5rem",
          }}
        >
          <StyledButtonsecondary
            style={{ width: "14.167rem", marginRight: "1.112rem" }}
            onClick={onDialogClose}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            style={{
              width: "14.167rem",
              ...(backgroundColor && { backgroundColor }),
              ...(color && { color }),
            }}
            onClick={onclickProcced}
          >
            {buttonName}
          </StyledButtonPrimary>
        </Column>
      </CustomRow>
    </GenericModal>
  );
};

JsonDialogsonDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  showEditDialog: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onclickProcced: PropTypes.func.isRequired,
  descriptionText: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

export default JsonDialogsonDialog;
