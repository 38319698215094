import React, { useState, useRef, useContext, useEffect } from "react";
import { Editor } from "@monaco-editor/react";
import styled from "styled-components";
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";
import { saveAs } from "file-saver";
import {
  Table,
  TableBody,
  TableData,
  TableRow,
  TableWrapper,
} from "../../components/common/_table";
import SqlEditorResult from "../../assets/images/sqlEditorResult.svg";
import Expand from "../../assets/images/fullscreenExpand.svg";
import Collapse from "../../assets/images/left_arrow_icon.svg";
import Download from "../../assets/images/download.svg";
import CopyJSON from "../../assets/images/ksqlcopy.svg";
import CopyHoverJSON from "../../assets/images/ksql-copy-hover.svg";
import ExpandHover from "../../assets/images/ksql-expand-hover.svg";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
// import PaginationContainer from "../../components/common/_paginationContainer";
import { parseKsqlResponse } from "./utils";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
`;

const SQLResultContainer = styled.div`
  margin-top: ${(props) => (props.isFullscreen ? "0" : "1rem")};
  width: ${(props) => (props.isFullscreen ? "98%" : "100%")};
  overflow: hidden;
  background-color: ${(props) => (props.isFullscreen ? "#000000" : "#2f2f2f")};
  border: 0.069rem solid #5e5e5e;
  position: ${(props) => (props.isFullscreen ? "fixed" : "relative")};
  top: ${(props) => (props.isFullscreen ? "5rem" : "auto")};
  left: ${(props) => (props.isFullscreen ? "1.11rem" : "auto")};
  right: ${(props) => (props.isFullscreen ? "1.11rem" : "auto")};
  bottom: ${(props) => (props.isFullscreen ? "0rem" : "auto")};
  z-index: ${(props) => (props.isFullscreen ? "1000" : "0")};
  border-radius: ${(props) =>
    props.isFullscreen ? "0.75rem 0.75rem 0 0" : "0.75rem"};
  border-bottom: ${(props) =>
    props.isFullscreen ? "none" : "0.069rem solid #5E5E5E"};
  padding-left: ${(props) => (props.isFullscreen ? "0.5rem" : "0")};
  padding-right: ${(props) => (props.isFullscreen ? "0.5rem" : "0")};
`;

const SQLResultHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isFullscreen ? "1.3rem 1rem" : "1rem")};
  background-color: ${(props) => (props.isFullscreen ? "#000000" : "#2f2f2f")};
  border-bottom: ${(props) =>
    props.isFullscreen ? "none" : "0.069rem solid #5E5E5E"};
  border-radius: ${(props) =>
    props.isFullscreen ? "0" : "0.75rem 0.75rem 0 0"};
`;

const SQLTableResultContent = styled.div`
  height: ${(props) => (props.isFullscreen ? "88%" : "22rem")};
  width: ${(props) => (props.isFullscreen ? "99%" : "100%")};
  margin: ${(props) => (props.isFullscreen ? "0 0.5rem 0 0.5rem" : "0")};
  background-color: ${(props) => (props.isFullscreen ? "#2f2f2f" : "#000000")};
  border-radius: ${(props) => (props.isFullscreen ? "0.75rem" : "0")};
  padding: 1rem;
`;

const SQLJSONResultContent = styled.div`
  height: ${(props) => (props.isFullscreen ? "88%" : "22rem")};
  width: ${(props) => (props.isFullscreen ? "98%" : "100%")};
  border-radius: ${(props) => (props.isFullscreen ? "1rem" : "0")};
  margin: ${(props) => (props.isFullscreen ? "0 1rem 0 1rem" : "0")};
  overflow: hidden;
`;

const Switch = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  & > input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch-bg {
    width: 1.95rem;
    height: 1rem;
    background-color: #47ccd629;
    border-radius: 694.375rem;
    transition: background-color 0.2s ease;
    border: 0.069rem solid #47ccd6;
    position: relative;
  }

  .switch-knob {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${(props) => (props.isJsonView ? "0.125rem" : "1rem")};
    height: 0.7rem;
    width: 0.7rem;
    background-color: #47ccd6;
    border-radius: 50%;
    transition: left 0.2s ease;
  }
`;

const SwitchLabels = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1rem;
  color: ${(props) =>
    props.disabled
      ? "#777777"
      : (props.isJsonView && props.value === "json") ||
          (!props.isJsonView && props.value === "table")
        ? "#47CCD6"
        : "#f6f6f6"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
const Text = styled.p`
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  margin: 0;
  margin-left: 1rem;
  color: #f6f6f6;
  font-size: 1.666rem;
  font-weight: bold;
`;

const SQLResultActions = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const Button = styled.button`
  outline: none;
  border: none;
  background: ${(props) => (props.isFullscreen ? "#47CCD6" : "none")};
  border-radius: ${(props) => (props.isFullscreen ? "0.75rem" : "none")};
  width: ${(props) => (props.isFullscreen ? "14.166rem" : "1.73rem")};
  padding: ${(props) => (props.isFullscreen ? "0.9rem" : "none")};
  font-weight: bold;
  font-size: 1.2rem;
  color: #f6f6f6;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

    .default-icon {
      display: ${(props) => (props.disabled ? "block" : "none")};
    }

    .hover-icon {
      display: ${(props) => (props.disabled ? "none" : "block")};
    }
  }

  .default-icon {
    display: block;
  }

  .hover-icon {
    display: none;
  }
`;

const rowStyle = {
  borderBottom: "0.069rem solid #9090903D",
};

const cellStyle = {
  height: "3.88rem",
  color: "#f6f6f6",
  fontSize: "1rem",
  fontFamily: "Articulat CF Medium",
  overflow: "hidden",
  paddingLeft: "1.3rem",
  wordBreak: "break-word",
};

const headerCellStyle = {
  textAlign: "left",
  color: "#B0B0B0",
  fontSize: "1.3rem",
  fontWeight: "bold",
  whiteSpace: "normal",
  wordBreak: "break-word",
  paddingLeft: "1.3rem",
  height: "3.8rem",
};

const SQLResult = ({ ksqlJsonResponse, ksqlError }) => {
  const [recordsCount, setRecordsCount] = useState();
  const [results, setResults] = useState([]);
  const { showMessage } = useContext(SnackbarContext);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isJsonView, setIsJsonView] = useState(true);
  const editorRef = useRef(null);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const toggleResultView = () => {
    if (ksqlError) return;
    setIsJsonView(!isJsonView);
  };

  const handleDownloadCSV = () => {
    if (ksqlError) return;

    const csvContent = [
      Object.keys(results[0]).join(","),
      ...results.map((row) => Object.values(row).join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "data.csv");
    showMessage("CSV downloaded successfully !", "success");
  };

  const columns = Object.keys(results[0] || {}).map((key) => ({
    accessorKey: key,
    header: () => key,
    cell: (info) => info.getValue(),
    size: Math.max(100, key.length * 200),
    minSize: 200,
    maxSize: 500,
  }));

  const table = useReactTable({
    data: results,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;

    if (monaco) {
      monaco.editor.defineTheme("customTheme", {
        base: isFullscreen ? "vs-dark" : "hc-black",
        inherit: true,
        rules: [],
        colors: {
          "editor.foreground": "#FFFFFF",
          "editorGhostText.foreground": "#777777",
          "editor.selectionBackground": "#47ccd63d",
          "editor.selectionForeground": "#f6f6f6",
          "editor.selectionHighlightBackground": "#47ccd63d",
          "editor.selectionHighlightBorder": "transparent",
          "editorBracketMatch.background": "transparent",
          "editorBracketMatch.border": "transparent",
          "editorBracketHighlight.foreground1": "#FFFFFF",
          "editorBracketHighlight.foreground2": "#FFFFFF",
          "editorBracketHighlight.foreground3": "#FFFFFF",
        },
      });

      monaco.editor.setTheme("customTheme");
    }
  };

  // Handle fullscreen toggle
  React.useEffect(() => {
    if (isFullscreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isFullscreen]);

  const copyJsonToClipboard = () => {
    if (ksqlError) return;

    if (ksqlJsonResponse) {
      navigator.clipboard
        .writeText(JSON.stringify(ksqlJsonResponse, null, 2))
        .then(() => {
          showMessage("Result copied to clipboard", "success");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  useEffect(() => {
    if (ksqlJsonResponse) {
      let updatedResult = parseKsqlResponse(ksqlJsonResponse);
      setResults(updatedResult.data);

      if (!updatedResult.data[0].hasOwnProperty("NO_DATA")) {
        setRecordsCount(updatedResult.data.length);
      } else {
        setRecordsCount("");
      }
    }
  }, [ksqlJsonResponse]);

  useEffect(() => {
    const addScrollbarStyle = () => {
      const styleId = "monaco-custom-scrollbar-style";

      if (!document.getElementById(styleId)) {
        const style = document.createElement("style");
        style.id = styleId;
        style.innerHTML = `
          /* Selection highlighting styles */
          .monaco-editor .selected-text {
            background-color: #47ccd63D !important;
            color: #f6f6f6 !important;
          }
          .monaco-editor .selectionHighlight {
            background-color: #47ccd63d !important;
            color: #f6f6f6 !important;
          }
            
          /* Force text color in selections */
          .monaco-editor .view-line .selected-text span,
          .monaco-editor .view-line span.selected-text {
            color: #f6f6f6 !important;
          }
          
          /* Force selection color across all themes */
          .monaco-editor.vs-dark .selected-text span,
          .monaco-editor.hc-black .selected-text span,
          .monaco-editor .selected-text span {
            color: #f6f6f6 !important;
          }
         
          
          
          /* Prevent dots in whitespace */
          .monaco-editor .view-overlays .whitespace {
            display: none !important;
          }
          
          /* Additional fix to ensure no dots in selected text */
          .monaco-editor .view-line span {
            border: none !important;
          }
          
          /* Hide any rendered whitespace */
          .monaco-editor .view-line .inline-deleted-margin-view-zone,
          .monaco-editor .view-line .whitespace {
            display: none !important;
          }
  
          /* Scrollbar styles */
          .monaco-editor .scrollbar.vertical .slider {
            background: #cccccc !important;
            border-radius: 10px;
          }
          .monaco-editor .scrollbar.horizontal .slider {
            background: #cccccc !important;
            border-radius: 10px;
          }
        `;
        document.head.appendChild(style);
      }
    };

    const timeout = setTimeout(addScrollbarStyle, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Overlay isVisible={isFullscreen} onClick={toggleFullscreen} />
      <SQLResultContainer isFullscreen={isFullscreen}>
        <SQLResultHeader isFullscreen={isFullscreen}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
              src={isFullscreen ? Collapse : SqlEditorResult}
              alt={isFullscreen ? "Back" : "Result"}
              onClick={isFullscreen ? toggleFullscreen : () => {}}
            />
            <Text>Result</Text>
          </div>
          <SQLResultActions>
            {recordsCount && !ksqlError && (
              <div style={{ color: "#b0b0b0", fontSize: "0.97rem" }}>
                {recordsCount} {recordsCount === 1 ? "Record" : "Records"} Found
              </div>
            )}
            {!isFullscreen && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <SwitchLabels
                  value="json"
                  isJsonView={isJsonView}
                  disabled={!!ksqlError}
                >
                  JSON
                </SwitchLabels>
                <Switch isJsonView={isJsonView} disabled={!!ksqlError}>
                  <input
                    type="checkbox"
                    checked={isJsonView}
                    onChange={toggleResultView}
                    disabled={!!ksqlError}
                  />
                  <div className="switch-bg">
                    <div className="switch-knob"></div>
                  </div>
                </Switch>
                <SwitchLabels
                  value="table"
                  isJsonView={isJsonView}
                  disabled={!!ksqlError}
                >
                  Table
                </SwitchLabels>
              </div>
            )}
            {isJsonView ? (
              isFullscreen ? (
                <StyledButtonPrimary
                  onClick={copyJsonToClipboard}
                  disabled={!!ksqlError}
                  style={{
                    width: "14.166rem",
                    height: "3.3rem",
                    fontSize: "1.2rem",
                  }}
                >
                  Copy JSON
                </StyledButtonPrimary>
              ) : (
                <Button onClick={copyJsonToClipboard} disabled={!!ksqlError}>
                  <span className="default-icon">
                    <img
                      src={CopyJSON}
                      alt="Copy JSON"
                      style={{ width: "1.66rem", height: "1.66rem" }}
                    />
                  </span>
                  <span className="hover-icon">
                    <img
                      src={CopyHoverJSON}
                      alt="Copy JSON"
                      style={{ width: "1.66rem", height: "1.66rem" }}
                    />
                  </span>
                </Button>
              )
            ) : isFullscreen ? (
              <StyledButtonPrimary
                onClick={handleDownloadCSV}
                disabled={!!ksqlError}
                style={{
                  width: "14.166rem",
                  height: "3.3rem",
                  fontSize: "1.2rem",
                }}
              >
                Download
              </StyledButtonPrimary>
            ) : (
              <Button onClick={handleDownloadCSV} disabled={!!ksqlError}>
                <img
                  src={Download}
                  alt="Download"
                  style={{ width: "1.66rem", height: "1.66rem" }}
                />
              </Button>
            )}
            {!isFullscreen && (
              <Button onClick={toggleFullscreen} disabled={!!ksqlError}>
                <span className="default-icon">
                  <img
                    src={Expand}
                    alt="Expand"
                    style={{ width: "1.944rem", height: "1.944rem" }}
                  />
                </span>
                <span className="hover-icon">
                  <img
                    src={ExpandHover}
                    alt="Expand"
                    style={{ width: "1.944rem", height: "1.944rem" }}
                  />
                </span>
              </Button>
            )}
          </SQLResultActions>
        </SQLResultHeader>
        {results?.length &&
        table.getRowModel().rows.length &&
        !isJsonView &&
        !ksqlError ? (
          <SQLTableResultContent isFullscreen={isFullscreen}>
            <TableWrapper style={{ height: "100%" }}>
              <Table style={{ width: "100%" }}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                      key={headerGroup.id}
                      style={{
                        borderBottom: isFullscreen
                          ? "none"
                          : "0.069rem solid #9090903D",
                        background: isFullscreen ? "#4B4B4B" : "none",
                      }}
                    >
                      {headerGroup.headers.map((header, index) => (
                        <th
                          key={header.id}
                          style={{
                            ...headerCellStyle,
                            borderTopLeftRadius:
                              index === 0 ? "0.75rem" : "0rem",
                            borderBottomLeftRadius:
                              index === 0 ? "0.75rem" : "0rem",
                            borderTopRightRadius:
                              index === headerGroup.headers.length - 1
                                ? "0.75rem"
                                : "0rem",
                            borderBottomRightRadius:
                              index === headerGroup.headers.length - 1
                                ? "0.75rem"
                                : "0rem",
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} style={rowStyle}>
                      {row.getVisibleCells().map((cell) => (
                        <TableData key={cell.id} style={cellStyle}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableData>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableWrapper>
            {/* {isFullscreen && (
              <PaginationContainer
                totalCount={recordsCount}
                currentPage={1}
                pageSize={10}
                onPageChange={() => {}}
              />
            )} */}
          </SQLTableResultContent>
        ) : isJsonView && ksqlJsonResponse && !ksqlError ? (
          <SQLJSONResultContent isFullscreen={isFullscreen}>
            <Editor
              height="100%"
              defaultLanguage="json"
              value={JSON.stringify(ksqlJsonResponse, null, 2)}
              theme={isFullscreen ? "vs-dark" : "hc-black"}
              options={{
                minimap: { enabled: false },
                guides: { indentation: false },
                scrollBeyondLastLine: false,
                lineNumbers: "on",
                renderLineHighlight: "none",
                fontSize: 14,
                matchBrackets: "false",
                renderWhitespace: "none",
                selectionHighlight: false,
                stickyScroll: {
                  enabled: false,
                },
                fontFamily: "'Fira Code', Consolas, 'Courier New', monospace",
                tabSize: 2,
                scrollbar: {
                  useShadows: false,
                  verticalScrollbarSize: 3,
                  horizontalScrollbarSize: 3,
                  verticalSliderSize: 3,
                  horizontalSliderSize: 3,
                  alwaysConsumeMouseWheel: false,
                },
                padding: {
                  top: 16,
                },
                readOnly: true,
                formatOnPaste: true,
                formatOnType: true,
              }}
              onMount={handleEditorDidMount}
            />
          </SQLJSONResultContent>
        ) : (
          <SQLTableResultContent>
            <div
              style={{
                color: "#777777",
                fontFamily: "'Fira Code', Consolas, 'Courier New', monospace",
                fontSize: "1rem",
              }}
            >
              {ksqlError ? (
                <div style={{ color: "white" }}>{ksqlError}</div>
              ) : (
                <>
                  {" "}
                  Click on <span style={{ color: "#47CCD6" }}>
                    Run Query
                  </span>{" "}
                  to execute the statement above{" "}
                </>
              )}
            </div>
          </SQLTableResultContent>
        )}
      </SQLResultContainer>
    </>
  );
};

export default SQLResult;
