import styled from "styled-components";
import { CommonFirstPage } from "../LandingPages/CommonFirstPage";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { useState, useContext } from "react";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import ChevronRight from "../../assets/images/Chevron-left.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const Text = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
 
  margin: 0;
  color: #f6f6f6;
  font-size: 1.388rem;
  line-height: 2.166rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Normal";
  font-weight: bold;

  font-size: 1.111rem;
  line-height: 1.944rem;
  
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
`;

const ForgotContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1.666rem;
  font-weight: ${({ $isSelected }) => ($isSelected ? "bold" : "500")};
  color: ${({ $isSelected }) => ($isSelected ? "#47CCD6" : "#777777")};
  padding-bottom: ${({ $isSelected }) =>
    $isSelected ? "0.625rem" : "0.694rem"};
  border-bottom: ${({ $isSelected }) =>
    $isSelected ? "0.138rem solid #47CCD6" : "0.069rem solid #4B4B4B"};
  border-bottom-left-radius: ${({ $isSelected }) =>
    $isSelected ? "0.138rem" : "0"};
  border-bottom-right-radius: ${({ $isSelected }) =>
    $isSelected ? "0.138rem" : "0"};
`;

export const ForgotUsernamePassword = () => {
  const history = useHistory();
  const location = useLocation();
  const { showMessage } = useContext(SnackbarContext);

  const [isForgotUsername, setIsForgotUsername] = useState(false);
  const [emailAddress, setEmailAddress] = useState(location.state.email ?? "");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [disableAllButtons, setDisableAllButtons] = useState(false);

  const handleSendOtpClick = async () => {
    setEmailAddressError("");
    let isEmailIdValid = true;

    // Email validations
    if (!emailAddress) {
      setEmailAddressError("Please enter a value for the email address");
      isEmailIdValid = false;
    } else if (emailAddress.length > 320) {
      setEmailAddressError("Email length should be less than 320 characters");
      isEmailIdValid = false;
    } else if (!EMAIL_REGEX.test(emailAddress)) {
      setEmailAddressError("Please enter a valid email address");
      isEmailIdValid = false;
    }

    if (!isEmailIdValid) {
      return;
    }

    try {
      setDisableAllButtons(true);
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/generateOtp",
        {
          emailId: emailAddress,
          isNewAccount: false,
        },
      );

      // As OTP is generated, move to OTP verification page
      history.push("/otpVerification", {
        isRedirected: true,
        isSignUp: false,
        isForgotUsername: isForgotUsername,
        emailId: emailAddress,
      });
    } catch (err) {
      console.log("Error: generateOtp", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to generate OTP, please try again later", "error");
      }
      setDisableAllButtons(false);
      return;
    }
  };

  return (
    <CommonFirstPage>
      <CustomRow>
        <CustomColumn mb={2.083} style={{ display: "flex" }}>
          {/* <ForgotContainer
            $isSelected={!isForgotUsername}
            onClick={() => !disableAllButtons && setIsForgotUsername(false)}
          >
            Forgot Password
          </ForgotContainer> */}
        </CustomColumn>
        <CustomColumn mb={1.667}>
          <Text style={{ letterSpacing: "0" }}>
            {isForgotUsername
              ? `Forgot your username? No worries! Enter the email you used to sign up with Condense, and we’ll send your username once verified.`
              : `Forgot your password? No problem. We will send an email with
            instructions to reset your password along with OTP.`}
          </Text>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <InputFieldContainer
            style={
              emailAddressError
                ? { borderColor: "#EA3A3A", marginBottom: "0.555rem" }
                : {}
            }
          >
            <InputField
              placeholder="Your email address"
              name="emailAddress"
              onChange={(e) => setEmailAddress(e.target.value)}
              value={emailAddress}
            />
          </InputFieldContainer>
          {emailAddressError && (
            <Text
              style={{
                fontSize: "0.833rem",
                lineHeight: "1.388rem",
                color: "#EA3A3A",
              }}
            >
              {emailAddressError}
            </Text>
          )}
        </CustomColumn>
        <CustomColumn mb={0.833}>
          <StyledButtonPrimary
            style={{ width: "100%", height: "3.888rem" }}
            onClick={handleSendOtpClick}
            disabled={disableAllButtons}
          >
            Send OTP
          </StyledButtonPrimary>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "auto",
            cursor: "pointer",
          }}
          onClick={() => !disableAllButtons && history.push("/signIn")}
        >
          <img
            src={ChevronRight}
            alt="Back Icon"
            style={{
              width: "0.833rem",
              height: "0.833rem",
              marginRight: "0.555rem",
            }}
          />
          <Text
            style={{
              color: "#CCCCCC",
              fontSize: "1.111rem",
              lineHeight: "1.388rem",
              letterSpacing: "0px",
              marginBottom: "0px",
            }}
          >
            Back to Sign In
          </Text>
        </CustomColumn>
      </CustomRow>
    </CommonFirstPage>
  );
};
