import styled, { createGlobalStyle } from "styled-components";
import GenericSelection from "../common/_dropDownComponent";
import { useContext, useState, useEffect } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import GenericModal from "../common/_genericModal";
import warning_icon from "../../assets/images/warning_yellow_icon.svg";
import PropTypes from "prop-types";

import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";

const Label = styled.div`
  color: #b0b0b0;
  // font-family: "Articulat CF Medium";
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.972rem;
  letter-spacing: 0rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.55rem;
  margin-bottom: 1.112rem;
`;

const CreateNewTopicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -0.7rem;
  gap: 0.55rem;
  letter-spacing: 0rem;
`;

const CreateButton = styled.span`
  font-family: Articulat CF Medium;
  line-height: 1.4rem;
  font-size: 0.972rem;
`;

const StyledInput = styled.input`
  height: 3.33rem;
  padding-left: 0.8rem;
  outline: none;
  background: #2f2f2f;
  width: 100%;
  border-radius: 0.84rem;
  border: 0.138rem solid #777777;
  color: #ececec;
  font-size: 1.112rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  transition: border 0.2s ease;
  &:focus {
    border: 0.12rem solid #ececec;
  }

  &::placeholder {
    font-family: "Articulat CF Normal";
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const WarningText = styled.div`
  margin-top: 0.7rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.041rem;
  font-style: italic;
  line-height: 1.112rem;
  display: flex;
  align-items: center;
  color: #edb62e;
`;

const ModalButtonContainer = styled.div`
  margin-top: 2.084rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const InputField = styled.input`
  font-family: "Articulat CF Normal";
  font-size: 0.972rem;
  line-height: 0.8rem;
  padding-left: 0.84rem;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #b0b0b0;
  }
  &[autoComplete="off"] {
    box-shadow: none;
  }
`;

const SASLInput = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.972rem;
  line-height: 0.8rem;
  padding-left: 0.84rem;
  color: #b0b0b0;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
`;

const InputFieldContainer = styled.div`
  padding: 1rem 0rem;
  width: 100%;
  border-radius: 0.84rem;
  border: 0.069rem solid #f6f6f6;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TelematicsInputForm = ({
  configs,
  setConfigs,
  deviceData,
  setPreviewData,
  allTopics,
  setAllTopics,
}) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { showMessage } = useContext(SnackbarContext);
  const [newTopicNamesMapper, setNewTopicNamesMapper] = useState({}); // mapper to store the new topicnames entered
  const [newTopicModalMapper, setNewTopicModalMapper] = useState({}); // mapper to store the state of new topic modal

  //Function to fetch kafka-credentials
  const fetchKafkaCredentials = async () => {
    try {
      const response = await axiosAdapter(
        "GET",
        `${env.REACT_APP_URL}kafka-management/getKafkaCredentials`,
        {},
      );
      if (response?.data) {
        setPassword(response.data.data.password);
        setUsername(response.data.data.username);
      }
    } catch (error) {
      showMessage(
        error.response?.data?.remarks ||
          "An error occurred while fetching Kafka credentials",
        "error",
      );
    }
  };

  useEffect(() => {
    fetchKafkaCredentials();
  }, []);

  // Function to create a new topic and store it if the response is successful
  const createNewTopic = async (index, newTopicName, closeModalCallback) => {
    try {
      let topicCreationResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "kafka-management/createTopic",
        JSON.stringify({
          topicName: newTopicName,
        }),
      );
      if (topicCreationResponse.data.status === "SUCCESS") {
        setAllTopics((prev) => {
          let tempTopics = [...prev, { name: newTopicName }];
          return tempTopics;
        });
        setConfigs((prev) => {
          let tempConfigs = [...prev];
          tempConfigs[index] = {
            ...tempConfigs[index],
            value: { name: newTopicName },
          };
          return tempConfigs;
        });
        setPreviewData((prev) => {
          let prevData = prev;
          prevData.interfaces[index] = {
            name: newTopicName,
            direction: configs[index].direction,
          };
          return prevData;
        });
        setNewTopicNamesMapper((prev) => {
          let tempMap = { ...prev, [index]: null };
          return tempMap;
        });
        closeModalCallback();
        showMessage("New topic created successfully!", "success");
      } else {
        console.log(
          "New topic addition failed",
          topicCreationResponse.data.message,
        );
        showMessage(topicCreationResponse.data.message);
      }
    } catch (err) {
      console.log("Err createNewTopic", err);
      showMessage(
        err.response?.data?.remarks || "Error in creating new topic",
        "error",
      );
    }
  };

  return (
    <>
      <InputContainer>
        <Label>Select a Device *</Label>
        <GenericSelection
          dropdownItems={deviceData}
          selectedItem={selectedDevice}
          placeholder={"Select a Device"}
          onSelect={(item) => {
            setSelectedDevice(item);
            setConfigs([
              {
                type: "text",
                name: "deviceName",
                label: "Device Name",
                placeHolder: "Device Name",
                isRequired: true,
                value: item.name,
              },
              ...JSON.parse(item.topic).map((item) => {
                return {
                  ...(item.type === "topic"
                    ? { ...item, value: { name: item.value } }
                    : item.label === "SASL Username"
                      ? { ...item, value: username }
                      : item.label === "SASL Password"
                        ? { ...item, value: password.trim() }
                        : { ...item }),
                };
              }),
            ]);
            setPreviewData((prev) => {
              let prevData = prev;
              prevData.interfaces = [
                undefined,
                ...JSON.parse(item.topic).map((topic) =>
                  topic.type === "topic"
                    ? {
                        name: topic.value,
                        direction: topic.direction,
                      }
                    : undefined,
                ),
              ];
              return prevData;
            });
          }}
          nameFunction={(item) => item.name}
          filterFunction={(arr, text) =>
            arr.filter((item) =>
              item.name.toLowerCase().includes(text.toLowerCase()),
            )
          }
          searchPlaceHolder={"Search for Devices"}
        />
      </InputContainer>
      {configs?.map((field, index) => {
        if (index === 0) return null; // Skip rendering for index 0

        if (field.type === "topic") {
          return (
            <InputContainer key={field.label}>
              <Label>
                {`${field.label} (${field.direction})`}
                {field.isRequired && " *"}
              </Label>
              <GenericSelection
                dropdownItems={
                  Array.isArray(allTopics)
                    ? allTopics.map((topic) =>
                        typeof topic === "string" ? { name: topic } : topic,
                      )
                    : []
                }
                selectedItem={field.value}
                placeholder={"Select a Topic"}
                onSelect={(item) => {
                  let tempConfigs = [...configs];
                  tempConfigs[index] = {
                    ...tempConfigs[index],
                    value: item,
                  };
                  setConfigs(tempConfigs);
                }}
                nameFunction={(item) => item.name}
                filterFunction={(arr, text) =>
                  arr.filter((item) =>
                    item?.name?.toLowerCase().includes(text.toLowerCase()),
                  )
                }
                searchPlaceHolder={"Search for Topics"}
              />
              <CreateNewTopicContainer>
                <CreateButton
                  style={{
                    color: "#777777",
                    fontFamily: "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  Can't Find the Topic?
                </CreateButton>
                <CreateButton
                  style={{
                    color: "#CCCCCC",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontFamily: "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                  onClick={() =>
                    setNewTopicModalMapper((prev) => ({
                      ...prev,
                      [field.id]: true,
                    }))
                  }
                >
                  Create a New Topic
                </CreateButton>
              </CreateNewTopicContainer>
              <GenericModal
                onClose={() =>
                  setNewTopicModalMapper((prev) => ({
                    ...prev,
                    [field.id]: false,
                  }))
                }
                show={newTopicModalMapper[field.id] || false}
                title={"Create a New Topic"}
              >
                <StyledInput
                  placeholder="Add a New Topic Name"
                  value={newTopicNamesMapper[field.id] || ""}
                  onChange={(e) =>
                    setNewTopicNamesMapper((prev) => ({
                      ...prev,
                      [field.id]: e.target.value,
                    }))
                  }
                />
                <WarningText>
                  <img
                    src={warning_icon}
                    alt="warning_icon"
                    style={{ marginRight: "5px" }}
                  />
                  A New Topic with this name will be created and available for
                  use
                </WarningText>
                <ModalButtonContainer>
                  <StyledButtonsecondary
                    style={{ width: "14.167rem" }}
                    onClick={() =>
                      setNewTopicModalMapper((prev) => ({
                        ...prev,
                        [field.id]: false,
                      }))
                    }
                  >
                    Cancel
                  </StyledButtonsecondary>
                  <StyledButtonPrimary
                    style={{ width: "14.167rem" }}
                    disabled={!newTopicNamesMapper[field.id]?.length}
                    onClick={() => {
                      createNewTopic(
                        index,
                        newTopicNamesMapper[field.id],
                        () => {
                          setNewTopicModalMapper((prev) => ({
                            ...prev,
                            [field.id]: false,
                          }));
                        },
                      );
                    }}
                  >
                    Create Topic
                  </StyledButtonPrimary>
                </ModalButtonContainer>
              </GenericModal>
            </InputContainer>
          );
        } else {
          if (
            field.label === "SASL Username" ||
            field.label === "SASL Password"
          ) {
            return (
              <InputContainer key={field.name}>
                <Label htmlFor={field.name}>{field.label}</Label>
                <InputFieldContainer>
                  <SASLInput>
                    {field.label === "SASL Username" ? username : password}
                  </SASLInput>
                </InputFieldContainer>
              </InputContainer>
            );
          } else {
            return (
              <InputContainer key={field.name}>
                <Label htmlFor={field.name}>
                  {field.label}
                  {field.isRequired && " *"}
                </Label>
                <InputFieldContainer>
                  <InputField
                    type={field.type}
                    id={field.name}
                    name={field.name}
                    placeholder={field.placeHolder}
                    required={field.isRequired}
                    autoComplete="one-time-code"
                    // value={field.value || ""}
                    defaultValue={""}
                    onChange={(e) => {
                      if (field.label === "Title") {
                        if (
                          /^[a-z0-9][a-z0-9.-]*$/.test(
                            e.target.value.toLowerCase(),
                          ) ||
                          e.target.value === ""
                        ) {
                          e.target.value = e.target.value.toLowerCase();
                        } else {
                          showMessage(
                            "Only lowercase alphanumeric characters along with [-.] are allowed and cannot start with [-.]",
                            "error",
                          );
                          return;
                        }
                      }
                      let tempConfigs = [...configs];
                      tempConfigs[index] = {
                        ...tempConfigs[index],
                        value: e.target.value,
                        ...(field.label === "Title" ? { name: "title" } : {}),
                      };
                      setConfigs(tempConfigs);
                      if (field.label === "Title") {
                        setPreviewData((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }));
                      }
                    }}
                  />
                </InputFieldContainer>
              </InputContainer>
            );
          }
        }
      })}
    </>
  );
};

TelematicsInputForm.propTypes = {
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "topic"]).isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      placeHolder: PropTypes.string,
      isRequired: PropTypes.bool,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ]),
      direction: PropTypes.oneOf(["input", "output"]),
    }),
  ),
  setConfigs: PropTypes.func.isRequired,

  deviceData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      topic: PropTypes.string.isRequired,
    }),
  ).isRequired,

  setPreviewData: PropTypes.func.isRequired,

  allTopics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,

  setAllTopics: PropTypes.func.isRequired,
};

TelematicsInputForm.defaultProps = {
  configs: [],
};

export default TelematicsInputForm;
