import { React, useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Column from "../common/_customColumn";
import { ReactComponent as ErrorIcon } from "../../assets/images/node_status_error_icon.svg";
import { ReactComponent as InspectIcon } from "../../assets/images/node_status_inspect_icon.svg";
import { ReactComponent as RunningIcon } from "../../assets/images/node_status_running_icon.svg";
import ConnectorInfoIcon from "../../assets/images/Frame 1000002437.svg";
import MemoryUtilizationIcon from "../../assets/images/MemoryUtilization.svg";
import ConnectorConfigIcon from "../../assets/images/Details.svg";
import CustomRow from "../common/_customRow";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import PropTypes from "prop-types";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { ReactComponent as CopyContentIcon } from "../../assets/images/copy_content_icon.svg";
import Tooltip from "../common/_tooltip";
import FlexContainer from "../../components/common/_flexContainer";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableData,
  TableRow,
  TableWrapper,
} from "../common/_table";
import { ReactComponent as StreanDetails } from "../../assets/images/Stream-Details.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import { ReactComponent as InfoIcon2 } from "../../assets/images/info.svg";
import CustomRightDropdown from "../../components/common/_customRightDropdown.jsx";
import Slider from "../../components/common/_slider";
import up_icon from "../../assets/images/up_icon.svg";
import down_icon from "../../assets/images/down_icon.svg";
import warning_icon from "../../assets/images/warning_yellow_icon.svg";
import GenericModal from "../../components/common/_genericModal";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import ReactFlowProviderContent from "../../pages/UtilityConnectorpages/GroupsRulesUI.jsx";
import StreamEmptyState from "../../assets/images/StreamsEmptyState.svg";
import Icon_strean from "../../assets/images/streamIcon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/AddRule.svg";
import { JsonModal } from "../../components/UtilityComponents/JsonModal";
import { default as Spinner2 } from "../../components/common/_spinner_teel.jsx";
import LockIcon from "../../assets/images/LockSimple.svg";
import { ReactComponent as AddIcon2 } from "../../assets/images/AddRuleWhite.svg";

const StyledCopyIcon = styled(CopyContentIcon)`
  height: 1.2rem;
  width: 1.3rem;
  cursor: pointer;
  margin-bottom: 0.277rem;
  color: #777777;
  margin-left: 0.555rem;
`;

const StyledInspectIcon = styled(InspectIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;

const StyledRunningIcon = styled(RunningIcon)`
  height: 1.111rem;
  width: 1.111rem;
  & path {
    stroke: ${(props) => getCategoryColor(props.category)};
  }
`;

const ConnectorForm = styled.div`
  height: 100%;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    margin-top: 1rem;
  }
`;
const ConnectorInfo = styled.div`
  padding: 1.112rem 1.112rem 1.67rem 1.112rem;
`;
const ConnectorInput = styled.div`
  padding: 1.67rem 1.112rem 1.112rem 1.112rem;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.39rem;
  color: #fafafa;
  padding-left: 0.84rem;
`;

const Label = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 0.972rem;
  color: #777777;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  // margin-bottom: 0.55rem;
`;

const Label2 = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.84rem;
  color: #b0b0b0;
  margin-bottom: 0.55rem;
  display: block;
  margin-top: 1rem;
`;

const Value = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.25rem;
  color: #f6f6f6;
  font-weight: bold;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const InputLabel = styled.div`
  font-family: "Articulat CF Medium";
  padding-top: 1.112rem;
  font-size: 0.972rem;
  font-weight: bold;
  color: var(--grey-2, #b0b0b0);
  letter-spacing: 0;
`;

const Spinner = styled.div`
  border: 0.138rem solid rgba(76, 74, 74, 0.1);
  border-top: 0.138rem solid ${(props) => getCategoryColor(props.category)};
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.277rem;
  animation: ${spin} 0.7s linear infinite;
`;

const InputContent = styled.div`
  font-family: "Articulat CF Medium";
  display: flex;
  flex-direction: column;
  gap: 0.84rem;
  font-size: 0.972rem;
  font-weight: bold;
  padding: 0.277rem;
`;

const ConnectorInformation = styled.div`
  display: flex;
`;
const ConnectorIcon = styled.img``;

const InputField = styled.input`
  width: 4rem;
  height: 2rem;
  padding: 1.112rem 0.84rem;
  border-radius: 0.84rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.972rem;
  line-height: 1.0833rem;
  background-color: #0d0d0d;
  border: none;
  outline: none;
`;

const NodeStatusContainer = styled.div`
  border: 0.104rem solid #f6f6f6;
  padding: 0.277rem 0.555rem;
  border-radius: 0.277rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Running {
    background: ${(props) => getBackgroundColor(props.category)};
    border-color: ${(props) => getCategoryColor(props.category)};
  }

  &.Inspect {
    background: #edb62e29;
    border-color: #edb62e;
  }

  &.Error {
    background: #ea3a3a29;
    border-color: #ea3a3a;
  }
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NodeStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.555rem;
`;

const rowStyle = {
  borderBottom: "0.0694rem solid #9090903D",
};

const cellStyle = {
  padding: "1.3888rem",
  color: "#f3f4f6",
  fontSize: "0.972rem",
  fontWeight: "bold",
};

const headerCellStyle = {
  padding: "1.0416rem 0rem 1.0416rem 1.3888rem",
  textAlign: "left",
  color: "#9ca3af",
  fontSize: "0.833rem",
  fontWeight: "400",
};

const getCategoryColor = (category) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B";
    case "Store Connector":
      return "#B5E3CE";
    case "Application":
      return "#EB6F95";
    case "Telematics Device":
      return "#F7C0D5";
    default:
      return "#EB6F95";
  }
};

const getBackgroundColor = (category) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B29";
    case "Store Connector":
      return "#B5E3CE29";
    case "Application":
      return "#EB6F9529";
    case "Telematics Device":
      return "#F7C0D529";
    default:
      return "#EB6F9529";
  }
};

const RunningStatus = ({ category }) => {
  return (
    <NodeStatusDiv>
      <StyledRunningIcon category={category} />
      <Text
        style={{
          fontSize: "0.972rem",
          color: getCategoryColor(category),
          fontFamily: "Articulat CF Normal",
          fontWeight: "bold",
          letterSpacing: "0.5px",
        }}
      >
        Running
      </Text>
    </NodeStatusDiv>
  );
};

const InspectStatus = () => (
  <NodeStatusDiv>
    <StyledInspectIcon />
    <Text
      style={{
        fontSize: "0.972rem",
        color: "#EDB62E",
        lineHeight: "1",
        fontFamily: "Articulat CF Normal",
        fontWeight: "bold",
        letterSpacing: "0.5px",
      }}
    >
      Inspect
    </Text>
  </NodeStatusDiv>
);

const ErrorStatus = () => (
  <NodeStatusDiv>
    <StyledErrorIcon />
    <Text
      style={{
        fontSize: "0.972rem",
        color: "#EA3A3A",
        lineHeight: "1",
        fontFamily: "Articulat CF Normal",
        fontWeight: "bold",
        letterSpacing: "0.5px",
      }}
    >
      Error
    </Text>
  </NodeStatusDiv>
);

const displayNodeStatus = (status, category) => {
  switch (status) {
    case "Running":
      return <RunningStatus category={category} />;
    case "Inspect":
      return <InspectStatus />;
    case "Error":
      return <ErrorStatus />;
    default:
      return <Spinner category={category} />;
  }
};

const StreamDetailsContainer = styled.div`
  padding: 1rem 1rem 2.5rem 1rem;
  border-radius: 0.84rem;
  background-color: #2f2f2f;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  overflow-y: auto; /* Enable scrolling */
  overflow-x: none;
  position: relative; /* Ensure dropdown options are positioned relative to this container */
`;
const StreanDetailsContent = styled.div`
  display: flex;
  /* flex-direction: column; */
`;

const InputFieldContainer = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0.8rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.84rem;
  background-color: #0d0d0d;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Arrow = styled.span`
  font-size: 0.9722rem;
  cursor: pointer;
  color: #b0b0b0;
  user-select: none;

  &:hover {
    color: #ffffff;
  }
`;

const ValueTextField = styled.span`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 1rem;
`;

const TitleTextField = styled.input`
  height: 2.9rem;
  flex: 1;
  padding: 0.8rem;
  color: rgb(255, 255, 255);
  background-color: #0d0d0d;
  border: none;
  border-radius: 0.5rem;
  overflow-x: auto;
  white-space: nowrap;
  outline: none;
  padding-right: 2.5rem; // Add padding to avoid overlap with the icon
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
`;

const TitleInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledInput = styled.input`
  height: 3.33rem;
  padding-left: 0.8rem;
  outline: none;
  background: #2f2f2f;
  width: 100%;
  border-radius: 0.84rem;
  border: 0.138rem solid #777777;
  color: #ececec;
  font-size: 1.112rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  transition: border 0.2s ease;
  &:focus {
    border: 0.12rem solid #ececec;
  }

  &::placeholder {
    font-family: "Articulat CF Normal";
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #b0b0b0;
    font-size: 0.972rem;
    background-color: #ea3a3a;
  }
`;

const ModalButtonContainer = styled.div`
  margin-top: 2.084rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const WarningText = styled.div`
  margin-top: 0.7rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.041rem;
  font-style: italic;
  line-height: 1.112rem;
  display: flex;
  align-items: center;
  color: #edb62e;
`;

const TextField = styled.input`
  flex: 1;
  padding-left: 0.8rem;
  font-size: 1.12rem;
  color: #fff;
  background-color: #0d0d0d;
  border: none;
  border-radius: 0 0.55rem 0.55rem 0; /* Rounded only on right side */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text from wrapping */
  outline: none; /* Remove focus outline */
`;

const PreviewContainer = styled.div`
  width: 30rem;
  height: 3.333rem;
  padding: 0.92rem 1rem;
  background-color: #0d0d0d;
  border-radius: 0.55rem;
  font-size: 0.93rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  border: 0.063rem solid #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Needed for absolute dropdown positioning */
`;

const PreviewContainerForRules = styled.div`
  width: 62.569rem;
  height: 3.333rem;
  padding: 0.92rem 1rem;
  background-color: #0d0d0d;
  border-radius: 0.55rem;
  font-size: 0.93rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  border: 0.063rem solid #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Needed for absolute dropdown positioning */
`;

const Pipe = styled.div`
  width: 0.07rem;
  height: 3.333rem;
  border-left: 0.07rem solid #4b4b4b;
  margin-left: 1rem;
`;

const HorizontalLine = styled.div`
  width: 64.722rem;
  border-top: 0.07rem solid #4b4b4b;
`;

const EmptystateText = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  color: #b0b0b0;
  width: 31.1805rem;
  margin-top: 0.4rem;
  line-height: 1.625;
  margin-left: 3.89rem;
`;

const EmptystateHeader = styled.div`
  margin-top: 2.89rem;
  color: #f6f6f6;
  font-size: 1.67rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-left: 3.89rem;
`;

const Footer = styled.div`
  height: 5.55rem;
  padding: 1.5rem 0 1.112rem 1.112rem;
`;

const ConfigurationContainer = styled.div`
  height: 100%;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  display: flex;
  overflow-y: hidden;
  overflow-x: none;
  flex-direction: column;
`;

const ConfigurationHeader = styled.div`
  height: 7.778rem;
  margin: 1.12rem 1.112rem 0 1.112rem;
  /* width: 64.73rem; */
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
`;

// const ConfiguraitonTextBox = styled.div``;

const AddGroupText = styled.div`
  color: #47ccd6;
  font-size: 1.22rem;
`;

const AddGroup = styled.span`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center; /* Align icon and text */
  gap: 0.4rem; /* Add spacing between icon and text */
`;

const StreamIcon = styled.div`
  height: 2.225rem;
  width: 2.225rem;
  border-radius: 0.28rem;
  background-color: #4b4b4b;
  padding: 0.28rem;
`;

const ConfigurationHeading = styled.div`
  font-size: 1.67rem;
  text-align: left;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  margin-left: 0.84rem;
  color: #fafafa;
`;

const Configuration = styled.div`
  height: 29.027rem;
  width: 64.722rem;
`;

const PreviewText = styled.span`
  display: flex;
  align-items: center; /* Align icon and text */
  gap: 0.4rem; /* Add spacing between icon and text */
  color: #777777;
  font-size: 1.12rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const ViewJsonText = styled.span`
  color: #47ccd6;
  font-size: 0.97rem;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  font-family: "Articulat CF Normal";
  letter-spacing: 0.5px;
  &:hover {
    color: #f6f6f6;
  }
`;

const ConnectorUtilityInfoForm = ({
  nodeData,
  setConfigs,
  fetchDeviceName,
  allTopics,
  setAllTopics,
  editModal,
  configs,
  setJsonValue,
  jsonValue,
  groupsAndRules,
  setGroupsAndRules,
  fieldOptionValues,
  setFieldOptionValues,
  isGroupError,
  setIsGroupError,
  groupBy,
  setGroupBy,
  rulesError,
  setRulesError,
  isAllTopicsLoading,
  setIsAllTopicsLoading,
  expressionTextObj,
  setExpressionTextObj,
  logic,
  setLogic,
  setIsFieldsLoading,
  isFieldsLoading,
  configResponseData,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [nodeStatus, setNodeStatus] = useState("");
  const [configValues, setConfigValues] = useState(null);
  const [servicePort, setServicePort] = useState(null);
  const [serviceDns, setServiceDns] = useState(null);
  const [ingressPath, setIngressPath] = useState(null);
  const [allPods, setAllPods] = useState([]);
  const [isUtilityConnector, setIsUtilityConnector] = useState(
    nodeData.category === "Transform" && nodeData.connectionType === "Output"
      ? true
      : false,
  );
  // const [jsonValue, setJsonValue] = useState("");
  const inputInterface = nodeData.interfaces.find(
    (i) => i.direction === "input",
  );
  const outputInterfaces = nodeData.interfaces.filter(
    (i) => i.direction === "output",
  );

  const [selectedInputTopic, setSelectedInputTopic] = useState(
    inputInterface.name ? inputInterface.name : "",
  );
  const [selectedPrimaryOutputTopic, setSelectedPrimaryOutputTopic] = useState(
    outputInterfaces[0]?.name ? outputInterfaces[0]?.name : "",
  );
  const [selectedSecondaryOutputTopic, setSelectedSecondaryOutputTopic] =
    useState(outputInterfaces[1]?.name ? outputInterfaces[1]?.name : "");
  const [expirationOffset, setExpirationOffset] = useState(0);
  const [expressionText, setExpressionText] = useState("");
  // const [expressionTextObj, setExpressionTextObj] = useState("");
  // const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  // const [rulesError, setRulesError] = useState(true);
  const [handleAddParent, setHandleAddParent] = useState(() => {});
  // const [logic, setLogic] = useState("");
  const [title, setTitle] = useState(nodeData.title ? nodeData.title : "");
  // const [groupsAndRules, setGroupsAndRules] = useState([]);
  const [connectionsVal, setConnectionsVal] = useState([]);
  const [jsonOnlyKeys, setJsonOnlyKeys] = useState([]);
  // const [groupBy, setGroupBy] = useState("");
  const [missedRulesTemp, setMissedRulesTemp] = useState([]);
  // const [isGroupError, setIsGroupError] = useState(false);
  // const [fieldOptionValues, setFieldOptionValues] = useState([]);
  const [newTopicModalMapper, setNewTopicModalMapper] = useState({});
  const [newTopicNamesMapper, setNewTopicNamesMapper] = useState({}); // mapper to store the new topicnames entered
  const [isConfigurationOpen, setisConfigurationOpen] = useState(false);
  const [isJsonModalOpen, setIsJsonModalOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const style = document.createElement("style");
    if (editModal) {
      style.innerHTML = `
      .info-icon:hover path {
        stroke: #47CCD6;
      }
      .info-icon-2:hover path {
        stroke: #47CCD6;
      }
    `;
    }

    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [editModal]);

  const setSliderValue = (val) => {
    setExpirationOffset(val);
    updateConfigValue("alertDuration", val);
  };

  const handleViewJson = () => {
    setIsJsonModalOpen(true);
  };

  const handleCloseModal = () => {
    setJsonValue(jsonValue);
    setIsJsonModalOpen(false);
  };

  const previewFunction = (item) => {
    setExpressionText(item);
  };

  const handleLogic = (item) => {
    setLogic(item);
  };

  const handlePreview = (item) => {
    console.log(item, "expressionTextObj");
    setExpressionTextObj(item);
  };

  const handleGroupsAndRules = (groups, connections) => {
    setGroupsAndRules(groups);
    setConnectionsVal(connections);
  };

  const isValidJsonDataCheck = (val) => {
    try {
      let parsed = "";
      if (typeof val === "string") {
        parsed = JSON.parse(val);
      } else {
        parsed = val;
      }
      return Object.keys(parsed).length > 0 ? true : false;
    } catch (e) {
      return false;
    }
  };

  const handleConfiguration = () => {
    if (!isFieldsLoading && isValidJsonDataCheck(jsonValue)) {
      setisConfigurationOpen(true);
    }
  };

  const createNewTopic = async (index, newTopicName, fieldDirection) => {
    try {
      let topicCreationResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "kafka-management/createTopic",
        JSON.stringify({
          topicName: newTopicName,
        }),
      );
      if (topicCreationResponse.data.status === "SUCCESS") {
        if (fieldDirection === "inputTopic") {
          setSelectedInputTopic(newTopicName);
          updateConfigValue("inputTopic", newTopicName);
        } else if (
          fieldDirection === "outputTopic" ||
          fieldDirection === "primaryOutputTopic"
        ) {
          setSelectedPrimaryOutputTopic(newTopicName);
          updateConfigValue(
            nodeData.name === "Alerts" ? "outputTopic" : "primaryOutputTopic",
            newTopicName,
          );
        } else {
          setSelectedSecondaryOutputTopic(newTopicName);
          updateConfigValue("secondaryOutputTopic", newTopicName);
        }
        setAllTopics((prev) => {
          let tempTopics = [...prev, newTopicName];
          return tempTopics;
        });
        setConfigs((prev) => {
          let tempConfigs = [...prev];
          tempConfigs[index] = {
            ...tempConfigs[index],
            value: newTopicName,
          };
          return tempConfigs;
        });
        setNewTopicNamesMapper((prev) => {
          let tempMap = { ...prev, [index]: null };
          return tempMap;
        });
        setNewTopicModalMapper((prev) => {
          let tempMap = { ...prev, [index]: false };
          return tempMap;
        });
      } else {
        showMessage(topicCreationResponse.data.message);
      }
    } catch (err) {
      console.log("Err createNewTopic", err);
      showMessage("Error in creating new topic");
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const num = newValue === "" ? 0 : parseInt(newValue, 10);

    if (num >= 0 && num <= 1800) {
      setExpirationOffset(num);
      updateConfigValue("alertDuration", num);
    } else {
      showMessage("Alert Duration must be between 0 and 1800 seconds");
    }
  };

  const handleIncrement = () => {
    if (expirationOffset < 1800) {
      setExpirationOffset((prev) => {
        const newValue = parseInt(prev, 10) + 1;
        updateConfigValue("alertDuration", newValue);
        return newValue;
      });
    } else {
      showMessage("Alert Duration must be between 0 and 1800 seconds");
    }
  };

  const handleDecrement = () => {
    if (expirationOffset > 0) {
      setExpirationOffset((prev) => {
        const newValue = parseInt(prev, 10) - 1;
        updateConfigValue("alertDuration", newValue);
        return newValue;
      });
    } else {
      showMessage("Alert Duration must be between 0 and 1800 seconds");
    }
  };

  const handleUsernameCopy = () => {
    navigator.clipboard.writeText(ingressPath);
    showMessage("Ingress Path copied to clipboard", "success");
  };
  // As connectorId is mapped to the configId  it's to be changes in backend we have to pass configId not connectorId

  useEffect(() => {
    const fetchResourceUtilization = async () => {
      try {
        const connectorId = nodeData.configId;
        let stringifyData = {
          connectorId: connectorId,
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
        };
        let response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getResourceUtilization",
          stringifyData,
        );

        if (response?.data?.data && response?.data?.data.length) {
          setAllPods(response.data.data);
        }
      } catch (err) {
        console.error("Error: getResourceUtilization", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Unable to obtain resource utilization for this deployment !!!",
          );
        }
      }
    };

    const getNodeStatus = async () => {
      try {
        let nodeStatusResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getNodeStatusNew",
          {
            ids: [nodeData.configId],
            workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId")),
          },
        );
        setNodeStatus(nodeStatusResponse.data.data[0].status || "");
      } catch (err) {
        console.log("Error: getNodeStatusNew", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to obtain deployment status !!!");
        }
      }
    };

    const fetchConnectorConfigValues = async () => {
      try {
        // setIsFieldsLoading(true);
        // let stringifiedData = {
        //   workspaceId: parseInt(
        //     localStorage.getItem("selectedWorkspaceId") || 0,
        //   ),
        //   connectorId: nodeData.configId,
        //   connectorCategory: nodeData.category,
        // };
        // let configResponse = await axiosAdapter(
        //   "POST",
        //   env.REACT_APP_URL + "pipeline/getDeployedConnectorConfigs",
        //   stringifiedData,
        // );
        // setIsFieldsLoading(false);

        let configResponse = configResponseData;
        let configValuesArray = configResponse.data?.data?.configJsonData
          ? JSON.parse(configResponse.data.data.configJsonData)
          : [];
        setConfigValues(configValuesArray);
        setConfigs(configValuesArray);

        if (configResponse.data?.data?.config) {
          let configData = JSON.parse(configResponse.data?.data?.config);
          let utilityGroup = JSON.parse(configData.utilityGroup);
          let configDataTemp = JSON.parse(utilityGroup?.uploadedJson);
          setJsonValue(configDataTemp);
          let fieldOptionsVal = handleJsonOptions(configDataTemp);
          setFieldOptionValues(fieldOptionsVal);

          try {
            let jsonArray = transformArray(
              fieldOptionsVal.length > 0 ? fieldOptionsVal : [],
            );
            setJsonOnlyKeys(jsonArray);
          } catch (error) {
            setJsonOnlyKeys([]);
          }

          let configDataTempNew = JSON.parse(utilityGroup?.groupsAndRulesJson);
          setGroupsAndRules(configDataTempNew);
          setisConfigurationOpen(true);

          setGroupBy(configData?.groupBy);

          try {
            let alertDurationInSeconds =
              parseInt(configData?.alertDuration, 10) / 1000;
            setExpirationOffset(alertDurationInSeconds);
            updateConfigValue("alertDuration", alertDurationInSeconds);
          } catch (error) {}

          try {
            if (configData?.primaryOutputTopic) {
              setSelectedPrimaryOutputTopic(configData?.primaryOutputTopic);
              updateConfigValue(
                "primaryOutputTopic",
                configData?.primaryOutputTopic,
              );
            }

            if (configData?.outputTopic) {
              setSelectedPrimaryOutputTopic(configData?.outputTopic);
              updateConfigValue("outputTopic", configData?.outputTopic);
            }
          } catch (error) {}

          try {
            setSelectedSecondaryOutputTopic(configData?.secondaryOutputTopic);
            updateConfigValue(
              "secondaryOutputTopic",
              configData?.secondaryOutputTopic,
            );
          } catch (error) {}
        }

        if (configResponse.data?.data?.publicDns) {
          setServiceDns(configResponse.data.data.publicDns);
        }
        if (configResponse.data?.data?.port) {
          setServicePort(configResponse.data.data.port);
        }

        if (configResponse.data?.data?.ingressPath) {
          setIngressPath(configResponse.data.data.ingressPath);
        }

        if (configResponse.data?.data?.name) {
          fetchDeviceName(configResponse.data.data.name);
        }
      } catch (err) {
        setIsFieldsLoading(false);
        console.log("Error in fetchConnectorConfigValues", err);
      }
    };

    fetchResourceUtilization();
    getNodeStatus();
    fetchConnectorConfigValues();

    const intervalId = setInterval(() => {
      fetchResourceUtilization();
      getNodeStatus();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [nodeData.category, nodeData.configId, setConfigs, showMessage]);

  const updateConfigValue = (nameKey, newValue) => {
    setConfigs((prevConfigs) => {
      let newConfigValues = prevConfigs.map((obj) =>
        obj.name === nameKey ? { ...obj, value: newValue } : obj,
      );
      return newConfigValues;
    });
  };

  const transformArray = (data) => data.map(({ label }) => label);

  const isNotEmptyObject = (obj) =>
    obj && typeof obj === "object" && Object.keys(obj).length > 0;

  const handleJsonOptions = (jsonVal) => {
    const sampleData = isNotEmptyObject(jsonVal)
      ? jsonVal
      : jsonVal && typeof jsonVal === "string"
        ? JSON.parse(jsonVal)
        : "";

    let fieldOptions = [];
    if (typeof sampleData === "object") {
      fieldOptions = Object.entries(sampleData).map(([key, value]) => ({
        label: key, // Key from sampleData
        value: ["string", "number", "boolean"].includes(typeof value)
          ? value
          : "Unknown",
      }));
    } else {
      fieldOptions = [];
    }
    return fieldOptions;
  };

  const maskValue = (value) => {
    return "*".repeat(value.length);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("podName", {
      header: () => "Replica",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("memoryUsage", {
      header: () => "Memory Utilization",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("cpuUsage", {
      header: () => "CPU Utilization",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: allPods,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const headerRowStyle = {
    width: "100%",
    backgroundColor: isUtilityConnector ? "#0D0D0D" : "#5E5E5E80",
  };

  return (
    <>
      <Column xs={6} style={{ padding: "1.112rem" }}>
        <ConnectorForm>
          <ConnectorInfo>
            <StyledRow>
              <Column
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ConnectorInformation>
                  <ConnectorIcon
                    style={{ width: "2.225rem", height: "2.225rem" }}
                    src={ConnectorInfoIcon}
                  ></ConnectorIcon>
                  <Title>
                    {isUtilityConnector ? "Utility" : "Connector"} Information
                  </Title>
                </ConnectorInformation>
                <NodeStatusContainer
                  className={nodeStatus || "Running"}
                  category={nodeData.category}
                >
                  {displayNodeStatus(nodeStatus, nodeData.category)}
                </NodeStatusContainer>
              </Column>
            </StyledRow>
            <CustomRow justifyContent="normal">
              <Column
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(33.33% - 0.740rem)",
                }}
              >
                <Label>Connector Name</Label>
                <Value>{nodeData.name}</Value>
              </Column>
              <Column
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(33.33% - 0.9rem)",
                  marginLeft: "1.3rem",
                }}
              >
                <Label>Connector Category</Label>
                <Value>
                  {isUtilityConnector ? "Utility" : nodeData.category}
                </Value>
              </Column>
              <Column
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(33.33% - 2.7rem)",
                  marginLeft: "2.9rem",
                }}
              >
                <Label>Connector Type</Label>
                <Value>
                  {isUtilityConnector ? "Transform" : nodeData.connectionType}
                </Value>
              </Column>
              {servicePort && (
                <Column
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(33.33% - 0.740rem)",
                  }}
                >
                  <Label>Service Port</Label>
                  <Value>{servicePort}</Value>
                </Column>
              )}
              {serviceDns && (
                <Column xs={12}>
                  <Label>Service DNS</Label>
                  <Value>{serviceDns}</Value>
                </Column>
              )}
              {ingressPath && (
                <Column
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(33.33% - 0.740rem)",
                  }}
                >
                  <Label>
                    Ingress Path <StyledCopyIcon onClick={handleUsernameCopy} />
                  </Label>
                  <Value>
                    <FlexContainer
                      $width="calc(100% - 0.277rem)"
                      $gap="0.277rem"
                    >
                      <Tooltip title={ingressPath} placement="bottom">
                        <Text
                          $maxWidth="min-content"
                          $fontWeight="bold"
                          $fontSize="1.111rem"
                          $noWrap
                        >
                          {ingressPath}
                        </Text>
                      </Tooltip>
                    </FlexContainer>
                  </Value>
                </Column>
              )}
            </CustomRow>
          </ConnectorInfo>
          <div
            style={{
              height: "0.069rem",
              backgroundColor: "#5e5e5e",
              marginRight: "0",
            }}
          ></div>
          <StreamDetailsContainer>
            <StreanDetailsContent
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <CustomRow>
                <StreanDetails style={{ height: "2rem", width: "2rem" }} />
                <Title>Stream Details</Title>
              </CustomRow>
              {jsonValue && !isFieldsLoading && !isAllTopicsLoading && (
                <ViewJsonText onClick={handleViewJson}>
                  Preview JSON
                </ViewJsonText>
              )}
              {isAllTopicsLoading ||
                (isFieldsLoading && (
                  <CustomRow
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Spinner2
                      animation="border"
                      size="md"
                      style={{ color: "#47CCD6" }}
                    />
                  </CustomRow>
                ))}
            </StreanDetailsContent>

            {isJsonModalOpen && (
              <>
                <JsonModal
                  fieldOptionValues={fieldOptionValues}
                  handleCloseModal={handleCloseModal}
                  setEditDialogOpen={setEditDialogOpen}
                  isEditDialogOpen={isEditDialogOpen}
                  setJsonValue={setJsonValue}
                  jsonValue={jsonValue}
                  isReuploadJson={jsonValue ? true : false}
                  isReadTopic={false}
                  setGroupsAndRules={setGroupsAndRules}
                  groupsAndRules={groupsAndRules}
                  setisConfigurationOpen={setisConfigurationOpen}
                  isConfigurationOpen={isConfigurationOpen}
                  setConnectionsVal={setConnectionsVal}
                  connectionsVal={connectionsVal}
                  setJsonOnlyKeys={setJsonOnlyKeys}
                  setFieldOptionValues={setFieldOptionValues}
                  utilityName={nodeData?.title ? nodeData?.title : ""}
                />
              </>
            )}

            {configValues?.map((field, index) => {
              if (field.type === "topic") {
                return (
                  <>
                    {(
                      field.name !== "inputTopic"
                        ? !jsonValue || !editModal
                        : true
                    ) ? (
                      <Label2 style={{ color: "#777777" }}>
                        {`${field.label}`} {field.isRequired && " *"}
                        {/* {field.isRequired && " *"} */}
                        {(nodeData.name !== "Alerts" ||
                          (nodeData.name !== "Split" &&
                            field.type === "topic")) && (
                          <InfoIcon2
                            style={{
                              height: "1.2rem",
                              width: "1rem",
                              marginLeft: "0.2rem",
                            }}
                          />
                        )}
                      </Label2>
                    ) : (
                      <Label2>
                        {`${field.label}`} {field.isRequired && " *"}
                        {/* {field.isRequired && " *"} */}
                        {(nodeData.name !== "Alerts" ||
                          (nodeData.name !== "Split" &&
                            field.type === "topic")) && (
                          <Tooltip
                            title={
                              field.name === "inputTopic"
                                ? "Input Topic"
                                : field.name === "outputTopic" ||
                                    field.name === "primaryOutputTopic"
                                  ? "Data will be sent to this topic"
                                  : "If the primary output is unavailable, data will be sent to this topic instead"
                            }
                            placement={"bottom"}
                          >
                            <InfoIcon2
                              style={{
                                height: "1.2rem",
                                width: "1rem",
                                marginLeft: "0.2rem",
                              }}
                              className="info-icon-2"
                            />
                          </Tooltip>
                        )}
                      </Label2>
                    )}

                    <CustomRightDropdown
                      isShowCreateTopic={true}
                      dropdownItems={allTopics}
                      selectedItem={allTopics.find(
                        (item) =>
                          item ===
                          (field.name === "inputTopic"
                            ? selectedInputTopic
                            : field.name === "outputTopic" ||
                                field.name === "primaryOutputTopic"
                              ? selectedPrimaryOutputTopic
                              : selectedSecondaryOutputTopic),
                      )}
                      placeholder={"Search for Topics"}
                      onSelect={(item) => {
                        if (item === "newTopic") {
                          setNewTopicModalMapper((prev) => {
                            let tempMap = { ...prev, [index]: true };
                            return tempMap;
                          });
                        } else {
                          let inputTopic = selectedInputTopic;
                          let primaryOutputTopic = selectedPrimaryOutputTopic;
                          let secondaryOutputTopic =
                            selectedSecondaryOutputTopic;

                          if (field.name === "inputTopic") {
                            inputTopic = item;
                            // setSelectedInputTopic(item);
                          } else if (
                            nodeData.name === "Alerts"
                              ? field.name === "outputTopic"
                              : field.name === "primaryOutputTopic"
                          ) {
                            primaryOutputTopic = item;
                            // setSelectedPrimaryOutputTopic(item);
                          } else {
                            secondaryOutputTopic = item;
                            // setSelectedSecondaryOutputTopic(item);
                          }

                          let isNoError = true;
                          // Check if all selected values are the same
                          if (
                            nodeData.name === "Alerts" &&
                            inputTopic &&
                            primaryOutputTopic
                          ) {
                            if (
                              inputTopic === primaryOutputTopic ||
                              inputTopic === secondaryOutputTopic ||
                              primaryOutputTopic === secondaryOutputTopic
                            ) {
                              isNoError = false;
                              showMessage(
                                "The primary and secondary output topics must be different. Please update your selection.",
                              );
                            }
                          } else if (nodeData.name === "Split") {
                            if (
                              inputTopic &&
                              primaryOutputTopic &&
                              secondaryOutputTopic
                            ) {
                              if (
                                inputTopic === primaryOutputTopic ||
                                inputTopic === secondaryOutputTopic ||
                                primaryOutputTopic === secondaryOutputTopic
                              ) {
                                isNoError = false;
                                showMessage(
                                  "The primary and secondary output topics must be different. Please update your selection.",
                                );
                              }
                            } else {
                              if (
                                inputTopic === primaryOutputTopic &&
                                inputTopic &&
                                primaryOutputTopic
                              ) {
                                isNoError = false;
                                showMessage(
                                  "The primary and secondary output topics must be different. Please update your selection.",
                                );
                              } else if (
                                inputTopic === secondaryOutputTopic &&
                                inputTopic &&
                                secondaryOutputTopic
                              ) {
                                isNoError = false;
                                showMessage(
                                  "The primary and secondary output topics must be different. Please update your selection.",
                                );
                              } else if (
                                primaryOutputTopic === secondaryOutputTopic &&
                                primaryOutputTopic &&
                                secondaryOutputTopic
                              ) {
                                isNoError = false;
                                showMessage(
                                  "The primary and secondary output topics must be different. Please update your selection.",
                                );
                              }
                            }
                          }

                          if (isNoError) {
                            setSelectedInputTopic(inputTopic);
                            setSelectedPrimaryOutputTopic(primaryOutputTopic);
                            setSelectedSecondaryOutputTopic(
                              secondaryOutputTopic,
                            );
                            updateConfigValue("inputTopic", inputTopic);
                            updateConfigValue(
                              nodeData.name === "Alerts"
                                ? "outputTopic"
                                : "primaryOutputTopic",
                              primaryOutputTopic,
                            );
                            updateConfigValue(
                              "secondaryOutputTopic",
                              secondaryOutputTopic,
                            );
                          }
                        }
                      }}
                      nameFunction={(item) => item}
                      filterFunction={(arr, text) =>
                        arr.filter((item) =>
                          item.toLowerCase().includes(text.toLowerCase()),
                        )
                      }
                      noMargin={true}
                      disable={
                        field.name !== "inputTopic"
                          ? !jsonValue || !editModal
                          : true
                      }
                      isShowLockIcon={
                        field.name === "inputTopic" ? true : false
                      }
                    />
                    <GenericModal
                      onClose={() =>
                        setNewTopicModalMapper((prev) => {
                          let tempMap = { ...prev, [index]: false };
                          return tempMap;
                        })
                      }
                      show={newTopicModalMapper[index] || false}
                      title={"Create a New Topic"}
                    >
                      <StyledInput
                        placeholder="Add a New Topic Name"
                        value={newTopicNamesMapper[index] || ""}
                        onChange={(e) =>
                          setNewTopicNamesMapper((prev) => {
                            let tempNewTopicMap = {
                              ...prev,
                              [index]: e.target.value,
                            };
                            return tempNewTopicMap;
                          })
                        }
                      />
                      <WarningText>
                        <img
                          src={warning_icon}
                          alt="warning_icon"
                          style={{ marginRight: "0.347rem" }}
                        />
                        A New Topic with this name will be created and available
                        for use
                      </WarningText>
                      <ModalButtonContainer>
                        <StyledButtonsecondary
                          style={{ width: "14.167rem" }}
                          onClick={() =>
                            setNewTopicModalMapper((prev) => {
                              let tempMap = { ...prev, [index]: false };
                              return tempMap;
                            })
                          }
                        >
                          Cancel
                        </StyledButtonsecondary>
                        <StyledButtonPrimary
                          style={{ width: "14.167rem" }}
                          disabled={!newTopicNamesMapper[index]?.length}
                          onClick={() =>
                            createNewTopic(
                              index,
                              newTopicNamesMapper[index],
                              field.name,
                            )
                          }
                        >
                          Create Topic
                        </StyledButtonPrimary>
                      </ModalButtonContainer>
                    </GenericModal>
                  </>
                );
              } else if (field.type === "number") {
                return (
                  <>
                    <CustomRow>
                      {!jsonValue || !editModal ? (
                        <Label2 style={{ color: "#777777" }}>
                          {field.label} {field.isRequired && " *"}
                        </Label2>
                      ) : (
                        <Label2>
                          {field.label} {field.isRequired && " *"}
                        </Label2>
                      )}
                      <Column xs={9} style={{ padding: "0 1rem 0 0.4rem" }}>
                        {!isJsonModalOpen && (
                          <Slider
                            minValue={0}
                            maxValue={1800}
                            markInterval={300}
                            sliderValue={expirationOffset}
                            setSliderValue={setSliderValue}
                            // markLabelFormatterFunction={(value) =>
                            //   expirationOffset
                            // }
                            isFromUtilities={false}
                            isDisabled={!jsonValue || !editModal}
                          />
                        )}
                      </Column>
                      <Column xs={2.6}>
                        <InputFieldContainer>
                          <InputField
                            type="text"
                            value={expirationOffset}
                            onChange={handleChange}
                            placeholder="Enter value"
                            disabled={!jsonValue || !editModal}
                            style={{
                              color:
                                !jsonValue || !editModal
                                  ? "#777777"
                                  : "#fafafa",
                              cursor:
                                !jsonValue || !editModal
                                  ? "not-allowed"
                                  : "auto",
                            }}
                          />
                          <ArrowContainer>
                            <Arrow
                              onClick={
                                !jsonValue || !editModal
                                  ? undefined
                                  : handleIncrement
                              }
                            >
                              <img
                                src={up_icon}
                                style={{ height: "1rem", width: "1rem" }}
                              />
                            </Arrow>
                            <Arrow
                              onClick={
                                !jsonValue || !editModal
                                  ? undefined
                                  : handleDecrement
                              }
                            >
                              <img
                                src={down_icon}
                                style={{ height: "1rem", width: "1rem" }}
                              />
                            </Arrow>
                          </ArrowContainer>
                        </InputFieldContainer>
                      </Column>
                    </CustomRow>
                  </>
                );
              } else if (field.type === "text") {
                return (
                  <ValueTextField>
                    {true ? (
                      <Label2 style={{ color: "#777777" }}>
                        {field.label} {field.isRequired && " *"}
                      </Label2>
                    ) : (
                      <Label2>
                        {field.label} {field.isRequired && " *"}
                      </Label2>
                    )}
                    <TitleInputContainer>
                      <TitleTextField
                        style={{
                          color: true ? "#4B4B4B" : "#F6F6F6",
                        }}
                        type="text"
                        placeholder={field.placeHolder}
                        value={title}
                        onChange={(e) => {
                          if (
                            /^[a-z0-9][a-z0-9.-]*$/.test(
                              e.target.value.toLowerCase(),
                            ) ||
                            e.target.value === ""
                          ) {
                            setTitle(e.target.value.toLowerCase());
                            updateConfigValue(
                              "title",
                              e.target.value.toLowerCase(),
                            );
                          } else {
                            showMessage(
                              "Only lowercase alphanumeric characters along with [-.] are allowed and cannot start with [-.]",
                              "error",
                            );
                            return;
                          }
                        }}
                        disabled={true}
                      />
                      <IconWrapper>
                        <img
                          src={LockIcon}
                          alt="up_icon"
                          style={{ height: "1.38rem", width: "1.38rem" }}
                        />
                      </IconWrapper>
                    </TitleInputContainer>
                  </ValueTextField>
                );
              } else if (field.type === "groupBy") {
                return (
                  <>
                    {!jsonValue || !editModal ? (
                      <Label2 style={{ color: "#777777" }}>
                        {field.label} {field.isRequired && " *"}
                      </Label2>
                    ) : (
                      <Label2>
                        {field.label} {field.isRequired && " *"}
                      </Label2>
                    )}
                    <CustomRightDropdown
                      isShowRedBorder={jsonValue && isGroupError}
                      isShowCreateTopic={false}
                      dropdownItems={jsonOnlyKeys}
                      selectedItem={jsonOnlyKeys.find(
                        (item) => item === groupBy,
                      )}
                      placeholder={field.description}
                      onSelect={(item) => {
                        setGroupBy(item);
                        updateConfigValue("groupBy", item);
                      }}
                      nameFunction={(item) => item}
                      filterFunction={(arr, text) =>
                        arr.filter((item) =>
                          item.toLowerCase().includes(text.toLowerCase()),
                        )
                      }
                      noMargin={true}
                      disable={!jsonValue || !editModal}
                    />
                  </>
                );
              }
            })}
          </StreamDetailsContainer>
          <div
            style={{
              height: "0.069rem",
              backgroundColor: "#5e5e5e",
              marginRight: "0",
            }}
          ></div>
          <ConnectorInfo>
            <StreanDetailsContent
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <CustomRow>
                <ConnectorIcon
                  style={{ width: "2.225rem", height: "2.225rem" }}
                  src={MemoryUtilizationIcon}
                />
                <Title>Resource Utilization</Title>
              </CustomRow>
            </StreanDetailsContent>
            <CustomRow justifyContent="normal">
              <Column
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(33.33% - 1rem)",
                }}
              >
                <Label
                  style={{
                    fontFamily: "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0px",
                  }}
                >
                  Replica
                </Label>
              </Column>
              <Column
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(33.33% - 1.5rem)",
                  marginLeft: "3rem",
                }}
              >
                <Label
                  style={{
                    fontFamily: "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0px",
                  }}
                >
                  Memory Utlization
                </Label>
              </Column>
              <Column
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(33.33% - 3.4rem)",
                  marginLeft: "2.8rem",
                }}
              >
                <Label
                  style={{
                    fontFamily: "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0px",
                  }}
                >
                  CPU Utlization
                </Label>
              </Column>
            </CustomRow>
            {allPods.length > 0 ? (
              allPods.map((pod) => (
                <CustomRow justifyContent="normal" style={{ gap: "1.111rem" }}>
                  <Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "calc(33.33% - 0.740rem)",
                    }}
                  >
                    <Tooltip title={pod.podName} placement={"top"}>
                      <Value>{pod.podName}</Value>
                    </Tooltip>
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "calc(33.33% - 0.740rem)",
                      marginLeft: "1.6rem",
                    }}
                  >
                    <Value>{pod.memoryUsage}</Value>
                  </Column>
                  <Column
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "calc(33.33% - 4rem)",
                      marginLeft: "1.1rem",
                    }}
                  >
                    <Value>{pod.cpuUsage}</Value>
                  </Column>
                </CustomRow>
              ))
            ) : (
              <>
                <Text
                  $width="100%"
                  $textAlign="center"
                  style={{ color: "white" }}
                >
                  No Replicas Found
                </Text>
              </>
            )}
          </ConnectorInfo>
        </ConnectorForm>
      </Column>
      <Column xs={8} style={{ padding: "1.112rem 1.112rem 1.112rem 0rem" }}>
        <ConfigurationContainer>
          <ConfigurationHeader>
            <CustomRow>
              <AddGroup>
                <StreamIcon>
                  <img
                    style={{ height: "1.67rem", width: "1.67rem" }}
                    src={Icon_strean}
                  />
                </StreamIcon>
                <ConfigurationHeading>Configurations</ConfigurationHeading>
              </AddGroup>
              <AddGroup
                style={{
                  cursor: "pointer",

                  pointerEvents:
                    isConfigurationOpen && editModal ? "auto" : "none",
                }}
                onClick={() => {
                  if (handleAddParent) {
                    if (isConfigurationOpen && editModal) handleAddParent();
                  }
                }}
                onMouseEnter={() => setIsHovered(true)} // Set hover state to true
                onMouseLeave={() => setIsHovered(false)} // Set hover state to false
              >
                {isHovered ? (
                  <AddIcon2
                    style={{
                      height: "1.4rem",
                      width: "1.4rem",
                      opacity: isConfigurationOpen && editModal ? "" : "40%",
                    }}
                  />
                ) : (
                  <AddIcon
                    style={{
                      height: "1.4rem",
                      width: "1.4rem",
                      opacity: isConfigurationOpen && editModal ? "" : "40%",
                    }}
                  />
                )}
                <AddGroupText
                  style={{
                    color:
                      isConfigurationOpen && editModal
                        ? isHovered
                          ? "#F6F6F6"
                          : "#47ccd6"
                        : "#277277",
                    cursor:
                      isConfigurationOpen && editModal
                        ? "pointer"
                        : "not-allowed",
                  }}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state to true
                  onMouseLeave={() => setIsHovered(false)} // Set hover state to false
                >
                  Add Group
                </AddGroupText>
              </AddGroup>
            </CustomRow>
            <CustomRow
              style={{
                justifyContent: "normal",
                display: "flex",
                marginTop: "1rem",
              }}
            >
              <PreviewContainerForRules>
                <PreviewText>
                  {editModal ? (
                    <Tooltip
                      title={
                        <>
                          Rules and Groups are
                          <br />
                          applied following BODMAS
                        </>
                      }
                      placement={"bottom"}
                    >
                      <InfoIcon
                        style={{ height: "1.4rem", width: "1.4rem" }}
                        className="info-icon"
                      />
                    </Tooltip>
                  ) : (
                    <InfoIcon
                      style={{ height: "1.4rem", width: "1.4rem" }}
                      className="info-icon"
                    />
                  )}
                  Preview
                </PreviewText>
                <Pipe />
                {/* {!isConfigurationOpen ? ( */}
                <TextField type="text" placeholder="" value={expressionText} />
                {/* ) : (
                <Expression>
                  <div>{expressionText}</div>
                </Expression>
              )} */}
              </PreviewContainerForRules>
            </CustomRow>
          </ConfigurationHeader>
          <HorizontalLine />
          <Configuration>
            {isConfigurationOpen && !isJsonModalOpen ? (
              <ReactFlowProviderContent
                key={fieldOptionValues}
                handleLogic={handleLogic}
                previewFunction={previewFunction}
                handlePreview={handlePreview}
                kafkaLastMessage={""}
                isReadTopic={false}
                jsonValue={jsonValue}
                setRulesError={setRulesError}
                showMessage={showMessage}
                onAddParent={(fn) => setHandleAddParent(() => fn)}
                handleGroupsAndRules={handleGroupsAndRules}
                groupsAndRules={groupsAndRules}
                connectionsVal={connectionsVal}
                setMissedRulesTemp={setMissedRulesTemp}
                fieldOptionValues={fieldOptionValues}
                setisConfigurationOpen={setisConfigurationOpen}
                isDisableFields={!editModal ? true : false}
              />
            ) : (
              <>
                <CustomRow>
                  <div className="EmptyState">
                    <EmptystateHeader>Build your Rules</EmptystateHeader>
                    <EmptystateText>
                      You currently have no rules added. Click on the button
                      below to get started!
                    </EmptystateText>
                  </div>
                </CustomRow>
                <CustomRow>
                  <Column xs={4}>
                    <StyledButtonPrimary
                      onClick={handleConfiguration}
                      style={{
                        width: "14.167rem",
                        marginLeft: "3.89rem",
                        marginBottom: "11rem",
                        backgroundColor:
                          !isFieldsLoading && isValidJsonDataCheck(jsonValue)
                            ? "#47CCD6"
                            : "#777777",
                      }}
                    >
                      Add Rule
                    </StyledButtonPrimary>
                  </Column>

                  <Column xs={8}>
                    <div className="emptystate">
                      <img
                        style={{
                          width: "41,67rem",
                          height: "16.67rem",
                          // paddingBottom: "2rem",
                        }}
                        src={StreamEmptyState}
                        alt="emptystate"
                      />
                    </div>
                  </Column>
                </CustomRow>
              </>
            )}
          </Configuration>
        </ConfigurationContainer>
      </Column>
    </>
  );
};

ConnectorUtilityInfoForm.propTypes = {
  nodeData: PropTypes.shape({
    configId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.oneOf([
      "Stream Connector",
      "Store Connector",
      "Application",
      "Telematics Device",
    ]).isRequired,
    connectionType: PropTypes.string.isRequired,
  }).isRequired,
  setConfigs: PropTypes.func.isRequired,
  fetchDeviceName: PropTypes.func.isRequired,
};

RunningStatus.propTypes = {
  category: PropTypes.oneOf([
    "Stream Connector",
    "Store Connector",
    "Application",
    "Telematics Device",
  ]).isRequired,
};

export default ConnectorUtilityInfoForm;
