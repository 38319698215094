import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Editor } from "@monaco-editor/react";
import sqlEditor from "../../assets/images/sqlEditor.svg";
import Expand from "../../assets/images/fullscreenExpand.svg";
import Collapse from "../../assets/images/left_arrow_icon.svg";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import PropTypes from "prop-types";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
// import { SnackbarContext } from "../../layouts/Context/snackBarContext";

const Text = styled.p`
  font-family: "Articulat CF Medium";
  letter-spacing: 0.5px;
  margin: 0;
  margin-left: 1rem;
  color: #f6f6f6;
  font-size: 1.666rem;
  font-weight: bold;
`;

const SQLEditorContainer = styled.div`
  width: ${(props) => (props.isFullscreen ? "98%" : "100%")};
  overflow: hidden;
  background-color: #000000;
  border: 0.0694rem solid #5e5e5e;
  position: ${(props) => (props.isFullscreen ? "fixed" : "relative")};
  top: ${(props) => (props.isFullscreen ? "5rem" : "auto")};
  left: ${(props) => (props.isFullscreen ? "1rem" : "auto")};
  right: ${(props) => (props.isFullscreen ? "1rem" : "auto")};
  bottom: ${(props) => (props.isFullscreen ? "0rem" : "auto")};
  z-index: ${(props) => (props.isFullscreen ? "1000" : "0")};
  border-radius: ${(props) => (props.isFullscreen ? "1rem 1rem 0 0" : "1rem")};
  border-bottom: ${(props) =>
    props.isFullscreen ? "none" : "0.069rm solid #5E5E5E"};
`;

const SQLEditorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.isFullscreen ? "1.3rem 1rem" : "1rem")};
  background-color: ${(props) => (props.isFullscreen ? "#000000" : "#2f2f2f")};
  border-bottom: ${(props) =>
    props.isFullscreen ? "none" : "0.069rem solid #5E5E5E"};
  border-radius: ${(props) => (props.isFullscreen ? "0" : "1rem 1rem 0 0")};
`;

const Spinner = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: 0.1388rem solid #4dabf7;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const SQLEditorContent = styled.div`
  height: ${(props) => (props.isFullscreen ? "88%" : "22rem")};
  width: ${(props) => (props.isFullscreen ? "98%" : "100%")};
  border: none;
  margin: ${(props) => (props.isFullscreen ? "0 1rem 0 1rem" : "0")};
  border-radius: 1rem;
  overflow: hidden;
`;

const SQLEditorActions = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

const ExpandButton = styled.button`
  outline: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  height: 1.944rem;
  width: 1.944rem;

  &:hover {
    transform: scale(1.1);
  }
`;

const StartQueryButton = styled.button`
  font-weight: bold;
  width: 11.11rem;
  color: #ffffff;
  border-radius: 0.7rem;
  padding: 0.5rem 0;
  border: 0.069rem solid ${(props) => (props.value ? "#47CCD6" : "#777777")};
  cursor: ${(props) => (props.value ? "pointer" : "not-allowed")};
  background: ${(props) => (props.value ? "#47CCD6" : "#777777")};
  pointer-events: ${(props) => (props.value ? "auto" : "none")};
  height: 2.5rem;
`;

const StopQueryButton = styled.button`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  background: none;
  border: 0.13rem solid white;
  border-radius: 0.55rem;
  color: #ffffff;
  width: 11.11rem;
  height: 2.5rem;
  font-size: 0.97rem;
  padding: 0.5rem 0;
  cursor: pointer;
  height: 2.5rem;
  &:hover {
    border: 0.13rem solid #47ccd6;
    color: #47ccd6;
  }

  &:active {
    background: var(--Primary-Accent-Darker, #1e565a);
  }
`;

// Background overlay when in fullscreen mode
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
`;

const SQLEditor = ({ setJSONData, setKSQLError }) => {
  const editorRef = useRef(null);
  const [isExecuting, setIsExecuting] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  // const { showMessage } = useContext(SnackbarContext);
  const [query, setQuery] = useState("");

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;

    monaco.editor.defineTheme("customTheme", {
      base: isFullscreen ? "vs-dark" : "hc-black",
      inherit: true,
      rules: [],
      colors: {
        "editor.foreground": "#FFFFFF",
        "editorGhostText.foreground": "#777777",
        "editor.selectionBackground": "#5e5e5e",
        "editor.selectionForeground": "#f6f6f6",
      },
    });

    monaco.editor.setTheme("customTheme");

    // Register SQL completion items
    monaco.languages.registerCompletionItemProvider("sql", {
      provideCompletionItems: () => {
        return {
          suggestions: [
            {
              label: "SELECT",
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: "SELECT",
            },
            {
              label: "FROM",
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: "FROM",
            },
            {
              label: "WHERE",
              kind: monaco.languages.CompletionItemKind.Keyword,
              insertText: "WHERE",
            },
          ],
        };
      },
    });
  }

  const handleQueryExecution = async () => {
    if (isExecuting) {
      setIsExecuting(false);
    } else {
      setIsExecuting(true);
      try {
        const response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + `stream/ksql`,
          {
            query,
          },
        );
        setIsExecuting(false);
        setJSONData(response.data.data);
        setKSQLError("");
      } catch (error) {
        setIsExecuting(false);
        // showMessage(
        //   error.response.data.remarks || "Error executing the query",
        //   error,
        // );
        setJSONData("");
        setKSQLError(error.response.data.remarks);
      }
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  // Handle escape key to exit fullscreen
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape" && isFullscreen) {
        setIsFullscreen(false);
      }
    };

    window.addEventListener("keydown", handleEscKey);

    if (isFullscreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      window.removeEventListener("keydown", handleEscKey);
      document.body.style.overflow = "";
    };
  }, [isFullscreen]);

  useEffect(() => {
    const addStyles = () => {
      const styleId = "monaco-custom-styles";

      if (!document.getElementById(styleId)) {
        const style = document.createElement("style");
        style.id = styleId;
        style.innerHTML = `
          /* Selection highlighting styles - more specific selectors */
          .monaco-editor .monaco-editor-background .selected-text,
          .monaco-editor .view-line .selected-text {
            background-color: #47ccd63D !important;
          }
          
          /* Force text color in selections */
          .monaco-editor .view-line .selected-text span,
          .monaco-editor .view-line span.selected-text {
            color: #f6f6f6 !important;
          }
          
          /* Force selection color across all themes */
          .monaco-editor.vs-dark .selected-text span,
          .monaco-editor.hc-black .selected-text span,
          .monaco-editor .selected-text span {
            color: #f6f6f6 !important;
          }
          
          /* Prevent dots in whitespace */
          .monaco-editor .view-overlays .whitespace {
            display: none !important;
          }
          
          /* Additional fix to ensure no dots in selected text */
          .monaco-editor .view-line span {
            border: none !important;
          }
          
          /* Hide any rendered whitespace */
          .monaco-editor .view-line .inline-deleted-margin-view-zone,
          .monaco-editor .view-line .whitespace {
            display: none !important;
          }
          
          /* Scrollbar styles */
          .monaco-editor .scrollbar.vertical .slider {
            background: #cccccc !important;
            border-radius: 10px;
          }
          .monaco-editor .scrollbar.horizontal .slider {
            background: #cccccc !important;
            border-radius: 10px;
          }
        `;
        document.head.appendChild(style);
      }
    };

    const timeout = setTimeout(addStyles, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Overlay isVisible={isFullscreen} onClick={toggleFullscreen} />
      <SQLEditorContainer isFullscreen={isFullscreen}>
        <SQLEditorHeader isFullscreen={isFullscreen}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                src={isFullscreen ? Collapse : sqlEditor}
                alt={isFullscreen ? "Back" : "SQL Editor Icon"}
                onClick={isFullscreen ? toggleFullscreen : () => {}}
              />
              <Text>SQL Editor</Text>
            </div>
            {isExecuting && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "2rem",
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
          {!isFullscreen && (
            <SQLEditorActions>
              {isExecuting ? (
                <StopQueryButton onClick={handleQueryExecution}>
                  Stop Query
                </StopQueryButton>
              ) : (
                <StyledButtonPrimary
                  onClick={handleQueryExecution}
                  disabled={!query}
                  style={{
                    width: "11.11rem",
                    height: "2.5rem",
                    borderRadius: "0.55rem",
                    fontSize: "0.97rem",
                  }}
                >
                  Run Query
                </StyledButtonPrimary>
              )}
              <ExpandButton onClick={toggleFullscreen}>
                <img
                  src={Expand}
                  alt="Expand"
                  style={{ height: "1.944rem", width: "1.944rem" }}
                />
              </ExpandButton>
            </SQLEditorActions>
          )}
        </SQLEditorHeader>

        {/* Editor Area */}
        <SQLEditorContent isFullscreen={isFullscreen}>
          <Editor
            height="100%"
            defaultLanguage="sql"
            defaultValue={query}
            value={query}
            onChange={(value) => setQuery(value)}
            theme={isFullscreen ? "vs-dark" : "hc-black"}
            options={{
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              lineNumbers: "on",
              renderLineHighlight: "none",
              automaticLayout: true,
              fontSize: 14,
              renderWhitespace: "none",
              selectionHighlight: false,
              fontFamily: "'Fira Code', Consolas, 'Courier New', monospace",
              placeholder: "Please type your query here",
              tabSize: 2,
              scrollbar: {
                useShadows: false,
                verticalHasArrows: false,
                horizontalHasArrows: false,
                verticalScrollbarSize: 3,
                horizontalScrollbarSize: 3,
                alwaysConsumeMouseWheel: false,
              },
              padding: {
                top: 16,
              },
              lineNumbersMinChars: 3,
              folding: false,
              glyphMargin: false,
            }}
            onMount={handleEditorDidMount}
          />
        </SQLEditorContent>
      </SQLEditorContainer>
    </>
  );
};

SQLEditor.propTypes = {
  setJSONData: PropTypes.func.isRequired,
};

export default SQLEditor;
