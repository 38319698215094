import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import SettingsIcon from "../../assets/images/card_setting_icon.svg";
import GoBackIcon from "../../assets/images/card_back_icon.svg";
import EditIcon from "../../assets/images/card_edit_icon.svg";
import DeleteIcon from "../../assets/images/card_delete_icon.svg";
import CustomConnectorDeletionModal from "./CustomConnectorDeletionModal";
import CustomConnectorEditModal from "./CustomConnectorEditModal";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { applicationIconMapper } from "../../components/common/_applicationIconMapper";
import PropTypes from "prop-types";
import { Offcanvas } from "react-bootstrap";
import ExitIcon from "../../assets/images/Exit-Frame.svg";
import { ReactComponent as GroupDeleteIcon } from "../../assets/images/Group-Delete.svg";
import { ReactComponent as GroupConnectorIcon } from "../../assets/images/Group-Connector.svg";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import GenericSelectionWithoutSearch from "../../components/common/_dropDownComponentWithoutSearch.jsx";
import { ReactComponent as RoundIcon } from "../../assets/images/Rectangle 31.svg";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "../../components/common/_tooltip";
import RestoreIcon from "../../assets/images/Restore.svg";
import RestoreApplicationModal from "./RestoreApplicationModal.jsx";

const CardContainer = styled.div`
  border-radius: 0.84rem;
  background-color: #2f2f2f;
  display: flex;
  justify-content: space-between;
  padding: 0.84rem;
  /* height: 11.62rem; */
`;

const LeftLine = styled.div`
  width: 0.277rem;
  height: 9rem;

  background-color: #eb6f95;
  border-radius: 0.3rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`;

const Text = styled.p`
  font-family: Articulat CF Medium;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-top: 0.97rem;
`;

const CustomConnectorCard = ({
  connectorId,
  connectorName,
  connectorVersion,
  publisher,
  description,
  iconFile,
  isClickable,
  handleCustomConnectorCardClick,
  filteredCustomConnectorList,
  isRestorable = false,
  vcProvider = "github", //github, gitlab or bitbucket or null
}) => {
  const [selectedConnectorId, setSelectedConnectorId] = useState(connectorId);
  const [isLoading, setIsLoading] = useState(false);

  const { showMessage } = useContext(SnackbarContext);

  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);

  const [isDrawerOpen, setDrawerOpen] = useState(false); // State for drawer visibility

  const [steps, setSteps] = useState([]);
  const [connectorNameState, setConnectorName] = useState(connectorName);
  const [connectorVersionState, setConnectorVersion] =
    useState(connectorVersion);
  const [publisherState, setPublisher] = useState(publisher);
  const [descriptionState, setDescription] = useState(description);
  const [iconFileState, setIconFile] = useState(iconFile);
  const [isRestoreOpen, setRestoreOpen] = useState(false);

  const formatTimeAgo = (timestamp) => {
    if (timestamp) {
      const now = Math.floor(Date.now() / 1000); // Current time in seconds
      const secondsAgo = now - timestamp; // Calculate the difference in seconds

      const intervals = [
        { label: "w", seconds: 604800 }, // 1 week
        { label: "d", seconds: 86400 }, // 1 day
        { label: "h", seconds: 3600 }, // 1 hour
        { label: "m", seconds: 60 }, // 1 minute
      ];

      for (const interval of intervals) {
        const count = Math.floor(secondsAgo / interval.seconds);
        if (count > 0) {
          return `${count}${interval.label} ago`;
        }
      }

      return `${secondsAgo} seconds ago`; // Fallback for less than a minute
    } else {
      return "";
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setAndFetchVersionHistory();
  }, [selectedConnectorId]);

  const setAndFetchVersionHistory = async () => {
    const selectedConnector = filteredCustomConnectorList.find(
      (item) => item.id === selectedConnectorId,
    );
    if (selectedConnector) {
      // Update the state with the selected connector's details
      setConnectorName(selectedConnector.connectorName);
      setConnectorVersion(selectedConnector.latestVersion);
      setPublisher(selectedConnector.latestPublishedBy);
      setDescription(selectedConnector.latestDescription);
      setIconFile(selectedConnector.latestIconFile);
    }
    fetchVersionHistory();
  };

  const fetchVersionHistory = async () => {
    const workspaceId = parseInt(
      localStorage.getItem("selectedWorkspaceId") || 0,
    );
    const payload = {
      workspaceId,
      customConnectorId: selectedConnectorId,
    };

    try {
      const response = await axiosAdapter(
        "POST",
        `${env.REACT_APP_URL}pipeline/getCustomConnectorVersionHistory`,
        payload,
      );
      let responseData = response.data.data;
      setSteps(responseData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching version history:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .offcanvas-backdrop {
        height: calc(-2.222rem + 100vh) !important;
        margin-top: 4rem !important;
      }
      .offcanvas-backdrop.show{
        z-index:1
      }
      .btn-close{
        background-image:url(${ExitIcon});
        font-size:1.8rem;
        opacity:1;
      }
        
      .offcanvas-body{
        padding:0 !important;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .form-select:focus{
        box-shadow:none;
        border-color:#777777 !important;
      }

      .form-select{
        background-color: #0D0D0D;
        color:#B0B0B0;
      }
      .custom-border-start {
          border-left-color: #5E5E5E !important; 
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style); // Clean up the style on unmount
    };
  }, []);

  return (
    <>
      <CardContainer
        style={
          isSettingsOpen
            ? { paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px" }
            : {}
        }
        onClick={() => {
          if (!isDrawerOpen) {
            if (isClickable) {
              handleCustomConnectorCardClick(
                connectorId,
                connectorName,
                description,
                connectorVersion,
                iconFile,
              );
            } else {
              showMessage(
                "You don't have permission to perform this operation",
              );
            }
          }
        }}
      >
        {!isSettingsOpen && <LeftLine />}
        <ContentContainer
          style={!isSettingsOpen ? { paddingLeft: "0.84rem" } : {}}
        >
          <CustomRow
            style={{
              flexWrap: "nowrap",
              columnGap: "0.84rem",
              ...(isSettingsOpen
                ? {
                    paddingLeft: "0.84rem",
                    paddingRight: "0.84rem",
                  }
                : {
                    marginTop: "0.277rem",
                  }),
            }}
          >
            <Column xs={isSettingsOpen ? 2 : 1.4}>
              <img
                src={applicationIconMapper[iconFile || "ConversionPath.svg"]}
                alt="Connector Icon"
                style={
                  !isSettingsOpen
                    ? {
                        width: "2.225rem",
                        height: "2.225rem",
                        maxWidth: "2.225rem",
                        maxHeight: "2.225rem",
                        background: "#4B4B4B",
                        borderRadius: "0.277rem",
                      }
                    : {
                        width: "3.33rem",
                        height: "3.33rem",
                        maxWidth: "3.33rem",
                        maxHeight: "3.33rem",
                        background: "#4B4B4B",
                        borderRadius: "0.277rem",
                      }
                }
              />
            </Column>
            <Column xs={isSettingsOpen ? 8 : 8.5}>
              <CustomRow>
                <Column>
                  <Tooltip title={connectorName}>
                    <Text
                      style={{
                        color: "#F6F6F6",
                        fontSize: "1.112rem",
                        fontFamily: "Articulat CF Medium",
                        fontWeight: "bold",
                      }}
                    >
                      {connectorName}
                    </Text>
                  </Tooltip>
                </Column>
                {isSettingsOpen && (
                  <Column>
                    <Text
                      style={{
                        color: "#777777",
                        fontSize: "0.84rem",
                        lineHeight: "0.972rem",
                        letterSpacing: "0px",
                      }}
                    >
                      Published by {publisher}
                    </Text>
                  </Column>
                )}
                {isSettingsOpen && (
                  <Column>
                    <Text
                      style={{
                        color: "#777777",
                        fontSize: "0.84rem",
                        lineHeight: "0.972rem",
                      }}
                    >
                      {connectorVersion}
                    </Text>
                  </Column>
                )}
              </CustomRow>
            </Column>
            <Column
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (isClickable || isRestorable) {
                  setSettingsOpen(!isSettingsOpen);
                } else {
                  showMessage(
                    "You don't have permission to perform this operation",
                  );
                }
              }}
            >
              <img
                src={isSettingsOpen ? GoBackIcon : SettingsIcon}
                alt={isSettingsOpen ? "Go Back Icon" : "Settings Icon"}
                style={{ height: "1.39rem", width: "1.39rem" }}
              />
            </Column>
          </CustomRow>
          {!isSettingsOpen && (
            <CustomRow>
              <Column xs={7}>
                <TitleAndSubtitleContainer>
                  <Text
                    style={{
                      color: "#777777",
                      fontSize: "0.7rem",
                      lineHeight: "1.041rem",
                      fontWeight: "bold",
                      fontFamily: "Articulat CF Medium",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Published By
                  </Text>
                  <Text
                    style={{
                      color: "#eb6f95",
                      fontSize: "0.84rem",
                      lineHeight: "0.972rem",
                      fontWeight: "bold",
                      fontFamily: "Articulat CF Normal",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {publisher}
                  </Text>
                </TitleAndSubtitleContainer>
              </Column>
              <Column xs={5}>
                <TitleAndSubtitleContainer>
                  <Text
                    style={{
                      color: "#777777",
                      fontSize: "0.7rem",
                      lineHeight: "1.041rem",
                      fontWeight: "bold",
                      fontFamily: "Articulat CF Medium",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Version
                  </Text>
                  <Text
                    style={{
                      color: "#eb6f95",
                      fontSize: "0.84rem",
                      lineHeight: "0.972rem",
                      fontWeight: "bold",
                      fontFamily: "Articulat CF Normal",
                      letterSpacing: "0.5px",
                    }}
                  >
                    {connectorVersion}
                  </Text>
                </TitleAndSubtitleContainer>
              </Column>
            </CustomRow>
          )}
          <CustomRow
            style={
              isSettingsOpen
                ? {
                    paddingLeft: "0.84rem",
                    paddingRight: "0.84rem",
                  }
                : {
                    marginBottom: "0.277rem",
                  }
            }
          >
            <Column>
              <Text
                className="multiline-ellipsis"
                style={{
                  color: "#F6F6F6",
                  fontSize: "0.84rem",
                  lineHeight: "0.972rem",
                  marginTop: "0.97rem",
                  height: "2rem",
                  wordWrap: "break-word",
                  fontWeight: "bold",
                  fontFamily: "Articulat CF Normal",
                  letterSpacing: "0.5px",
                }}
              >
                {description}
              </Text>
            </Column>
          </CustomRow>
          {isSettingsOpen && (
            <CustomRow
              style={{
                padding: "0.69rem 0.84rem",
                backgroundColor: "#EE6F4766",
                borderRadius: "0px 0 0.84rem 0.84rem",
                display: "flex",
                alignItems: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();
                return null;
              }}
            >
              <Column xs={4.7}>
                <Text
                  style={{
                    textDecoration: "underLine",
                    color: "#F6F6F6",
                    fontSize: "0.84rem",
                    lineHeight: "0.84rem",
                    cursor: "pointer", //pointer//not-allowed
                    fontFamily: "Articulat CF Normal",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                  onClick={() => {
                    setSelectedConnectorId(connectorId);
                    setDrawerOpen(true);
                    if (selectedConnectorId === connectorId)
                      setAndFetchVersionHistory();
                  }}
                >
                  Version History
                </Text>
              </Column>
              <Column
                xs={isRestorable ? 3 : 2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {isRestorable && (
                  <Tooltip title={"Restore Application"} placement="bottom">
                    <button
                      type="button"
                      aria-label="Restore"
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        cursor: isRestorable ? "pointer" : "not-allowed",
                        height: "1.112rem",
                        width: "1.112rem",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isRestorable) {
                          setRestoreOpen(true);
                        } else {
                          showMessage(
                            "You don't have permission to perform this operation",
                          );
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          e.stopPropagation();
                          if (isClickable) {
                            setRestoreOpen(true);
                          } else {
                            showMessage(
                              "You don't have permission to perform this operation",
                            );
                          }
                        }
                      }}
                    >
                      <img
                        src={RestoreIcon}
                        alt="Restore Icon"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </button>
                  </Tooltip>
                )}

                <Tooltip title={"Edit Application"} placement="bottom">
                  <button
                    type="button"
                    aria-label="Edit"
                    style={{
                      background: "none",
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                      height: "1.112rem",
                      width: "1.112rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isClickable) {
                        setEditOpen(true);
                      } else {
                        showMessage(
                          "You don't have permission to perform this operation",
                        );
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.stopPropagation();
                        if (isClickable) {
                          setEditOpen(true);
                        } else {
                          showMessage(
                            "You don't have permission to perform this operation",
                          );
                        }
                      }
                    }}
                  >
                    <img
                      src={EditIcon}
                      alt="Edit Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </button>
                </Tooltip>
                <Tooltip title={"Delete Application"} placement="bottom">
                  <button
                    type="button"
                    aria-label="Delete"
                    style={{
                      background: "none",
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                      height: "1.112rem",
                      width: "1.112rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isClickable) {
                        setDeleteOpen(true);
                      } else {
                        showMessage(
                          "You don't have permission to perform this operation",
                        );
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.stopPropagation();
                        if (isClickable) {
                          setDeleteOpen(true);
                        } else {
                          showMessage(
                            "You don't have permission to perform this operation",
                          );
                        }
                      }
                    }}
                  >
                    <img
                      src={DeleteIcon}
                      alt="Delete Icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </button>
                </Tooltip>
              </Column>
            </CustomRow>
          )}

          <Offcanvas
            show={isDrawerOpen}
            onHide={() => setDrawerOpen(false)}
            placement="end"
            style={{
              height: "calc(100vh - 2.222rem)",
              // overflowY: "auto",
              // overflowX: "auto",
              marginTop: "4rem",
              backgroundColor: "#2F2F2F",
              width: "32rem",
              zIndex: 1,
            }}
          >
            <Offcanvas.Header
              closeButton
              style={{ borderBottom: "2px solid #777777" }}
            >
              <Offcanvas.Title
                style={{
                  color: "#F6F6F6",
                  fontFamily: "Articulat CF Normal",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                  fontSize: "1.4rem",
                }}
              >
                Version History
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                style={{
                  borderBottom: isLoading ? "none" : "2px solid #777777",
                  padding: "1.3rem 1.1rem 1.3rem 1.2rem",
                }}
              >
                <GenericSelectionWithoutSearch
                  dropdownItems={filteredCustomConnectorList}
                  selectedItem={filteredCustomConnectorList.find(
                    (item) => item.id === selectedConnectorId,
                  )}
                  placeholder={"Select a Type"}
                  onSelect={(item) => {
                    setSelectedConnectorId(item.id);
                  }}
                  nameFunction={(item) => item.connectorName}
                  filterFunction={(arr, text) =>
                    arr.filter((item) =>
                      item.connectorName
                        .toLowerCase()
                        .includes(text.toLowerCase()),
                    )
                  }
                  noMargin={true}
                />
                {isLoading ? (
                  <CustomRow
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "3rem",
                    }}
                  >
                    <Spinner animation="border" style={{ color: "#47CCD6" }} />
                  </CustomRow>
                ) : (
                  <>
                    <CustomRow
                      style={{
                        flexWrap: "nowrap",
                        columnGap: "0.22rem",
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Column xs={isSettingsOpen ? 2 : 1.4}>
                        <img
                          src={
                            applicationIconMapper[
                              iconFileState || "ConversionPath.svg"
                            ]
                          }
                          alt="Connector Icon"
                          style={
                            !isSettingsOpen
                              ? {
                                  width: "2.225rem",
                                  height: "2.225rem",
                                  maxWidth: "2.225rem",
                                  maxHeight: "2.225rem",
                                  background: "#4B4B4B",
                                  borderRadius: "0.277rem",
                                }
                              : {
                                  width: "3.33rem",
                                  height: "3.33rem",
                                  maxWidth: "3.33rem",
                                  maxHeight: "3.33rem",
                                  background: "#4B4B4B",
                                  borderRadius: "0.277rem",
                                }
                          }
                        />
                      </Column>
                      <Column xs={isSettingsOpen ? 8 : 8.5}>
                        <CustomRow>
                          <Column>
                            <Text
                              style={{
                                color: "#F6F6F6",
                                fontSize: "1.112rem",
                                fontFamily: "Articulat CF Medium",
                                fontWeight: "bold",
                              }}
                            >
                              {connectorNameState}
                            </Text>
                          </Column>
                          {isSettingsOpen && (
                            <Column>
                              <Text
                                style={{
                                  color: "#777777",
                                  fontSize: "0.84rem",
                                  lineHeight: "0.972rem",
                                  letterSpacing: "0px",
                                }}
                              >
                                Published by {publisherState}
                              </Text>
                            </Column>
                          )}
                          {isSettingsOpen && (
                            <Column>
                              <Text
                                style={{
                                  color: "#777777",
                                  fontSize: "0.84rem",
                                  lineHeight: "0.972rem",
                                }}
                              >
                                {connectorVersionState}
                              </Text>
                            </Column>
                          )}
                        </CustomRow>
                      </Column>
                    </CustomRow>
                    <CustomRow
                      style={{
                        marginBottom: "0.277rem",
                      }}
                    >
                      <Column>
                        <Text
                          className="multiline-ellipsis"
                          style={{
                            color: "#CCCCCC",
                            fontSize: "0.84rem",
                            lineHeight: "0.972rem",
                            marginTop: "0.89rem",
                            wordWrap: "break-word",
                          }}
                        >
                          {descriptionState}
                        </Text>
                      </Column>
                    </CustomRow>
                  </>
                )}
              </div>
              {!isLoading && (
                <div
                  style={{
                    paddingTop: "1.3rem ",
                    paddingBottom: "1.3rem",
                    paddingRight: "1.3rem",
                    paddingLeft: "1.2rem",
                  }}
                >
                  <div className="d-flex flex-column position-relative">
                    {steps.map((step, index) => (
                      <div
                        key={step.id}
                        className="d-flex position-relative"
                        style={{ paddingBottom: "2.5rem" }}
                      >
                        {/* Left Indicator */}
                        <div className="position-relative d-flex flex-column align-items-center me-3">
                          <div className="text-white d-flex justify-content-center align-items-center">
                            {step.operation === "PUBLISHED" ? (
                              <GroupConnectorIcon
                                style={{ width: "1.9rem", height: "2rem" }}
                              />
                            ) : (
                              <GroupDeleteIcon
                                style={{ width: "1.9rem", height: "2rem" }}
                              />
                            )}
                          </div>
                          {index !== steps.length - 1 && (
                            <div
                              className="border-start custom-border-start"
                              style={{
                                height:
                                  step?.description?.length <= 54
                                    ? "9rem"
                                    : `${9 + Math.ceil((step.description.length - 54) / 54) * 2}rem`,
                                // height: "9rem",
                                position: "absolute",
                                top: "30px",
                                left: "50%",
                                width: "26.625rem",
                              }}
                            ></div>
                          )}
                        </div>

                        {/* Step Content */}
                        <div className="row" style={{ width: "95%" }}>
                          <h5
                            className="mb-1"
                            style={{
                              color: "#F6F6F6",
                              fontSize: "0.99rem",
                              fontWeight: "bold",
                              wordWrap: "break-word",
                            }}
                          >
                            {step.operation === "PUBLISHED"
                              ? "Published"
                              : "Deleted"}{" "}
                            Version {step.version}
                          </h5>
                          <div
                            className="rounded p-2 mt-2"
                            style={{
                              backgroundColor:
                                step.operation === "PUBLISHED"
                                  ? "#47CCD629"
                                  : "#EA3A3A29",
                              color: "#F6F6F6",
                              border:
                                step.operation === "PUBLISHED"
                                  ? "1px solid #47CCD6"
                                  : "1px solid #EA3A3A",
                              fontSize: "0.89rem",
                              marginLeft: "calc(var(--bs-gutter-x) * .5)",
                              marginRight: "calc(var(--bs-gutter-x) * .5)",
                              width: "95%",
                              wordWrap: "break-word",
                            }}
                          >
                            {step.description}
                          </div>
                          <span
                            className="mb-1 mt-2"
                            style={{
                              color: "#777777",
                              margin: 0,
                              fontSize: "0.86rem",
                              wordWrap: "break-word",
                            }}
                          >
                            {step.operationPerformedBy}
                            {step.operationTime && (
                              <>
                                <RoundIcon
                                  style={{
                                    height: "0.32rem",
                                    width: "0.32rem",
                                    marginLeft: "0.5rem",
                                    marginRight: "0.5rem",
                                  }}
                                />
                                {formatTimeAgo(step.operationTime)}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Offcanvas.Body>
          </Offcanvas>
        </ContentContainer>
      </CardContainer>
      {isDeleteOpen && (
        <CustomConnectorDeletionModal
          customConnectorId={connectorId}
          customConnectorName={connectorName}
          isOpen={isDeleteOpen}
          onClose={() => setDeleteOpen(false)}
        />
      )}
      {isEditOpen && (
        <CustomConnectorEditModal
          customConnectorId={connectorId}
          customConnectorName={connectorName}
          isOpen={isEditOpen}
          onClose={() => setEditOpen(false)}
          customConnectorDescription={description}
          customConnectorVersion={connectorVersion}
          customConnectorIconFile={iconFile}
        />
      )}

      {isRestoreOpen && (
        <RestoreApplicationModal
          customConnectorId={connectorId}
          customConnectorName={connectorName}
          isRestorable={isRestorable}
          isOpen={isRestoreOpen}
          onClose={() => setRestoreOpen(false)}
          customConnectorDescription={description}
          customConnectorVersion={connectorVersion}
          customConnectorIconFile={iconFile}
          vcProvider={vcProvider}
        />
      )}
    </>
  );
};

CustomConnectorCard.propTypes = {
  connectorId: PropTypes.string.isRequired,
  connectorName: PropTypes.string.isRequired,
  connectorVersion: PropTypes.string.isRequired,
  publisher: PropTypes.string,
  description: PropTypes.string,
  iconFile: PropTypes.string,
  isClickable: PropTypes.bool,
  handleCustomConnectorCardClick: PropTypes.func,
  isRestorable: PropTypes.bool.isRequired,
  vcProvider: PropTypes.string.isRequired,
  filteredCustomConnectorList: PropTypes.string,
};

CustomConnectorCard.defaultProps = {
  publisher: "",
  description: "",
  iconFile: "",
  isClickable: false,
  handleCustomConnectorCardClick: () => {},
};

export default CustomConnectorCard;
