import React, { useCallback, useContext, useEffect, useState } from "react";
import FlexContainer from "../../../components/common/_flexContainer";
import Text from "../../../components/common/_text";
import { ReactComponent as EditIcon } from "../../../assets/images/workspace_settings_edit_icon.svg";
import { ReactComponent as SaveIcon } from "../../../assets/images/workspace_settings_save_icon.svg";
import styled from "styled-components";
import { InputField, InputFieldGridContainer } from "./InputField";
import axiosAdapter from "../../../utils";
import { CountryCodeSelector } from "../../../components/common/_countryCodeSelector";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import { ChangePasswordModal } from "./ChangePasswordModal";
import Divider from "../../../components/common/_divider";
import IconButton from "../../../components/common/_iconButton";
import { env } from "../../../env";
import PropTypes from "prop-types";

const EditIconStyled = styled(EditIcon)`
  width: 1.666rem;
  height: 1.666rem;
  cursor: pointer;
`;

const SaveIconStyled = styled(SaveIcon)`
  width: 1.666rem;
  height: 1.666rem;
  cursor: pointer;
`;

const UnderlinedTextButton = styled.button`
  position: absolute;
  right: 1.111rem;
  bottom: 20%;
  background: none;
  border: none;
  color: #47ccd6;
  cursor: pointer;
  font-size: 0.972rem;
  font-family: "Articulat CF Medium";
  text-decoration: underline;
`;

const CustomWrapper = styled.div`
  position: relative;
  display: inline-block;

  & > *:first-child > *:nth-child(2) {
    ${({ $addBorder }) => ($addBorder ? "border: 0.069rem solid #b0b0b0;" : "")}
  }
`;

const getPersonalDetails = async () => {
  const response = await axiosAdapter(
    "GET",
    env.REACT_APP_URL + "user-management/getPersonalDetails",
  );
  return response.data;
};

const PersonalDetailsSectionContents = ({
  refetchUsers,
  refetchWorkspaces,
}) => {
  const { showMessage } = useContext(SnackbarContext);

  const [personalDetails, setPersonalDetails] = useState({
    username: "",
    fullName: "",
    phoneNumber: "",
    countryCode: "91",
    emailId: "",
  });

  const [personalDetailsEditMode, setPersonalDetailsEditMode] = useState(0);
  const [isPasswordChangeModalOpen, setPasswordChangeModalOpen] =
    useState(false);

  const refetchPersonalDetails = useCallback(async () => {
    try {
      const response = await getPersonalDetails();
      setPersonalDetails(response.data);
    } catch (err) {
      console.log("Error: getPersonalDetails", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage(
          "Unable to get the personal details, please try again later",
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const personalDetailsFormSubmit = async () => {
    if (!personalDetailsEditMode) setPersonalDetailsEditMode(1);
    else {
      const { emailId, ...payload } = personalDetails;
      try {
        const response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "user-management/updatePersonalDetails",
          payload,
        );
        if (
          response.data.remarks ===
          "No changes to save. We have detected that there are no changes made"
        ) {
          showMessage(response.data.remarks, "info");
          setPersonalDetailsEditMode(0);
        } else if (
          response.data.code === 200 &&
          response.data.status === "SUCCESS"
        ) {
          showMessage(response.data.remarks, "success");
          refetchPersonalDetails();
          refetchUsers();
          refetchWorkspaces();
          setPersonalDetailsEditMode(0);
        } else {
          showMessage(
            response.data.remarks ||
              "Unable to update the personal details, please try again later",
          );
          setPersonalDetailsEditMode(0);
        }
      } catch (err) {
        console.log("Error: updatePersonalDetails", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Unable to update the personal details, please try again later",
          );
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getPersonalDetails();
        setPersonalDetails(response.data);
      } catch (err) {
        console.log("Error: getPersonalDetails", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Unable to get the personal details, please try again later",
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("PERSONAl details", personalDetails);

  return (
    <>
      <FlexContainer $width="100%" $gap="1.666rem">
        <Text $fontWeight="bold" $fontSize="1.388rem" $letterSpacing="0.5px">
          Personal Details
        </Text>
        <IconButton
          $width="1.666rem"
          $height="1.666rem"
          $background="none"
          onClick={() => personalDetailsFormSubmit()}
        >
          {!personalDetailsEditMode ? <EditIconStyled /> : <SaveIconStyled />}
        </IconButton>
        <Divider />
        <InputFieldGridContainer
          $width="100%"
          $gap="1.527rem"
          style={{
            fontFamily: "Articulat CF Normal",
            fontWeight: "bold",
            letterSpacing: "0.5px",
          }}
        >
          <InputField
            value={personalDetails}
            setValue={setPersonalDetails}
            labelText="Username"
            fieldName="username"
            inputFieldType="text"
            autoCompleteType="username"
            disabled={!personalDetailsEditMode}
          />
          <InputField
            value={personalDetails}
            setValue={setPersonalDetails}
            labelText="Full Name"
            fieldName="fullName"
            inputFieldType="text"
            autoCompleteType="given-name"
            disabled={!personalDetailsEditMode}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label style={{ fontSize: "0.97rem", color: "#B0B0B0" }}>
              Phone Number
            </label>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                columnGap: "1.111rem",
              }}
            >
              <CountryCodeSelector
                selectedContryCode={personalDetails.countryCode}
                onContryCodeSelect={(item) =>
                  setPersonalDetails((value) => ({
                    ...value,
                    countryCode: item.code,
                  }))
                }
                disabled={!personalDetailsEditMode}
                addBorder={Boolean(personalDetailsEditMode)}
              />
              <InputField
                value={personalDetails}
                setValue={setPersonalDetails}
                labelText=""
                fieldName="phoneNumber"
                inputFieldType="tel"
                autoCompleteType="tel"
                disabled={!personalDetailsEditMode}
              />
            </div>
          </div>
          <InputField
            value={personalDetails}
            setValue={setPersonalDetails}
            labelText="Email Address"
            fieldName="emailId"
            inputFieldType="email"
            autoCompleteType="email"
            disabled={true}
          />
          {/* {!personalDetails.isSSO && (
            <CustomWrapper $addBorder={Boolean(personalDetailsEditMode)}>
              <InputField
                value={{ password: "●●●●●●●●●●" }}
                setValue={setPersonalDetails}
                labelText="Password"
                fieldName="password"
                disabled={true}
              />
              {Boolean(personalDetailsEditMode) && (
                <UnderlinedTextButton
                  onClick={() => {
                    setPasswordChangeModalOpen(true);
                  }}
                >
                  Change Password
                </UnderlinedTextButton>
              )}
            </CustomWrapper>
          )} */}
        </InputFieldGridContainer>
      </FlexContainer>
      {isPasswordChangeModalOpen && (
        <ChangePasswordModal
          isOpen={isPasswordChangeModalOpen}
          onClose={() => setPasswordChangeModalOpen(false)}
        />
      )}
    </>
  );
};

PersonalDetailsSectionContents.propTypes = {
  refetchUsers: PropTypes.func.isRequired,
  refetchWorkspaces: PropTypes.func.isRequired,
};

export default PersonalDetailsSectionContents;
