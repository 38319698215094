import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./context/AuthContext";
import { GrafanaCredentialsProvider } from "./context/GrafanaUserCredentialsContext";
import { env } from "./env";

const root = ReactDOM.createRoot(document.getElementById("root"));

const config = {
  domain: env.REACT_APP_AUTH0_DOMAIN,
  clientId: env.REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/authLoading`,
  },
};

root.render(
  <Auth0Provider {...config}>
    <AuthProvider>
      <GrafanaCredentialsProvider>
        <App />
      </GrafanaCredentialsProvider>
    </AuthProvider>
  </Auth0Provider>,
);
