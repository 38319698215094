import styled from "styled-components";
import ConnectorIcon from "../../assets/images/connector_node_preview_icon.svg";
import PropTypes from "prop-types";
import { applicationIconMapper } from "../../components/common/_applicationIconMapper";
import ConnectorIconNew from "../../assets/images/Connectors.svg";

const CardContainer = styled.div`
  width: 21rem;
  height: 11.5rem;
  border-radius: 0.84rem;
  background-color: #0d0d0d;
  display: flex;
  justify-content: space-between;
  padding: 0.84rem;
  cursor: not-allowed;
  box-shadow: 0px 0px 2.77rem 0px #00000029;
`;

const LeftLine = styled.div`
  width: 0.277rem;
  background-color: #47ccd6;
  border-radius: 0.347rem;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-left: 0.84rem;
  overflow: hidden;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;

  height: 2.25rem;
  width: 2.25rem;
  border-radius: 0.35rem;
  background-color: #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;
const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TitleAndIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 190px;
`;

const ReactFlowNodeCard = (props) => {
  const { title, connectionType, category, description, name, icon } =
    props.data;

  const getCategoryColor = (category, connectionType = "") => {
    switch (category) {
      case "Stream Connector":
        return "#6DC89B";
      case "Store Connector":
        return "#B5E3CE";
      case "Application":
        return "#EB6F95";
      case "Telematics Device":
        return "#F7C0D5";
      case "Transform":
        if (connectionType && connectionType === "Output") {
          return "#C7A6F1";
        } else {
          return "#EB6F95";
        }
      default:
        return "#EB6F95";
    }
  };

  return (
    <CardContainer>
      <LeftLine
        style={{
          backgroundColor: getCategoryColor(category, connectionType),
        }}
      />
      <ContentContainer>
        <ContentRow style={{ gap: "0.55rem" }}>
          <IconContainer category={category}>
            <img
              src={
                category === "Application"
                  ? applicationIconMapper[icon || "ConversionPath.svg"]
                  : icon || ConnectorIconNew
              }
              alt="Connector Icon"
              style={{
                height: category === "Application" ? "2rem" : "1.67rem",
                width: category === "Application" ? "2rem" : "1.67rem",
              }}
            />
          </IconContainer>
          <TitleAndSubtitleContainer>
            {title && (
              <Text
                style={{
                  fontSize: "1.112rem",
                  lineHeight: "1.39rem",
                  color: "#F6F6F6",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                {title}
              </Text>
            )}
            {name && (
              <Text
                style={{
                  fontSize: "0.7rem",
                  lineHeight: "0.972rem",
                  color: "#777777",
                  fontFamily: "Articulat CF Medium",
                  fontWeight: "bold",
                  letterSpacing: "0px",
                }}
              >
                {name}
              </Text>
            )}
          </TitleAndSubtitleContainer>
        </ContentRow>
        <ContentRow style={{ gap: "0.55rem" }}>
          <TitleAndSubtitleContainer style={{ width: "55%", maxWidth: "60%" }}>
            <Text
              style={{
                fontSize: "0.84rem",
                lineHeight: "1.25rem",
                color: "#777777",
                fontFamily: "Articulat CF Medium",
                fontWeight: "bold",
                letterSpacing: "0px",
              }}
            >
              Category
            </Text>
            <Text
              style={{
                fontSize: "0.84rem",
                fontWeight: "bold",
                lineHeight: "1.25rem",
                color: getCategoryColor(category, connectionType),
                fontFamily: "Articulat CF Normal",
              }}
            >
              {category}
            </Text>
          </TitleAndSubtitleContainer>
          <TitleAndSubtitleContainer style={{ width: "45%", maxWidth: "40%" }}>
            <Text
              style={{
                fontSize: "0.84rem",
                lineHeight: "1.125rem",
                color: "#777777",
                fontFamily: "Articulat CF Medium",
                fontWeight: "bold",
                letterSpacing: "0px",
              }}
            >
              Type
            </Text>
            <Text
              style={{
                fontSize: "0.84rem",
                fontWeight: "bold",
                lineHeight: "1.25rem",
                color: getCategoryColor(category, connectionType),
                fontFamily: "Articulat CF Normal",
              }}
            >
              {connectionType}
            </Text>
          </TitleAndSubtitleContainer>
        </ContentRow>
        <ContentRow style={{ gap: "8px" }}>
          <TitleAndSubtitleContainer>
            <Text
              className="multiline-ellipsis"
              style={{
                fontSize: "0.84rem",
                lineHeight: "0.972rem",
                color: "#F6F6F6",
                fontFamily: "Articulat CF Normal",
                fontWeight: "bold",
                letterSpacing: "0.5px",
              }}
            >
              {description}
            </Text>
          </TitleAndSubtitleContainer>
        </ContentRow>
      </ContentContainer>
    </CardContainer>
  );
};

ReactFlowNodeCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    connectionType: PropTypes.string,
    category: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default ReactFlowNodeCard;
