import React from "react";
import Tooltip from "../common/_tooltip";
import styled from "styled-components";
import PropTypes from "prop-types";

const Title = styled.div`
  font-size: 1.115rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  padding-left: 0.6rem;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TitleSlice = ({ title }) => {
  const FullTitle = title || "";
  return (
    <Tooltip title={FullTitle}>
      <Title>{FullTitle}</Title>
    </Tooltip>
  );
};

TitleSlice.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleSlice;
