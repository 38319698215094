import React, { useEffect, useRef, memo, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ModalContainer = styled.div`
  background: #2f2f2f;
  padding: ${({ $isShowPadding }) => ($isShowPadding ? "2.222rem" : "0")};
  border-radius: 1.8rem;
  border: 0.1rem solid #777777;
  width: 55vw;
  box-shadow: 0 0.138rem 0.7rem rgba(0, 0, 0, 0.1);
  max-height: ${({ $maxHeight }) => $maxHeight};
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
`;

const Title = styled.h2`
  margin: 0;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.67rem;
  line-height: 1;
  color: #f6f6f6;
  text-align: left;
  font-weight: bold;
`;

const Body = styled.div``;

/**
 * Renders a generic modal component.
 *
 * @param {boolean} props.show - Indicates whether the modal should be shown.
 * @param {function} props.onClose - The function to be called when the modal is closed.
 * @param {string} props.title - The title of the modal.
 * @param {boolean} props.disableCloseOnOutsideClick - Indicates whether the modal should be closed when the user clicks outside of it.
 * @param {number} props.maxHeight - The maximum height of the modal in vh.
 * @param {ReactNode} props.children - The content of the modal.
 * @return {ReactElement|null} The rendered modal component or null if the modal should not be shown.
 */
const ModalContent = memo(
  ({
    show,
    onClose,
    title,
    disableCloseOnOutsideClick,
    children,
    maxHeight = "60vh",
    initialFocusSelector = 'input:not([type="hidden"]), textarea, select, button',
    isShowPadding = true,
  }) => {
    const modalRef = useRef(null);
    const previousFocusRef = useRef(null);
    const [isModalMounted, setIsModalMounted] = useState(false);

    // Track modal mounting
    useEffect(() => {
      if (show) {
        setIsModalMounted(true);
      }
      return () => setIsModalMounted(false);
    }, [show]);

    useEffect(() => {
      if (!show || !isModalMounted) return;

      previousFocusRef.current = document.activeElement;

      const focusInterval = setInterval(() => {
        if (modalRef.current) {
          const elementToFocus =
            modalRef.current.querySelector(initialFocusSelector);
          if (elementToFocus && document.activeElement !== elementToFocus) {
            elementToFocus.focus({ preventScroll: true });
          }
        }
      }, 100);

      // Stop checking after 2 seconds
      const cleanupTimeout = setTimeout(() => {
        clearInterval(focusInterval);
      }, 2000);

      return () => {
        clearInterval(focusInterval);
        clearTimeout(cleanupTimeout);
        if (previousFocusRef.current && !show) {
          previousFocusRef.current.focus();
        }
      };
    }, [show, isModalMounted, initialFocusSelector]);

    // Keyboard handling in separate effect
    useEffect(() => {
      if (!show) return;

      const handleKeyDown = (e) => {
        if (e.key === "Escape" && onClose) {
          onClose();
          return;
        }

        if (e.key === "Tab") {
          if (!modalRef.current) return;

          const focusableElements = modalRef.current.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
          );

          if (focusableElements.length === 0) return;

          const firstFocusableElement = focusableElements[0];
          const lastFocusableElement =
            focusableElements[focusableElements.length - 1];

          if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === lastFocusableElement) {
              firstFocusableElement.focus();
              e.preventDefault();
            }
          }
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      document.body.style.overflow = "hidden";

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.body.style.overflow = "unset";
      };
    }, [show, onClose]);

    const handleOverlayClick = (e) => {
      if (
        e.target === e.currentTarget &&
        !disableCloseOnOutsideClick &&
        onClose
      ) {
        onClose();
      }
    };

    return (
      <Overlay
        onClick={(e) =>
          !disableCloseOnOutsideClick && onClose && handleOverlayClick(e)
        }
      >
        <ModalContainer
          ref={modalRef}
          $maxHeight={maxHeight}
          $isShowPadding={isShowPadding}
        >
          {title && (
            <Header>
              <Title>{title}</Title>
            </Header>
          )}
          <Body>{children}</Body>
        </ModalContainer>
      </Overlay>
    );
  },
);

ModalContent.displayName = "ModalContent";

const GenericModal = (props) => {
  if (!props.show) return null;

  return createPortal(<ModalContent {...props} />, document.body);
};

GenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  disableCloseOnOutsideClick: PropTypes.bool,
  children: PropTypes.node,
  maxHeight: PropTypes.string,
  initialFocusSelector: PropTypes.string,
  isShowPadding: PropTypes.bool,
};

export default memo(GenericModal);
