import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import styled from "styled-components";
import BackIcon from "../../assets/images/Back_Arrow.svg";
import ConnectorIcon from "../../assets/images/Connectors copy.svg";
import AdditionIcon from "../../assets/images/settings_icon.svg";
import ConnectorInfoForm from "./ConnectorInfoForm";
import ConnectorLogs from "./ConnectorInfoLog";
import EditConfigurationModal from "./EditConnectorInfoModal";
import GenericModal from "../common/_genericModal";
import Column from "../common/_customColumn";
import { useHistory, useLocation } from "react-router-dom";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import {
  StyledButtonsecondary,
  StyledDeleteButton,
  StyledButtonPrimary,
} from "../common/_buttonNewOne";
import CampaignPageContainer from "./CampaignPage";
import ConnectorUtilityInfoForm from "./ConnectorUtilityInfoForm";
import Spinner from "react-bootstrap/Spinner";
import CustomRow from "../../components/common/_customRow";
import { default as Spinner2 } from "../../components/common/_spinner.jsx";
import loadingAnimation from "../../assets/images/Loading Animation.gif";

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 16.666rem;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 0.277rem;
  background-color: #2f2f2f;
  border-radius: 0.138rem;
  box-shadow: -0.555rem 0.277rem 0.625rem 0rem #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const HoverImage = styled.img`
  height: 10.625rem;
  cursor: pointer;
  &:hover {
    background-color: #2b2b2b;
    border-radius: 0.55rem;
  }
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 0.277rem;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.67rem;
  text-align: left;
  color: #fafafa;
  padding-left: 1.112rem;
  font-weight: bold;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;

const Icon = styled.img`
  cursor: pointer;
`;

const ConnectorInfoTab = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  gap: 0.55rem;
  color: ${(props) => (props.isActive ? "#47ccd6" : "#777777")};
`;
const TabIcon = styled.img`
  height: 1.67rem;
  width: 1.67rem;
  filter: ${(props) =>
    props.isActive
      ? "brightness(0) saturate(100%) invert(80%) sepia(11%) saturate(2146%) hue-rotate(134deg) brightness(89%) contrast(101%)"
      : "grayscale(100%)"};
`;

const TabName = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.25rem;
  font-weight: bold;
`;

const TabLine = styled.div`
  position: relative;
  height: 0.277rem;
  margin-left: 1.25rem;
  margin-top: 0.55rem;
  background-color: ${(props) => (props.isActive ? "#47ccd6" : "transparent")};
  z-index: 1;
  border-radius: 0.138rem;
`;

const Divider = styled.div`
  position: absolute;
  border: none;
  height: 0.138rem;
  background-color: #90909052;
  width: 100vw;
  z-index: 1;
  bottom: 0;
  left: 0;
`;

const ModalDescription = styled.div`
  font-family: "Articulat CF Normal";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.55rem;
  color: #ececec;
  opacity: 0.7;
  letter-spacing: 0.5px;
`;

export default function ConnectorInfoConfiguration(props) {
  const history = useHistory();
  const location = useLocation();
  const { showMessage } = useContext(SnackbarContext);
  let { nodeData, nodes, edges } = location.state || {};
  const [selectedTab, setSelectedTab] = useState("connectorInfo");
  const [editModal, setEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLogsExpanded, setIsLogsExpanded] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [deviceName, setDeviceName] = useState(null);
  const [isUtilityConnector, setIsUtilityConnector] = useState(
    nodeData.category === "Transform" && nodeData.connectionType === "Output"
      ? true
      : false,
  );
  const [saveLoading, setSaveLoading] = useState(false);
  const [jsonValue, setJsonValue] = useState("");
  const [groupsAndRules, setGroupsAndRules] = useState([]);
  const [fieldOptionValues, setFieldOptionValues] = useState([]);
  const [isGroupError, setIsGroupError] = useState(false);
  const [groupBy, setGroupBy] = useState("");
  const [rulesError, setRulesError] = useState(true);
  const [formKey, setFormKey] = useState(0);
  const [isAllTopicsLoading, setIsAllTopicsLoading] = useState(false);
  const [expressionTextObj, setExpressionTextObj] = useState("");
  const [logic, setLogic] = useState("");
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [configResponseData, setConfigResponseData] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  useEffect(() => {
    if (!fieldOptionValues.some((op) => op.label === groupBy)) {
      setIsGroupError(true);
    } else {
      setIsGroupError(false);
    }
  }, [fieldOptionValues, groupBy]);

  useEffect(() => {
    (async () => {
      setIsAllTopicsLoading(true);
      try {
        let fetchAllTopics = await axiosAdapter(
          "GET",
          env.REACT_APP_URL + "kafka-management/listTopics",
        );
        setAllTopics(fetchAllTopics.data.data);
        setIsAllTopicsLoading(false);
      } catch (error) {
        setIsAllTopicsLoading(false);
      }

      if (isUtilityConnector) {
        try {
          setIsFieldsLoading(true);
          let stringifiedData = {
            workspaceId: parseInt(
              localStorage.getItem("selectedWorkspaceId") || 0,
            ),
            connectorId: nodeData.configId,
            connectorCategory: nodeData.category,
          };
          let configResponse = await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "pipeline/getDeployedConnectorConfigs",
            stringifiedData,
          );
          setConfigResponseData(configResponse);
          setIsFieldsLoading(false);
        } catch {}
      }
    })();
  }, []);

  // If there is no nodeData in the location state, go back to main page
  if (!nodeData) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  // If there is no nodeData in the location state, go back to main page
  if (!nodeData) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const toggleEditModal = () => {
    setEditModal(!editModal);
    if (isUtilityConnector && editModal) setFormKey((prevKey) => prevKey + 1);
  };
  const toggleDeleteModal = () => {
    if (!isSubmitLoading) {
      setShowDeleteModal(!showDeleteModal);
    }
  };
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleDeleteConnector = async () => {
    if (!isSubmitLoading) {
      const workspaceId = parseInt(
        localStorage.getItem("selectedWorkspaceId") || 0,
      );
      try {
        setIsSubmitLoading(true);
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/deleteNamespaceDeploymentNew",
          {
            connectorId: nodeData.configId,
            workspaceId: workspaceId,
            isDevice: nodeData.category === "Telematics Device",
          },
        );
        setIsSubmitLoading(false);
        toggleDeleteModal();
        showMessage("Connector Deleted Successfully", "success");
        setTimeout(() => {
          history.push("/connectorsPlayground");
        }, 1000);
      } catch (err) {
        setIsSubmitLoading(false);
        console.log("Error deleting connector:", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Failed to delete connector");
        }
      }
    }
  };

  const fetchDeviceName = (device) => {
    setDeviceName(device);
  };

  const buildConfigObject = (configs) => {
    const configObj = {};

    for (let item of configs) {
      configObj[item.name] =
        item.type === "topic"
          ? validateTopicConfig(item)
          : item.name === "alertDuration"
            ? validateAlertDurationConfig(item)
            : validatePortConfig(item);
    }
    let utilityGroup = {
      groupsAndRulesJson: JSON.stringify(groupsAndRules),
      uploadedJson: JSON.stringify(jsonValue),
    };

    const parsedObj = JSON.parse(expressionTextObj);
    configObj.fields = parsedObj?.fields ? parsedObj?.fields : "";
    configObj.type = parsedObj?.type ? parsedObj?.type : "";
    configObj.operator = parsedObj?.operator ? parsedObj?.operator : "";
    configObj.values = parsedObj?.values ? parsedObj?.values : "";
    configObj.logic = logic;

    configObj.utilityGroup = JSON.stringify(utilityGroup);

    console.log(configObj, "configObj===========");

    return configObj;
  };

  const validateAlertDurationConfig = (item) => {
    let val = item.value / 1000;
    if (item.isRequired && (val < 0 || val > 1800)) {
      throw {
        code: "INPUT_ERROR",
        message: `Please enter value for the field '${item.label}'`,
      };
    }
    return item.value;
  };

  const validatePortConfig = (item) => {
    if (item.isRequired && !item.value) {
      throw {
        code: "INPUT_ERROR",
        message: `Please enter value for the field '${item.label}'`,
      };
    }
    if (item.type === "port") {
      const portValue = parseInt(item.value);
      if (!(portValue >= item.min && portValue <= item.max)) {
        throw {
          code: "INPUT_ERROR",
          message: `Please enter port number between ${item.min} and ${item.max} range`,
        };
      }
    }
    return item.value;
  };

  // Helper functions to break down the complexity
  const validateTopicConfig = (item) => {
    if (item.isRequired && !item.value) {
      throw {
        code: "INPUT_ERROR",
        message: `Please select or create a topic for the field ${item.label} (${item.direction})`,
      };
    }
    return item.value;
  };

  const updateNodeTitle = (nodes, newTitle, configs) => {
    // Extract interfaces from configs where type is "topic"
    const interfaces = configs
      .filter((config) => config.type === "topic")
      .map((config) => ({
        name: config.value || "",
        direction: config.direction,
      }));

    return nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        title: newTitle,
        interfaces: interfaces, // Update interfaces
      },
    }));
  };

  const updateNodeInterfaces = (nodes, nodeData, configs) => {
    const selectedNodeInterface = configs
      .filter((item) => item.type === "topic")
      .map((item) => ({
        name: item.value,
        direction: item.direction,
      }));

    const tempNodes = [...nodes];
    const selectedNode = tempNodes.find(
      (node) => parseInt(node.id) === parseInt(nodeData.configId),
    );

    if (selectedNode) {
      selectedNode.data.interfaces = selectedNodeInterface;
      delete selectedNode.data.status;
    }

    return selectedNode;
  };

  const handleUpdateConfigNew = async () => {
    if (!saveLoading) {
      try {
        if (rulesError) {
          showMessage(
            "All rules fields must be filled/ field type should match the type of value",
            "error",
          );
        } else {
          setSaveLoading(true);
          let configsValues = updateAlertDuration(configs);

          // Build and validate configuration
          const configObj = buildConfigObject(configsValues);

          nodes = updateNodeTitle(nodes, configObj?.title, configsValues);
          // Update node interfaces
          const updatedNode = updateNodeInterfaces(
            nodes,
            nodeData,
            configsValues,
          );

          // Update configuration
          const updateData = {
            id: nodeData.configId,
            connectorName: configObj?.name,
            type: nodeData.category,
            config: configObj,
            workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId")),
          };

          await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "pipeline/updateConnectorConfigs",
            JSON.stringify(updateData),
          );

          // Deploy configuration
          const deployData = {
            workspaceId: parseInt(
              localStorage.getItem("selectedWorkspaceId") || 0,
            ),
            connectorId: nodeData.configId,
            isDevice: nodeData.category === "Telematics Device",
            newConnectorObject: updatedNode,
          };

          await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "pipeline/setTransformDeploymentNew",
            JSON.stringify(deployData),
          );

          setSaveLoading(false);
          window.location.reload();
        }
      } catch (err) {
        const errorMessages = {
          INPUT_ERROR: err.message,
          UPDATE_ERROR:
            err.message || "Failed to set the configuration of this connector",
          DEPLOY_ERROR:
            err.message ||
            "Failed to deploy the configuration of this connector",
          WORKSPACE_ERROR:
            err.message || "Failed to updated the workspace layout",
        };

        showMessage(
          errorMessages[err.code] ||
            "Failed to configure the selected connector",
        );
        setSaveLoading(false);
        console.log("Err in edit connector", err);
      }
    }
  };

  const updateAlertDuration = (data) => {
    return data.map((item) => {
      if (item.name === "alertDuration") {
        return { ...item, value: item.value * 1000 };
      }
      return item;
    });
  };

  const renderContent = () => {
    if (isUtilityConnector && (isAllTopicsLoading || isFieldsLoading)) {
      return (
        <div style={{ backgroundColor: "#171717" }}>
          <ParentContainer>
            <AnimationContainer>
              <ScrollAnimationBar />
              <LoadingAnimation
                src={loadingAnimation}
                alt="Loading Animation"
              />
            </AnimationContainer>
          </ParentContainer>
        </div>
      );
    } else {
      return (
        <div style={{ backgroundColor: "#171717" }}>
          <div style={{ height: isUtilityConnector ? "4.194rem" : "8.194rem" }}>
            <StyledRow style={{ padding: "1.112rem" }}>
              <Column xs={7} style={{ display: "flex", alignItems: "center" }}>
                <Icon
                  src={BackIcon}
                  alt="Back Icon"
                  style={{ width: "1.4rem", height: "0.972rem" }}
                  onClick={() => history.push("/connectorsPlayground")}
                />
                <Title>
                  {nodeData.title ? nodeData.title : "Your Connector"}
                </Title>
              </Column>
              {isUtilityConnector && editModal && (
                <Column
                  xs={5}
                  style={{
                    display: "flex",
                    gap: "1.112rem",
                    justifyContent: "flex-end",
                  }}
                >
                  {selectedTab === "connectorInfo" && (
                    <StyledButtonsecondary
                      onClick={toggleEditModal}
                      style={{ width: "14.167rem" }}
                      disabled={
                        nodeData.category === "Stream Connector" ||
                        nodeData.category === "Store Connector"
                      }
                    >
                      Cancel
                    </StyledButtonsecondary>
                  )}
                  <StyledButtonPrimary
                    style={{ width: "14.167rem" }}
                    onClick={handleUpdateConfigNew}
                    disabled={rulesError}
                  >
                    {saveLoading ? (
                      <CustomRow
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Spinner2
                          animation="border"
                          style={{ color: "#FFF" }}
                        />
                      </CustomRow>
                    ) : (
                      "Save Configurations"
                    )}
                  </StyledButtonPrimary>
                </Column>
              )}

              {!editModal && (
                <Column
                  xs={5}
                  style={{
                    display: "flex",
                    gap: "1.112rem",
                    justifyContent: "flex-end",
                  }}
                >
                  {selectedTab === "connectorInfo" && (
                    <StyledButtonsecondary
                      onClick={toggleEditModal}
                      style={{ width: "14.167rem" }}
                      disabled={
                        nodeData.category === "Stream Connector" ||
                        nodeData.category === "Store Connector"
                      }
                    >
                      Edit Configurations
                    </StyledButtonsecondary>
                  )}
                  <StyledDeleteButton
                    style={{ width: "14.167rem" }}
                    onClick={toggleDeleteModal}
                  >
                    Delete {isUtilityConnector ? "Utility" : "Connector"}
                  </StyledDeleteButton>
                </Column>
              )}
            </StyledRow>
            {isUtilityConnector ? (
              <></>
            ) : (
              <>
                <StyledRow>
                  <Column xs={1.5}>
                    <ConnectorInfoTab
                      onClick={() => handleTabChange("connectorInfo")}
                      isActive={selectedTab === "connectorInfo"}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <TabIcon
                        src={ConnectorIcon}
                        alt="img"
                        isActive={selectedTab === "connectorInfo"}
                      />
                      <TabName>Information</TabName>
                    </ConnectorInfoTab>
                    <div style={{ position: "relative" }}>
                      <TabLine isActive={selectedTab === "connectorInfo"} />
                      <Divider />
                    </div>
                  </Column>
                  {nodeData.category === "Telematics Device" && (
                    <Column xs={1.5}>
                      <ConnectorInfoTab
                        onClick={() => handleTabChange("additionalConfig")}
                        isActive={selectedTab === "additionalConfig"}
                      >
                        <TabIcon
                          src={AdditionIcon}
                          alt="img"
                          isActive={selectedTab === "additionalConfig"}
                        />
                        <TabName>Actions</TabName>
                      </ConnectorInfoTab>
                      <TabLine isActive={selectedTab === "additionalConfig"} />
                    </Column>
                  )}
                </StyledRow>
                <StyledRow></StyledRow>
              </>
            )}
          </div>
          <StyledRow
            style={{
              height: isUtilityConnector
                ? "calc(100vh - 7.25rem)"
                : "calc(100vh - 10.25rem)",
              overflow: "scroll",
            }}
          >
            {selectedTab === "connectorInfo" && !isUtilityConnector ? (
              <>
                <Column xs={5.2} style={{ padding: "1.112rem" }}>
                  <ConnectorInfoForm
                    nodeData={nodeData}
                    setConfigs={setConfigs}
                    fetchDeviceName={fetchDeviceName}
                  />
                </Column>
                <Column
                  xs={6.8}
                  style={{ padding: "1.112rem 1.112rem 1.112rem 0rem" }}
                >
                  <ConnectorLogs
                    isLogsExpanded={isLogsExpanded}
                    setIsLogsExpanded={setIsLogsExpanded}
                    nodeData={nodeData}
                  />
                </Column>
              </>
            ) : (
              <>
                <ConnectorUtilityInfoForm
                  key={formKey}
                  nodeData={nodeData}
                  setConfigs={setConfigs}
                  fetchDeviceName={fetchDeviceName}
                  allTopics={allTopics}
                  setAllTopics={setAllTopics}
                  editModal={editModal}
                  configs={configs}
                  setJsonValue={setJsonValue}
                  jsonValue={jsonValue}
                  setGroupsAndRules={setGroupsAndRules}
                  groupsAndRules={groupsAndRules}
                  fieldOptionValues={fieldOptionValues}
                  setFieldOptionValues={setFieldOptionValues}
                  isGroupError={isGroupError}
                  setIsGroupError={setIsGroupError}
                  groupBy={groupBy}
                  setGroupBy={setGroupBy}
                  rulesError={rulesError}
                  setRulesError={setRulesError}
                  isAllTopicsLoading={isAllTopicsLoading}
                  setIsAllTopicsLoading={setIsAllTopicsLoading}
                  expressionTextObj={expressionTextObj}
                  setExpressionTextObj={setExpressionTextObj}
                  logic={logic}
                  setLogic={setLogic}
                  setIsFieldsLoading={setIsFieldsLoading}
                  isFieldsLoading={isFieldsLoading}
                  configResponseData={configResponseData}
                />
              </>
            )}
            {selectedTab === "additionalConfig" && (
              <CampaignPageContainer deviceName={deviceName} />
            )}
          </StyledRow>
          {editModal && !isUtilityConnector && (
            <EditConfigurationModal
              toggleEditModal={toggleEditModal}
              isModelOpen={editModal}
              configs={configs}
              setConfigs={setConfigs}
              allTopics={allTopics}
              setAllTopics={setAllTopics}
              nodeData={nodeData}
              edges={edges}
              nodes={nodes}
            />
          )}
          {showDeleteModal && (
            <GenericModal
              show={showDeleteModal}
              onClose={toggleDeleteModal}
              title={`Are you sure you want to delete the ${nodeData.title}?`}
            >
              <Row noGutters>
                <Col xs={12}>
                  <ModalDescription>
                    {`If you delete ${nodeData.title}, you might break the flow of data in the pipeline and might cause problems in your deployed applications.`}
                  </ModalDescription>
                </Col>
              </Row>
              <Row
                noGutters
                style={{
                  display: "flex",
                  gap: "1.112rem",
                  justifyContent: "flex-end",
                  marginTop: "6.1805rem",
                }}
              >
                <StyledButtonsecondary
                  onClick={toggleDeleteModal}
                  style={{ width: "14.167rem" }}
                  disabled={isSubmitLoading}
                >
                  Cancel
                </StyledButtonsecondary>
                <StyledDeleteButton
                  style={{ width: "14.167rem" }}
                  onClick={handleDeleteConnector}
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? (
                    <CustomRow
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner2 animation="border" style={{ color: "#FFF" }} />
                    </CustomRow>
                  ) : (
                    "Delete Connector"
                  )}
                </StyledDeleteButton>
              </Row>
            </GenericModal>
          )}
        </div>
      );
    }
  };
  return <>{renderContent()}</>;
}
