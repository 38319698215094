import { React, useState, useRef, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import UploadIcon from "../../assets/images/UploadIcon.svg";
import BulkCancel from "../../assets/images/bulkCancel.svg";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import CustomRow from "../../components/common/_customRow";
import FileUploaded from "../../assets/images/FileUploaded.svg";
import Download from "../../assets/images/download icon C2D.svg";
import * as XLSX from "xlsx";
import { decryptData } from "../../utils";
import { env } from "../../env";
import PropTypes from "prop-types";

const BulkUpload = styled.div`
  width: 97.5%;
  height: 97%;
  border: 0.069rem
    ${(props) => {
      if (props.uploadStatus === "progress") {
        return "dashed #47ccd6";
      } else if (props.uploadStatus === "uploaded") {
        return "solid #47ccd6";
      } else if (props.uploadStatus === "error") {
        return "dashed #ea3a3a";
      } else if (props.uploadStatus === "partialupload") {
        return "solid #77777";
      }
      return "dashed #b0b0b0";
    }};
  background-color: ${(props) => {
    if (props.uploadStatus === "progress") {
      return "#47ccd614";
    } else if (props.uploadStatus === "uploaded") {
      return "#47ccd614";
    } else if (props.uploadStatus === "error") {
      return "#ea3a3a14";
    }
    return "none";
  }};
  border-radius: 0.55rem;
`;

const UploadTextArea = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ClickText = styled.div`
  color: #47ccd6;
  text-decoration: underline;
  cursor: pointer;
`;

const UploadImage = styled.img`
  height: 2.77rem;
  width: 2.77rem;
`;

const UploadText = styled.div`
  color: #b0b0b0;
  font-size: 1.112rem;
  width: 23.8rem;
  padding-top: 0.84rem;
  line-height: 1.44rem;
  text-align: center;
`;

const FileNameText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.44rem;
  text-align: left;
  color: #f6f6f6;
`;

const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.277rem;
`;

const FileName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.97rem;
  width: 91.5%;
`;

const FileSize = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  font-size: 0.97rem;
  line-height: 1.44rem;
  text-align: left;
  color: #b0b0b0;
  opacity: 0.7;
`;

const CancelIcon = styled.img`
  height: 1.11rem;
  width: 1.11rem;
  margin-right: 0.97rem;
  cursor: pointer;
  &:hover {
    filter: brightness(0) saturate(100%) invert(31%) sepia(89%) saturate(5088%)
      hue-rotate(335deg) brightness(92%) contrast(97%);
  }
`;

const DownloadIcon = styled.img`
  height: 1.11rem;
  width: 1.3rem;
  cursor: pointer;
  margin-left: 3.5rem;
`;

const ProgressPercent = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  font-size: 0.97rem;
  color: #f6f6f6;
  opacity: 0.7;
  width: 5%;
`;

const ProgressBar = styled.div`
  width: 92%;
  height: 0.41rem;
  background-color: #5e5e5e;
  border-radius: 0.138rem;
  position: relative;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: #47ccd6;
  border-radius: 0.138rem;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease;
`;

const UploadedFileIcon = styled.img`
  height: 2.77rem;
  width: 2.77rem;
`;

const EntryContainer = styled.div`
  width: 49.3%;
  display: flex;
  align-items: center;
  height: 3.34rem;
  border: 0.069rem solid var(--Primary-Accent, #47ccd6);
  background-color: #47ccd614;
  border-radius: 0.55rem;
  padding-left: 1.12rem;
`;

const EntryCount = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  font-size: 1.66rem;
  color: #f6f6f6;
  width: 3.125rem;
`;

const EntryText = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  font-size: 0.97rem;
  color: var(--grey-2, #b0b0b0);
`;

const CampaignBulkUpload = ({
  campaignName,
  commands,
  expiryDate,
  createdBy,
  onUploadSuccess,
}) => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [failedList, setFailedList] = useState([]);
  const { showMessage } = useContext(SnackbarContext);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 30 * 1024 * 1024) {
        showMessage("File size exceeds 30MB limit", "failed");
        return;
      }

      const validExtensions = [".xls", ".xlsx"];
      const fileExtension = file.name
        .toLowerCase()
        .slice(file.name.lastIndexOf("."));

      if (!validExtensions.includes(fileExtension)) {
        showMessage("Please upload only Excel files (.xls or .xlsx)", "failed");
        return;
      }

      setSelectedFile(file);
      setUploadStatus("progress");
      setUploadProgress(0);

      try {
        const formData = new FormData();
        const workspaceId = localStorage.getItem("selectedWorkspaceId");
        formData.append("file", file);
        formData.append("campaignName", campaignName);
        formData.append("expiryDate", expiryDate);
        formData.append("command", commands);
        formData.append("createdBy", createdBy);
        formData.append("workspaceId", workspaceId);

        const response = await axios.post(
          `${env.REACT_APP_URL}command/createBulkCampaign`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              setUploadProgress(progress);
            },
          },
        );

        let responseData = decryptData(response?.data);
        if (response.status === 200) {
          if (responseData?.data?.failedList?.length > 0) {
            setUploadStatus("partialupload");
            setSuccessCount(responseData.data.successCount);
            setFailedCount(responseData.data.failedList.length);
            setFailedList(responseData.data.failedList);
            setUploadProgress(100);
            showMessage("Bulk campaign created with some failures", "warning");
          } else {
            setTotalEntries(responseData.data.totalCount);
            setUploadStatus("uploaded");
            setUploadProgress(100);
            onUploadSuccess();
            showMessage("Bulk campaign created successfully", "success");
          }
        } else {
          setUploadStatus("error");
          setUploadProgress(0);
          showMessage(responseData.data.remarks, "failed");
        }
      } catch (error) {
        let decryptedError = decryptData(error.response?.data);
        setUploadStatus("error");
        setUploadProgress(0);
        showMessage(
          decryptedError?.remarks || "Failed to create campaign",
          "failed",
        );
      }
    }
  };

  const handleDownloadFailedList = () => {
    try {
      const dataForExcel = failedList.map((item) => ({
        IMEI: item.imei,
        Remarks: item.remarks,
      }));
      const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Failed Entries");
      const fileName = `${campaignName}.xlsx`;
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error downloading failed list:", error);
      showMessage("Failed to download failed entries", "failed");
    }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    fileInputRef.current.click();
  };

  const handleCancelUpload = () => {
    setSelectedFile(null);
    setUploadStatus("");
    setUploadProgress(0);
  };

  return (
    <BulkUpload uploadStatus={uploadStatus} selectedFile={selectedFile}>
      {uploadStatus === "progress" ? (
        <UploadTextArea hasFile>
          <FileName>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "0.97rem" }}>
                <UploadImage src={UploadIcon} alt="Upload Icon" />
                <FileDetails>
                  <FileNameText>{selectedFile.name}</FileNameText>
                  <FileSize>{Math.floor(selectedFile.size / 1024)} MB</FileSize>
                </FileDetails>
              </div>
              <CancelIcon
                src={BulkCancel}
                alt="Cancel upload"
                onClick={handleCancelUpload}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ProgressBar>
                <ProgressFill progress={uploadProgress} />
              </ProgressBar>
              <ProgressPercent>{uploadProgress}%</ProgressPercent>
            </div>
          </FileName>
        </UploadTextArea>
      ) : uploadStatus === "uploaded" ? (
        <UploadTextArea>
          <UploadedFileIcon src={FileUploaded} alt="" />
          <FileNameText style={{ paddingTop: "1.5rem" }}>
            File Uploaded!
          </FileNameText>
          <FileSize
            style={{
              paddingTop: "0.55rem",
              width: "23.47rem",
              textAlign: "center",
              opacity: "1",
            }}
          >
            Your file was uploaded successfully with{" "}
            <span style={{ color: "#47ccd6" }}>{totalEntries}</span> entries and
            no duplicates or errors found.
          </FileSize>
        </UploadTextArea>
      ) : uploadStatus === "error" ? (
        <UploadTextArea>
          <FileName>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "0.97rem" }}>
                <UploadImage src={UploadIcon} alt="Upload Icon" />
                <FileDetails>
                  <FileNameText>{selectedFile.name}</FileNameText>
                  <FileSize>---</FileSize>
                </FileDetails>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ProgressBar style={{ backgroundColor: "#ea3a3a" }} />
              <ProgressPercent
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={handleCancelUpload}
              >
                Retry
              </ProgressPercent>
            </div>
          </FileName>
        </UploadTextArea>
      ) : uploadStatus === "partialupload" ? (
        <>
          <CustomRow>
            <EntryContainer>
              <EntryCount>{successCount}</EntryCount>
              <EntryText>Entries Uploaded Correctly</EntryText>
              <CancelIcon
                src={BulkCancel}
                alt="Cancel upload"
                style={{ marginLeft: "3.8rem" }}
              />
            </EntryContainer>
            <EntryContainer
              style={{
                border: "0.069rem solid #ea3a3a",
                backgroundColor: "#ea3a3a14",
              }}
            >
              <EntryCount>{failedCount}</EntryCount>
              <EntryText>Incorrect Entries Found</EntryText>
              <DownloadIcon
                src={Download}
                alt="download"
                onClick={handleDownloadFailedList}
              />
              <CancelIcon
                src={BulkCancel}
                alt="Cancel upload"
                style={{ marginLeft: "1rem" }}
              />
            </EntryContainer>
          </CustomRow>
          <UploadTextArea
            style={{
              marginTop: "1.12rem",
              height: "12rem",
              border: "0.069rem dashed #b0b0b0",
              borderRadius: "0.55rem",
            }}
          >
            <UploadImage src={UploadIcon} alt="Upload Icon" />
            <UploadText>
              <ClickText onClick={handleClick}>Click to upload</ClickText> or
              drag and drop your file .XLS (max 30MB)
            </UploadText>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept=".xls,.xlsx"
              style={{ display: "none" }}
            />
          </UploadTextArea>
        </>
      ) : (
        <UploadTextArea>
          <UploadImage src={UploadIcon} alt="Upload Icon" />
          <UploadText>
            <ClickText onClick={handleClick}>Click to upload</ClickText> or drag
            and drop your file .XLS (max 30MB)
          </UploadText>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".xls,.xlsx"
            style={{ display: "none" }}
          />
        </UploadTextArea>
      )}
    </BulkUpload>
  );
};

CampaignBulkUpload.propTypes = {
  campaignName: PropTypes.string.isRequired,
  commands: PropTypes.string.isRequired,
  expiryDate: PropTypes.string.isRequired,
  createdBy: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
};
export default CampaignBulkUpload;
