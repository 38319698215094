import { React, useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Column from "../common/_customColumn";
import { ReactComponent as ErrorIcon } from "../../assets/images/node_status_error_icon.svg";
import { ReactComponent as InspectIcon } from "../../assets/images/node_status_inspect_icon.svg";
import { ReactComponent as RunningIcon } from "../../assets/images/node_status_running_icon.svg";
import ConnectorInfoIcon from "../../assets/images/Frame 1000002437.svg";
import MemoryUtilizationIcon from "../../assets/images/MemoryUtilization.svg";
import ConnectorConfigIcon from "../../assets/images/Details.svg";
import CustomRow from "../common/_customRow";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import PropTypes from "prop-types";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { ReactComponent as CopyContentIcon } from "../../assets/images/copy_content_icon.svg";
import Tooltip from "../common/_tooltip";
import FlexContainer from "../../components/common/_flexContainer";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableData,
  TableRow,
  TableWrapper,
} from "../common/_table";

const StyledCopyIcon = styled(CopyContentIcon)`
  height: 1.2rem;
  width: 1.3rem;
  cursor: pointer;
  margin-bottom: 0.277rem;
  color: #777777;
  margin-left: 0.555rem;
`;

const StyledInspectIcon = styled(InspectIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;

const StyledRunningIcon = styled(RunningIcon)`
  height: 1.111rem;
  width: 1.111rem;
  & path {
    stroke: ${(props) => getCategoryColor(props.category)};
  }
`;

const ConnectorForm = styled.div`
  height: 100%;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  overflow-y: auto;
`;
const ConnectorInfo = styled.div`
  padding: 1.112rem 1.112rem 1.67rem 1.112rem;
`;
const ConnectorInput = styled.div`
  padding: 1.67rem 1.112rem 1.112rem 1.112rem;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.39rem;
  color: #fafafa;
  padding-left: 0.84rem;
`;

const Label = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0px;
  font-size: 1.112rem;
  color: #777777;
  padding-top: 1.67rem;
`;

const Value = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.25rem;
  color: #f6f6f6;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const InputLabel = styled.div`
  font-family: "Articulat CF Normal";
  padding-top: 1.112rem;
  font-size: 0.972rem;
  font-weight: bold;
  color: var(--grey-2, #b0b0b0);
  letter-spacing: 0.5px;
`;

const Spinner = styled.div`
  border: 0.138rem solid rgba(76, 74, 74, 0.1);
  border-top: 0.138rem solid ${(props) => getCategoryColor(props.category)};
  border-radius: 50%;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.277rem;
  animation: ${spin} 0.7s linear infinite;
`;

const InputContent = styled.div`
  font-family: "Articulat CF Medium";
  display: flex;
  flex-direction: column;
  gap: 0.84rem;
  font-size: 0.972rem;
  font-weight: bold;
  padding: 0.277rem;
`;

const ConnectorInformation = styled.div`
  display: flex;
`;
const ConnectorIcon = styled.img``;

const InputField = styled.input`
  padding: 1.112rem 0.84rem;
  border-radius: 0.84rem;
  border: 0.138rem solid #b0b0b0;
  background-color: #2f2f2f;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.972rem;
  line-height: 1.0833rem;
  color: #fafafa;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const NodeStatusContainer = styled.div`
  border: 0.104rem solid #f6f6f6;
  padding: 0.277rem 0.555rem;
  border-radius: 0.277rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.Running {
    background: ${(props) => getBackgroundColor(props.category)};
    border-color: ${(props) => getCategoryColor(props.category)};
  }

  &.Inspect {
    background: #edb62e29;
    border-color: #edb62e;
  }

  &.Error {
    background: #ea3a3a29;
    border-color: #ea3a3a;
  }
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NodeStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.555rem;
`;

const rowStyle = {
  borderBottom: "0.0694rem solid #9090903D",
};

const cellStyle = {
  padding: "1.3888rem",
  color: "#f3f4f6",
  fontSize: "0.9722",
  fontWeight: "bold",
};

const headerRowStyle = {
  width: "100%",
  backgroundColor: "#5E5E5E80",
};

const headerCellStyle = {
  padding: "1.0416rem 0rem 1.0416rem 1.3888rem",
  textAlign: "left",
  color: "#9ca3af",
  fontSize: "1.25rem",
  fontWeight: "400",
};

const getCategoryColor = (category) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B";
    case "Store Connector":
      return "#B5E3CE";
    case "Application":
      return "#EB6F95";
    case "Telematics Device":
      return "#F7C0D5";
    default:
      return "#EB6F95";
  }
};

const getBackgroundColor = (category) => {
  switch (category) {
    case "Stream Connector":
      return "#6DC89B29";
    case "Store Connector":
      return "#B5E3CE29";
    case "Application":
      return "#EB6F9529";
    case "Telematics Device":
      return "#F7C0D529";
    default:
      return "#EB6F9529";
  }
};

const RunningStatus = ({ category }) => {
  return (
    <NodeStatusDiv>
      <StyledRunningIcon category={category} />
      <Text
        style={{
          fontSize: "0.972rem",
          color: getCategoryColor(category),
          fontFamily: "Articulat CF Normal",
          fontWeight: "bold",
          letterSpacing: "0.5px",
        }}
      >
        Running
      </Text>
    </NodeStatusDiv>
  );
};

const InspectStatus = () => (
  <NodeStatusDiv>
    <StyledInspectIcon />
    <Text
      style={{
        fontSize: "0.972rem",
        color: "#EDB62E",
        lineHeight: "1",
        fontFamily: "Articulat CF Normal",
        fontWeight: "bold",
        letterSpacing: "0.5px",
      }}
    >
      Inspect
    </Text>
  </NodeStatusDiv>
);

const ErrorStatus = () => (
  <NodeStatusDiv>
    <StyledErrorIcon />
    <Text
      style={{
        fontSize: "0.972rem",
        color: "#EA3A3A",
        lineHeight: "1",
        fontFamily: "Articulat CF Normal",
        fontWeight: "bold",
        letterSpacing: "0.5px",
      }}
    >
      Error
    </Text>
  </NodeStatusDiv>
);

const displayNodeStatus = (status, category) => {
  switch (status) {
    case "Running":
      return <RunningStatus category={category} />;
    case "Inspect":
      return <InspectStatus />;
    case "Error":
      return <ErrorStatus />;
    default:
      return <Spinner category={category} />;
  }
};

const ConnectorInfoForm = ({ nodeData, setConfigs, fetchDeviceName }) => {
  const { showMessage } = useContext(SnackbarContext);
  const [nodeStatus, setNodeStatus] = useState("");
  const [configValues, setConfigValues] = useState(null);
  const [servicePort, setServicePort] = useState(null);
  const [serviceDns, setServiceDns] = useState(null);
  const [ingressPath, setIngressPath] = useState(null);
  const [allPods, setAllPods] = useState([]);

  const handleUsernameCopy = () => {
    navigator.clipboard.writeText(ingressPath);
    showMessage("Ingress Path copied to clipboard", "success");
  };
  // As connectorId is mapped to the configId  it's to be changes in backend we have to pass configId not connectorId

  useEffect(() => {
    const fetchResourceUtilization = async () => {
      try {
        const connectorId = nodeData.configId;
        let stringifyData = {
          connectorId: connectorId,
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
        };
        let response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getResourceUtilization",
          stringifyData,
        );

        if (response?.data?.data && response?.data?.data.length) {
          setAllPods(response.data.data);
        }
      } catch (err) {
        console.error("Error: getResourceUtilization", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Unable to obtain resource utilization for this deployment !!!",
          );
        }
      }
    };

    const getNodeStatus = async () => {
      try {
        let nodeStatusResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getNodeStatusNew",
          {
            ids: [nodeData.configId],
            workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId")),
          },
        );
        setNodeStatus(nodeStatusResponse.data.data[0].status || "");
      } catch (err) {
        console.log("Error: getNodeStatusNew", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to obtain deployment status !!!");
        }
      }
    };

    const fetchConnectorConfigValues = async () => {
      try {
        let stringifiedData = {
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
          connectorId: nodeData.configId,
          connectorCategory: nodeData.category,
        };
        let configResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getDeployedConnectorConfigs",
          stringifiedData,
        );
        setConfigValues(
          configResponse.data?.data?.configJsonData
            ? JSON.parse(configResponse.data.data.configJsonData)
            : [],
        );
        if (configResponse.data?.data?.publicDns) {
          setServiceDns(configResponse.data.data.publicDns);
        }
        if (configResponse.data?.data?.port) {
          setServicePort(configResponse.data.data.port);
        }

        if (configResponse.data?.data?.ingressPath) {
          setIngressPath(configResponse.data.data.ingressPath);
        }

        if (configResponse.data?.data?.name) {
          fetchDeviceName(configResponse.data.data.name);
        }
        setConfigs(
          configResponse.data?.data?.configJsonData
            ? JSON.parse(configResponse.data.data.configJsonData).map(
                (obj) => ({
                  ...obj,
                  ...(obj.type === "topic"
                    ? {
                        value: {
                          name: obj.value,
                        },
                      }
                    : { value: obj.value }),
                }),
              )
            : [],
        );
      } catch (err) {
        console.log("Error in fetchConnectorConfigValues", err);
      }
    };

    fetchResourceUtilization();
    getNodeStatus();
    fetchConnectorConfigValues();

    const intervalId = setInterval(() => {
      fetchResourceUtilization();
      getNodeStatus();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [nodeData.category, nodeData.configId, setConfigs, showMessage]);

  const maskValue = (value) => {
    try {
      return "*".repeat(value.length);
    } catch (error) {
      return "";
    }
  };

  const renderConfigurationContent = () => {
    if (!configValues) {
      return (
        <Text
          style={{
            fontSize: "1.39rem",
            color: "#f6f6f6",
            fontFamily: "Articulat CF Normal",
            fontWeight: "bold",
            letterSpacing: "0.5px",
          }}
        >
          Loading Configurations
        </Text>
      );
    }

    if (configValues.length === 0) {
      return (
        <Text
          style={{
            fontSize: "1.39rem",
            color: "#f6f6f6",
            fontFamily: "Articulat CF Normal",
            fontWeight: "bold",
            letterSpacing: "0.5px",
          }}
        >
          No Configurations
        </Text>
      );
    }

    return configValues.map((config) => (
      <Column md={12} lg={12} xl={5.8} key={config.name}>
        <InputContent>
          <InputLabel>{config.label}</InputLabel>
          <InputField
            placeholder={config.placeholder}
            value={
              config.type === "password"
                ? maskValue(config?.value)
                : config?.value
            }
            readOnly={true}
          />
        </InputContent>
      </Column>
    ));
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("podName", {
      header: () => "Replica",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("memoryUsage", {
      header: () => "Memory Utilization",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("cpuUsage", {
      header: () => "CPU Utilization",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: allPods,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <ConnectorForm>
      <ConnectorInfo>
        <StyledRow>
          <Column
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ConnectorInformation>
              <ConnectorIcon
                style={{ width: "2.225rem", height: "2.225rem" }}
                src={ConnectorInfoIcon}
              ></ConnectorIcon>
              <Title>Connector Information</Title>
            </ConnectorInformation>
            <NodeStatusContainer
              className={nodeStatus || "Running"}
              category={nodeData.category}
            >
              {displayNodeStatus(nodeStatus, nodeData.category)}
            </NodeStatusContainer>
          </Column>
        </StyledRow>
        <CustomRow justifyContent="normal" style={{ gap: "1.111rem" }}>
          <Column
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(33.33% - 0.740rem)",
            }}
          >
            <Label>Connector Name</Label>
            <Value>{nodeData.name}</Value>
          </Column>
          <Column
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(33.33% - 0.740rem)",
            }}
          >
            <Label>Connector Category</Label>
            <Value>{nodeData.category}</Value>
          </Column>
          <Column
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(33.33% - 0.740rem)",
            }}
          >
            <Label>Connector Type</Label>
            <Value>{nodeData.connectionType}</Value>
          </Column>
          {servicePort && (
            <Column
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(33.33% - 0.740rem)",
              }}
            >
              <Label>Service Port</Label>
              <Value>{servicePort}</Value>
            </Column>
          )}
          {serviceDns && (
            <Column xs={12}>
              <Label>Service DNS</Label>
              <Value>{serviceDns}</Value>
            </Column>
          )}
          {ingressPath && (
            <Column
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(33.33% - 0.740rem)",
              }}
            >
              <Label>
                Ingress Path <StyledCopyIcon onClick={handleUsernameCopy} />
              </Label>
              <Value>
                <FlexContainer $width="calc(100% - 0.277rem)" $gap="0.277rem">
                  <Tooltip title={ingressPath} placement="bottom">
                    <Text
                      $maxWidth="min-content"
                      $fontWeight="bold"
                      $fontSize="1.111rem"
                      $noWrap
                    >
                      {ingressPath}
                    </Text>
                  </Tooltip>
                </FlexContainer>
              </Value>
            </Column>
          )}
        </CustomRow>
      </ConnectorInfo>
      <div
        style={{
          height: "0.069rem",
          backgroundColor: "#5e5e5e",
          marginRight: "0",
        }}
      ></div>
      <ConnectorInfo>
        <StyledRow>
          <Column
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1.5rem",
            }}
          >
            <ConnectorInformation>
              <ConnectorIcon
                style={{ width: "2.225rem", height: "2.225rem" }}
                src={MemoryUtilizationIcon}
              ></ConnectorIcon>
              <Title>Resource Utilization</Title>
            </ConnectorInformation>
          </Column>
        </StyledRow>
        {table.getRowModel().rows.length ? (
          <TableWrapper $maxHeight="30.372rem">
            <Table style={{ width: "100%" }}>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} style={headerRowStyle}>
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        style={{
                          ...headerCellStyle,
                          borderTopLeftRadius:
                            index === 0 ? "0.8333rem" : "0rem",
                          borderBottomLeftRadius:
                            index === 0 ? "0.8333rem" : "0rem",
                          borderTopRightRadius:
                            index === headerGroup.headers.length - 1
                              ? "0.8333rem"
                              : "0rem",
                          borderBottomRightRadius:
                            index === headerGroup.headers.length - 1
                              ? "0.8333rem"
                              : "0rem",
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} style={rowStyle}>
                    {row.getVisibleCells().map((cell) => (
                      <TableData key={cell.id} style={cellStyle}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableData>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        ) : (
          <>
            <Text $width="100%" $textAlign="center" style={{ color: "white" }}>
              No Replicas Found
            </Text>
          </>
        )}
      </ConnectorInfo>
      <div
        style={{
          height: "0.069rem",
          backgroundColor: "#5e5e5e",
          marginRight: "0",
        }}
      ></div>
      <ConnectorInput>
        <StyledRow>
          <Column xs={12} style={{ display: "flex" }}>
            <ConnectorIcon
              style={{ width: "2.225rem", height: "2.225rem" }}
              src={ConnectorConfigIcon}
            ></ConnectorIcon>
            <Title>Connector Configurations</Title>
          </Column>
        </StyledRow>
        <CustomRow>{renderConfigurationContent()}</CustomRow>
      </ConnectorInput>
    </ConnectorForm>
  );
};

ConnectorInfoForm.propTypes = {
  nodeData: PropTypes.shape({
    configId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.oneOf([
      "Stream Connector",
      "Store Connector",
      "Application",
      "Telematics Device",
    ]).isRequired,
    connectionType: PropTypes.string.isRequired,
  }).isRequired,
  setConfigs: PropTypes.func.isRequired,
  fetchDeviceName: PropTypes.func.isRequired,
};

RunningStatus.propTypes = {
  category: PropTypes.oneOf([
    "Stream Connector",
    "Store Connector",
    "Application",
    "Telematics Device",
  ]).isRequired,
};

export default ConnectorInfoForm;
