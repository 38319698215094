import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import styled from "styled-components";
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
} from "react-flow-renderer";
import DragIcon from "../../assets/images/DragIcon.svg";
import Delete from "../../assets/images/DeleteUpdated.svg";
import Add from "../../assets/images/AddRuleIcon.svg";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import CustomDropdown from "./CustomDropDown";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

const ValueTextField = styled.span`
  display: flex;
  flex-flow: column;
  width: 48%;
  margin-bottom: 1rem;
`;
const TextField = styled.input`
  flex: 1 1 0%;
  padding: 0.8rem;
  font-size: 1.12rem;
  color: rgb(255, 255, 255);
  background-color: #181818;
  border: none;
  border-radius: 0.5rem;
  overflow-x: auto;
  white-space: nowrap;
  outline: none;
`;

const Label = styled.label`
  color: #b3b3b3;
  font-size: 0.972rem;
`;

const Container = styled.div`
  height: 28.027rem;
  width: 64.722rem;
`;

const GroupContainer = styled.div`
  width: 27.083rem;
  height: 28.027rem;
  border-right: 0.07rem solid #4b4b4b;
  padding: 1.112rem;
  overflow: auto;

  .react-flow__viewport {
    min-width: 100%;
    min-height: 100%;
    position: relative;
  }
  .react-flow__renderer {
    transform: translateY(-75px);
    /* overflow: auto; */
  }
`;

const ReactFlowWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const FeildsContainer = styled.div`
  width: 37.638rem;
  height: 28.1rem;
  padding: 1.112rem;
`;

const Text = styled.div`
  font-size: 0.84rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  color: #b0b0b0;
  margin-bottom: 0.6rem;
`;

const initialNodes = [
  {
    id: "parent-1",
    data: {
      label: "Group 1",
    },
    position: { x: 300, y: -250 },
    sourcePosition: "left",
    targetPosition: "left",
    draggable: false,
    style: {
      backgroundColor: "#0D0D0D",
      height: "6.2rem",
      width: "39rem",
      borderRadius: "0.84rem",
      color: "#B0B0B0",
      fontFamily: "Articulat CF Medium",
      fontWeight: "bold",
      fontSize: "1.88rem",
      paddingTop: "0.3rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  {
    id: "parent-1-add-rule",
    data: {
      label: <div className="add-rule">Add Rule</div>,
    },
    position: { x: 550, y: -100 },
    sourcePosition: "left",
    targetPosition: "left",
    draggable: true,
    className: "add-rule",
    style: {
      backgroundColor: "transparent",
      height: "6.2rem",
      width: "29rem",
      borderRadius: "0.84rem",
      color: "#B0B0B0",
      fontFamily: "Articulat CF Medium",
      fontWeight: "bold",
      fontSize: "1.88rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "4px dashed #666",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
    },
  },
  {
    id: "add-group-button",
    data: {
      label: (
        <div className="add-group" style={{ marginRight: "12rem" }}>
          {" "}
          <img
            style={{
              height: "2.67rem",
              width: "2.67rem",
              cursor: "pointer",
              marginRight: "10rem",
            }}
            src={Add}
            alt="Add"
          />
          Add Group
        </div>
      ),
    },
    position: { x: 300, y: 50 },
    sourcePosition: "left",
    targetPosition: "left",
    draggable: false,
    className: "add-group",
    style: {
      backgroundColor: "transparent",
      height: "6.2rem",
      width: "39rem",
      borderRadius: "0.84rem",
      color: "#B0B0B0",
      fontFamily: "Articulat CF Medium",
      fontWeight: "bold",
      fontSize: "1.88rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "4px dashed #666",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
    },
  },
];

const initialEdges = [
  {
    id: "eparent-1-parent-1-child-1",
    source: "parent-1",
    target: "parent-1-child-1",
    type: "smoothstep",
    style: { stroke: "#47CCD6", strokeWidth: 2 },
  },
  {
    id: "eparent-1-add-rule",
    source: "parent-1",
    target: "parent-1-add-rule",
    type: "smoothstep",
    style: { stroke: "#47CCD6", strokeWidth: 2 },
  },
  {
    id: "eparent-1-add-group-button",
    source: "parent-1",
    target: "add-group-button",
    type: "smoothstep",
    style: { stroke: "#47CCD6", strokeWidth: 2 },
  },
];

const BOUNDARY = { xMin: 0, xMax: 100, yMin: 0, yMax: 100 };

const isWithinBoundary = (position) => {
  return (
    position.x >= BOUNDARY.xMin &&
    position.x <= BOUNDARY.xMax &&
    position.y >= BOUNDARY.yMin &&
    position.y <= BOUNDARY.yMax
  );
};

export const ConfigurationComponent = ({
  previewFunction,
  handlePreview,
  kafkaLastMessage,
  isReadTopic,
  jsonValue,
  setRulesError,
  onAddParent,
  // updateRuleConfigurationNew,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [parentCount, setParentCount] = useState(1);
  const [selectedParentId, setSelectedParentId] = useState("parent-1");
  const [selectedField, setSelectedField] = useState("");
  const [selectedFieldType, setSelectedFieldType] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueTemp, setSelectedValueTemp] = useState("");
  const [hasRules, setHasRules] = useState(false);
  const [selectedFieldValue, setSelectedFieldValue] = useState("");
  const [ruleConfigurations, setRuleConfigurations] = useState({});

  const [fieldTypeOptions, setFieldTypeOptions] = useState([]);
  const [conditionTypeOptions, setConditionTypeOptions] = useState([
    { label: "Equals to", value: "equals" },
    { label: "Not equals to", value: "not_equals" },
    { label: "Greater than", value: "greater_than" },
    { label: "Greater than or equal to", value: "greater_than_or_equals" },
    { label: "Less than", value: "less_than" },
    { label: "Less than or equal to", value: "less_than_or_equals" },
    { label: "Contains", value: "contains" },
    { label: "Starts with", value: "starts_with" },
    { label: "Ends with", value: "ends_with" },
    { label: "Between", value: "between" },
    { label: "Matches", value: "matches" },
    { label: "In", value: "in" },
  ]);
  const [valueOptions, setValueOptions] = useState([]);
  const [edgeOperators, setEdgeOperators] = useState({});
  const [selectedRuleId, setSelectedRuleId] = useState(null);
  const [ruleConfigs, setRuleConfigs] = useState({});
  const [groupConfigurations, setGroupConfigurations] = useState({
    group1: {
      rules: {},
      operator: "AND",
      // operator: {},
      ruleRelationships: {},
    },
  });

  const [groupConfigurationsTemp, setGroupConfigurationsTemp] = useState("");

  const [jsonExpression, setJsonExpression] = useState(null);

  const [groupRelationships, setGroupRelationships] = useState({});
  const conditionTypeValues = [
    { label: "Between", value: "between" },
    { label: "Equals to", value: "equals" },
    { label: "Not equals to", value: "not_equals" },
    { label: "Greater than", value: "greater_than" },
    { label: "Less than", value: "less_than" },
    { label: "Greater than or equal to", value: "greater_than_or_equals" },
    { label: "Less than or equal to", value: "less_than_or_equals" },
    { label: "Contains", value: "contains" },
    { label: "Starts with", value: "starts_with" },
    { label: "Ends with", value: "ends_with" },
    { label: "Between", value: "between" },
    { label: "Matches", value: "matches" },
    { label: "In", value: "in" },
  ];

  const handleAddParent = () => {
    handleAddParentNode();
  };

  useEffect(() => {
    onAddParent(handleAddParent);
  }, []);

  useEffect(() => {
    setSelectedCondition(selectedCondition);
    validateAndSetValue(selectedValueTemp);
  }, [selectedCondition]);

  useEffect(() => {
    validateAndSetValue(selectedValueTemp);
  }, [selectedValueTemp]);

  useEffect(() => {
    setSelectedFieldType(selectedFieldType);
    validateAndSetValue(selectedValueTemp);
  }, [selectedFieldType]);

  const inputRef = useRef(null);
  const conditionTypeDropdownRef = useRef(null);
  const fieldTypeDropdownRef = useRef(null);
  const fieldOptionsDropdownRef = useRef(null);

  const validateAndSetValue = (inputValue) => {
    let inputValueTemp = inputRef.current.value;

    if (!selectedFieldType && inputValueTemp?.trim()) {
      setRulesError(true);
      // showMessage("Please select Field Type", "error");
      return;
    }
    let parsedValue = inputValueTemp.trim(); // Trim spaces
    let isValid = true;

    switch (selectedFieldType.toLowerCase()) {
      case "integer":
        if (/^-?\d+$/.test(parsedValue)) {
          parsedValue = Number(parsedValue);
          isValid = true;
        } else {
          isValid = false;
        }
        break;

      case "float":
        if (/^-?\d+\.\d+$/.test(parsedValue)) {
          parsedValue = parseFloat(parsedValue);
          isValid = true;
        } else {
          isValid = false;
        }
        break;

      case "boolean":
        if (["true", "TRUE"].includes(parsedValue.toLowerCase())) {
          parsedValue = "true";
        } else if (["false", "FALSE"].includes(parsedValue.toLowerCase())) {
          parsedValue = "false";
        } else {
          isValid = false;
        }
        break;

      case "string":
        if (parsedValue.toLowerCase())
          isValid = true; // Always valid
        else isValid = false;
        break;

      case "unknown":
        isValid = true;
        break;

      default:
        isValid = false;
    }

    if (
      !isValid &&
      fieldOptionsDropdownRef.current.getSelectedValue() &&
      fieldTypeDropdownRef.current.getSelectedValue() &&
      conditionTypeDropdownRef.current.getSelectedValue()
    ) {
      // showMessage(`Value must match type of Field type`, "error");
    }

    if (!isValid) {
      setSelectedValue("");
      setMainObj("");
      setRulesError(true);
      return;
    }
    setSelectedValue(parsedValue); // Store the correctly formatted value
    setMainObj(parsedValue);
    setRulesError(false);
  };

  const setMainObj = (parsedValue) => {
    if (selectedRuleId && parsedValue !== undefined && parsedValue !== null) {
      const groupId = `group${selectedRuleId.split("-")[1]}`;
      const ruleNumber = selectedRuleId.split("-child-")[1];

      setRuleConfigs((prev) => ({
        ...prev,
        [selectedRuleId]: {
          ...prev[selectedRuleId],
          value: parsedValue,
        },
      }));

      updateRuleConfiguration(groupId, `rule${ruleNumber}`, {
        field: fieldOptionsDropdownRef.current.getSelectedValue(),
        fieldType: fieldTypeDropdownRef.current.getSelectedValue(),
        condition: getConditionValueByLabel(
          conditionTypeDropdownRef.current.getSelectedValue(),
        ),
        value: parsedValue, // Use correctly parsed value
      });

      // updateRuleConfigurationNew(groupId, `rule${ruleNumber}`, {
      //   field: fieldOptionsDropdownRef.current.getSelectedValue(),
      //   fieldType: fieldTypeDropdownRef.current.getSelectedValue(),
      //   condition: getConditionValueByLabel(
      //     conditionTypeDropdownRef.current.getSelectedValue(),
      //   ),
      //   value: parsedValue,
      // });
      // console.log(groupConfigurations,'groupConfigurations')
      setJsonExpression(generatePipeSeparatedExpression(groupConfigurations));
      handlePreview(generatePipeSeparatedExpression(groupConfigurations));
    }
  };

  const getConditionValueByLabel = (label) => {
    if (label) {
      const condition = conditionTypeValues.find(
        (option) => option.label === label,
      );
      if (condition && typeof condition.value === "string") {
        return condition?.value?.toUpperCase();
      }
    }
    return "";
  };

  useEffect(() => {
    console.log(groupConfigurations, "groupConfigurations--");
    setJsonExpression(generatePipeSeparatedExpression(groupConfigurations));
    handlePreview(generatePipeSeparatedExpression(groupConfigurations));
    setGroupConfigurationsTemp(JSON.parse(JSON.stringify(groupConfigurations)));

    // setGroupConfigurationsTemp(groupConfigurations);
    console.log(jsonExpression, "jsonExpression built ");
  }, [groupConfigurations]);
  function generatePipeSeparatedExpression(data) {
    let fields = [];
    let types = [];
    let operators = [];
    let values = [];
    let logicParts = [];

    let groupKeys = Object.keys(data);
    for (let i = 0; i < groupKeys.length; i++) {
      let group = data[groupKeys[i]];
      let ruleKeys = Object.keys(group.rules);
      let ruleLogic = [];

      ruleKeys.forEach((ruleKey, index) => {
        let rule = group.rules[ruleKey];
        fields.push(rule.fieldName);
        types.push(rule.fieldType);
        operators.push(rule?.conditionType?.toUpperCase());
        // Convert boolean-like values to 1 (true) and 0 (false)
        let formattedValue = "";
        if (typeof rule.value === "boolean") {
          formattedValue = rule.value === 1 ? 1 : 0;
        } else {
          formattedValue = rule.value;
        }

        values.push(formattedValue);
      });

      let relationships = Object.values(group.ruleRelationships);
      ruleLogic =
        relationships.length > 0 ? `(${relationships.join("|")})` : "";
      logicParts.push(ruleLogic);

      if (i < groupKeys.length - 1) {
        logicParts.push(group.operator);
      }
    }

    return JSON.stringify(
      {
        routing: {
          fields: fields.join("|"),
          type: types.join("|"),
          operator: operators.join("|"),
          values: values.join("|"),
          logic: logicParts.join(""),
        },
      },
      null,
      2,
    );
  }

  const updateRuleConfiguration = (groupId, ruleId, config) => {
    setGroupConfigurations((prev) => ({
      ...prev,
      [groupId]: {
        ...prev[groupId],
        rules: {
          ...prev[groupId]?.rules,
          [ruleId]: {
            fieldName: config.field || "",
            fieldType: config.fieldType || "",
            conditionType: config.condition || "",
            value: config.value || "",
          },
        },
        // operator:{},
      },
    }));
  };

  const generateRuleExpression = (rule, ruleId) => {
    if (!rule.fieldName || !rule.conditionType || !rule.value) {
      return ruleId || "Incomplete Rule";
    }

    const conditionMap = {
      BETWEEN: "BETWEEN",
      EQUALS: "==",
      NOT_EQUALS: "!==",
      GREATER_THAN: ">",
      GREATER_THAN_OR_EQUALS: ">=",
      LESS_THAN: "<",
      LESS_THAN_OR_EQUALS: "<=",
      CONTAINS: "CONTAINS",
      STARTS_WITH: "STARTS WITH",
      ENDS_WITH: "ENDS WITH",
      MATCHES: "MATCHES",
      IN: "IN",
    };

    const operator = conditionMap[rule.conditionType] || rule.conditionType;
    return `${rule.fieldName} ${operator} ${rule.value}`;
  };

  const generateCompleteExpression = () => {
    const groupExpressions = Object.entries(groupConfigurations)
      .map(([groupId, group]) => {
        const groupNumber = groupId.replace("group", "");
        const parentId = `parent-${groupNumber}`;

        // Get all rules for this group
        const ruleEntries = Object.entries(group.rules);
        if (ruleEntries.length === 0) return "";

        // Handle single rule case
        if (ruleEntries.length === 1) {
          const [ruleId, rule] = ruleEntries[0];
          return generateRuleExpression(
            rule,
            `Rule ${ruleId.replace("rule", "")}`,
          );
        }

        // Handle multiple rules with their relationships
        let ruleExpression = "";

        ruleEntries.forEach(([ruleId, rule], index) => {
          const ruleNumber = ruleId.replace("rule", "");
          const nodeId = `${parentId}-child-${ruleNumber}`;

          // Add rule expression
          const expr = generateRuleExpression(rule, `Rule ${ruleNumber}`);

          if (index === 0) {
            ruleExpression = expr;
          } else {
            // Get the previous rule's nodeId
            const prevRuleNumber = ruleEntries[index - 1][0].replace(
              "rule",
              "",
            );
            const prevNodeId = `${parentId}-child-${prevRuleNumber}`;

            // Get the operator between these rules
            const edgeKey = `erule-${prevNodeId}-${nodeId}`;

            // Use the specific rule relationship if available, otherwise fall back to the group operator
            const operator = edgeOperators[edgeKey] || group.operator;

            ruleExpression += ` ${operator} ${expr}`;
          }
        });

        // Wrap in parentheses if more than one rule
        return ruleExpression.length > 0
          ? `(${ruleExpression})`
          : ruleExpression;
      })
      .filter((expr) => expr !== "");

    // Join groups with their relationships
    let finalExpression = groupExpressions[0] || "";

    for (let i = 1; i < groupExpressions.length; i++) {
      const prevGroupId = `group${i}`;
      const currentGroupId = `group${i + 1}`;
      const relationKey = `${prevGroupId}-${currentGroupId}`;
      const operator = groupRelationships[relationKey] || "AND";
      finalExpression += ` ${operator} ${groupExpressions[i]}`;
    }

    previewFunction(finalExpression);
    return finalExpression;
  };

  const isNotEmptyObject = (obj) =>
    obj && typeof obj === "object" && Object.keys(obj).length > 0;

  const sampleData = isNotEmptyObject(jsonValue)
    ? jsonValue
    : jsonValue && typeof jsonValue === "string"
      ? JSON.parse(jsonValue)
      : "";

  const getFieldType = (value) => {
    if (Number.isInteger(value)) return "Integer";
    if (typeof value === "number") return "Float";
    if (typeof value === "string") return "String";
    if (typeof value === "boolean") return "Boolean";
    return "Unknown";
  };
  let fieldOptions = [];
  if (typeof sampleData === "object") {
    fieldOptions = Object.entries(sampleData).map(([key, value]) => ({
      label: key, // Key from sampleData
      value: ["string", "number", "boolean"].includes(typeof value)
        ? value
        : "Unknown",
    }));
  } else {
    fieldOptions = [];
  }

  // Generate field type options based on the data
  const generateFieldTypeOptions = (fieldName) => {
    const value = sampleData[fieldName];
    return [{ label: getFieldType(value), value: getFieldType(value) }];
  };

  const generateValueOptions = (fieldName) => {
    const value = sampleData[fieldName];
    if (value === undefined) return [];

    if (typeof value === "number") {
      const baseValue = Math.floor(value);
      return [
        { label: String(baseValue - 20), value: String(baseValue - 20) },
        { label: String(baseValue - 10), value: String(baseValue - 10) },
        { label: String(baseValue), value: String(baseValue) },
        { label: String(baseValue + 10), value: String(baseValue + 10) },
        { label: String(baseValue + 20), value: String(baseValue + 20) },
      ];
    }

    return [{ label: String(value), value: String(value) }];
  };

  // console.log("🚀 ~ ConfigurationComponent ~ nodes:", nodes);
  // console.log("🚀 ~ ConfigurationComponent ~ edges:", edges);

  useEffect(() => {
    (async () => {
      try {
        let response = await axiosAdapter(
          "GET",
          env.REACT_APP_URL + "admin/getUtilityDataTypes",
        );

        if (response.data.status === "SUCCESS") {
          const { dataTypes, conditionTypes } = response.data;
          // setFieldTypeOptions(
          //   dataTypes.map((item) => ({ label: item, value: item })),
          // );
          let types = [
            "Integer",
            "String",
            "Float",
            "Boolean",
            // "Double",
            // "Array",
            // "Object",
            // "Null",
            // "Undefined",
            // "Symbol",
            // "BigInt",
            // "BigInt"
            // "Timestamp",
            "Unknown",
          ];
          setFieldTypeOptions(
            types.map((item) => ({ label: item, value: item })),
          );
          // setConditionTypeOptions(
          //   conditionTypes.map((item) => ({
          //     label: item,
          //     value: item,
          //   })),
          // );
        }
      } catch (error) {
        console.error("Error fetching field and condition types:", error);
      }
    })();
  }, []);

  // useEffect(() => {
  //   setValueOptions([
  //     { label: "10", value: "10" },
  //     { label: "20", value: "20" },
  //     { label: "30", value: "30" },
  //     { label: "80", value: "80" },
  //   ]);
  // }, []);

  useEffect(() => {
    if (selectedField) {
      let selectedFieldTypeStr = "unknown"; // Default type if not recognized

      if (typeof selectedFieldValue === "string") {
        selectedFieldTypeStr = "string";
      } else if (typeof selectedFieldValue === "number") {
        selectedFieldTypeStr = Number.isInteger(selectedFieldValue)
          ? "integer"
          : "float";
      } else if (typeof selectedFieldValue === "boolean") {
        selectedFieldTypeStr = "boolean";
      }

      const matchedOption = fieldTypeOptions.find((option) => {
        return (
          option.label.toLowerCase() === selectedFieldTypeStr ||
          option.value.toLowerCase() === selectedFieldTypeStr
        );
      });

      if (matchedOption) {
        console.log(matchedOption, "selectedFieldValue", selectedFieldValue);
        handleFieldTypeDropdownChange(matchedOption.value);
        // setSelectedFieldType(matchedOption.value); // Set the actual field type
      }
    }
  }, [selectedField]);

  // Update useEffect for value options
  // useEffect(() => {
  //   if (selectedField) {
  //     setValueOptions(generateValueOptions(selectedField));
  //   }
  // }, [selectedField]);

  const createNodeLabel = (ruleId, displayText) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        padding: "0 1rem",
      }}
    >
      <img
        style={{
          height: "2.67rem",
          width: "2.67rem",
          cursor: "pointer",
        }}
        src={DragIcon}
        alt="DragIcon"
      />
      <span>{displayText}</span>
      <img
        style={{
          height: "2.67rem",
          width: "2.67rem",
          cursor: "pointer",
        }}
        src={Delete}
        alt="delete Icon"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteNode(ruleId);
        }}
      />
    </div>
  );

  // Update the nodes when a rule is selected
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id.includes("-child-")) {
          return {
            ...node,
            style: {
              ...node.style,
              color: node.id === selectedRuleId ? "#F6F6F6" : "#B0B0B0",
              backgroundColor:
                node.id === selectedRuleId ? "#1E565A" : "#0D0D0D",
              border: node.id === selectedRuleId ? "2px solid #47CCD6" : "none",
            },
          };
        }
        return node;
      }),
    );
    console.log(selectedRuleId, "selectedRuleId");
    showPrefilled(selectedRuleId);
  }, [selectedRuleId]);

  const showPrefilled = (selectedRuleId) => {
    console.log(
      "i am here",
      selectedRuleId,
      "groupConfigurations",
      groupConfigurations,
    );

    try {
      let parent = "";
      let rule = "";

      // Handle rule nodes (e.g., parent-1-child-1)
      const regex = /parent-(\d+)-child-(\d+)/;
      const match = selectedRuleId.match(regex);

      if (match) {
        parent = match[1]; // Extracted parent ID
        rule = match[2]; // Extracted rule ID
      }

      // Output the formatted result
      console.log(`parent = ${parent || "N/A"}, rule = ${rule || "N/A"}`);

      // If it's a rule node, extract and update the rule configuration
      if (match) {
        const groupIdVal = `group${parent}`;
        const ruleNumber = `rule${rule}`;

        // Create a deep copy of groupConfigurationsTemp to avoid state mutation
        const groupConfigurationsTempCopy = JSON.parse(
          JSON.stringify(groupConfigurations),
        );

        let obj = extractRule(
          groupConfigurationsTempCopy,
          groupIdVal,
          ruleNumber,
        );
        console.log(obj, "heyyyyyyyyy");

        if (obj) {
          setSelectedField(() => obj.fieldName);
          setSelectedFieldType(() => obj.fieldType);
          setSelectedCondition(() => obj.conditionType?.toLowerCase());
          setSelectedValue(() => obj.value);
          setSelectedValueTemp(() => obj.value);
        }

        console.log(parent, "parent", rule, "rule");
      }
    } catch (error) {
      console.error("Error in onNodeClick:", error);
    }
  };
  // Update the edges when a rule is selected
  useEffect(() => {
    setEdges((eds) =>
      eds.map((edge) => {
        const isSelectedRuleEdge =
          edge.source === selectedRuleId || edge.target === selectedRuleId;
        return {
          ...edge,
          style: {
            ...edge.style,
            stroke: isSelectedRuleEdge ? "#47CCD6" : "#5E5E5E",
            strokeWidth: isSelectedRuleEdge ? 3 : 2,
          },
        };
      }),
    );
    console.log(selectedRuleId, "selectedRuleId 1");

    showPrefilled(selectedRuleId);
  }, [selectedRuleId]);

  const EdgeDropdown = ({ id, source, target }) => {
    const operator = edgeOperators[id] || "AND";

    const handleChange = (e) => {
      const newValue = e.target.value;
      setEdgeOperators((prev) => ({
        ...prev,
        [id]: newValue,
      }));

      // Handle rule-to-rule relationships
      if (id.startsWith("erule-")) {
        // Extract the parent ID and rule numbers
        const parts = id.split("-");
        const groupNum = parts[1].split("-child-")[0].replace("parent-", "");
        const groupId = `group${groupNum}`;

        // Update the operator in groupConfigurations
        setGroupConfigurations((prev) => ({
          ...prev,
          [groupId]: {
            ...prev[groupId],
            operator: newValue,
            // Store individual rule relationships if needed
            ruleRelationships: {
              ...prev[groupId]?.ruleRelationships,
              [id]: newValue,
            },
          },
        }));
      }
      // Handle group-to-group relationships
      else if (id.startsWith("egroup-")) {
        const [_, relation] = id.split("egroup-");
        const [sourceGroup, targetGroup] = relation.split("-");
        const sourceGroupId = `group${sourceGroup.replace("parent-", "")}`;
        const targetGroupId = `group${targetGroup.replace("parent-", "")}`;

        setGroupRelationships((prev) => ({
          ...prev,
          [`${sourceGroupId}-${targetGroupId}`]: newValue,
        }));
      }
    };

    return (
      <div
        style={{
          background: "#333",
          borderRadius: "5px",
          padding: "2px 5px",
          border: "1px solid #666",
        }}
      >
        <select
          value={operator}
          onChange={handleChange}
          style={{
            background: "transparent",
            color: "#B0B0B0",
            border: "none",
            fontFamily: "Articulat CF Medium",
            fontWeight: "bold",
            fontSize: "1rem",
            padding: "2px",
            cursor: "pointer",
          }}
        >
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </select>
      </div>
    );
  };

  // Custom edge
  const customEdgeTypes = {
    customEdge: ({
      id,
      source,
      target,
      sourceX,
      sourceY,
      targetX,
      targetY,
      style = {},
      markerEnd,
    }) => {
      // Determine if this edge should have a dropdown
      const shouldShowDropdown = () => {
        // Between two rules
        const isRuleToRule =
          source.includes("-child-") && target.includes("-child-");

        // Between two groups
        const isGroupToGroup =
          source.startsWith("parent-") &&
          !source.includes("-child-") &&
          !source.includes("-add-rule") &&
          target.startsWith("parent-") &&
          !target.includes("-child-") &&
          !target.includes("-add-rule") &&
          target !== "add-group-button";

        return isRuleToRule || isGroupToGroup;
      };

      const edgePath = `M ${sourceX} ${sourceY} C ${sourceX + 15} ${sourceY}, ${targetX - 7} ${targetY}, ${targetX} ${targetY}`;

      return (
        <>
          <path
            id={id}
            style={style}
            className="react-flow__edge-path"
            d={edgePath}
            markerEnd={markerEnd}
          />
          {shouldShowDropdown() && (
            <foreignObject
              width={60}
              height={30}
              x={(sourceX + targetX) / 2 - 30}
              y={(sourceY + targetY) / 2 - 15}
              className="edge-operator-dropdown"
            >
              <EdgeDropdown id={id} source={source} target={target} />
            </foreignObject>
          )}
        </>
      );
    },
  };

  const handleAddParentNode = () => {
    // Find the lowest y-position among existing nodes
    const lowestNode = nodes.reduce(
      (lowest, current) => {
        return current.position.y > lowest.position.y ? current : lowest;
      },
      { position: { y: -250 } },
    );

    const baseY = lowestNode.position.y + 200; // Add spacing from the lowest node

    const newParentId = `parent-${parentCount + 1}`;

    // Create new parent node
    const newParentNode = {
      id: newParentId,
      data: { label: `Group ${parentCount + 1}` },
      position: { x: 300, y: baseY },
      sourcePosition: "left",
      targetPosition: "left",
      draggable: false,
      style: {
        backgroundColor: "#0D0D0D",
        height: "6.2rem",
        width: "39rem",
        borderRadius: "0.84rem",
        color: "#B0B0B0",
        fontFamily: "Articulat CF Medium",
        fontWeight: "bold",
        fontSize: "1.88rem",
        paddingTop: "0.3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    };

    // Create new add rule button
    const newAddRuleButton = {
      id: `${newParentId}-add-rule`,
      data: { label: <div className="add-rule">Add Rule</div> },
      position: { x: 550, y: baseY + 150 },
      sourcePosition: "left",
      targetPosition: "left",
      draggable: true,
      className: "add-rule",
      style: {
        backgroundColor: "transparent",
        height: "6.2rem",
        width: "29rem",
        borderRadius: "0.84rem",
        color: "#B0B0B0",
        fontFamily: "Articulat CF Medium",
        fontWeight: "bold",
        fontSize: "1.88rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "4px dashed #666",
        cursor: "pointer",
        transition: "border-color 0.3s ease",
      },
    };

    const newGroupId = `group${parentCount + 1}`;
    setGroupConfigurations((prev) => ({
      ...prev,
      [newGroupId]: {
        rules: {},
        operator: "AND",
        ruleRelationships: {},
      },
    }));

    // Create new edges
    const newEdges = [
      {
        id: `e${newParentId}-add-rule`,
        source: newParentId,
        target: `${newParentId}-add-rule`,
        type: "smoothstep",
        style: { stroke: "#47CCD6", strokeWidth: 2 },
      },
    ];

    if (parentCount > 0) {
      const prevParentId = `parent-${parentCount}`;
      const groupToGroupEdge = {
        id: `egroup-${prevParentId}-${newParentId}`,
        source: prevParentId,
        target: newParentId,
        type: "customEdge",
        style: { stroke: "#47CCD6", strokeWidth: 2 },
      };

      setEdgeOperators((prev) => ({
        ...prev,
        [`egroup-${prevParentId}-${newParentId}`]: "AND",
      }));

      setEdges((eds) => [...eds, groupToGroupEdge]);
    }

    if (parentCount > 0) {
      const prevGroupId = `group${parentCount}`;
      setGroupRelationships((prev) => ({
        ...prev,
        [`${prevGroupId}-${newGroupId}`]: "AND",
      }));
    }

    // Update nodes and edges
    setNodes((nds) => [...nds, newParentNode, newAddRuleButton]);
    setEdges((eds) => [...eds, ...newEdges]);
    setParentCount(parentCount + 1);
    setSelectedParentId(newParentId);

    // Update add-group-button position
    setNodes((nds) =>
      nds.map((node) =>
        node.id === "add-group-button"
          ? {
              ...node,
              position: { x: 300, y: baseY + 300 },
            }
          : node,
      ),
    );

    // Add edge from new parent to add-group-button
    setEdges((eds) => [
      ...eds,
      {
        id: `e${newParentId}-add-group-button`,
        source: newParentId,
        target: "add-group-button",
        type: "smoothstep",
        style: { stroke: "#47CCD6", strokeWidth: 2 },
      },
    ]);
  };

  const handleAddChildNode = () => {
    if (!selectedParentId) {
      console.error("No parent selected to add a child node.");
      return;
    }

    const parentNode = nodes.find((node) => node.id === selectedParentId);
    if (!parentNode) {
      console.error(`Parent node with id ${selectedParentId} not found.`);
      return;
    }

    // Get all child nodes for the selected parent
    const childNodes = nodes.filter(
      (node) =>
        node.id.startsWith(`${selectedParentId}-child`) &&
        !node.id.includes("add-rule"),
    );

    // Get the "Add Rule" button node for this parent
    const addRuleButton = nodes.find(
      (node) => node.id === `${selectedParentId}-add-rule`,
    );

    const newChildId = `${selectedParentId}-child-${childNodes.length + 1}`;
    const verticalSpacing = 150;

    // Create new child node
    const newChildNode = {
      id: newChildId,
      data: {
        label: createNodeLabel(newChildId, `Rule ${childNodes.length + 1}`),
      },
      position: {
        x: parentNode.position.x + 250,
        y: parentNode.position.y + (childNodes.length + 1) * verticalSpacing,
      },
      sourcePosition: "left",
      targetPosition: "left",
      draggable: true,
      style: {
        backgroundColor: "#0D0D0D",
        height: "6.2rem",
        width: "29rem",
        borderRadius: "0.84rem",
        color: "#B0B0B0",
        fontFamily: "Articulat CF Medium",
        fontWeight: "bold",
        fontSize: "1.88rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    };

    if (childNodes.length > 0) {
      // Last child node
      const lastChildNode = childNodes[childNodes.length - 1];
      const edgeId = `erule-${lastChildNode.id}-${newChildId}`;

      // Create an edge between the last child and new child
      const ruleToRuleEdge = {
        id: edgeId,
        source: lastChildNode.id,
        target: newChildId,
        type: "customEdge",
        style: { stroke: "#47CCD6", strokeWidth: 2 },
      };

      // Set initial value in edgeOperators
      setEdgeOperators((prev) => ({
        ...prev,
        [edgeId]: "AND",
      }));

      // Also set in the group configuration
      const groupNum = selectedParentId.replace("parent-", "");
      const groupId = `group${groupNum}`;

      setGroupConfigurations((prev) => ({
        ...prev,
        [groupId]: {
          ...prev[groupId],
          ruleRelationships: {
            ...prev[groupId]?.ruleRelationships,
            [edgeId]: "AND",
          },
        },
      }));

      // Add to edges when updating
      setEdges((currentEdges) => [...currentEdges, ruleToRuleEdge]);
    }

    // Update nodes array with new child node and adjust positions
    setNodes((nds) => {
      const updatedNodes = [...nds, newChildNode];
      const currentParentY = parentNode.position.y;

      return updatedNodes.map((node) => {
        // First update the Add Rule button position
        if (node.id === `${selectedParentId}-add-rule`) {
          return {
            ...node,
            position: {
              ...node.position,
              y: newChildNode.position.y + verticalSpacing,
            },
          };
        }

        // Then update positions of all nodes that are below the current parent
        if (
          node.position.y > currentParentY &&
          node.id !== newChildId &&
          !node.id.startsWith(selectedParentId)
        ) {
          return {
            ...node,
            position: {
              ...node.position,
              y: node.position.y + verticalSpacing,
            },
          };
        }
        return node;
      });
    });

    // Create edge from parent to new child
    const newEdge = {
      id: `e${selectedParentId}-${newChildId}`,
      source: selectedParentId,
      target: newChildId,
      type: "smoothstep",
      style: { stroke: "#47CCD6", strokeWidth: 2 },
    };

    // Update edges array
    setEdges((eds) => {
      const updatedEdges = addEdge(newEdge, eds);

      // Update edge to "Add Rule" button if it exists
      if (addRuleButton) {
        return updatedEdges.map((edge) =>
          edge.target === addRuleButton.id
            ? {
                ...edge,
                sourceHandle: null,
                targetHandle: null,
              }
            : edge,
        );
      }
      return updatedEdges;
    });

    // Reset all dropdowns
    setSelectedField("");
    setSelectedFieldType("");
    setSelectedCondition("");
    setSelectedValue("");
    setSelectedValueTemp("");
    setSelectedRuleId(newChildId); // Set the new rule as selected

    // Initialize rule configuration
    setRuleConfigs((prev) => ({
      ...prev,
      [newChildId]: {
        field: "",
        fieldType: "",
        condition: "",
        value: "",
      },
    }));
    setHasRules(true);
  };

  const getRuleConfiguration = (ruleConfigurations, groupId, ruleId) => {
    const groupKey = `group${groupId}`; // Ensure correct group key

    if (ruleConfigurations[groupKey] && ruleConfigurations[groupKey][ruleId]) {
      return ruleConfigurations[groupKey][ruleId]; // Return the rule object
    }

    return null; // Return null if the rule doesn't exist
  };

  const updateRuleConfigurationNew1 = (groupId, ruleId, config, type) => {
    setRuleConfigurations((prevConfig) => {
      console.log("Previous State:", JSON.stringify(prevConfig, null, 2)); // Print before updating

      const newConfig = { ...prevConfig };

      const groupKey = `group${groupId}`; // Ensure group key matches the state structure

      if (type === "delete") {
        if (newConfig[groupKey]) {
          delete newConfig[groupKey][ruleId];

          // If the group becomes empty, remove the group
          if (Object.keys(newConfig[groupKey]).length === 0) {
            delete newConfig[groupKey];
          }
        }
      } else {
        // Ensure the group exists
        if (!newConfig[groupKey]) newConfig[groupKey] = {};

        // Update or add the rule
        newConfig[groupKey][ruleId] = { ...config };
      }
      console.log("Updated State:", JSON.stringify(newConfig, null, 2)); // Print after updating

      return { ...newConfig }; // Ensure a new reference is created
    });
  };

  const handleDeleteNode = (nodeId) => {
    const regex = /parent-(\d+)-child-(\d+)/;
    const match = nodeId.match(regex);

    if (!match) {
      console.error("Invalid nodeId format");
      return;
    }

    const parent = match[1]; // Extracted parent ID
    const child = match[2]; // Extracted child ID

    try {
      console.log(`rule${child} yyyyyyyy`, typeof child);
      // Delete the rule
      updateRuleConfigurationNew1(parent, `rule${child}`, {}, "delete");
    } catch (error) {
      console.error("Error processing rule deletion and update:", error);
    }

    console.log(ruleConfigurations, "ruleConfigurations");
    const createNodeLabel = (ruleId, displayText) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "0 1rem",
        }}
      >
        <img
          style={{
            height: "2.67rem",
            width: "2.67rem",
            cursor: "pointer",
          }}
          src={DragIcon}
          alt="DragIcon"
        />
        <span>{displayText}</span>
        <img
          style={{
            height: "2.67rem",
            width: "2.67rem",
            cursor: "pointer",
          }}
          src={Delete}
          alt="delete Icon"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteNode(ruleId);
          }}
        />
      </div>
    );

    // Remove the node
    setNodes((nds) => {
      const parentId = nodeId.split("-child-")[0];

      // Get remaining nodes before deletion
      const remainingNodes = nds.filter((node) => node.id !== nodeId);

      // Get all child nodes for this parent after deletion
      const childNodes = remainingNodes
        .filter(
          (node) =>
            node.id.startsWith(`${parentId}-child`) &&
            !node.id.includes("add-rule"),
        )
        .sort((a, b) => {
          const aNum = parseInt(a.id.split("-child-")[1]);
          const bNum = parseInt(b.id.split("-child-")[1]);
          return aNum - bNum;
        });

      // Find parent node
      const parentNode = remainingNodes.find((n) => n.id === parentId);
      if (!parentNode) return remainingNodes;

      // Update positions while preserving labels
      return remainingNodes.map((node) => {
        // Handle child nodes
        if (
          node.id.startsWith(`${parentId}-child`) &&
          !node.id.includes("add-rule")
        ) {
          const index = childNodes.findIndex((n) => n.id === node.id);
          // Use existing rule config to preserve the field name
          const existingConfig = ruleConfigs[node.id];
          const displayText =
            existingConfig?.field ||
            node.data.label.props.children[1].props.children;

          return {
            ...node,
            position: {
              x: node.position.x,
              y: parentNode.position.y + (index + 1) * 150,
            },
            data: {
              ...node.data,
              label: createNodeLabel(node.id, displayText),
            },
          };
        }

        // Handle "Add Rule" button
        if (node.id === `${parentId}-add-rule`) {
          return {
            ...node,
            position: {
              x: node.position.x,
              y: parentNode.position.y + (childNodes.length + 1) * 150,
            },
          };
        }

        // Handle nodes below the parent group
        const nodeParentId = node.id.split("-child-")[0];
        if (
          nodeParentId !== parentId &&
          node.position.y > parentNode.position.y &&
          !node.id.includes(parentId)
        ) {
          return {
            ...node,
            position: {
              ...node.position,
              y: node.position.y - 150,
            },
          };
        }

        return node;
      });
    });

    // Remove associated edges
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId),
    );

    // Update rule configurations
    setRuleConfigs((prev) => {
      const newConfigs = { ...prev };
      delete newConfigs[nodeId];
      return newConfigs;
    });

    // Clear selected rule if it was deleted
    if (selectedRuleId === nodeId) {
      setSelectedRuleId(null);
      setSelectedField("");
      setSelectedFieldType("");
      setSelectedCondition("");
      setSelectedValue("");
      setSelectedValueTemp("");
    }

    // Update edges with new positions
    setEdges((eds) => {
      return eds.map((edge) => {
        if (edge.source.startsWith(nodeId.split("-child-")[0])) {
          return {
            ...edge,
            sourceHandle: null,
            targetHandle: null,
          };
        }
        return edge;
      });
    });

    if (nodeId.includes("-child-")) {
      const groupId = `group${nodeId.split("-")[1]}`;
      const ruleNumber = nodeId.split("-child-")[1];

      // Clean up rule from groupConfigurations
      setGroupConfigurations((prev) => {
        const updatedGroup = { ...prev };
        if (updatedGroup[groupId] && updatedGroup[groupId].rules) {
          const { [`rule${ruleNumber}`]: removedRule, ...remainingRules } =
            updatedGroup[groupId].rules;
          updatedGroup[groupId].rules = remainingRules;
        }
        return updatedGroup;
      });
    }
    setNodes((nds) => {
      const remainingRules = nds.filter((node) => node.id.includes("-child-"));
      if (remainingRules.length === 0) {
        setHasRules(false);
      }
      return nds;
    });

    try {
      const ruleIndex = parseInt(child, 10);
      console.log(ruleIndex, "ruleIndex");
      let tempValue = "";

      if (ruleIndex > 0) {
        // Fetch the previous rule (ruleIndex - 1)
        setPrefilledValues(ruleConfigurations, parent, ruleIndex - 1);
      }
    } catch (error) {}
  };

  const setPrefilledValues = (ruleConfigurations, parent, ruleIndex) => {
    const ruleObject = getRuleConfiguration(
      ruleConfigurations,
      parent,
      `rule${ruleIndex}`,
    );

    let tempValue = "";
    if (ruleObject) {
      tempValue = ruleObject.value;
      console.log(ruleObject, "ruleObject");

      // Update selected values
      setSelectedField(ruleObject.field);
      setSelectedFieldType(ruleObject.fieldType);
      setSelectedCondition(ruleObject.condition);
      setSelectedValueTemp(ruleObject.value);

      // Update dropdowns
      fieldOptionsDropdownRef.current?.setSelectedValue(ruleObject.field);
      fieldTypeDropdownRef.current?.setSelectedValue(ruleObject.fieldType);
      conditionTypeDropdownRef.current?.setSelectedValue(ruleObject.condition);
      selectedValueTemp.current?.setSelectedValue(ruleObject.value);

      // Validate and set value
    } else {
      tempValue = "";
      // Update selected values
      setSelectedField("");
      setSelectedFieldType("");
      setSelectedCondition("");
      setSelectedValueTemp("");

      // Update dropdowns
      fieldOptionsDropdownRef.current?.setSelectedValue("");
      fieldTypeDropdownRef.current?.setSelectedValue("");
      conditionTypeDropdownRef.current?.setSelectedValue("");
      selectedValueTemp.current?.setSelectedValue("");
    }
    validateAndSetValue(tempValue);
  };

  const extractRule = (groupConfigurationsObj, groupName, ruleName) => {
    console.log("Extracting Rule:", {
      groupConfigurationsObj,
      groupName,
      ruleName,
    });

    if (!groupConfigurationsObj[groupName]) {
      console.error(`Group '${groupName}' not found`);
      return null;
    }

    const group = groupConfigurationsObj[groupName];
    const rule = group.rules[ruleName];

    if (!rule) {
      console.error(`Rule '${ruleName}' not found in group '${groupName}'`);
      return null;
    }

    console.log("Extracted Rule:", rule); // Check if correct rule is being retrieved
    return rule;
  };

  const onNodeClick = useCallback(
    (event, node) => {
      if (node.id.includes("-child-")) {
        setSelectedRuleId(node.id);

        const config = ruleConfigs[node.id] || {
          field: "",
          fieldType: "",
          condition: "",
          value: "",
        };
        // Reset dropdowns when clicking a different rule
        if (selectedRuleId !== node.id) {
          setSelectedField("");
          setSelectedFieldType("");
          setSelectedCondition("");
          setSelectedValue("");
          setSelectedValueTemp("");
        }
      }

      // Check if the clicked node is a parent node
      if (
        node.id.startsWith("parent-") &&
        !node.id.includes("add-rule") &&
        !node.id.includes("child")
      ) {
        setSelectedParentId(node.id);
      }

      // Check if the clicked node is an "Add Rule" button
      if (node.id.includes("-add-rule")) {
        // Extract the parent ID from the add-rule button ID
        const parentId = node.id.split("-add-rule")[0];
        setSelectedParentId(parentId); // Set the selected parent
        handleAddChildNode(); // Call handleAddChildNode with the correct parent context
      }

      // Check if the clicked node is the "Add Group" button
      if (node.id === "add-group-button") {
        handleAddParentNode();
      }

      // try {
      //   const regex = /parent-(\d+)-child-(\d+)/;
      //   const match = node.id.match(regex);

      //   if (!match) {
      //     console.error("Invalid nodeId format");
      //     return;
      //   }

      //   const parent = match[1]; // Extracted parent ID
      //   const child = match[2];

      //   setPrefilledValues(ruleConfigurations, parent, child);
      // } catch (error) {}
    },
    [handleAddParentNode, handleAddChildNode, selectedRuleId, ruleConfigs],
  );
  const [draggedNodeOriginalPos, setDraggedNodeOriginalPos] = useState(null);

  useEffect(() => {
    if (selectedRuleId && selectedField) {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === selectedRuleId) {
            return {
              ...node,
              data: {
                ...node.data,
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "space-between",
                      padding: "0 1rem",
                    }}
                  >
                    <img
                      style={{
                        height: "2.67rem",
                        width: "2.67rem",
                        cursor: "pointer",
                      }}
                      src={DragIcon}
                      alt="DragIcon"
                    />
                    <span>{selectedField}</span>
                    <img
                      style={{
                        height: "2.67rem",
                        width: "2.67rem",
                        cursor: "pointer",
                      }}
                      src={Delete}
                      alt="delete Icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteNode(node.id);
                      }}
                    />
                  </div>
                ),
              },
            };
          }
          return node;
        }),
      );
    }
  }, [selectedField, selectedRuleId]);

  // const handleFieldChange = (e) => {
  //   const newField = e.target.value;
  //   setSelectedField(newField);

  //   if (selectedRuleId) {
  //     // Update rule configuration
  //     setRuleConfigs((prev) => ({
  //       ...prev,
  //       [selectedRuleId]: {
  //         ...prev[selectedRuleId],
  //         field: newField,
  //       },
  //     }));

  //     // Update node label
  //     setNodes((nds) =>
  //       nds.map((node) => {
  //         if (node.id === selectedRuleId) {
  //           return {
  //             ...node,
  //             data: {
  //               ...node.data,
  //               label: createNodeLabel(
  //                 selectedRuleId,
  //                 newField || `Rule ${node.id.split("-child-")[1]}`,
  //               ),
  //             },
  //           };
  //         }
  //         return node;
  //       }),
  //     );
  //   }
  // };

  const handleFieldTypeDropdownChange = (value) => {
    setSelectedFieldType(value);
    if (fieldTypeDropdownRef.current) {
      fieldTypeDropdownRef.current.setSelectedValue(value); // Update dropdown value manually
    }
    if (selectedRuleId) {
      setRuleConfigs((prev) => ({
        ...prev,
        [selectedRuleId]: {
          ...prev[selectedRuleId],
          fieldType: value,
        },
      }));
    }
  };

  const handleFieldChange = (e) => {
    const newField = e.target.label;
    const newValueField = e.target.value;

    setSelectedField(newField);
    setSelectedFieldValue(newValueField);
    if (selectedRuleId) {
      const groupId = `group${selectedRuleId.split("-")[1]}`;
      const ruleNumber = selectedRuleId.split("-child-")[1];

      updateRuleConfiguration(groupId, `rule${ruleNumber}`, {
        field: selectedField,
        fieldType: selectedFieldType,
        condition: selectedCondition,
        value: selectedValue,
      });
    }
  };

  const handleFieldTypeChange = (e) => {
    const newFieldType = e.target.value;
    setSelectedFieldType(newFieldType);

    if (selectedRuleId) {
      const groupId = `group${selectedRuleId.split("-")[1]}`;
      const ruleNumber = selectedRuleId.split("-child-")[1];

      updateRuleConfiguration(groupId, `rule${ruleNumber}`, {
        field: selectedField,
        fieldType: newFieldType,
        condition: selectedCondition,
        value: selectedValue,
      });
    }
  };

  const onNodeDrag = useCallback(
    (event, draggedNode) => {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === draggedNode.id
            ? {
                ...node,
                className: "dragging",
                position: isWithinBoundary(draggedNode.position)
                  ? draggedNode.position
                  : node.position,
              }
            : node,
        ),
      );

      // Store the original position only when the node starts moving
      if (!draggedNodeOriginalPos) {
        setDraggedNodeOriginalPos({
          id: draggedNode.id,
          position: { ...draggedNode.position },
        });
      }
    },
    [setNodes, draggedNodeOriginalPos],
  );

  const onNodeDragStop = useCallback(
    (event, draggedNode) => {
      if (!draggedNodeOriginalPos) return;

      setNodes((nds) => {
        // Find the node at the new drop position
        const targetNode = nds.find(
          (node) =>
            node.id !== draggedNode.id &&
            node.id.includes("-child-") &&
            Math.abs(node.position.y - draggedNode.position.y) < 40,
        );

        if (!targetNode) {
          // Remove dragging class if no target found
          return nds.map((node) => ({
            ...node,
            className: node.id === draggedNode.id ? "" : node.className,
          }));
        }

        // Update nodes positions
        const updatedNodes = nds.map((node) => {
          if (node.id === draggedNode.id) {
            return { ...node, position: targetNode.position };
          }
          if (node.id === targetNode.id) {
            return { ...node, position: draggedNodeOriginalPos.position };
          }
          return node;
        });

        // Update group configurations when rules are swapped
        if (
          draggedNode.id.includes("-child-") &&
          targetNode.id.includes("-child-")
        ) {
          const draggedGroupId = `group${draggedNode.id.split("-")[1]}`;
          const draggedRuleNumber = draggedNode.id.split("-child-")[1];
          const targetRuleNumber = targetNode.id.split("-child-")[1];

          setGroupConfigurations((prev) => {
            const updatedGroup = { ...prev[draggedGroupId] };

            // Get the rules to swap
            const draggedRule = {
              ...updatedGroup.rules[`rule${draggedRuleNumber}`],
            };
            const targetRule = {
              ...updatedGroup.rules[`rule${targetRuleNumber}`],
            };

            // Swap the rules
            updatedGroup.rules[`rule${draggedRuleNumber}`] = targetRule;
            updatedGroup.rules[`rule${targetRuleNumber}`] = draggedRule;

            // Update relationships if needed
            const updatedRelationships = {};
            Object.entries(updatedGroup.ruleRelationships).forEach(
              ([key, value]) => {
                let newKey = key;
                if (key.includes(draggedNode.id)) {
                  newKey = key.replace(draggedNode.id, targetNode.id);
                } else if (key.includes(targetNode.id)) {
                  newKey = key.replace(targetNode.id, draggedNode.id);
                }
                updatedRelationships[newKey] = value;
              },
            );
            updatedGroup.ruleRelationships = updatedRelationships;

            return {
              ...prev,
              [draggedGroupId]: updatedGroup,
            };
          });

          // Update edge operators for the swapped rules
          setEdgeOperators((prev) => {
            const updatedOperators = { ...prev };
            Object.keys(updatedOperators).forEach((key) => {
              if (key.includes(draggedNode.id) || key.includes(targetNode.id)) {
                const newKey = key
                  .replace(draggedNode.id, "TEMP")
                  .replace(targetNode.id, draggedNode.id)
                  .replace("TEMP", targetNode.id);
                updatedOperators[newKey] = updatedOperators[key];
                if (key !== newKey) {
                  delete updatedOperators[key];
                }
              }
            });
            return updatedOperators;
          });
        }

        return updatedNodes.map((node) => ({
          ...node,
          className: "",
        }));
      });

      setDraggedNodeOriginalPos(null);
    },
    [setNodes, draggedNodeOriginalPos],
  );

  // const expression = generateCompleteExpression();

  // console.log(expression, "expressionexpressionexpression");

  useEffect(() => {
    // If you want to make the configuration available to parent components
    if (typeof window !== "undefined") {
      window.ruleConfigData = {
        groupConfigurations,
        groupRelationships,
        expression: generateCompleteExpression(),
      };
    }
  }, [groupConfigurations, groupRelationships]);

  return (
    <div>
      <Container>
        <CustomRow>
          <GroupContainer>
            <ReactFlowWrapper>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={onNodeClick}
                onNodeDragStop={onNodeDragStop}
                onNodeDrag={onNodeDrag}
                edgeTypes={customEdgeTypes}
                zoomOnScroll={false}
                zoomOnPinch={false}
                zoomOnDoubleClick={false}
                proOptions={{ hideAttribution: true }}
                style={{
                  height: "100%",
                  width: "100%",
                  overflow: "auto",
                }}
                fitView
              >
                <style>
                  {`
              
                .add-group {
                  transition: border-color 0.3s ease, color 0.3s ease;
                }
        
                .add-group:hover {
                  border-color: #F6F6F6 !important; 
                  color: #F6F6F6 !important; 
                }

                .add-rule {
                  transition: border-color 0.3s ease, color 0.3s ease;
                }

                .add-rule:hover {
                  border-color: #F6F6F6 !important; 
                  color: #F6F6F6 !important; 
                }
                   .react-flow__watermark {
                   display: none !important;
                }
                   .react-flow__node {
                   transition: transform 0.2s ease, background-color 0.2s ease, border 0.2s ease, color 0.2s ease;
                 }

                .react-flow__edge {
                 transition: stroke 0.3s ease, stroke-width 0.2s ease;
                 }

                .react-flow__node-default {
                 transition: all 0.2s ease;
                }

                .react-flow__node.dragging {
                 transform: scale(1.05);
                z-index: 1000;
                }
              `}
                </style>
              </ReactFlow>
            </ReactFlowWrapper>
          </GroupContainer>
          <FeildsContainer>
            <CustomRow xs={6} style={{ justifyContent: "space-around" }}>
              <CustomDropdown
                ref={fieldOptionsDropdownRef}
                label="Field Name"
                options={fieldOptions}
                value={selectedField}
                onChange={handleFieldChange}
                disabled={!hasRules}
                style={{
                  opacity: hasRules ? 1 : 0.5,
                  "& select": {
                    color: hasRules ? "#B0B0B0" : "#666666",
                  },
                }}
                type="utility"
              />

              <CustomDropdown
                ref={fieldTypeDropdownRef}
                label="Field Type"
                options={fieldTypeOptions}
                value={selectedFieldType}
                onChange={(e) => handleFieldTypeDropdownChange(e.target.value)}
                disabled={!hasRules}
                style={{
                  opacity: hasRules ? 1 : 0.5,
                  "& select": {
                    color: hasRules ? "#B0B0B0" : "#666666",
                  },
                }}
              />
            </CustomRow>
            <CustomRow xs={6} style={{ justifyContent: "space-around" }}>
              <CustomDropdown
                ref={conditionTypeDropdownRef}
                label="Condition Type"
                options={conditionTypeOptions}
                value={selectedCondition}
                onChange={(e) => {
                  const newCondition = e.target.value;
                  setSelectedCondition(newCondition);
                  if (selectedRuleId) {
                    const groupId = `group${selectedRuleId.split("-")[1]}`;
                    const ruleNumber = selectedRuleId.split("-child-")[1];

                    updateRuleConfiguration(groupId, `rule${ruleNumber}`, {
                      field: selectedField,
                      fieldType: selectedFieldType,
                      condition: newCondition,
                      value: selectedValue,
                    });
                  }
                }}
                disabled={!hasRules}
                style={{
                  opacity: hasRules ? 1 : 0.5,
                  "& select": {
                    color: hasRules ? "#B0B0B0" : "#666666",
                  },
                }}
              />

              <ValueTextField>
                <Label>Value</Label>
                <TextField
                  type="text"
                  placeholder="Enter value"
                  value={selectedValueTemp}
                  onChange={(e) => setSelectedValueTemp(e.target.value)}
                  ref={inputRef}
                  disabled={!hasRules}
                  style={{
                    opacity: hasRules ? 1 : 0.5,
                    "& select": {
                      color: hasRules ? "#B0B0B0" : "#666666",
                    },
                  }}
                />
              </ValueTextField>
              {/* <CustomDropdown
                label="Value"
                options={valueOptions}
                value={selectedValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setSelectedValue(newValue);
                  if (selectedRuleId) {
                    const groupId = `group${selectedRuleId.split("-")[1]}`;
                    const ruleNumber = selectedRuleId.split("-child-")[1];

                    updateRuleConfiguration(groupId, `rule${ruleNumber}`, {
                      field: selectedField,
                      fieldType: selectedFieldType,
                      condition: selectedCondition,
                      value: newValue,
                    });
                  }
                }}
                disabled={!hasRules}
                style={{
                  opacity: hasRules ? 1 : 0.5,
                  "& select": {
                    color: hasRules ? "#B0B0B0" : "#666666",
                  },
                }}
              /> */}
            </CustomRow>

            {/* For object reference (Donot remove this code) */}

            {/* <CustomRow style={{ marginTop: "2rem" }}>
              <Column>
                <Text>Generated Configuration: {jsonExpression}</Text>
                <div
                  style={{
                    backgroundColor: "#0D0D0D",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                    fontFamily: "monospace",
                    whiteSpace: "pre-wrap",
                    overflowY: "auto",
                    maxHeight: "12rem",
                    color: "#47CCD6",
                  }}
                >
                  {JSON.stringify(groupConfigurations, null, 2)}
                </div>
              </Column>
            </CustomRow> */}
          </FeildsContainer>
        </CustomRow>
      </Container>
    </div>
  );
};
