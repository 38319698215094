import styled from "styled-components";
import PropTypes from "prop-types";
import GenericModal from "../../components/common/_genericModal";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../components/common/_buttonNewOne";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { env } from "../../env";

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  margin: 0;
  font-size: 1.666rem;
  font-weight: bold;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #b0b0b0;
`;

const vcNameMap = {
  github: "Github",
  gitlab: "Gitlab",
  bitbucket: "Bit Bucket",
};

export const VersionControlDisconnectModal = ({
  isOpen,
  onClose,
  vcProvider,
  refetchVcData,
}) => {
  const { showMessage } = useContext(SnackbarContext);

  const [confirmationText, setConfirmationText] = useState("");
  const [isDeleteApiLoading, setDeleteApiLoading] = useState(false);
  const [isNamesApiLoading, setNamesApiLoading] = useState(true);
  const [applicationNames, setApplicationNames] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getAllApplicationNamesForVc",
          {
            vcProvider,
          },
        );
        setApplicationNames(response.data.data.appNames);
        setNamesApiLoading(false);
      } catch (err) {
        console.log("Error: getAllApplicationNamesForVc", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            "Failed to get the application names, please try again later",
          );
        }
        setNamesApiLoading(false);
      }
    })();
  }, [showMessage, vcProvider]);

  const disconnectClick = useCallback(async () => {
    setDeleteApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/removeVcProvider",
        {
          vcProvider,
        },
      );
      showMessage(
        `Successfully disconnected ${vcNameMap[vcProvider]}`,
        "success",
      );
      setDeleteApiLoading(false);
      onClose();
      refetchVcData();
    } catch (err) {
      setDeleteApiLoading(false);
      console.log("Error: removeVcProvider", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage(
          "Failed to disconnect the provider, please try again later",
        );
      }
    }
  }, [onClose, refetchVcData, showMessage, vcProvider]);

  const hasConnectedApplications = applicationNames.length > 0;

  return (
    <GenericModal
      title={`Are you sure you want to disconnect ${vcNameMap[vcProvider]}?`}
      show={isOpen}
      onClose={onClose}
      disableCloseOnOutsideClick={isDeleteApiLoading || isNamesApiLoading}
    >
      <CustomRow>
        {hasConnectedApplications && (
          <CustomColumn>
            <Text
              style={{
                fontSize: "1.25rem",
                fontFamily: "Articulat CF Normal",
                letterSpacing: "0.5px",
                fontWeight: "bold",
                color: "#B0B0B0",
              }}
            >
              {" "}
              You have the following applications connected to this version
              control provider:
            </Text>
          </CustomColumn>
        )}
        <CustomColumn mb={1}>
          <Text
            style={{
              fontSize: "1.25rem",
              fontFamily: "Articulat CF Normal",
              letterSpacing: "0.5px",
              fontWeight: "bold",
            }}
          >
            {isNamesApiLoading ? "Loading..." : applicationNames.join(", ")}
          </Text>
        </CustomColumn>
        {hasConnectedApplications && (
          <CustomColumn mb={1.666}>
            <Text
              style={{
                fontSize: "1.25rem",
                fontFamily: "Articulat CF Normal",
                letterSpacing: "0.5px",
                fontWeight: "bold",
                color: "#B0B0B0",
              }}
            >
              You will lose all these applications if you logout. Are you sure
              you want to proceed?
            </Text>
          </CustomColumn>
        )}
        <CustomColumn mb={1.666}>
          <Text>Type 'Disconnect' to confirm</Text>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <InputFieldContainer>
            <InputField
              placeholder="Disconnect"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              readOnly={isDeleteApiLoading || isNamesApiLoading}
            />
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
            disabled={isDeleteApiLoading || isNamesApiLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            width="13.888rem"
            onClick={() => disconnectClick()}
            disabled={
              isDeleteApiLoading ||
              isNamesApiLoading ||
              confirmationText !== "Disconnect"
            }
          >
            Disconnect
          </StyledPrimaryButton2>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};

VersionControlDisconnectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vcProvider: PropTypes.oneOf(["github", "gitlab", "bitbucket"]).isRequired,
  refetchVcData: PropTypes.func.isRequired,
};

export default VersionControlDisconnectModal;
