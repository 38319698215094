import styled from "styled-components";

const Text = styled.div`
  width: ${({ $width }) => $width || ""};
  max-width: ${({ $maxWidth }) => $maxWidth || ""};
  font-family: ${({ $fontFamily }) => $fontFamily || ""};
  font-weight: ${({ $fontWeight }) => $fontWeight || ""};
  font-size: ${({ $fontSize }) => $fontSize || ""};
  font-style: ${({ $fontStyle }) => $fontStyle};
  color: ${({ $color }) => $color || ""};
  text-align: ${({ $textAlign }) => $textAlign || ""};
  letter-spacing: ${({ $letterSpacing }) => $letterSpacing || ""};
  line-height: ${({ $lineHeight }) => $lineHeight || ""};
  margin: ${({ $margin }) => $margin || ""};
  padding: ${({ $padding }) => $padding || ""};
  white-space: ${({ $noWrap }) => ($noWrap ? "nowrap" : "")};
  overflow: ${({ $noWrap }) => ($noWrap ? "hidden" : "")};
  text-overflow: ${({ $noWrap }) => ($noWrap ? "ellipsis" : "")};
  overflow-wrap: ${({ $noWrap }) => ($noWrap ? "" : "break-word")};
  cursor: ${({ $cursor }) => $cursor || ""};
`;

export default Text;
