import React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Column from "../../components/common/_customColumn";
import CustomRow from "../../components/common/_customRow";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import backIcon from "../../assets/images/left_arrow_icon.svg";
import Icon_strean from "../../assets/images/streamIcon.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import StreamEmptyState from "../../assets/images/StreamsEmptyState.svg";
import { ReactComponent as AddIcon } from "../../assets/images/AddRule.svg";
import ArrowDown from "../../assets/images/Arrow.svg";
import ArrowUp from "../../assets/images/up_icon.svg";
import { JsonModal } from "../../components/UtilityComponents/JsonModal";
import JsonDialogsonDialog from "../../components/UtilityComponents/JsonDialogsonDialog";
import { ConfigurationComponent } from "./ConfigurationComponent";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import { ReactComponent as StreanDetails } from "../../assets/images/Stream-Details.svg";
import Tooltip from "../../components/common/_tooltip";
import { ReactComponent as InfoIcon2 } from "../../assets/images/info.svg";
import SetupJson from "../../assets/images/Setup-Json.svg";
import UploadJsonIcon from "../../assets/images/Upload-Json.svg";
import "./CustomSwitch.css";
import CustomDropdown from "../../components/common/_customDropdown.jsx";
import CustomRightDropdown from "../../components/common/_customRightDropdown.jsx";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import GenericModal from "../../components/common/_genericModal";
import warning_icon from "../../assets/images/warning_yellow_icon.svg";
import Slider from "../../components/common/_slider";
import up_icon from "../../assets/images/up_icon.svg";
import down_icon from "../../assets/images/down_icon.svg";
import Spinner from "../../components/common/_spinner_teel.jsx";
import ReactFlowProviderContent from "../../pages/UtilityConnectorpages/GroupsRulesUI.jsx";
import AddDisabeled from "../../assets/images/AddDisabled.svg";
import { default as Spinner2 } from "../../components/common/_spinner.jsx";
import { ReactComponent as AddIcon2 } from "../../assets/images/AddRuleWhite.svg";

const StyledInput = styled.input`
  height: 3.33rem;
  padding-left: 0.8rem;
  outline: none;
  background: #2f2f2f;
  width: 100%;
  border-radius: 0.84rem;
  border: 0.138rem solid #777777;
  color: #ececec;
  font-size: 1.112rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  transition: border 0.2s ease;
  &:focus {
    border: 0.12rem solid #ececec;
  }

  &::placeholder {
    font-family: "Articulat CF Normal";
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #b0b0b0;
    font-size: 0.972rem;
  }
`;

const WarningText = styled.div`
  margin-top: 0.7rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.041rem;
  font-style: italic;
  line-height: 1.112rem;
  display: flex;
  align-items: center;
  color: #edb62e;
`;

const ModalButtonContainer = styled.div`
  margin-top: 2.084rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.39rem;
`;

const JsonContainer = styled.div`
  border-radius: 0.84rem;
  background-color: #2f2f2f;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  overflow-y: auto;
  overflow-x: hidden;
  width: 31.945rem;
  height: 12.5rem;
  position: relative;
  padding: 1rem;
`;

const StreamDetailsContainer = styled.div`
  padding: 1rem 1rem 2.5rem 1rem;
  margin-top: 1.12rem;
  border-radius: 0.84rem;
  background-color: #2f2f2f;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  overflow-y: auto; /* Enable scrolling */
  overflow-x: none;
  width: 31.945rem;
  height: 23.105rem;
  position: relative; /* Ensure dropdown options are positioned relative to this container */
  ::-webkit-scrollbar {
    width: 0.142rem;
    height: 5.555rem !important;
    /* position: relative; */
    margin: 0 0.5rem 0 0 !important; /* top right bottom left */
  }

  ::-webkit-scrollbar-thumb {
    /* background-color: #777777; */
    height: 5.555rem;
    border-radius: 0.071rem;
  }

  ::-webkit-scrollbar-track {
    margin-top: 1rem;
    margin-right: 2rem;
  }
  & {
    scrollbar-gutter: stable;
    margin-right: 1.5rem;
  }
`;

const CustomDropdownContainer = styled.div`
  position: relative;
  /* margin-top: 1rem; */
`;

const Label = styled.label`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.84rem;
  color: #b0b0b0;
  margin-bottom: 0.55rem;
  display: block;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputBox = styled.input`
  width: 29.722rem;
  padding: 0.8rem;
  margin-left: 1.112rem;
  background: #0d0d0d;
  border: 0.063rem solid #0d0d0d;
  border-radius: 0.6rem;
  color: #777777;
  font-size: 1rem;
  outline: none;
  padding-right: 2rem; /* Space for the arrow */
`;

const ArrowIcon = styled.img`
  position: absolute;
  right: 2rem;
  width: 1.67rem;
  height: 1.67rem;
  pointer-events: none;
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  background: #1f1f1f;
  border: 0.063rem solid #444;
  border-radius: 0.5rem;
  margin-top: 0.2rem;
  list-style: none;
  padding: 0;
  max-height: 10rem;
  overflow-y: auto;
`;

const DropdownItem = styled.li`
  padding: 0.8rem;
  cursor: pointer;
  &:hover {
    background: #444;
  }
`;

const AddTopicButton = styled.button`
  width: 100%;
  padding: 0.8rem;
  background: #3a3a3a;
  border: none;
  color: #fafafa;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  &:hover {
    background: #555;
  }
`;

const JsonTextContent = styled.div`
  /* flex-direction: column; */
  margin-top: 1.12rem;
  margin-left: 1.112rem;
`;

const JsonTextContainer = styled.div`
  width: 29.722rem;
  height: 2.5rem;
  /* margin: 1.12rem 0 1.12rem 0; */
`;

const JsonText = styled.div`
  color: #ececec;
  font-size: 0.97rem;
  // letter-spacing: 0.05rem;
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word; /* Ensures words break to the next line instead of leaving gaps */
  hyphens: auto;
`;

const StreanDetailsContent = styled.div`
  display: flex;
  /* flex-direction: column; */
`;

const Heading = styled.div`
  font-size: 1.67rem;
  text-align: left;
  /* margin-bottom: 2.25rem; */
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  margin-left: 0.84rem;
  color: #fafafa;
`;

const ConfigurationHeading = styled.div`
  font-size: 1.67rem;
  text-align: left;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.5rem;
  margin-left: 0.84rem;
  color: #fafafa;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  margin-top: 0.277rem;
  margin-bottom: 1.39rem;
  font-size: 1.67rem;
`;

const HeadingContent = styled.div`
  display: flex;
  gap: 1.112rem;
  font-size: 1.67rem;
  font-weight: bold;
`;

const Icon = styled.div`
  height: 2.225rem;
  width: 2.225rem;
  cursor: pointer;
`;

const StreamIcon = styled.div`
  height: 2.225rem;
  width: 2.225rem;
  border-radius: 0.28rem;
  background-color: #4b4b4b;
  padding: 0.28rem;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
`;

const ConfigurationContainer = styled.div`
  height: 36.79rem;
  width: 64.722rem;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  display: flex;
  overflow-y: hidden;
  overflow-x: none;
  flex-direction: column;
`;

const ConfigurationHeader = styled.div`
  height: 7.778rem;
  margin: 1.12rem 1.112rem 0 1.112rem;
  /* width: 64.73rem; */
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
`;

// const ConfiguraitonTextBox = styled.div``;

const AddGroupText = styled.div`
  color: #47ccd6;
  font-size: 1.22rem;
  &:hover {
    color: #f6f6f6;
  }
`;

const UploadJsonContainer = styled.div`
  width: 29.722rem;
  height: 3.333rem;
  background-color: #4b4b4b;
  margin: 1.112rem;
  border-radius: 0.55rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UploadJson = styled.div`
  /* width: 14.861rem; */
  /* height: 3.333rem; */
  color: #f6f6f6;
  font-size: 1.112rem;
  font-weight: bold;
  /* padding: 0.84rem 3.958rem; */
  border-radius: 0.55rem;
`;
const ReadTopic = styled.div`
  width: 14.861rem;
  /* padding: 0.84rem 3rem; */
  color: #f6f6f6;
  font-size: 1.112rem;
  font-weight: bold;
`;

const PreviewText = styled.span`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center; /* Align icon and text */
  gap: 0.4rem; /* Add spacing between icon and text */
  color: #777777;
  font-size: 1.12rem;
`;

const Expression = styled.div`
  font-size: 1.112rem;
  font-family: "Articulat CF Medium";
  color: #b0b0b0;
`;

const AddGroup = styled.span`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center; /* Align icon and text */
  gap: 0.4rem; /* Add spacing between icon and text */
`;

const TextField = styled.input`
  flex: 1;
  padding-left: 0.8rem;
  font-size: 1.12rem;
  color: #fff;
  background-color: #0d0d0d;
  border: none;
  border-radius: 0 0.55rem 0.55rem 0; /* Rounded only on right side */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent text from wrapping */
  outline: none; /* Remove focus outline */
`;

const PreviewContainer = styled.div`
  width: 30rem;
  height: 3.333rem;
  padding: 0.92rem 1rem;
  background-color: #0d0d0d;
  border-radius: 0.55rem;
  font-size: 0.93rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  border: 0.07rem solid #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Needed for absolute dropdown positioning */
`;

const PreviewContainerForRules = styled.div`
  width: 62.569rem;
  height: 3.333rem;
  padding: 0.92rem 1rem;
  background-color: #0d0d0d;
  border-radius: 0.55rem;
  font-size: 0.93rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  border: 0.063rem solid #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Needed for absolute dropdown positioning */
`;

const Pipe = styled.div`
  width: 0.07rem;
  height: 3.333rem;
  border-left: 0.07rem solid #4b4b4b;
  margin-left: 1rem;
`;

const HorizontalLine = styled.div`
  width: 64.722rem;
  border-top: 0.07rem solid #4b4b4b;
`;

const EmptystateText = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.25rem;
  color: #b0b0b0;
  width: 31.1805rem;
  margin-top: 0.4rem;
  line-height: 1.625rem;
  margin-left: 3.89rem;
`;

const EmptystateHeader = styled.div`
  margin-top: 2.89rem;
  color: #f6f6f6;
  font-size: 1.67rem;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-left: 3.89rem;
`;

const Footer = styled.div`
  height: 5.55rem;
  padding: 1.5rem 0 1.112rem 1.112rem;
`;

const Tab = styled.div`
  flex: 1;
  text-align: center;
  height: 3.333rem;
  width: 14.861rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.isActive ? "#777777" : "#4B4B4B")};
  color: ${(props) => (props.isActive ? "#F6F6F6" : "#777777")};
  font-size: 1.112rem;
  font-weight: bold;
  border-radius: 0.55rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.isActive ? "#777777" : "#5c5c5c")};
  }
`;
const ReadTopicDropdownContainer = styled.div`
  position: absolute;
  top: 46%;
  left: 2.4%;
  z-index: 1000;
  transform: translateX(-50%);
`;

const ReadTopicDropdown = styled.div`
  width: 22.45rem;
  height: 11.945rem;
  background-color: #2f2f2f;
  border: 0.063rem solid #4b4b4b;
  border-radius: 0.55rem;
  z-index: 999; /* Ensure it appears on top */
  position: absolute;
  top: 100%; /* Places it right below the parent */
  left: 0;
  margin-top: 0.5rem; /* Add spacing */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
`;

const StreamInputDropdown = styled.div`
  width: 29.7rem;
  height: 11.945rem;
  background-color: #2f2f2f;
  border: 0.063rem solid #4b4b4b;
  border-radius: 0.55rem;
  z-index: 999;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const StreamInputDropdownContainer = styled.div`
  position: absolute;
  top: 68%;
  left: 2.25%;
  z-index: 1000;
  transform: translateX(-50%);
`;
const StreamOutputDropdown = styled.div`
  width: 29.7rem;
  height: 11.945rem;
  background-color: #2f2f2f;
  border: 0.063rem solid #4b4b4b;
  border-radius: 0.55rem;
  z-index: 999;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const StreamOutputDropdownContainer = styled.div`
  position: absolute;
  top: 79%;
  left: 2.25%;
  z-index: 1000;
  transform: translateX(-50%);
`;

const Configuration = styled.div`
  height: 29.027rem;
  width: 64.722rem;
`;

const InputFieldContainer = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0.8rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.84rem;
  background-color: #0d0d0d;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 0.972rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.112rem;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  text-align: left;

  &::placeholder {
    color: #777777;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Arrow = styled.span`
  font-size: 0.9722rem;
  cursor: pointer;
  color: #b0b0b0;
  user-select: none;

  &:hover {
    color: #ffffff;
  }
`;

const ValueTextField = styled.span`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 1rem;
`;
const TitleTextField = styled.input`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  height: 3.333rem;
  flex: 1 1 0%;
  padding: 0.8rem;
  color: rgb(255, 255, 255);
  background-color: #0d0d0d;
  border: none;
  border-radius: 0.5rem;
  overflow-x: auto;
  white-space: nowrap;
  outline: none;
`;

const TitleLabel = styled.label`
  color: #b3b3b3;
  font-size: 0.972rem;
`;

export const UtilityConfigurationPage = ({}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [activeTab, setActiveTab] = useState("upload");
  const [isStreamDropdownOpen, setisStreamDropdownOpen] = useState(false);
  const [isJsonModalOpen, setIsJsonModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [topic, setTopic] = React.useState("");
  const [streamTopic, setStreamTopic] = useState("");
  const [inputTopic, setInputTopic] = useState("");
  const [outputTopic, setOutputTopic] = useState("");
  const [isConfigurationOpen, setisConfigurationOpen] = useState(false);
  const [filteredCustomConnectorList, setFilteredCustomConnectorList] =
    useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [newTopicNamesMapper, setNewTopicNamesMapper] = useState({}); // mapper to store the new topicnames entered
  const [newTopicModalMapper, setNewTopicModalMapper] = useState({}); // mapper to store the state of new topic modal
  const [configs, setConfigs] = useState([]);
  const [configRules, setConfigRules] = useState({});
  const [content, setContent] = useState("");
  const [previewData, setPreviewData] = useState({});
  const [isTestConnectionApplicable, setTestConnectionAppicable] =
    useState(false);
  const [isAllTopicsLoading, setIsAllTopicsLoading] = useState(false);

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedMainTopic, setSelectedMainTopic] = useState("");
  const [selectedMainTopicTemp, setSelectedMainTopicTemp] = useState("");
  const [selectedInputTopic, setSelectedInputTopic] = useState("");
  const [selectedPrimaryOutputTopic, setSelectedPrimaryOutputTopic] =
    useState("");
  const [selectedSecondaryOutputTopic, setSelectedSecondaryOutputTopic] =
    useState("");
  const [jsonValue, setJsonValue] = useState("");
  const [expirationOffset, setExpirationOffset] = useState(0);
  const [kafkaLastMessage, setKafkaLastMessage] = useState("");
  const [isMainTopicChanged, setIsMainTopicChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expressionText, setExpressionText] = useState("");
  const [expressionTextObj, setExpressionTextObj] = useState("");
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [rulesError, setRulesError] = useState(true);
  const [handleAddParent, setHandleAddParent] = useState(() => {});
  const [logic, setLogic] = useState("");
  const [title, setTitle] = useState("");
  const [groupsAndRules, setGroupsAndRules] = useState([]);
  const [connectionsVal, setConnectionsVal] = useState([]);
  const [jsonOnlyKeys, setJsonOnlyKeys] = useState([]);
  const [groupBy, setGroupBy] = useState("");
  const [missedRulesTemp, setMissedRulesTemp] = useState([]);
  const [isGroupError, setIsGroupError] = useState(false);
  const [fieldOptionValues, setFieldOptionValues] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const previewFunction = (item) => {
    setExpressionText(item);
  };

  const handleLogic = (item) => {
    setLogic(item);
  };

  const handlePreview = (item) => {
    console.log(item, "expressionTextObj");
    setExpressionTextObj(item);
  };

  const handleGroupsAndRules = (groups, connections) => {
    setGroupsAndRules(groups);
    setConnectionsVal(connections);
  };

  const [ruleConfigurations, setRuleConfigurations] = useState({});
  const [logicStructure, setLogicStructure] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  // useEffect(() => {
  //   try {
  //     let exp = generateExpression(ruleConfigurations);
  //     let formattedExp = formatExpression(exp);
  //     setExpressionText(formattedExp);
  //     setLogicStructure(extractLogicStructure(formattedExp));
  //   } catch (error) {
  //     console.error("Error generating expression:", error);
  //     setExpressionText(""); // Fallback to an empty expression
  //     setLogicStructure("");
  //   }
  // }, [ruleConfigurations]);

  const updateRuleConfigurationNew = (groupId, ruleId, config, type) => {
    setRuleConfigurations((prevConfig) => {
      const newConfig = { ...prevConfig };

      if (type === "delete") {
        if (newConfig[groupId]) {
          delete newConfig[groupId][ruleId];

          // If the group becomes empty, remove the group as well
          if (Object.keys(newConfig[groupId]).length === 0) {
            delete newConfig[groupId];
          }
        }
      } else {
        // Ensure the group exists
        if (!newConfig[groupId]) newConfig[groupId] = {};

        // Update or add the rule
        newConfig[groupId][ruleId] = { ...config };
      }

      return { ...newConfig }; // Create a fresh state reference
    });

    if (type === "delete") {
      // Manually call the function after state updates
      setTimeout(() => {
        try {
          let exp = generateExpression(ruleConfigurations);
          let formattedExp = formatExpression(exp);
          setExpressionText(formattedExp);
          setLogicStructure(extractLogicStructure(formattedExp));
        } catch (error) {
          console.error("Error generating expression:", error);
          setExpressionText("");
          setLogicStructure("");
        }
      }, 0);
    }
  };

  // useEffect(() => {
  //   console.log(expressionText, "expressionText");
  // }, [expressionText]);
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      .info-icon:hover path {
        stroke: #47CCD6;
      }
      .info-icon-2:hover path {
        stroke: #47CCD6;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Function to fetch the configs and topics for non-telematics devices
  const fetchConfigsAndTopics = useCallback(async () => {
    try {
      let stringifiedData = JSON.stringify({
        // connectorName: "Geofence",
        // type: "GEOFENCE_TRANSFORM",
        connectorName: location.state?.selectedConnectorName,
        type: location.state?.selectedConnectorType,
      });

      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConnectorConfigs",
        stringifiedData,
      );
      setContent(fetchConfigResponse.data.data.templateContent);
      setConfigs(
        fetchConfigResponse.data.data.configJson.config.map((obj) => ({
          value: "",
          ...obj,
        })),
      );
      setConfigRules(fetchConfigResponse.data.data.rules.routing);

      setPreviewData((prev) => ({
        ...prev,
        connectionType: location.state?.selectedConnectorType,
        category: location.state?.selectedConnectorCategory,
        name: location.state?.selectedConnectorName,
        description: location.state?.selectedConnectorDescription,
        interfaces: [],
      }));

      if (fetchConfigResponse.data.data.configJson.testConnection === true) {
        setTestConnectionAppicable(true);
      }
    } catch (err) {
      console.log("Error: getConnectorConfigs", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch the configs for the selected connector");
      }
      setConfigs([]);
      setConfigRules({});
    }
  }, [location.state, showMessage]);

  // Function to create a new topic and store it if the response is successful
  const createNewTopic = async (index, newTopicName, fieldDirection) => {
    try {
      let topicCreationResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "kafka-management/createTopic",
        JSON.stringify({
          topicName: newTopicName,
        }),
      );
      if (topicCreationResponse.data.status === "SUCCESS") {
        if (fieldDirection === "inputTopic") {
          setSelectedInputTopic(newTopicName);
        } else if (
          fieldDirection === "outputTopic" ||
          fieldDirection === "primaryOutputTopic"
        ) {
          setSelectedPrimaryOutputTopic(newTopicName);
        } else {
          setSelectedSecondaryOutputTopic(newTopicName);
        }
        setAllTopics((prev) => {
          let tempTopics = [...prev, newTopicName];
          return tempTopics;
        });
        setConfigs((prev) => {
          let tempConfigs = [...prev];
          tempConfigs[index] = {
            ...tempConfigs[index],
            value: newTopicName,
          };
          return tempConfigs;
        });
        setPreviewData((prev) => {
          let prevData = prev;
          prevData.interfaces[index] = {
            name: newTopicName,
            direction: configs[index].direction,
          };
          return prevData;
        });
        setNewTopicNamesMapper((prev) => {
          let tempMap = { ...prev, [index]: null };
          return tempMap;
        });
        setNewTopicModalMapper((prev) => {
          let tempMap = { ...prev, [index]: false };
          return tempMap;
        });
      } else {
        showMessage(topicCreationResponse.data.message);
      }
    } catch (err) {
      console.log("Err createNewTopic", err);
      showMessage("Error in creating new topic");
    }
  };

  const handleViewJson = () => {
    setIsJsonModalOpen(true);
  };

  const handleCloseModal = () => {
    setJsonValue(jsonValue);
    setIsJsonModalOpen(false);
  };

  const toggleStreamDropdown = () => {
    setisStreamDropdownOpen(true);
  };

  const goBackClickHandler = useCallback(() => {
    if (!isLoading) history.push("/connectors");
  }, [history]);

  const handleConnectorsClick = useCallback(() => {
    if (!isLoading) history.push("/connectors");
  }, [history]);

  const handleStreamTopicSelect = (selectedTopic) => {
    setStreamTopic(selectedTopic);
    setisStreamDropdownOpen(false);
  };

  const handleConfiguration = () => {
    if (
      !isFieldsLoading && isReadTopic
        ? isValidJsonDataCheck(kafkaLastMessage)
        : isValidJsonDataCheck(jsonValue)
    ) {
      setisConfigurationOpen(true);
    }
  };

  const [isReadTopic, setIsReadTopic] = useState(
    location.state?.selectedConnectorName === "Alerts" ? true : false,
  );

  const toggleSwitch = () => {
    if (!isFieldsLoading) {
      let selection = !isReadTopic;
      setIsReadTopic(selection);
      if (selection === true) {
        if (kafkaLastMessage) {
          setIsJsonModalOpen(true);
          setKafkaLastMessage(jsonValue);
        } else {
          setKafkaLastMessage("");
          setFieldOptionValues([]);
        }
      }
    }
  };

  const fetchKafkaLastMessage = async () => {
    if (!isFieldsLoading) {
      setIsFieldsLoading(true);
      try {
        let payload = { topicName: selectedMainTopic };
        let kafkaLastMessgeValue = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "kafka-management/getLastMessageFromTopic",
          payload,
        );
        setIsFieldsLoading(false);
        if (kafkaLastMessgeValue.data.status === "SUCCESS") {
          let gotVal = kafkaLastMessgeValue?.data?.data;
          let val = "";
          if (typeof gotVal === "object") {
            val = JSON.stringify(gotVal);
          } else if (typeof gotVal === "string" && isValidJson(gotVal)) {
            val = gotVal;
          }
          if (val && val !== "" && val !== "null") {
            setKafkaLastMessage(val);
            setIsJsonModalOpen(true);
          } else {
            showMessage(
              "Selected topic doesn't have valid JSON in data",
              "error",
            );
            setKafkaLastMessage("");
            setFieldOptionValues([]);
            setJsonOnlyKeys([]);
          }
        } else {
          showMessage(kafkaLastMessgeValue.data.remarks);
          setKafkaLastMessage("");
          setFieldOptionValues([]);
          setJsonOnlyKeys([]);
        }
      } catch (err) {
        setIsFieldsLoading(false);
        setKafkaLastMessage("");
        setFieldOptionValues([]);
        setJsonOnlyKeys([]);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to fetch topics !!!");
        }
      }
    }
  };

  const isValidJson = (str) => {
    try {
      const parsed = JSON.parse(str);
      return typeof parsed === "object" && parsed !== null;
    } catch (e) {
      return false;
    }
  };

  const isValidJsonDataCheck = (val) => {
    try {
      let parsed = "";
      if (typeof val === "string") {
        parsed = JSON.parse(val);
      } else {
        parsed = val;
      }
      return Object.keys(parsed).length > 0 ? true : false;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (selectedMainTopic) fetchKafkaLastMessage();
  }, [selectedMainTopic]);

  const fetchAllTopics = useCallback(async () => {
    setIsAllTopicsLoading(true);
    try {
      let allTopicsResponse = await axiosAdapter(
        "GET",
        env.REACT_APP_URL + "kafka-management/listTopics",
      );
      setAllTopics(allTopicsResponse.data.data);
      setIsAllTopicsLoading(false);
    } catch (err) {
      setIsAllTopicsLoading(false);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to fetch topics !!!");
      }
      setAllTopics([]);
    }
  }, [showMessage]);

  useEffect(() => {
    fetchAllTopics();
    fetchConfigsAndTopics();
  }, [location.state, fetchAllTopics, fetchConfigsAndTopics]);

  const handleIncrement = () => {
    if (expirationOffset < 1800) {
      setExpirationOffset((prev) => prev + 1);
    } else {
      showMessage("Alert Duration must be between 0 and 1800 seconds");
    }
  };

  const handleDecrement = () => {
    if (expirationOffset > 0) {
      setExpirationOffset((prev) => prev - 1);
    } else {
      showMessage("Alert Duration must be between 0 and 1800 seconds");
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const num = newValue === "" ? 0 : parseInt(newValue, 10);

    if (num >= 0 && num <= 1800) {
      setExpirationOffset(num);
    } else {
      showMessage("Alert Duration must be between 0 and 1800 seconds");
    }
  };

  const setConnectorConfigsAndTestFunction = async (configArg) => {
    let tempConfigurationId;
    try {
      let stringifiedData = JSON.stringify({
        ...configArg,
        workspaceId: parseInt(localStorage.getItem("selectedWorkspaceId") || 0),
      });
      let setConfigsResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setConnectorConfigsNew",
        stringifiedData,
      );
      tempConfigurationId = setConfigsResponse.data.data.id;
      if (!tempConfigurationId) {
        setIsLoading(false);
        showMessage("Error in setting configuration id");
      } else {
        let interfaces = [];
        configs.forEach((item) => {
          if (item.type === "topic") {
            if (item.name === "inputTopic") {
              interfaces.push({
                name: selectedInputTopic,
                direction: item.direction,
              });
            }

            if (item.name === "secondaryOutputTopic") {
              interfaces.push({
                name: selectedSecondaryOutputTopic,
                direction: item.direction,
              });
            }

            if (
              item.name === "outputTopic" ||
              item.name === "primaryOutputTopic"
            ) {
              interfaces.push({
                name: selectedPrimaryOutputTopic,
                direction: item.direction,
              });
            }
          }
        });
        // let interfaces = [
        //   {
        //     name: selectedInputTopic,
        //     direction: "input",
        //   },
        // ];
        // if (location.state?.selectedConnectorName === "Alerts") {
        //   interfaces.push({
        //     name: selectedPrimaryOutputTopic,
        //     direction: "output",
        //   });
        // } else {
        //   interfaces.push(
        //     {
        //       name: selectedPrimaryOutputTopic,
        //       direction: "output",
        //     },
        //     {
        //       name: selectedSecondaryOutputTopic,
        //       direction: "output",
        //     },
        //   );
        // }
        let newConnectorObject = {
          id: tempConfigurationId.toString(),
          type: location.state?.selectedConnectorType,
          data: {
            connectionType: location.state?.selectedConnectorCategory,
            category: location.state?.selectedConnectorType,
            name: location.state?.selectedConnectorName,
            description: location.state?.selectedConnectorDescription,
            interfaces: interfaces,
            title: title,
            firstNode: false,
            configId: tempConfigurationId,
            // icon: location.state?.selectedConnectorIcon,
          },
          targetPosition: "left",
          sourcePosition: "right",
          position: {
            x: null,
            y: null,
          },
        };
        let stringifiedData = JSON.stringify({
          workspaceId: parseInt(
            localStorage.getItem("selectedWorkspaceId") || 0,
          ),
          connectorId: tempConfigurationId,
          isDevice: false,
          newConnectorObject,
        });
        let deployResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/setTransformDeploymentNew",
          stringifiedData,
        );
        if (deployResponse.data.code === 200) {
          setIsLoading(false);
          showMessage("Utility Deployed Successfully", "success");
          setTimeout(() => {
            history.push("/connectorsPlayground");
          }, 1000);
        } else {
          setIsLoading(false);
          showMessage(deployResponse.data.remarks);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log("Err: setConnectorConfigsNew", err);
    }
  };

  const handleBuildAction = async () => {
    if (!isFieldsLoading && !rulesError && !isAllTopicsLoading && !isLoading) {
      if (
        isReadTopic && location.state?.selectedConnectorName === "Alerts"
          ? !isGroupError
          : true
      ) {
        setIsLoading(true);
        try {
          const configArg = buildStandardConfig(configs, location.state);
          await setConnectorConfigsAndTestFunction(configArg);
        } catch (err) {
          setIsLoading(false);
          console.log("Err: setConnectorConfigsNew", err);
          showMessage(err.message);
        }
      }
    }
  };

  const buildStandardConfig = (configs, locationState) => {
    const configObj = {};

    configs.forEach((item) => {
      let value = "";
      if (item.name === "inputTopic") {
        value = selectedInputTopic;
      }

      if (location.state?.selectedConnectorName === "Alerts") {
        if (item.name === "alertDuration") {
          let alertDuration = parseInt(expirationOffset, 10);
          value = alertDuration * 1000;
        }
      } else {
        if (item.name === "secondaryOutputTopic") {
          value = selectedSecondaryOutputTopic;
        }
      }
      if (item.name === "outputTopic" || item.name === "primaryOutputTopic") {
        value = selectedPrimaryOutputTopic;
      }

      if (item.name === "groupBy") {
        value = groupBy;
      }

      if (item.name === "title") {
        value = title;
      }

      if (item.name) {
        configObj[item.name] = value;
      }
    });

    const parsedObj = JSON.parse(expressionTextObj);
    configObj.fields = parsedObj?.fields ? parsedObj?.fields : "";
    configObj.type = parsedObj?.type ? parsedObj?.type : "";
    configObj.operator = parsedObj?.operator ? parsedObj?.operator : "";
    configObj.values = parsedObj?.values ? parsedObj?.values : "";
    configObj.logic = logic;

    let utilityGroup = {
      groupsAndRulesJson: JSON.stringify(groupsAndRules),
      uploadedJson: JSON.stringify(jsonValue),
    };
    configObj.utilityGroup = JSON.stringify(utilityGroup);

    console.log(configObj, "configObj===========");

    // setIsLoading(false);

    const config = {
      connectorName: locationState?.selectedConnectorName,
      type: locationState?.selectedConnectorType,
      config: configObj,
    };
    return config;
  };

  const generateExpression = (data) => {
    let expressions = [];

    const conditionMap = {
      BETWEEN: "BETWEEN",
      EQUALS: "==",
      NOT_EQUALS: "!=",
      GREATER_THAN: ">",
      GREATER_THAN_OR_EQUALS: ">=",
      LESS_THAN: "<",
      LESS_THAN_OR_EQUALS: "<=",
      CONTAINS: "CONTAINS",
      STARTS_WITH: "STARTS WITH",
      ENDS_WITH: "ENDS WITH",
      MATCHES: "MATCHES",
      IN: "IN",
    };

    Object.keys(data).forEach((groupKey) => {
      let group = data[groupKey];
      let rules = Object.keys(group)
        .map((ruleKey) => {
          let rule = group[ruleKey];
          if (
            rule.value !== undefined &&
            rule.value !== null &&
            rule.value !== ""
          ) {
            let operator = conditionMap[rule.condition] || rule.condition;
            return `${rule.field} ${operator} ${rule.value}`;
          }
          return null;
        })
        .filter(Boolean); // Remove null values

      if (rules.length > 0) {
        let groupExpression = rules.join(" AND ");
        expressions.push(`(${groupExpression})`);
      }
    });

    return expressions.length > 0 ? expressions.join(" AND ") : "";
  };

  // const transformRules = (data) => {
  //   let fields = [];
  //   let types = [];
  //   let operators = [];
  //   let values = [];
  //   let logicParts = [];

  //   let groupKeys = Object.keys(data);
  //   for (let i = 0; i < groupKeys.length; i++) {
  //     let group = data[groupKeys[i]];
  //     let ruleKeys = Object.keys(group);

  //     ruleKeys.forEach((ruleKey) => {
  //       let rule = group[ruleKey];
  //       fields.push(rule.field);
  //       types.push(rule.fieldType);
  //       operators.push(rule.condition.toUpperCase());

  //       let formattedValue = "";
  //       if (typeof rule.value === "boolean") {
  //         formattedValue = rule.value ? 1 : 0;
  //       } else {
  //         formattedValue = rule.value;
  //       }

  //       values.push(formattedValue);
  //     });

  //     logicParts.push("AND");
  //   }

  //   return {
  //     field: fields.join("|"),
  //     logic: "AND",
  //     operator: operators.join("|"),
  //     type: types.join("|"),
  //     values: values.join("|"),
  //   };
  // };
  const onclickProcced = () => {
    // setIsJsonModalOpen(true);
    setSelectedMainTopic(selectedMainTopicTemp);
    setIsMainTopicChanged(false);
  };

  function formatExpression(expression) {
    const operators = {
      greater_than: ">",
      less_than: "<",
      equals: "==",
      not_equals: "!==",
      greater_than_or_equal: ">=",
      less_than_or_equal: "<=",
    };

    expression = expression.replace(/\bAND\b/g, "&&").replace(/\bOR\b/g, "||");

    Object.keys(operators).forEach((key) => {
      const regex = new RegExp(key, "g");
      expression = expression.replace(regex, operators[key]);
    });

    expression = expression.replace(/(\!==|>=|<=|==|!=|>|<|&&|\|\|)/g, " $1 ");

    return expression;
  }

  const extractLogicStructure = (expression) => {
    try {
      if (!expression || typeof expression !== "string") {
        throw new Error("Invalid expression format");
      }

      // Extract groups inside parentheses
      let groups = expression.match(/\([^()]*\)/g) || [];

      let transformedExpression = expression;
      let transformedGroups = [];

      groups.forEach((group) => {
        let operators = group.match(/&&|\|\|/g) || [];
        let logic = `(${operators.map((op) => (op === "&&" ? "AND" : "OR")).join("|")})`;
        transformedGroups.push(logic);
        transformedExpression = transformedExpression.replace(
          group,
          `__GROUP_${transformedGroups.length - 1}__`,
        );
      });

      // Extract top-level logical operators (those between groups)
      let mainOperators = transformedExpression.match(/&&|\|\|/g) || [];
      let mainLogic = mainOperators
        .map((op) => (op === "&&" ? "AND" : "OR"))
        .join("");

      // Reconstruct the final output
      let result = transformedGroups.length
        ? transformedGroups.join(mainLogic)
        : "AND";

      return result;
    } catch (error) {
      console.error("Error parsing logic structure:", error);
      return "";
    }
  };

  useEffect(() => {
    if (!fieldOptionValues.some((op) => op.label === groupBy)) {
      setIsGroupError(true);
    } else {
      setIsGroupError(false);
    }
  }, [fieldOptionValues, groupBy]); // Include groupBy as a dependency

  // useEffect(() => {
  //   if (missedRulesTemp.includes(groupBy)) {
  //     setGroupBy("");
  //     setIsGroupError(true);
  //   } else {
  //     setIsGroupError(false);
  //   }
  // }, [missedRulesTemp]);

  return (
    <div
      style={{
        backgroundColor: "#171717",
        height: "calc(100vh - 2rem)",
        overflow: "auto",
        padding: "1.112rem 1.112rem 0.6rem 1.112rem",
      }}
    >
      <CustomRow style={{ display: "flex", alignItems: "center" }}>
        <Column xs={6}>
          <HeadingContainer>
            <HeadingContent>
              <Icon onClick={handleConnectorsClick}>
                <img
                  src={backIcon}
                  alt="Back Icon"
                  style={{ width: "2.225rem", height: "2.225rem" }}
                />
              </Icon>
              <Title>{`Configure ${
                location.state?.selectedConnectorName || "Your Connector"
              }`}</Title>
            </HeadingContent>
          </HeadingContainer>
        </Column>
      </CustomRow>
      <CustomRow style={{}}>
        <Column xs={4.05} style={{ height: "36rem" }}>
          {/******************************************************** Upload/Read section **************************************************************/}
          <JsonContainer>
            <CustomRow style={{ justifyContent: "space-between" }}>
              <AddGroup>
                <img
                  src={SetupJson}
                  style={{ height: "2rem", width: "2rem" }}
                />
                <Heading>Setup JSON</Heading>
              </AddGroup>
              {(isFieldsLoading || isAllTopicsLoading) && (
                <CustomRow
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Spinner animation="border" size="md" />
                </CustomRow>
              )}
            </CustomRow>
            <CustomRow style={{ marginTop: "1rem" }}>
              <JsonText>
                Choose an input topic to auto-fill the JSON or manually upload
                your own. This will automatically populate the required fields.
              </JsonText>
            </CustomRow>
            <CustomRow
              style={{
                justifyContent: "normal",
                display: "flex",
                marginTop: "1rem",
              }}
            >
              <PreviewContainer>
                <div
                  style={{
                    minWidth: "13rem",
                    maxWidth: "13rem",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <div className="switch-container" onClick={toggleSwitch}>
                    <span
                      className="text-on"
                      style={{
                        color: !isReadTopic ? "#47CCD6" : "#ECECEC",
                        fontFamily: "Articulat CF Normal",
                        fontWeight: "bold",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Upload
                    </span>
                    <div className={`switch ${isReadTopic ? "on" : "off"}`}>
                      <span
                        className={`slider ${isReadTopic ? "slider-on" : "slider-off"}`}
                      ></span>
                    </div>
                    <span
                      className={`text-off`}
                      style={{
                        color: isReadTopic ? "#47CCD6" : "#ECECEC",
                        fontFamily: "Articulat CF Normal",
                        fontWeight: "bold",
                        letterSpacing: "0.5px",
                      }}
                    >
                      Read Topic
                    </span>
                  </div>
                </div>

                <Pipe />
                {!isReadTopic ? (
                  <div
                    style={{
                      width: "15rem",
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: "1rem",
                      cursor: "pointer",
                      fontFamily: "Articulat CF Medium",
                      fontWeight: "bold",
                      letterSpacing: "0.5px",
                    }}
                    onClick={handleViewJson}
                  >
                    <span>
                      {jsonValue ? "Re-upload/Edit JSON" : "Upload JSON"}
                    </span>
                    <img
                      src={UploadJsonIcon}
                      alt="Toggle Dropdown"
                      style={{ height: "1.4rem", width: "1.4rem" }}
                    />
                  </div>
                ) : (
                  <CustomDropdown
                    widthValue={"14.6rem"}
                    heightValue={"3.1rem"}
                    isFromUtilSetupJson={"3.1rem"}
                    dropdownItems={allTopics}
                    placeholder={"Choose a Topic"}
                    isSearchRequired={true}
                    onSelect={(item) => {
                      if (selectedMainTopic && selectedMainTopic !== item) {
                        setIsMainTopicChanged(true);
                        setSelectedMainTopicTemp(item);
                      } else {
                        setSelectedMainTopic(item);
                        // setIsJsonModalOpen(true);
                      }
                    }}
                    selectedItem={allTopics.find(
                      (item) => item === selectedMainTopic,
                    )}
                    nameFunction={(item) => item}
                    filterFunction={(arr, text) =>
                      arr.filter((item) =>
                        item.toLowerCase().includes(text.toLowerCase()),
                      )
                    }
                    noMargin={true}
                  />
                )}
                {isJsonModalOpen && (
                  <>
                    <JsonModal
                      fieldOptionValues={fieldOptionValues}
                      handleCloseModal={handleCloseModal}
                      setEditDialogOpen={setEditDialogOpen}
                      isEditDialogOpen={isEditDialogOpen}
                      setJsonValue={setJsonValue}
                      jsonValue={isReadTopic ? kafkaLastMessage : jsonValue}
                      isReuploadJson={jsonValue ? true : false}
                      isReadTopic={isReadTopic}
                      setGroupsAndRules={setGroupsAndRules}
                      groupsAndRules={groupsAndRules}
                      setisConfigurationOpen={setisConfigurationOpen}
                      isConfigurationOpen={isConfigurationOpen}
                      setConnectionsVal={setConnectionsVal}
                      connectionsVal={connectionsVal}
                      setJsonOnlyKeys={setJsonOnlyKeys}
                      setFieldOptionValues={setFieldOptionValues}
                    />
                  </>
                )}
              </PreviewContainer>
            </CustomRow>
            {isMainTopicChanged && (
              <JsonDialogsonDialog
                dialogTitle="Changing the Topic will Reset Flow"
                showEditDialog={isMainTopicChanged}
                onDialogClose={() => {
                  setIsMainTopicChanged(false);
                  setSelectedMainTopicTemp("");
                }}
                buttonName={"Change Topic"}
                descriptionText={
                  "Switching topics will impact your existing progress, JSON, and all related data in the utility. This action cannot be undone. Do you want to proceed?"
                }
                onclickProcced={onclickProcced}
              />
            )}
          </JsonContainer>

          {/******************************************************** Stream Details section **************************************************************/}

          {!isJsonModalOpen && (
            <StreamDetailsContainer>
              <StreanDetailsContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CustomRow>
                  <StreanDetails style={{ height: "2rem", width: "2rem" }} />
                  <Heading>Stream Details</Heading>
                </CustomRow>
                {isAllTopicsLoading && (
                  <CustomRow
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Spinner
                      animation="border"
                      size="md"
                      style={{ color: "#47CCD6" }}
                    />
                  </CustomRow>
                )}
              </StreanDetailsContent>

              {configs?.map((field, index) => {
                if (field.type === "topic") {
                  return (
                    <>
                      {(
                        isReadTopic
                          ? !selectedMainTopic || !kafkaLastMessage
                          : !jsonValue
                      ) ? (
                        <Label
                          style={{
                            marginTop: "1rem",
                            color: "#777777",
                          }}
                        >
                          {`${field.label}`} {field.isRequired && " *"}
                          {/* {field.isRequired && " *"} */}
                          {(location.state?.selectedConnectorName !==
                            "Alerts" ||
                            (location.state?.selectedConnectorName !==
                              "Split" &&
                              field.type === "topic")) && (
                            <InfoIcon2
                              style={{
                                height: "1.2rem",
                                width: "1rem",
                                marginLeft: "0.2rem",
                              }}
                            />
                          )}
                        </Label>
                      ) : (
                        <Label style={{ marginTop: "1rem" }}>
                          {`${field.label}`} {field.isRequired && " *"}
                          {/* {field.isRequired && " *"} */}
                          {(location.state?.selectedConnectorName !==
                            "Alerts" ||
                            (location.state?.selectedConnectorName !==
                              "Split" &&
                              field.type === "topic")) && (
                            <Tooltip
                              title={
                                field.name === "inputTopic"
                                  ? "Input Topic"
                                  : field.name === "outputTopic" ||
                                      field.name === "primaryOutputTopic"
                                    ? "Data will be sent to this topic"
                                    : "If the primary output is unavailable, data will be sent to this topic instead"
                              }
                              placement={"bottom"}
                            >
                              <InfoIcon2
                                style={{
                                  height: "1.2rem",
                                  width: "1rem",
                                  marginLeft: "0.2rem",
                                }}
                                className="info-icon-2"
                              />
                            </Tooltip>
                          )}
                        </Label>
                      )}

                      <CustomRightDropdown
                        // isShowRedBorder={isReadTopic
                        // ? selectedMainTopic && kafkaLastMessage
                        // : jsonValue &&(field.name === "inputTopic" ? !selectedInputTopic : (field.name === "outputTopic" ||
                        //   field.name === "primaryOutputTopic") ? !selectedPrimaryOutputTopic : !selectedSecondaryOutputTopic)}

                        isShowCreateTopic={true}
                        dropdownItems={allTopics}
                        heightValue={"2.8rem"}
                        selectedItem={allTopics.find(
                          (item) =>
                            item ===
                            (field.name === "inputTopic"
                              ? selectedInputTopic
                              : field.name === "outputTopic" ||
                                  field.name === "primaryOutputTopic"
                                ? selectedPrimaryOutputTopic
                                : selectedSecondaryOutputTopic),
                        )}
                        placeholder={"Search for Topics"}
                        onSelect={(item) => {
                          if (item === "newTopic") {
                            setNewTopicModalMapper((prev) => {
                              let tempMap = { ...prev, [index]: true };
                              return tempMap;
                            });
                          } else {
                            let inputTopic = selectedInputTopic;
                            let primaryOutputTopic = selectedPrimaryOutputTopic;
                            let secondaryOutputTopic =
                              selectedSecondaryOutputTopic;

                            if (field.name === "inputTopic") {
                              inputTopic = item;
                              // setSelectedInputTopic(item);
                            } else if (
                              location.state?.selectedConnectorName === "Alerts"
                                ? field.name === "outputTopic"
                                : field.name === "primaryOutputTopic"
                            ) {
                              primaryOutputTopic = item;
                              // setSelectedPrimaryOutputTopic(item);
                            } else {
                              secondaryOutputTopic = item;
                              // setSelectedSecondaryOutputTopic(item);
                            }

                            let isNoError = true;
                            // Check if all selected values are the same
                            if (
                              location.state?.selectedConnectorName ===
                                "Alerts" &&
                              inputTopic &&
                              primaryOutputTopic
                            ) {
                              if (
                                inputTopic === primaryOutputTopic ||
                                inputTopic === secondaryOutputTopic ||
                                primaryOutputTopic === secondaryOutputTopic
                              ) {
                                isNoError = false;
                                showMessage(
                                  "The primary and secondary output topics must be different. Please update your selection.",
                                );
                              }
                            } else if (
                              location.state?.selectedConnectorName === "Split"
                            ) {
                              if (
                                inputTopic &&
                                primaryOutputTopic &&
                                secondaryOutputTopic
                              ) {
                                if (
                                  inputTopic === primaryOutputTopic ||
                                  inputTopic === secondaryOutputTopic ||
                                  primaryOutputTopic === secondaryOutputTopic
                                ) {
                                  isNoError = false;
                                  showMessage(
                                    "The primary and secondary output topics must be different. Please update your selection.",
                                  );
                                }
                              } else {
                                if (
                                  inputTopic === primaryOutputTopic &&
                                  inputTopic &&
                                  primaryOutputTopic
                                ) {
                                  isNoError = false;
                                  showMessage(
                                    "The primary and secondary output topics must be different. Please update your selection.",
                                  );
                                } else if (
                                  inputTopic === secondaryOutputTopic &&
                                  inputTopic &&
                                  secondaryOutputTopic
                                ) {
                                  isNoError = false;
                                  showMessage(
                                    "The primary and secondary output topics must be different. Please update your selection.",
                                  );
                                } else if (
                                  primaryOutputTopic === secondaryOutputTopic &&
                                  primaryOutputTopic &&
                                  secondaryOutputTopic
                                ) {
                                  isNoError = false;
                                  showMessage(
                                    "The primary and secondary output topics must be different. Please update your selection.",
                                  );
                                }
                              }
                            }

                            if (isNoError) {
                              setSelectedInputTopic(inputTopic);
                              setSelectedPrimaryOutputTopic(primaryOutputTopic);
                              setSelectedSecondaryOutputTopic(
                                secondaryOutputTopic,
                              );
                            }
                          }
                        }}
                        nameFunction={(item) => item}
                        filterFunction={(arr, text) =>
                          arr.filter((item) =>
                            item.toLowerCase().includes(text.toLowerCase()),
                          )
                        }
                        noMargin={true}
                        disable={
                          isReadTopic
                            ? !selectedMainTopic || !kafkaLastMessage
                            : !jsonValue
                        }
                      />
                      <GenericModal
                        onClose={() =>
                          setNewTopicModalMapper((prev) => {
                            let tempMap = { ...prev, [index]: false };
                            return tempMap;
                          })
                        }
                        show={newTopicModalMapper[index] || false}
                        title={"Create a New Topic"}
                      >
                        <StyledInput
                          placeholder="Add a New Topic Name"
                          value={newTopicNamesMapper[index] || ""}
                          onChange={(e) =>
                            setNewTopicNamesMapper((prev) => {
                              let tempNewTopicMap = {
                                ...prev,
                                [index]: e.target.value,
                              };
                              return tempNewTopicMap;
                            })
                          }
                        />
                        <WarningText>
                          <img
                            src={warning_icon}
                            alt="warning_icon"
                            style={{ marginRight: "0.347rem" }}
                          />
                          A New Topic with this name will be created and
                          available for use
                        </WarningText>
                        <ModalButtonContainer>
                          <StyledButtonsecondary
                            style={{ width: "14.167rem" }}
                            onClick={() =>
                              setNewTopicModalMapper((prev) => {
                                let tempMap = { ...prev, [index]: false };
                                return tempMap;
                              })
                            }
                          >
                            Cancel
                          </StyledButtonsecondary>
                          <StyledButtonPrimary
                            style={{ width: "14.167rem" }}
                            disabled={!newTopicNamesMapper[index]?.length}
                            onClick={() =>
                              createNewTopic(
                                index,
                                newTopicNamesMapper[index],
                                field.name,
                              )
                            }
                          >
                            Create Topic
                          </StyledButtonPrimary>
                        </ModalButtonContainer>
                      </GenericModal>
                    </>
                  );
                } else if (field.type === "number") {
                  return (
                    <>
                      <CustomRow>
                        {(
                          isReadTopic
                            ? !selectedMainTopic || !kafkaLastMessage
                            : !jsonValue
                        ) ? (
                          <Label
                            style={{
                              marginTop: "1rem",
                              color: "#777777",
                            }}
                          >
                            {field.label} {field.isRequired && " *"}
                          </Label>
                        ) : (
                          <Label style={{ marginTop: "1rem" }}>
                            {field.label} {field.isRequired && " *"}
                          </Label>
                        )}
                        <Column
                          xs={9}
                          style={{
                            padding: "0 1rem 0 0.4rem",
                          }}
                        >
                          <Slider
                            minValue={0}
                            maxValue={1800}
                            markInterval={300}
                            sliderValue={expirationOffset}
                            setSliderValue={setExpirationOffset}
                            // markLabelFormatterFunction={(value) =>
                            //   expirationOffset
                            // }
                            isFromUtilities={false}
                            isDisabled={
                              isReadTopic
                                ? !selectedMainTopic || !kafkaLastMessage
                                : !jsonValue
                            }
                          />
                        </Column>
                        <Column xs={2.6}>
                          <InputFieldContainer>
                            <InputField
                              type="text"
                              value={expirationOffset}
                              onChange={handleChange}
                              placeholder="Enter value"
                              disabled={
                                isReadTopic
                                  ? !selectedMainTopic || !kafkaLastMessage
                                  : !jsonValue
                              }
                              style={{
                                color: (
                                  isReadTopic
                                    ? !selectedMainTopic || !kafkaLastMessage
                                    : !jsonValue
                                )
                                  ? "#777777"
                                  : "#fafafa",
                                cursor: (
                                  isReadTopic
                                    ? !selectedMainTopic || !kafkaLastMessage
                                    : !jsonValue
                                )
                                  ? "not-allowed"
                                  : "auto",
                              }}
                            />
                            <ArrowContainer>
                              <Arrow
                                onClick={
                                  (
                                    isReadTopic
                                      ? !selectedMainTopic || !kafkaLastMessage
                                      : !jsonValue
                                  )
                                    ? undefined
                                    : handleIncrement
                                }
                              >
                                <img
                                  src={up_icon}
                                  style={{ height: "1rem", width: "1rem" }}
                                />
                              </Arrow>
                              <Arrow
                                onClick={
                                  (
                                    isReadTopic
                                      ? !selectedMainTopic || !kafkaLastMessage
                                      : !jsonValue
                                  )
                                    ? undefined
                                    : handleDecrement
                                }
                              >
                                <img
                                  src={down_icon}
                                  style={{ height: "1rem", width: "1rem" }}
                                />
                              </Arrow>
                            </ArrowContainer>
                          </InputFieldContainer>
                        </Column>
                      </CustomRow>
                    </>
                  );
                } else if (field.type === "text") {
                  return (
                    <ValueTextField>
                      {(
                        isReadTopic
                          ? !selectedMainTopic || !kafkaLastMessage
                          : !jsonValue
                      ) ? (
                        <Label style={{ marginTop: "1rem", color: "#777777" }}>
                          {field.label} {field.isRequired && " *"}
                        </Label>
                      ) : (
                        <Label style={{ marginTop: "1rem" }}>
                          {field.label} {field.isRequired && " *"}
                        </Label>
                      )}

                      <TitleTextField
                        style={{
                          color: isReadTopic
                            ? !selectedMainTopic || !kafkaLastMessage
                            : !jsonValue
                              ? "#4B4B4B"
                              : "#F6F6F6",
                        }}
                        // isShowRedBorder={isReadTopic
                        // ? selectedMainTopic && kafkaLastMessage
                        // : jsonValue &&(!title)}
                        type="text"
                        placeholder={field.placeHolder}
                        value={title}
                        onChange={(e) => {
                          if (
                            /^[a-z0-9][a-z0-9.-]*$/.test(
                              e.target.value.toLowerCase(),
                            ) ||
                            e.target.value === ""
                          ) {
                            setTitle(e.target.value.toLowerCase());
                          } else {
                            showMessage(
                              "Only lowercase alphanumeric characters along with [-.] are allowed and cannot start with [-.]",
                              "error",
                            );
                            return;
                          }
                        }}
                        disabled={
                          isReadTopic
                            ? !selectedMainTopic || !kafkaLastMessage
                            : !jsonValue
                        }
                      />
                    </ValueTextField>
                  );
                } else if (field.type === "groupBy") {
                  return (
                    <>
                      {(
                        isReadTopic
                          ? !selectedMainTopic || !kafkaLastMessage
                          : !jsonValue
                      ) ? (
                        <Label style={{ marginTop: "1rem", color: "#777777" }}>
                          {field.label} {field.isRequired && " *"}
                        </Label>
                      ) : (
                        <Label style={{ marginTop: "1rem" }}>
                          {field.label} {field.isRequired && " *"}
                        </Label>
                      )}
                      <CustomRightDropdown
                        isShowRedBorder={jsonValue && isGroupError}
                        isShowCreateTopic={false}
                        dropdownItems={jsonOnlyKeys}
                        selectedItem={jsonOnlyKeys.find(
                          (item) => item === groupBy,
                        )}
                        placeholder={field.description}
                        onSelect={(item) => {
                          setGroupBy(item);
                        }}
                        nameFunction={(item) => item}
                        filterFunction={(arr, text) =>
                          arr.filter((item) =>
                            item.toLowerCase().includes(text.toLowerCase()),
                          )
                        }
                        noMargin={true}
                        disable={
                          isReadTopic
                            ? !selectedMainTopic || !kafkaLastMessage
                            : !jsonValue
                        }
                      />
                    </>
                  );
                }
              })}
            </StreamDetailsContainer>
          )}
        </Column>

        {/******************************************************** configuration section **************************************************************/}

        <Column xs={7.95} style={{ height: "36rem" }}>
          <ConfigurationContainer>
            <ConfigurationHeader>
              <CustomRow>
                <AddGroup>
                  <StreamIcon>
                    <img
                      style={{ height: "1.67rem", width: "1.67rem" }}
                      src={Icon_strean}
                    />
                  </StreamIcon>
                  <ConfigurationHeading>Configurations</ConfigurationHeading>
                </AddGroup>
                <AddGroup
                  style={{
                    cursor: "pointer",

                    pointerEvents: isConfigurationOpen ? "auto" : "none",
                  }}
                  onClick={() => {
                    if (isConfigurationOpen && handleAddParent) {
                      handleAddParent();
                    }
                  }}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state to true
                  onMouseLeave={() => setIsHovered(false)} // Set hover state to false
                >
                  {isHovered ? (
                    <AddIcon2
                      style={{
                        height: "1.4rem",
                        width: "1.4rem",
                        opacity: isConfigurationOpen ? "" : "40%",
                      }}
                    />
                  ) : (
                    <AddIcon
                      style={{
                        height: "1.4rem",
                        width: "1.4rem",
                        opacity: isConfigurationOpen ? "" : "40%",
                      }}
                    />
                  )}
                  <AddGroupText
                    style={{
                      color: isConfigurationOpen
                        ? isHovered
                          ? "#F6F6F6"
                          : "#47ccd6"
                        : "#277277",
                      cursor: isConfigurationOpen ? "pointer" : "not-allowed",
                    }}
                    onMouseEnter={() => setIsHovered(true)} // Set hover state to true
                    onMouseLeave={() => setIsHovered(false)} // Set hover state to false
                  >
                    Add Group
                  </AddGroupText>
                </AddGroup>
              </CustomRow>
              <CustomRow
                style={{
                  justifyContent: "normal",
                  display: "flex",
                  marginTop: "1rem",
                }}
              >
                <PreviewContainerForRules>
                  <PreviewText>
                    <Tooltip
                      title={
                        <>
                          Rules and Groups are
                          <br />
                          applied following BODMAS
                        </>
                      }
                      placement={"bottom"}
                    >
                      <InfoIcon
                        style={{ height: "1.4rem", width: "1.4rem" }}
                        className="info-icon"
                      />
                    </Tooltip>
                    Preview
                  </PreviewText>
                  <Pipe />
                  {/* {!isConfigurationOpen ? ( */}
                  <TextField
                    type="text"
                    placeholder=""
                    value={expressionText}
                  />
                  {/* ) : (
                    <Expression>
                      <div>{expressionText}</div>
                    </Expression>
                  )} */}
                </PreviewContainerForRules>
              </CustomRow>
            </ConfigurationHeader>
            <HorizontalLine />
            <Configuration>
              {isConfigurationOpen && !isJsonModalOpen ? (
                <ReactFlowProviderContent
                  key={fieldOptionValues}
                  handleLogic={handleLogic}
                  previewFunction={previewFunction}
                  handlePreview={handlePreview}
                  kafkaLastMessage={kafkaLastMessage}
                  isReadTopic={isReadTopic}
                  jsonValue={jsonValue}
                  setRulesError={setRulesError}
                  showMessage={showMessage}
                  onAddParent={(fn) => setHandleAddParent(() => fn)}
                  handleGroupsAndRules={handleGroupsAndRules}
                  groupsAndRules={groupsAndRules}
                  connectionsVal={connectionsVal}
                  setMissedRulesTemp={setMissedRulesTemp}
                  fieldOptionValues={fieldOptionValues}
                  setisConfigurationOpen={setisConfigurationOpen}
                />
              ) : (
                <>
                  <CustomRow>
                    <div className="EmptyState">
                      <EmptystateHeader>Build your Rules</EmptystateHeader>
                      <EmptystateText>
                        You currently have no rules added. Click on the button
                        below to get started!
                      </EmptystateText>
                    </div>
                  </CustomRow>
                  <CustomRow>
                    <Column xs={4}>
                      <StyledButtonPrimary
                        onClick={handleConfiguration}
                        style={{
                          width: "14.167rem",
                          marginLeft: "3.89rem",
                          marginBottom: "11rem",
                          backgroundColor:
                            !isFieldsLoading &&
                            (isReadTopic
                              ? isValidJsonDataCheck(kafkaLastMessage)
                              : isValidJsonDataCheck(jsonValue))
                              ? "#47CCD6"
                              : "#777777",
                        }}
                      >
                        Add Rule
                      </StyledButtonPrimary>
                    </Column>

                    <Column xs={8}>
                      <div className="emptystate">
                        <img
                          style={{
                            width: "41,67rem",
                            height: "16.67rem",
                            // paddingBottom: "2rem",
                          }}
                          src={StreamEmptyState}
                          alt="emptystate"
                        />
                      </div>
                    </Column>
                  </CustomRow>
                </>
              )}
            </Configuration>
          </ConfigurationContainer>
        </Column>
      </CustomRow>
      <Footer>
        <CustomRow
          style={{
            marginLeft: "67.2rem",
            display: "flex",
          }}
        >
          <StyledButtonsecondary
            onClick={goBackClickHandler}
            style={{ width: "14.167rem" }}
          >
            Go Back
          </StyledButtonsecondary>
          <StyledButtonPrimary
            style={{ width: "14.167rem" }}
            disabled={
              (isReadTopic
                ? !selectedMainTopic || !kafkaLastMessage
                : !jsonValue) ||
              (location.state?.selectedConnectorName === "Alerts"
                ? !selectedInputTopic ||
                  !selectedPrimaryOutputTopic ||
                  expirationOffset < 0 ||
                  expirationOffset > 1800 ||
                  isGroupError
                : !selectedInputTopic ||
                  !selectedPrimaryOutputTopic ||
                  !selectedSecondaryOutputTopic) ||
              !title ||
              isFieldsLoading ||
              isAllTopicsLoading ||
              rulesError
            }
            onClick={handleBuildAction}
          >
            {isLoading ? (
              <CustomRow
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner2 animation="border" style={{ color: "#FFF" }} />
              </CustomRow>
            ) : (
              "Deploy Utility"
            )}
          </StyledButtonPrimary>
        </CustomRow>
      </Footer>
    </div>
  );
};

UtilityConfigurationPage.propTypes = {
  deviceData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    }),
  ),
  configs: PropTypes.object.isRequired,
  setConfigs: PropTypes.func.isRequired,
  setConfigRules: PropTypes.func.isRequired,
  allTopics: PropTypes.array.isRequired,
  setAllTopics: PropTypes.func.isRequired,
  setPreviewData: PropTypes.func.isRequired,
  connectorVersionList: PropTypes.array,
  setSelectedConnectorVersion: PropTypes.func,
  selectedConnectorVersion: PropTypes.string,
};
