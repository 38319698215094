import styled from "styled-components";
import PropTypes from "prop-types";
import EmptyState from "../../assets/images/empty_state_no_config_template.svg";

const MoreInfoCard = styled.div`
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  height: 21.877rem;
  overflow: auto;
  margin-left: 1.112rem;
  padding: 1.67rem;
  font-family: "Articulat CF Medium";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  color: white;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0.8rem;
`;

const Subheading = styled.p`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  color: white;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 0rem;
  color: rgb(92, 90, 90);
`;

const MoreInfoPart = (props) => {
  const { content } = props;

  const fallbackContent = (
    <>
      <img
        src={EmptyState}
        alt="Empty State"
        style={{
          width: "19.7916rem",
          height: "12.9305rem",
        }}
      />
      <Title>All quiet on this Connector</Title>
      <Subheading>No additional details are available for this</Subheading>
      <Subheading>connector right now</Subheading>
    </>
  );

  return (
    <MoreInfoCard>
      {content ? (
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          style={{ marginTop: "70rem" }}
        />
      ) : (
        fallbackContent
      )}
    </MoreInfoCard>
  );
};

MoreInfoPart.propTypes = {
  content: PropTypes.string,
};

export default MoreInfoPart;
