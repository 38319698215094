import styled from "styled-components";
import PlusCircleIcon from "../../../assets/images/add_circle.svg";

const CardContainer = styled.div`
  width: 256px;
  height: 126px;
  max-width: 256px;
  max-height: 126px;
  border-radius: 12px;
  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23777' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  box-shadow: 0px 0px 40px 0px #00000029;
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PipelineBlankCard = () => {
  return (
    <CardContainer>
      <Icon src={PlusCircleIcon} alt="Add Icon" />
      <Text style={{ color: "#CCCCCC", fontSize: "16px" }}>
        Add a Connector
      </Text>
    </CardContainer>
  );
};

export default PipelineBlankCard;
