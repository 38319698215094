// External imports
import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "../../scss/PreBuiltConnectUpdated.scss";
import ConnectorIcon from "../../assets/images/Connectors.svg";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import TitleSlice from "./TitleSlice";

// Styled components
const Container = styled.div`
  display: flex;
  background-color: #2f2f2f;
  border-radius: 0.84rem;
  color: #fafafa;
  font-family: "Articulat CF Medium";
  font-weight: bold;
`;

const LeftContent = styled.div`
  display: flex;
  width: 10%;
  align-items: center;
  justify-content: center;
`;

const RightContent = styled.div`
  width: 90%;
  padding-top: 1.1rem;
`;

const Line = styled.div`
  width: 0.27778rem;
  height: 9rem;
  background-color: #c7a6f1;
  border-radius: 0.35rem;
`;

const ConnectorNameArea = styled.div`
  display: flex;
  align-items: center;
`;

const LogoArea = styled.div`
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 0.35rem;
  background-color: #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CategoryDiv = styled.div`
  display: flex;
  margin-top: 0.97rem;
`;

const CategoryArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryTitle = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.84rem;
  line-height: 1.083rem;
  color: #777777;
`;

const CategoryContent = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.84rem;
  line-height: 1.083rem;
  color: #c7a6f1;
  letter-spacing: 0px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.div`
  font-family: "Articulat CF Normal";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.84rem;
  margin-top: 0.97rem;
  margin-bottom: 0.84rem;
  margin-right: 0.55rem;
  height: 2.1rem;
  line-height: 1.1rem;
`;

// const getCategoryColor = (category) => {
//   switch (category) {
//     case "Stream Connector":
//       return "#6DC89B";
//     case "Store Connector":
//       return "#B5E3CE";
//     case "Application":
//       return "#EB6F95";
//     case "Telematics Device":
//       return "#F7C0D5";
//     default:
//       return "#EB6F95";
//   }
// };

/**
 * Utility Connector component renders a card displaying information about Utilities.
 *
 * @param {string} props.title - The title of the connector.
 * @param {string} props.category - The category of the connector.
 * @param {string} props.type - The type of the connector.
 * @param {string} props.description - The description of the connector.
 * @param {} icon - The link of icon of that particular type connector.
 *
 * @returns {JSX.Element} The rendered Utility component.
 */

const UtilityCard = ({
  title = "NA",
  category = "NA",
  type = "NA",
  description = "NA",
  icon,
  id,
  handleUtilityConnectorClick,
  isClickable,
}) => {
  const { showMessage } = useContext(SnackbarContext);

  return (
    <Container
      onClick={() => {
        handleUtilityConnectorClick();
        // if (isClickable) {
        //   handleUtilityConnectorClick();
        //   // title,
        //   // type || "INPUT",
        //   // id,
        //   // category,
        //   // description,
        // } else {
        //   showMessage("You don't have permission to perform this operation");
        // }
      }}
    >
      <LeftContent>
        <Line />
      </LeftContent>
      <RightContent>
        <ConnectorNameArea>
          <LogoArea>
            <img
              src={icon || ConnectorIcon}
              alt=""
              style={{ height: "1.39rem", width: "1.39rem" }}
            />
          </LogoArea>
          <TitleSlice title={title} />
        </ConnectorNameArea>
        <CategoryDiv>
          <CategoryArea style={{ width: "60%" }}>
            <CategoryTitle>Category</CategoryTitle>
            <CategoryContent>{category}</CategoryContent>
          </CategoryArea>
          <CategoryArea style={{ width: "38%", marginRight: "2.225rem" }}>
            <CategoryTitle>Type</CategoryTitle>
            <CategoryContent>{type}</CategoryContent>
          </CategoryArea>
        </CategoryDiv>
        <Description>{description}</Description>
      </RightContent>
    </Container>
  );
};

// Prop types
UtilityCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  handlePreConnectorClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,

  // Optional props
  icon: PropTypes.string,
  isClickable: PropTypes.bool,
};

export default UtilityCard;
